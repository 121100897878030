import { Envelope, Phone, User } from "@phosphor-icons/react"
import { openConfirmDialog } from "@vesatogo/grass-confirm-modal"
import { FormInput, Toast } from "@vesatogo/grass-core"
import classNames from "classnames"
import dayjs from "dayjs"
import { omit, pick } from "lodash-es"
import toast from "react-hot-toast"
import AddressForm from "~/components/Registration/AddressForm"
import { CertificateCard } from "~/components/Registration/CertificateCard"
import MandiFormSections from "~/components/Registration/FormSections/Mandi/MandiFormSections"
import { SectionCard } from "~/components/Registration/SectionCard"
import { FileUpload } from "~/components/dropzone/FileUploader/FileUploader"
import Codenames from "~/constants/Codenames"
import { MandiSectionCommonType } from "~/constants/registration/MandiRegistrationFormSections"
import {
  Address_Constraint,
  Address_Update_Column,
  Auth_Group_Constraint,
  Auth_Group_Update_Column,
  Base_User_Constraint,
  Base_User_Groups_Constraint,
  Base_User_Groups_Update_Column,
  Base_User_Update_Column,
  Business_Constraint,
  Business_Update_Column,
  Document_Constraint,
  Document_Update_Column,
  Market_Constraint,
  Market_Update_Column,
  Media_Constraint,
  Media_Update_Column,
  useAllGroupsQuery,
  useDeleteAttachmentMutation,
  useGetDocumentKindsQuery,
  useInsertBaseUserMutation,
  useInsertMarketMutation,
} from "~/generated/graphql"
import { getFileUploadProps } from "~/utils/form-helpers"

type POCDetailProps = MandiSectionCommonType & {}
export const POCDetail = ({
  form,
  entity = "farmer",
  refetch,
  next,
  setActiveSection,
}) => {
  const [{ data: dData }] = useGetDocumentKindsQuery()
  const [{ fetching }, upsertMarket] = useInsertMarketMutation()
  const [, upsertBusiness] = useInsertBaseUserMutation()
  const [, deleteAttachment] = useDeleteAttachmentMutation()

  const [{ data: businessPOC }] = useAllGroupsQuery({
    variables: {
      where: {
        name: {
          _eq: Codenames.BusinessPOC,
        },
      },
    },
  })

  async function handleSubmit() {
    const poc = form.values?.poc
    try {
      // if (!areaPolygon) throw new Error("Please select a valid area polygon")
      // if (!form.values.area?.color?.id)
      //   throw new Error("Please select a valid color")
      const image = poc?.image?.[0]
      const pocData = {
        data: {
          id: poc?.id,
          email: poc?.email,
          number: poc?.number,
          first_name: poc?.first_name,
          middle_name: poc?.middle_name,
          last_name: poc?.last_name,
          username: poc?.number,
          date_of_birth: "1970-01-01",
          status: "active",
          gender: "",
          image: image?.url
            ? {
                data: {
                  ...pick(image, "key", "is_encrypted", "kind", "url", "id"),
                },
                on_conflict: {
                  update_columns: [
                    Media_Update_Column.Url,
                    Media_Update_Column.Kind,
                    Media_Update_Column.IsEncrypted,
                  ],
                  constraint: Media_Constraint.MediaPkey,
                },
              }
            : undefined,
          documents: {
            data: [
              poc?.aadhar?.id_number && {
                id: poc?.aadhar?.id,
                id_number: poc?.aadhar?.id_number,
                issued_on: poc?.aadhar?.issued_on
                  ? dayjs(poc?.aadhar?.issued_on).format("YYYY-MM-DD")
                  : "01-01-1970",
                kind_id: dData?.document_kind?.find(
                  dc => dc.codename === Codenames.AADHAR
                )?.id,
                attachments: {
                  data: poc?.aadhar?.attachments?.map(attachment => ({
                    ...pick(
                      attachment,
                      "key",
                      "is_encrypted",
                      "kind",
                      "url",
                      "id"
                    ),
                  })),
                  on_conflict: {
                    update_columns: [
                      Media_Update_Column.Url,
                      Media_Update_Column.IsEncrypted,
                      Media_Update_Column.Kind,
                      Media_Update_Column.Id,
                      Media_Update_Column.Key,
                      Media_Update_Column.DocumentId,
                    ],
                    constraint: Media_Constraint.MediaPkey,
                  },
                },
              },
              poc?.pan?.id_number && {
                id: poc?.pan?.id,
                id_number: poc?.pan?.id_number?.toUpperCase(),
                issued_on: poc?.aadhar?.issued_on
                  ? dayjs(poc?.aadhar?.issued_on).format("YYYY-MM-DD")
                  : "01-01-1970",
                kind_id: dData?.document_kind?.find(
                  dc => dc.codename === Codenames.PAN_CARD
                )?.id,
                attachments: {
                  data: poc?.pan?.attachments?.map(attachment => ({
                    ...pick(
                      attachment,
                      "key",
                      "is_encrypted",
                      "kind",
                      "url",
                      "id"
                    ),
                  })),
                  on_conflict: {
                    update_columns: [
                      Media_Update_Column.Url,
                      Media_Update_Column.IsEncrypted,
                      Media_Update_Column.Kind,
                      Media_Update_Column.Id,
                      Media_Update_Column.Key,
                      Media_Update_Column.DocumentId,
                    ],
                    constraint: Media_Constraint.MediaPkey,
                  },
                },
              },
            ].filter(d => d),
            on_conflict: {
              update_columns: [Document_Update_Column.Id],
              constraint: Document_Constraint.DocumentPkey,
            },
          },
          address: {
            data: {
              ...omit(form.values?.poc?.address, ["__typename"]),
              name: "",
            },
            on_conflict: {
              update_columns: Object.values(Address_Update_Column).filter(
                a => a !== Address_Update_Column.Id
              ),
              constraint: Address_Constraint.AddressPkey,
            },
          },
        },
        on_conflict: {
          update_columns: [
            Base_User_Update_Column.FirstName,
            Base_User_Update_Column.LastName,
            Base_User_Update_Column.MiddleName,
            Base_User_Update_Column.Email,
            Base_User_Update_Column.Number,
            Base_User_Update_Column.AddressId,
            Base_User_Update_Column.ImageId,
          ],
          constraint: Base_User_Constraint.BaseUserPkey,
        },
      }
      if (entity === "business") {
        const basicDetails = form?.values?.basic
        const isBusinessGroup = poc?.groups?.filter(group => {
          return group?.group_id === businessPOC?.auth_group?.[0]?.id
        })
        const { data, error } = await upsertBusiness({
          baseUserIn: {
            id: form?.values?.id,
            first_name: basicDetails?.first_name,
            last_name: basicDetails?.last_name,
            email: basicDetails?.email || "",
            number: basicDetails?.number,
            status: "active",
            gender: basicDetails?.gender,
            date_of_birth: basicDetails?.date_of_birth || "01-01-1997",
            username: basicDetails?.number,
            businesses_owned: {
              data: [
                {
                  id: basicDetails?.business_id,
                  name: basicDetails?.business_name,
                  email: basicDetails?.business?.email || "",
                  kind_id: basicDetails?.business_type.id,
                  number: basicDetails?.business_number,
                  poc: {
                    data: {
                      ...pocData?.data,
                      groups: {
                        data:
                          [
                            ...(poc?.groups?.map(group => {
                              return {
                                id: group?.id,
                                group: {
                                  data: {
                                    id: group?.group?.id,
                                    name: group?.group?.name,
                                  },
                                  on_conflict: {
                                    constraint:
                                      Auth_Group_Constraint.AuthGroupPkey,
                                    update_columns: [
                                      Auth_Group_Update_Column.Id,
                                      Auth_Group_Update_Column.Name,
                                    ],
                                  },
                                },
                              }
                            }) || []),
                            (!isBusinessGroup || !poc?.groups?.length) && {
                              group_id: businessPOC?.auth_group?.[0]?.id,
                            },
                          ].filter?.(d => d) || [],
                        on_conflict: {
                          constraint:
                            Base_User_Groups_Constraint.BaseUserGroupsPkey,
                          update_columns: [
                            Base_User_Groups_Update_Column.Id,
                            Base_User_Groups_Update_Column.GroupId,
                          ],
                        },
                      },
                    },
                    on_conflict: {
                      ...pocData?.on_conflict,
                    },
                  },
                },
              ],
              on_conflict: {
                constraint: Business_Constraint.BusinessPkey,
                update_columns: [
                  Business_Update_Column.Id,
                  Business_Update_Column.PocId,
                ],
              },
            },
          },
          on_conflict: {
            constraint: Base_User_Constraint.BaseUserPkey,
            update_columns: [Base_User_Update_Column.Id],
          },
        })
        if (data?.insert_base_user_one?.businesses_owned?.[0]?.poc?.id) {
          toast.custom(<Toast title="POC Saved Successfully" />)
          next && setActiveSection(next.id)
        } else {
          if (error) {
            return toast.custom(
              <Toast
                intent="danger"
                title={"Something went wrong! " + error?.message}
              />
            )
          }
        }
      } else {
        const { data, error } = await upsertMarket({
          object: {
            id: form.values?.id,
            name: form.values?.basic?.name,
            kind_id: form?.values?.basic?.kind?.id,
            is_active: true,
            description: "",
            area_color: "",
            address_id: form?.values?.basic?.address?.id,
            poc: pocData,
          },
          on_conflict: {
            update_columns: [Market_Update_Column.PocId],
            constraint: Market_Constraint.MarketPkey,
          },
        })
        if (data) {
          return toast.custom(<Toast title="POC Saved Successfully" />)
        } else {
          if (error) {
            return toast.custom(
              <Toast
                intent="danger"
                title={"Something went wrong! " + error?.message}
              />
            )
          }
        }
      }
    } catch (error: any) {
      toast.custom(<Toast intent="danger" title={error.message} />)
    }
  }
  return (
    <SectionCard
      cardClassName="min-h-[80vh] overflow-auto"
      icon={User}
      title={"POC Details - (optional)"}
      className="flex flex-col gap-10"
    >
      <AddressForm
        setFieldValue={form.setFieldValue}
        useNestedFieldSet
        showMap={true}
        address={form.values.poc?.address}
        compact={false}
        formClassName={classNames("w-full", "!grid-cols-3")}
        noteClassName={"col-span-3 text-sm !display-none"}
        getInputProps={form.getInputProps}
        addressKey={`poc.address`}
        setValues={form.setFieldValue}
        mapClassName="!w-full"
        mapWidth="full"
        className="flex-col"
      >
        {/* <div className=""> */}
        <div className="row-span-6">
          <FileUpload
            {...getFileUploadProps(form, "poc.image", 1)}
            appName="mandi"
          />
        </div>
        {/* <div className="grid grid-cols-2 gap-4 h-max w-full"> */}
        <FormInput
          data-testid="firstName"
          required
          label="First Name"
          {...form.getInputProps("poc.first_name")}
        />
        <FormInput
          data-testid="middleName"
          required
          label="Middle Name"
          {...form.getInputProps("poc.middle_name")}
        />
        <FormInput
          data-testid="lastName"
          required
          label="Last Name"
          {...form.getInputProps("poc.last_name")}
        />
        <FormInput
          data-testid="inputMobileNo"
          leftElement={<Phone />}
          required
          label="Mobile Number"
          maxLength={10}
          {...form.getInputProps("poc.number")}
        />
        <FormInput
          data-testid="email"
          leftElement={<Envelope />}
          required
          label="Email"
          {...form.getInputProps("poc.email")}
        />
        {/* </div> */}
        {/* </div> */}
      </AddressForm>

      <div className="flex flex-col gap-4">
        <CertificateCard title={"Aadhar Card"} className="flex flex-col gap-4">
          <div className="flex gap-4">
            <div>
              <FileUpload
                {...getFileUploadProps(form, "poc.aadhar.attachments", 2, true)}
                appName="mandi"
                onDelete={e => {
                  const onDelete = async () => {
                    const { error } = await deleteAttachment({
                      id: e.id,
                    })
                    if (error) {
                      return toast.custom(
                        <Toast
                          intent="danger"
                          title={"Something went wrong! " + error.message}
                        />
                      )
                    } else {
                      refetch?.({
                        requestPolicy: "network-only",
                      })
                      toast.custom(<Toast title={"Certificate deleted!"} />)
                    }
                  }
                  if (e.id) {
                    openConfirmDialog({
                      isOpen: true,
                      onSubmit: () => onDelete(),
                      confirmationButtonText: "Delete",
                      message:
                        "Are you sure you want to delete this attachment?",
                    })
                  }
                  refetch?.({
                    requestPolicy: "network-only",
                  })
                }}
              />

              <div className="italic text-sm text-center text-green-600 underline">
                Please add 1 front and 1 backside Photo
              </div>
            </div>
          </div>
          <div>
            <FormInput
              maxLength={12}
              className={"w-[50%]"}
              label="Aadhar Card no."
              required
              {...form.getInputProps("poc.aadhar.id_number")}
            ></FormInput>
          </div>
        </CertificateCard>
        <CertificateCard title={"PAN Card"} className="flex flex-col gap-4">
          <div className="flex gap-4">
            <div>
              <FileUpload
                {...getFileUploadProps(form, "poc.pan.attachments", 1, true)}
                appName="mandi"
                onDelete={e => {
                  const onDelete = async () => {
                    const { error } = await deleteAttachment({
                      id: e.id,
                    })
                    if (error) {
                      return toast.custom(
                        <Toast
                          intent="danger"
                          title={"Something went wrong! " + error.message}
                        />
                      )
                    } else {
                      refetch?.({
                        requestPolicy: "network-only",
                      })
                      toast.custom(<Toast title={"Certificate deleted!"} />)
                    }
                  }
                  if (e.id) {
                    openConfirmDialog({
                      isOpen: true,
                      onSubmit: () => onDelete(),
                      confirmationButtonText: "Delete",
                      message:
                        "Are you sure you want to delete this attachment?",
                    })
                  }
                  refetch?.({
                    requestPolicy: "network-only",
                  })
                }}
              />

              <div className="italic text-sm  text-green-600 underline">
                Please add Photo
              </div>
            </div>
          </div>
          <div>
            <FormInput
              label="PAN Card no."
              required
              className={"w-[50%] uppercase"}
              maxLength={10}
              inputProps={{
                style: {
                  textTransform: "uppercase",
                },
              }}
              {...form.getInputProps("poc.pan.id_number")}
            ></FormInput>
          </div>
        </CertificateCard>
        <MandiFormSections loading={fetching} onSubmit={handleSubmit} />
      </div>
    </SectionCard>
  )
}
