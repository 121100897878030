import { useForm } from "@mantine/form"
import { CheckCircle } from "@phosphor-icons/react"
import { Breadcrumbs } from "@vesatogo/grass-core"
import classNames from "classnames"
import { isArray } from "lodash-es"
import { useEffect, useMemo, useState } from "react"
import { Link, useParams } from "react-router-dom"
import VerifyAsset from "~/components/VerifyAsset"
import Codenames from "~/constants/Codenames"
import { FarmerRegistrationFormSections } from "~/constants/registration/FarmerRegistrationFormSections"
import { AppRoutes } from "~/constants/routes"
import {
  useBaseUserDetailQuery,
  useUpdateBaseUserMutation,
} from "~/generated/graphql"

export const FARMER_INITIAL_STATE = {
  id: null as any,
  is_verified: null as any,
  current_address: {},
  payment: {} as any,
  certificates: {
    pan: {
      number: "",
      photo: [],
    } as any,
    aadhar: {
      number: "",
      photo: [],
    } as any,
  },
  plots: [
    {
      address: {} as any,
      documents: [],
    },
  ] as any,
  basic: {
    photo: [],
  } as any,
}

export const FarmerDetails = () => {
  const { id } = useParams()
  const farmerId = Number(id) ? Number(id) : null
  const [activeSection, setActiveSection] = useState<any>()

  useEffect(() => {
    setActiveSection("basic")
  }, [])

  const form = useForm<Partial<typeof FARMER_INITIAL_STATE>>({
    initialValues: FARMER_INITIAL_STATE,
    validateInputOnChange: true,
    validateInputOnBlur: true,
    clearInputErrorOnChange: false,
  })

  const [{ data, fetching }, refetch] = useBaseUserDetailQuery({
    variables: {
      id: farmerId,
    },
    pause: !farmerId,
  })

  useEffect(() => {
    const pocAadhar = data?.base_user_by_pk?.documents?.find(
      doc => doc?.kind?.codename === Codenames.AADHAR
    )
    const pocPan = data?.base_user_by_pk?.documents?.find(
      doc => doc?.kind?.codename === Codenames.PAN_CARD
    )
    const farmer = data?.base_user_by_pk
    form.setValues({
      id: farmer?.id,
      is_verified: farmer?.is_verified,
      basic: {
        id: farmer?.id,
        first_name: farmer?.first_name,
        last_name: farmer?.last_name,
        middle_name: farmer?.middle_name,
        number: farmer?.number,
        full_name: farmer?.full_name,
        email: farmer?.email,
        gender: farmer?.gender,
        photo: farmer?.image ? [farmer?.image] : [],
        groups: farmer?.groups,
      },
      certificates: {
        aadhar:
          {
            id: pocAadhar?.id,
            id_number: pocAadhar?.id_number,
            kind: pocAadhar?.kind,
            attachments: pocAadhar?.attachments?.length
              ? pocAadhar?.attachments
              : [],
          } || {},
        pan:
          {
            id: pocPan?.id,
            id_number: pocPan?.id_number,
            kind: pocPan?.kind,
            attachments: pocPan?.attachments?.length ? pocPan?.attachments : [],
          } || {},
      },
      current_address: farmer?.address || ({} as any),
      payment: farmer?.payment_details?.map(payment_detail => {
        return {
          ...payment_detail,
          account_holder_name:
            payment_detail.account_holder_name || farmer.full_name,
          verify_account_number: payment_detail.account_number,
        }
      }),
      plots: farmer?.plots,
    })
  }, [data])

  const sectionsFilledMap = useMemo(() => {
    return FarmerRegistrationFormSections.reduce((prev, section) => {
      return {
        ...prev,
        [section.id as any]:
          section.id === "plots"
            ? true
            : section.id === "certificates"
            ? form.values?.[section?.id as any]?.aadhar?.id
              ? true
              : false
            : isArray(form.values?.[section?.id as any])
            ? form.values?.[section?.id as any]?.[0]?.id
            : form.values?.[section?.id as any]?.id || false,
      }
    }, {})
  }, [form.values])

  const allSectionsFilled = Object.values(sectionsFilledMap)?.every(e => e)
  return (
    <>
      <header className="bg-gray-100 shadow-sm p-3 border-b-gray-300 border-b flex justify-between">
        <Breadcrumbs
          items={[
            {
              text: "Farmer",
              link: AppRoutes.farmerRegistration,
              isActive: false,
            },
            {
              text: data?.base_user_by_pk?.full_name || "New",
              link: `${AppRoutes.farmerRegistration}/${id}`,
              isActive: true,
            },
          ]}
          linkRenderer={(link, item) => {
            return <Link to={link as any}>{item}</Link>
          }}
        />
      </header>
      <div className="p-4 ">
        <div className="max-w-6xl mx-auto p-4 flex gap-4 w-full">
          <div className="w-[20%] flex flex-col justify-between rounded-xl bg-white py-4 h-[80vh] overflow-auto">
            <div className="">
              {FarmerRegistrationFormSections?.map(section => {
                const isSectionFilled =
                  section.id === "certificates"
                    ? form.values?.[section?.id as any]?.aadhar?.id
                      ? true
                      : false
                    : isArray(form.values?.[section?.id as any])
                    ? form.values?.[section?.id as any]?.[0]?.id
                    : form.values?.[section?.id as any]?.id

                return (
                  <div
                    key={section?.id}
                    className={classNames(
                      "p-2 flex gag-2 items-center justify-between",
                      activeSection === section.id && "text-green bg-gray-200",
                      !form.values.id && section.id !== "basic"
                        ? "pointer-events-none text-gray-300"
                        : "cursor-pointer"
                    )}
                    onClick={() => {
                      setActiveSection(section.id)
                    }}
                  >
                    {section.title}{" "}
                    {isSectionFilled && (
                      <CheckCircle
                        className="text-green"
                        weight="fill"
                        size={20}
                      />
                    )}
                  </div>
                )
              })}
            </div>
          </div>
          <div className="w-[80%]">
            {FarmerRegistrationFormSections?.map((section, idx) => {
              const Component = section.component
              if (section.id === activeSection)
                return (
                  <Component
                    refetch={refetch}
                    form={form}
                    disabled={section.id !== "basic" && !form.values.id}
                    setActiveSection={setActiveSection}
                    entity="farmer"
                    next={FarmerRegistrationFormSections[idx + 1]}
                  />
                )
            })}
          </div>
        </div>
      </div>
      {allSectionsFilled && (
        <div className="bg-white p-3">
          <VerifyAsset
            id={form?.values?.id as any}
            is_verified={form?.values?.is_verified}
            useUpdateHook={useUpdateBaseUserMutation}
          />
        </div>
      )}
    </>
  )
}

export default FarmerDetails
