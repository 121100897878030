import { useForm, yupResolver } from "@mantine/form"
import {
  Button,
  Card,
  FormInput,
  FormQuerySelector,
  Modal,
  SpinnerOverlay,
  Toast,
} from "@vesatogo/grass-core"
import classNames from "classnames"
import { omit, pick } from "lodash-es"
import { useEffect } from "react"
import toast from "react-hot-toast"
import { useSearchParams } from "react-router-dom"
import AddressForm from "~/components/Registration/AddressForm"
import Codenames from "~/constants/Codenames"
import {
  Address_Constraint,
  Address_Update_Column,
  Auth_Group_Constraint,
  Auth_Group_Update_Column,
  Base_User_Constraint,
  Base_User_Groups_Constraint,
  Base_User_Groups_Update_Column,
  Base_User_Update_Column,
  Transport_Vehicle_Constraint,
  Transport_Vehicle_Update_Column,
  useAllGroupsQuery,
  useAllNonEmployeeUsersQuery,
  useBaseUserDetailQuery,
  useInsertVehicleMutation,
  useVehicleDetailsQuery,
} from "~/generated/graphql"
import { ownerModel } from "~/models/owner"

export type AddOwnerModalProps = {
  getInputProps?: any
  state?: any
  form?: any
  setState?: any
}
export const AddOwnerModal = () => {
  const [params, setParams] = useSearchParams()
  const vehicleId = params.get("vehicleId") || ""
  const formType = params?.get("form")
  const show = vehicleId !== "" && vehicleId !== "new" && formType === "owner"

  const form = useForm<any>({
    initialValues: {},
    validate: yupResolver(ownerModel),
    validateInputOnChange: true,
    validateInputOnBlur: true,
    clearInputErrorOnChange: true,
  })

  const state = form.values
  const setState = form.setValues

  function getInputProps(values: any) {
    return {
      ...form.getInputProps(values),
    }
  }

  const [{ data, fetching }] = useVehicleDetailsQuery({
    variables: {
      id: parseInt(vehicleId),
    },
    pause: !show,
  })

  const [{ data: ownerData }] = useBaseUserDetailQuery({
    variables: {
      id: state?.user_search?.id,
    },
    pause: !state?.user_search?.id,
  })

  const [{ data: vehicleOwnerGroup }] = useAllGroupsQuery({
    variables: {
      where: {
        name: {
          _eq: Codenames.VehicleOwnerGroup,
        },
      },
    },
  })

  const [{ fetching: insertFetching }, insertVehicle] =
    useInsertVehicleMutation()

  useEffect(() => {
    const owner =
      (state?.user_search?.id !== undefined && ownerData?.base_user_by_pk) ||
      data?.transport_vehicle_by_pk?.owner
    setState(val => ({
      ...val,
      first_name: owner?.first_name,
      last_name: owner?.last_name,
      address: owner?.address || ({} as any),
      number: owner?.number,
      username: owner?.username,
      email: owner?.email,
      registration_number: data?.transport_vehicle_by_pk?.registration_number,
      vehicle_type: {
        name: data?.transport_vehicle_by_pk?.vehicle_type?.name,
        category: data?.transport_vehicle_by_pk?.vehicle_type?.category,
      },
      status: owner?.status || "active",
      gender: owner?.gender,
      date_of_birth: owner?.date_of_birth,
      owner_id: owner?.id,
    }))
  }, [data, ownerData, state?.user_search])

  const onClose = () => {
    setParams({})
    form?.reset()
  }
  const onSubmit = async () => {
    const { errors, hasErrors } = form?.validate()
    if (hasErrors) {
      return toast.custom(
        <Toast
          intent="danger"
          title={"Please resolve all errors before proceeding!"}
        />
      )
    }
    const { data: insertData, error } = await insertVehicle({
      object: {
        ...omit(
          data?.transport_vehicle_by_pk,
          "fuel_type",
          "created_at",
          "vehicle_type",
          "status",
          "driver",
          "__typename"
        ),
        id: vehicleId ? vehicleId : undefined,
        vehicle_type_id: data?.transport_vehicle_by_pk?.vehicle_type?.id,
        fuel_type_id: data?.transport_vehicle_by_pk?.fuel_type?.id,
        loading_capacity: 0,
        passenger_capacity: 0,
        owner: {
          data: {
            ...pick(
              state,
              "username",
              "first_name",
              "last_name",
              "email",
              "number",
              "gender",
              "date_of_birth",
              "status"
            ),
            groups:
              data?.transport_vehicle_by_pk?.owner?.groups?.length !== 0
                ? {
                    data:
                      data?.transport_vehicle_by_pk?.owner?.groups?.map(
                        group => {
                          return {
                            id: group?.id,
                            group: {
                              data: {
                                id: group?.group?.id,
                                name: group?.group?.name,
                              },
                              on_conflict: {
                                constraint: Auth_Group_Constraint.AuthGroupPkey,
                                update_columns: [
                                  Auth_Group_Update_Column.Id,
                                  Auth_Group_Update_Column.Name,
                                ],
                              },
                            },
                          }
                        }
                      ) || [],
                    on_conflict: {
                      constraint:
                        Base_User_Groups_Constraint.BaseUserGroupsPkey,
                      update_columns: [
                        Base_User_Groups_Update_Column.Id,
                        Base_User_Groups_Update_Column.GroupId,
                      ],
                    },
                  }
                : vehicleOwnerGroup?.auth_group?.[0]?.id !== undefined
                ? {
                    data: [
                      {
                        group_id: vehicleOwnerGroup?.auth_group?.[0]?.id,
                      },
                    ],
                    on_conflict: {
                      constraint:
                        Base_User_Groups_Constraint.BaseUserGroupsPkey,
                      update_columns: [
                        Base_User_Groups_Update_Column.BaseuserId,
                        Base_User_Groups_Update_Column.GroupId,
                        Base_User_Groups_Update_Column.Id,
                      ],
                    },
                  }
                : undefined,
            username: state?.username || state?.number,
            gender: state?.gender || "Male",
            email: state?.email || "",
            date_of_birth: state?.date_of_birth || "01-01-1997",
            id: state?.owner_id,
            address: {
              data: {
                name: state?.address?.name || "address",
                ...omit(state?.address, "__typename"),
              },
              on_conflict: {
                constraint: Address_Constraint.AddressPkey,
                update_columns: [Address_Update_Column.Country],
              },
            },
          },
          on_conflict: {
            constraint: Base_User_Constraint.BaseUserPkey,
            update_columns: [
              Base_User_Update_Column.AddressId,
              Base_User_Update_Column.Username,
              Base_User_Update_Column.Id,
            ],
          },
        },
      },
      on_conflict: {
        constraint: Transport_Vehicle_Constraint.TransportVehiclePkey,
        update_columns: [
          Transport_Vehicle_Update_Column.Id,
          Transport_Vehicle_Update_Column.RegistrationNumber,
          Transport_Vehicle_Update_Column.ChassisNumber,
          Transport_Vehicle_Update_Column.DateBought,
          Transport_Vehicle_Update_Column.EstimatedAverage,
          Transport_Vehicle_Update_Column.FuelTankCapacity,
          Transport_Vehicle_Update_Column.FuelTypeId,
          Transport_Vehicle_Update_Column.LoadingCapacity,
          Transport_Vehicle_Update_Column.ModelName,
          Transport_Vehicle_Update_Column.OwnerId,
          Transport_Vehicle_Update_Column.VehicleTypeId,
        ],
      },
    })

    if (error) {
      return toast.custom(
        <Toast
          intent="danger"
          title={"Something went wrong! " + error.message}
        />
      )
    }
    if (insertData) {
      onClose()
      toast.custom(
        <Toast
          title={
            vehicleId === "new"
              ? "Vehicle added successfully!"
              : "Vehicle updated successfully!"
          }
        />
      )
    }
  }

  return (
    <Modal
      bodyClassName="overflow-visible w-[60%]"
      title="Owner details"
      contentClassName="bg-gray-100"
      footer={
        <div className="flex justify-end gap-3">
          <Button size="sm" onClick={onClose} variant="outline">
            Cancel
          </Button>
          <Button loading={insertFetching} size="sm" onClick={onSubmit}>
            Save
          </Button>
        </div>
      }
      isOpen={show}
      onClose={onClose}
    >
      <Card className="p-4">
        <SpinnerOverlay show={fetching} />
        <div>
          <AddressForm
            setValues={form.setValues}
            getInputProps={getInputProps}
            mapClassName="!w-full"
            mapWidth="full"
            address={state.address as any}
            addressKey={"address"}
            formClassName={classNames("w-full", "!grid-cols-3")}
            className="flex-col"
            noteClassName={"col-span-3 "}
            disabled={state?.user_search}
          >
            <div className="col-span-3 text-gray-600 text-sm">
              <Card className="w-fit p-2 !rounded-lg">
                Vehicle -{" "}
                <span className="text-gray-800 font-600">
                  {state?.registration_number}{" "}
                </span>
                (
                {state?.vehicle_type?.name +
                  " - " +
                  state?.vehicle_type?.category}
                )
              </Card>
            </div>
            <div className="col-span-3 py-2 grid grid-cols-3">
              <FormQuerySelector
                label={
                  <div>
                    Populate <span className="text-blue-300">this Owner </span>{" "}
                    from the list of existing users
                  </div>
                }
                dataHook={useAllNonEmployeeUsersQuery}
                className={"w-full col-span-2 "}
                size="sm"
                variables={{
                  where: {
                    groups: {
                      group: {
                        name: {
                          _in: [
                            Codenames.FarmerGroup,
                            Codenames.TraderGroup,
                            Codenames.VehicleDriverGroup,
                            Codenames.VehicleOwnerGroup,
                          ],
                        },
                      },
                    },
                  },
                }}
                value={state?.user_search}
                onChange={e => {
                  if (e === null) setState({ user_search: undefined })
                  setState({ user_search: e })
                }}
              />
            </div>
            <FormInput
              label="First Name"
              required
              disabled={state?.user_search}
              {...getInputProps("first_name")}
            />
            <FormInput
              label="Last Name"
              required
              disabled={state?.user_search}
              {...getInputProps("last_name")}
            />
            <FormInput
              label="Mobile Number"
              required
              disabled={state?.user_search}
              {...getInputProps("number")}
              maxLength={10}
            />
          </AddressForm>
        </div>
      </Card>
    </Modal>
  )
}
