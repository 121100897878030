import { useForm } from "@mantine/form"
import { CheckCircle } from "@phosphor-icons/react"
import { Breadcrumbs } from "@vesatogo/grass-core"
import classNames from "classnames"
import { isArray } from "lodash-es"
import { useEffect, useMemo, useState } from "react"
import { Link, useParams } from "react-router-dom"
import VerifyAsset from "~/components/VerifyAsset"
import Codenames from "~/constants/Codenames"
import { RegisteredTraderFormSections } from "~/constants/registration/RegisteredTraderFormSections"
import { AppRoutes } from "~/constants/routes"
import {
  useAuthenticatedUserQuery,
  useBusinessUserDetailQuery,
  useUpdateBaseUserMutation,
  useUpdateBusinessMutation,
} from "~/generated/graphql"

export const REGISTERED_TRADER_INITIAL_STATE = {
  banks: [{ attachments: [] }],
  photo: [],
  current_address: {},

  pan: {
    number: "",
    photo: [],
  },
  license: {
    number: "",
    photo: [],
  },
  gstin: {
    number: "",
    photo: [],
  },
  poc: {
    first_name: "",
    last_name: "",
    number: "",
    photo: [],
    pan: {
      number: "",
      photo: [],
    },
    aadhar: {
      number: "",
      front: [],
      back: [],
    },
  },
  shops: [
    {
      address: {} as any,
      certificates: [],
      gstin: {
        number: "",
        photo: [],
      },
      shop_act: {
        number: "",
        photo: [],
      },
    },
  ],
}

export const RegisteredTraderDetails = () => {
  const { id } = useParams()
  const businessId = Number(id) ? Number(id) : null
  const [activeSection, setActiveSection] = useState<any>()

  useEffect(() => {
    setActiveSection("basic")
  }, [])

  const form = useForm<any>({
    initialValues: REGISTERED_TRADER_INITIAL_STATE,
    // validate: yupResolver(farmerModelYup),
    validateInputOnChange: false,
    validateInputOnBlur: true,
    clearInputErrorOnChange: false,
  })

  const [{ data }, refetch] = useBusinessUserDetailQuery({
    variables: {
      id: businessId,
    },
    pause: !businessId,
  })
  const [, updateAsset] = useUpdateBaseUserMutation()
  const [{ data: meData }] = useAuthenticatedUserQuery()
  const currentUserId = meData?.me?.id

  useEffect(() => {
    const license =
      data?.base_user_by_pk?.businesses_owned?.[0]?.documents?.find(
        doc => doc?.kind?.codename === Codenames.MARKET_LICENSE
      )
    const pan = data?.base_user_by_pk?.businesses_owned?.[0]?.documents?.find(
      doc => doc?.kind?.codename === Codenames.PAN_CARD
    )
    const gstin = data?.base_user_by_pk?.businesses_owned?.[0]?.documents?.find(
      doc => doc?.kind?.codename === Codenames.GST_BASE_USER
    )

    const trader = data?.base_user_by_pk
    const pocAadhar = trader?.businesses_owned?.[0]?.poc?.documents?.find(
      doc => doc?.kind?.codename === Codenames.AADHAR
    )
    const pocPan = trader?.businesses_owned?.[0].poc?.documents?.find(
      doc => doc?.kind?.codename === Codenames.PAN_CARD
    )
    form.setValues({
      id: trader?.id,
      is_verified: trader?.businesses_owned?.[0]?.is_verified,
      basic: {
        id: trader?.businesses_owned?.[0]?.id,
        first_name: trader?.first_name,
        last_name: trader?.last_name,
        number: trader?.number,
        email: trader?.email,
        gender: trader?.gender,
        photo: trader?.image ? [trader?.image] : [],
        groups: trader?.groups,
        business_id: trader?.businesses_owned?.[0]?.id,
        business_type: trader?.businesses_owned[0]?.kind,
        business_name: trader?.businesses_owned?.[0]?.name,
        business_email: trader?.businesses_owned?.[0]?.email,
        business_number: trader?.businesses_owned?.[0]?.number,
        business_image: trader?.businesses_owned?.[0]?.image
          ? [trader?.businesses_owned?.[0]?.image]
          : [],
      },
      documents: {
        gstin:
          {
            ...gstin,
            attachments: gstin?.attachments?.length ? gstin?.attachments : [],
          } || {},
        license:
          {
            ...license,
            attachments: license?.attachments?.length
              ? license?.attachments
              : [],
          } || {},
        pan:
          {
            ...pan,
            attachments: pan?.attachments?.length ? pan?.attachments : [],
          } || {},
      },
      current_address: trader?.businesses_owned?.[0]?.address || ({} as any),
      payment: trader?.businesses_owned?.[0]?.payment_details?.map(
        payment_detail => {
          return {
            ...payment_detail,
            verify_account_number: payment_detail.account_number,
          }
        }
      ),
      poc: {
        id: trader?.businesses_owned?.[0]?.poc?.id,
        username: trader?.businesses_owned?.[0]?.poc?.username,
        first_name: trader?.businesses_owned?.[0].poc?.first_name || "",
        middle_name: trader?.businesses_owned?.[0].poc?.middle_name || "",
        last_name: trader?.businesses_owned?.[0].poc?.last_name || "",
        number: trader?.businesses_owned?.[0].poc?.number || "",
        email: trader?.businesses_owned?.[0].poc?.email || "",
        address: trader?.businesses_owned?.[0].poc?.address || {},
        groups: trader?.businesses_owned?.[0].poc?.groups || [],
        image: trader?.businesses_owned?.[0].poc?.image
          ? [trader?.businesses_owned?.[0].poc?.image]
          : [],
        aadhar:
          {
            ...pocAadhar,
            m2m_id: pocAadhar?.id,
            attachments: pocAadhar?.attachments?.length
              ? pocAadhar?.attachments
              : [],
          } || {},
        pan:
          {
            ...pocPan,
            m2m_id: pocPan?.id,
            attachments: pocPan?.attachments?.length ? pocPan?.attachments : [],
          } || {},
      },
      shops: trader?.businesses_owned?.[0]?.shops_owned?.map(shop => {
        return {
          ...shop,
          address: shop?.address || ({} as any),
          gstin: {
            ...shop?.documents?.filter(
              doc => doc?.kind?.codename === Codenames?.GST_BASE_USER
            )[0],
            attachments:
              shop?.documents?.filter(
                doc => doc?.kind?.codename === Codenames?.GST_BASE_USER
              )[0]?.attachments || [],
          },
          shop_act: {
            ...shop?.documents?.filter(
              doc => doc?.kind?.codename === Codenames?.SHOP_ACT
            )[0],
            attachments:
              shop?.documents?.filter(
                doc => doc?.kind?.codename === Codenames?.SHOP_ACT
              )[0]?.attachments || [],
          },
        }
      }),
    })
  }, [data])

  const sectionsFilledMap = useMemo(() => {
    return RegisteredTraderFormSections.reduce((prev, section) => {
      return {
        ...prev,
        [section.id as any]:
          section.id === "shops" || section.id === "poc"
            ? true
            : section.id === "documents"
            ? form.values?.[section?.id as any]?.license?.id &&
              form.values?.[section?.id as any]?.pan?.id
              ? true
              : false
            : isArray(form.values?.[section?.id as any])
            ? form.values?.[section?.id as any]?.[0]?.id
            : form.values?.[section?.id as any]?.id || false,
      }
    }, {})
  }, [form.values])

  const allSectionsFilled = Object.values(sectionsFilledMap)?.every(e => e)

  return (
    <>
      <header className="bg-gray-100 shadow-sm p-3 border-b-gray-300 border-b flex justify-between">
        <Breadcrumbs
          items={[
            {
              text: "Trader",
              link: AppRoutes.traderRegistration,
              isActive: false,
            },
            {
              text: data?.base_user_by_pk?.businesses_owned?.[0]?.name || "New",
              link: `${AppRoutes.traderRegistration}/registered/${id}`,
              isActive: true,
            },
          ]}
          linkRenderer={(link, item) => {
            return <Link to={link as any}>{item}</Link>
          }}
        />
      </header>
      <div className="p-4 ">
        <div className="max-w-6xl mx-auto p-4 flex gap-4 w-full">
          <div className="w-[20%] flex flex-col justify-between rounded-xl bg-white py-4 h-[80vh] overflow-auto">
            <div className="">
              {RegisteredTraderFormSections?.map(section => {
                const isSectionFilled =
                  section.id === "documents"
                    ? form.values?.[section?.id as any]?.license?.id &&
                      form.values?.[section?.id as any]?.pan?.id
                      ? true
                      : false
                    : isArray(form.values?.[section?.id as any])
                    ? form.values?.[section?.id as any]?.[0]?.id
                    : form.values?.[section?.id as any]?.id || false

                return (
                  <div
                    key={section?.id}
                    className={classNames(
                      "p-2 flex gag-2 items-center justify-between",
                      activeSection === section.id && "text-green bg-gray-200",
                      !form.values.id && section.id !== "basic"
                        ? "pointer-events-none text-gray-300"
                        : "cursor-pointer"
                    )}
                    onClick={() => {
                      setActiveSection(section.id)
                    }}
                  >
                    {section.title}{" "}
                    {isSectionFilled && (
                      <CheckCircle
                        className="text-green"
                        weight="fill"
                        size={20}
                      />
                    )}
                  </div>
                )
              })}
            </div>
          </div>
          <div className="w-[80%]">
            {RegisteredTraderFormSections?.map((section, idx) => {
              const Component = section.component
              if (section.id === activeSection)
                return (
                  <Component
                    form={form}
                    key={section?.id}
                    refetch={refetch}
                    entity={"business"}
                    docs={["gstin", "license", "pan"]}
                    setActiveSection={setActiveSection}
                    next={RegisteredTraderFormSections[idx + 1]}
                  />
                )
            })}
          </div>
        </div>
      </div>
      {allSectionsFilled && (
        <div className="bg-white p-3">
          <VerifyAsset
            id={form?.values?.basic?.business_id as any}
            is_verified={form?.values?.is_verified}
            useUpdateHook={useUpdateBusinessMutation}
            onCompleted={async (isVerified, remark) => {
              const { data } = await updateAsset({
                pk_columns: {
                  id: form?.values?.id,
                },
                _set: {
                  is_verified: isVerified,
                  verified_by_id: currentUserId,
                  verification_remark: remark?.id || remark?.label || "",
                },
                _inc: {},
              })
            }}
          />
        </div>
      )}
    </>
  )
}

export default RegisteredTraderDetails
