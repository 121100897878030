import {
  Activity,
  NotePencil,
  ShoppingBag,
  Truck,
  User,
  UserList,
  UsersThree,
} from "@phosphor-icons/react"
import { Menu, MenuItem, Popover } from "@vesatogo/grass-core"
import { NavLink, Navigate, Outlet } from "react-router-dom"
import RequestTimeline from "~/components/RequestTimeline"
import { AppRoutes } from "~/constants/routes"
import CommodityIcon from "~/icons/CommodityIcon"
import CommodityVarietyIcon from "~/icons/CommodityVarietyIcon"
import CropIcon from "~/icons/CropIcon"
import CropVarietyIcon from "~/icons/CropVarietyIcon"
import GradeIcon from "~/icons/GradeIcon"
import MandiIcon from "~/icons/MandiIcon"
import CommodityMaster from "~/pages/d/admin/crop-master/Commodity"
import CommodityVarietyMaster from "~/pages/d/admin/crop-master/CommodityVariety"
import CropMaster from "~/pages/d/admin/crop-master/Crop"
import CropVarietyMaster from "~/pages/d/admin/crop-master/CropVariety"
import GradeMaster from "~/pages/d/admin/crop-master/Grade"
import MandiMaster from "~/pages/d/admin/mandi-master"
import { MandiDetail } from "~/pages/d/admin/mandi-master/[id]"
import GroupMaster from "~/pages/d/admin/user-master/Group"
import UserMaster from "~/pages/d/admin/user-master/User"
import VehicleMaster from "~/pages/d/admin/vehicle-master/VehicleMaster"
import FarmerRegistration from "~/pages/d/registration/farmer"
import FarmerDetails from "~/pages/d/registration/farmer/[id]"
import TraderRegistration from "~/pages/d/registration/trader"
import NonRegisteredTraderDetails from "~/pages/d/registration/trader/non-registered/[id]"
import RegisteredTraderDetails from "~/pages/d/registration/trader/registered/[id]"
import TradeMetrics from "~/pages/d/trade-metrics"
import AllTradeRequests from "~/pages/d/trade-requests"
import TradeRequestDetail from "~/pages/d/trade-requests/[id]"
import TradeInvoices from "~/pages/d/trade-requests/invoices"
import TradeReceipts from "~/pages/d/trade-requests/receipts"
import ReceiptDetail from "~/pages/d/trade-requests/receipts/[id]"

// import { TradeTemplates } from "~/pages/d/trade-templates"

function SidebarMenuItem({
  link,
  name,
  icon: MenuIcon,
  svg = false,
}: {
  link: string
  name: string
  icon?: any
  svg?: boolean
}) {
  return (
    <MenuItem className="p-0 [&>div]:m-0">
      <NavLink
        className={({ isActive }) =>
          `p-2 block ${
            isActive
              ? "bg-gray-100 !text-green-400 !font-500"
              : "!text-gray-600"
          } flex`
        }
        to={link}
      >
        {!svg && MenuIcon ? (
          <MenuIcon />
        ) : MenuIcon ? (
          <img alt="logo" width={20} height={20} src={MenuIcon} />
        ) : (
          <></>
        )}
        {name}
      </NavLink>
    </MenuItem>
  )
}

export const dashboardRoutes: any[] = [
  {
    path: "trade-metrics",
    title: "Trade Metrics",
    icon: <Activity weight="fill" />,
    element: (
      <>
        <RequestTimeline />
        <Outlet />
      </>
    ),
    children: [
      {
        index: true,
        element: <TradeMetrics />,
      },
    ],
  },
  {
    path: "trade-requests",
    title: "Trade Requests",
    icon: <ShoppingBag />,
    element: (
      <>
        <RequestTimeline />
        <Outlet />
      </>
    ),
    children: [
      {
        index: true,
        element: <AllTradeRequests />,
      },
      {
        path: ":id",
        element: <TradeRequestDetail />,
      },
      {
        path: "receipts",
        element: <TradeReceipts />,
      },
      {
        path: "invoices",
        element: <TradeInvoices />,
      },
      {
        path: "receipts/:id",
        element: <ReceiptDetail />,
      },
    ],
  },
  // {
  //   path: "trade-templates",
  //   title: "Trade Templates",
  //   icon: (
  //     <Popover
  //       minimal
  //       arrow={false}
  //       className="p-0 border-t border-gray-200 ml-4 -mt-4"
  //       trigger="mouseover"
  //       interactive
  //       animation="fade"
  //       placement="right-start"
  //       content={
  //         <Menu className="max-h-52">
  //           <SidebarMenuItem link="trade-templates/expenses" name="Expenses" />
  //           <SidebarMenuItem
  //             link="trade-templates/seller"
  //             name="Seller Terms"
  //           />
  //           <SidebarMenuItem link="trade-templates/buyer" name="Buyer Terms" />
  //         </Menu>
  //       }
  //     >
  //       <Article weight="fill" />
  //     </Popover>
  //   ),
  //   element: (
  //     <>
  //       <RequestTimeline />
  //       <Outlet />
  //     </>
  //   ),
  //   children: [
  //     {
  //       index: true,
  //       element: <Navigate to="expenses" />,
  //     },
  //     {
  //       path: "expenses",
  //       title: "Expenses Templates",
  //       element: <TradeTemplates type="expenses" />,
  //     },
  //     {
  //       path: "seller",
  //       title: "Seller Terms Templates",
  //       element: <TradeTemplates type="seller" />,
  //     },
  //     {
  //       path: "buyer",
  //       title: "Buyer Terms Templates",
  //       element: <TradeTemplates type="buyer" />,
  //     },
  //   ],
  // },
  {
    path: "registration",
    title: "Registration Approval",
    icon: <UserList weight="fill" />,
    element: (
      <>
        <RequestTimeline />
        <Outlet />
      </>
    ),
    children: [
      {
        index: true,
        element: <Navigate to="farmer" />,
      },
      {
        path: "farmer",
        element: <FarmerRegistration />,
      },
      {
        path: "trader",
        element: <TraderRegistration />,
      },
      {
        path: "farmer/:id",
        element: <FarmerDetails />,
      },
      {
        path: "trader/registered/:id",
        element: <RegisteredTraderDetails />,
      },
      {
        path: "trader/non-registered/:id",
        element: <NonRegisteredTraderDetails />,
      },
    ],
  },
  {
    path: "admin",
    title: "Admin",
    icon: (
      <Popover
        minimal
        arrow={false}
        className="p-0 border-t border-gray-200 ml-4 -mt-4 !rounded-xl "
        trigger="mouseover"
        interactive
        hideOnClick
        placement="right-start"
        content={
          <Menu className="max-h-full !p-0 !rounded-md !shadow-md">
            <p className="bg-green-100 p-2 font-600 text-gray-600">
              User Master
            </p>
            <SidebarMenuItem
              link={AppRoutes.userMaster}
              name="User"
              icon={User}
            />
            <SidebarMenuItem
              link={AppRoutes.groupMaster}
              name="Groups"
              icon={UsersThree}
            />
            <p className="bg-green-100 p-2 font-600 text-gray-600">
              Crop Master
            </p>
            <SidebarMenuItem
              link={AppRoutes.cropMaster}
              name="Crop"
              icon={CropIcon}
            />
            <SidebarMenuItem
              link={AppRoutes.cropVarietyMaster}
              name="Crop Variety"
              icon={CropVarietyIcon}
              // svg
            />
            <SidebarMenuItem
              link={AppRoutes.commodityMaster}
              name="Commodity"
              icon={CommodityIcon}
              // svg
            />
            <SidebarMenuItem
              link={AppRoutes.commodityVarietyMaster}
              name="Commodity Variety"
              icon={CommodityVarietyIcon}
              // svg
            />
            <SidebarMenuItem
              link={AppRoutes.gradeMaster}
              name="Grade"
              icon={GradeIcon}
            />
            <p className="bg-green-100 p-2 font-600 text-gray-600">
              Vehicle Master
            </p>
            <SidebarMenuItem
              link={AppRoutes.vehicleMaster}
              name="Vehicle"
              icon={Truck}
            />
            <p className="bg-green-100 p-2 font-600 text-gray-600">
              Mandi Master
            </p>
            <SidebarMenuItem
              link={AppRoutes.mandiMaster}
              name="Mandi"
              icon={MandiIcon}
            />
          </Menu>
        }
      >
        <NotePencil weight="fill" />
      </Popover>
    ),
    element: (
      <>
        <RequestTimeline />
        <Outlet />
      </>
    ),
    children: [
      {
        index: true,
        element: <Navigate to="user" />,
      },
      {
        path: "user",
        title: "User Master",
        element: <UserMaster />,
      },
      {
        path: "groups",
        title: "Group Master",
        element: <GroupMaster />,
      },
      {
        path: "crop",
        title: "Crop Master",
        element: <CropMaster />,
      },
      {
        path: "crop-variety",
        title: "Crop Variety Master",
        element: <CropVarietyMaster />,
      },
      {
        path: "commodity",
        title: "Commodity Master",
        element: <CommodityMaster />,
      },
      {
        path: "commodity-variety",
        title: "Commodity Master",
        element: <CommodityVarietyMaster />,
      },
      {
        path: "grade",
        title: "Grade Master",
        element: <GradeMaster />,
      },
      {
        path: "vehicle",
        title: "Vehicle Master",
        element: <VehicleMaster />,
      },
      {
        path: "mandi",
        title: "Mandi Master",
        element: <MandiMaster />,
      },
      {
        path: "mandi/:id",
        title: "Mandi Detail",
        element: <MandiDetail />,
      },
    ],
  },
]
