import { useForm, yupResolver } from "@mantine/form"
import { openConfirmDialog } from "@vesatogo/grass-confirm-modal"
import {
  Button,
  Card,
  FormInput,
  FormQuerySelector,
  FormSelector,
  Modal,
  SpinnerOverlay,
  Toast,
} from "@vesatogo/grass-core"
import { FormDateInput } from "@vesatogo/grass-dates"
import { pick } from "lodash-es"
import { useEffect } from "react"
import toast from "react-hot-toast"
import { useSearchParams } from "react-router-dom"
import { AddCompleteType } from "~/components/Admin/AddCompleteType"
import { FileUpload } from "~/components/dropzone/FileUploader/FileUploader"
import {
  Base_User_Constraint,
  Base_User_Groups_Constraint,
  Base_User_Update_Column,
  Market_Pocs_Constraint,
  Market_Pocs_Update_Column,
  Media_Constraint,
  Media_Update_Column,
  useAllMarketsSelectorQuery,
  useBaseUserDetailQuery,
  useDeleteBaseUserGroupMutation,
  useDeleteMarketPocMutation,
  useGroupsSelectorQuery,
  useInsertBaseUserMutation,
} from "~/generated/graphql"
import { employeeModel } from "~/models/employee"
import { getFileUploadProps, getInputProps } from "~/utils/form-helpers"

const INITIAL_STATE = {
  photo: [],
}

export const AddUserModal = ({ onAddComplete }: AddCompleteType) => {
  const [params, setParams] = useSearchParams()
  const userId = params?.get("id") || ""

  const form = useForm<any>({
    initialValues: INITIAL_STATE,
    validate: yupResolver(employeeModel),
    validateInputOnChange: true,
    validateInputOnBlur: true,
    clearInputErrorOnChange: true,
  })

  const state = form.values
  const setState = form.setValues

  const [{ data, fetching }, refetch] = useBaseUserDetailQuery({
    variables: {
      id: params?.get("id"),
    },
    pause: !Boolean(params.get("id")) || params?.get("id") === "new",
  })
  const [{ data: allGroups }] = useGroupsSelectorQuery({
    variables: {
      query: "%%",
    },
  })

  const [{ fetching: insertFetching }, insertBaseUser] =
    useInsertBaseUserMutation()
  const [{ fetching: deleteFetching }, deleteBaseUserGroup] =
    useDeleteBaseUserGroupMutation()
  const [, deleteMarketPOC] = useDeleteMarketPocMutation()

  useEffect(() => {
    const user = data?.base_user_by_pk
    userId && userId !== "new"
      ? setState(val => ({
          ...val,
          first_name: user?.first_name,
          last_name: user?.last_name,
          username: user?.username,
          number: user?.number,
          email: user?.email || "",
          gender: user?.gender
            ? {
                id: String(user?.gender).toLowerCase(),
                name: user?.gender,
              }
            : undefined,
          dob: user?.date_of_birth,
          photo: user?.image ? [user?.image] : [],
          groups: user?.groups?.map(group => {
            return {
              ...group?.group,
              base_user_group_id: group?.id,
            }
          }),
          market: user?.markets_assigned?.flatMap(market => {
            return { ...market?.market, market_id: market?.id }
          }),
        }))
      : form?.reset()
  }, [data, userId])

  const onClose = () => {
    setParams({})
    form?.reset()
  }
  const onSubmit = async () => {
    const { errors, hasErrors } = form?.validate()
    if (hasErrors) {
      return toast.custom(
        <Toast
          intent="danger"
          title={"Please resolve all errors before proceeding!"}
        />
      )
    }
    const { data: insertData, error } = await insertBaseUser({
      baseUserIn: {
        id: userId !== "new" ? userId : undefined,
        first_name: state?.first_name,
        last_name: state?.last_name,
        number: state?.number,
        email: state?.email || "",
        status: "active",
        username: state?.username,
        date_of_birth: state?.dob,
        gender: state?.gender?.name || "Male",
        groups: {
          data:
            state?.groups?.map(group => {
              return { group_id: group?.id, id: group?.base_user_group_id }
            }) ||
            allGroups?.auth_group
              ?.filter(group => group?.name === "Employee")
              ?.map(group => {
                return { group_id: group?.id }
              }),
          on_conflict: {
            constraint: Base_User_Groups_Constraint.BaseUserGroupsPkey,
            update_columns: [],
          },
        },
        markets_assigned:
          state?.market?.length > 0
            ? {
                data: state?.market?.map(market => {
                  return {
                    id: market?.market_id,
                    market_id: market?.id,
                  }
                }),
                on_conflict: {
                  constraint: Market_Pocs_Constraint.MarketPocsPkey,
                  update_columns: [
                    Market_Pocs_Update_Column.MarketId,
                    Market_Pocs_Update_Column.Id,
                  ],
                },
              }
            : undefined,
        image: state?.photo[0]
          ? {
              data: {
                ...pick(
                  state?.photo[0],
                  "kind",
                  "id",
                  "is_encrypted",
                  "url",
                  "key"
                ),
              },
              on_conflict: {
                constraint: Media_Constraint.MediaPkey,
                update_columns: [Media_Update_Column.Url],
              },
            }
          : undefined,
      },
      on_conflict: {
        constraint: Base_User_Constraint.BaseUserPkey,
        update_columns: [
          Base_User_Update_Column.FirstName,
          Base_User_Update_Column.LastName,
          Base_User_Update_Column.Number,
          Base_User_Update_Column.ImageId,
          Base_User_Update_Column.Email,
          Base_User_Update_Column.Username,
          Base_User_Update_Column.Gender,
        ],
      },
    })
    if (error) {
      return toast.custom(
        <Toast
          intent="danger"
          title={"Something went wrong! " + error.message}
        />
      )
    }
    if (insertData) {
      onClose()
      toast.custom(
        <Toast
          title={
            userId === "new"
              ? "User added successfully!"
              : "User updated successfully!"
          }
        />
      )
      onAddComplete?.({ requestPolicy: "network-only" })
    }
  }

  return (
    <Modal
      bodyClassName="overflow-visible w-[60%]"
      title="Enter your personal details"
      contentClassName="bg-gray-100"
      footer={
        <div className="flex justify-end gap-3">
          <Button size="sm" onClick={onClose} variant="outline">
            Cancel
          </Button>
          <Button loading={insertFetching} size="sm" onClick={onSubmit}>
            Save
          </Button>
        </div>
      }
      isOpen={Boolean(params.get("id"))}
      onClose={onClose}
    >
      <SpinnerOverlay show={fetching} />
      <Card className="p-4">
        <div className="flex gap-20">
          <div>
            <FileUpload
              {...getFileUploadProps(form, "photo", 1)}
              appName="mandi"
              // showPreviewForDocument
            />
          </div>
          <div className="grid grid-cols-2 gap-4 w-full">
            <FormInput
              label="First Name"
              required
              {...getInputProps(form, "first_name")}
              placeholder="First Name"
            />
            <FormInput
              label="Last Name"
              required
              {...getInputProps(form, "last_name")}
              placeholder="Last Name"
            />
            <FormDateInput
              label="Date of Birth"
              required
              {...getInputProps(form, "dob")}
            />
            <FormInput
              label="Username"
              required
              {...getInputProps(form, "username")}
              placeholder="Enter Username"
            />
            <FormSelector
              label="Gender"
              options={[
                {
                  id: "Male",
                  name: "Male",
                },
                {
                  id: "Female",
                  name: "Female",
                },
              ]}
              required
              {...getInputProps(form, "gender")}
              placeholder="Enter Gender"
            />

            <FormInput
              label="Mobile Number"
              required
              {...getInputProps(form, "number")}
              placeholder="Enter Number"
              maxLength={10}
            />
            <FormInput
              label="Email"
              {...getInputProps(form, "email")}
              placeholder="abc@xyz.com"
            />
            <FormQuerySelector
              label="Select Market"
              {...getInputProps(form, "market")}
              placeholder="Select Markets"
              dataHook={useAllMarketsSelectorQuery}
              listPath="market"
              isMulti
              onChange={(e, action) => {
                if (
                  action.action === "remove-value" &&
                  action?.removedValue?.market_id !== undefined
                ) {
                  openConfirmDialog({
                    isOpen: true,
                    onSubmit() {
                      deleteMarketPOC({
                        id: action?.removedValue?.market_id,
                      }).then(() => {
                        refetch({ requestPolicy: "network-only" })
                      })
                    },
                    confirmationButtonText: "Remove",
                    message: "Are you sure you want to remove market?",
                  })
                } else setState({ market: e })
              }}
            />
            <FormQuerySelector
              label="Select Groups"
              required
              isMulti
              isLoading={deleteFetching}
              dataHook={useGroupsSelectorQuery}
              value={state?.groups}
              onChange={(e, action) => {
                if (
                  action.action === "remove-value" &&
                  action?.removedValue?.base_user_group_id !== undefined
                ) {
                  openConfirmDialog({
                    isOpen: true,
                    onSubmit() {
                      deleteBaseUserGroup({
                        id: action?.removedValue?.base_user_group_id,
                      }).then(() => {
                        refetch({ requestPolicy: "network-only" })
                      })
                    },
                    confirmationButtonText: "Remove",
                    message: "Are you sure you want to remove group?",
                  })
                } else setState({ groups: e })
              }}
              listPath="auth_group"
              serverSideQuery
              placeholder="Enter Group Name"
            />
          </div>
        </div>
      </Card>
    </Modal>
  )
}
