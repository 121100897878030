export const AppRoutes = {
  home: "/",
  tradeReceipts: "/d/trade-requests/receipts",
  tradeInvoices: "/d/trade-requests/invoices",
  tradeReceiptDetail(id: string | number) {
    return `/d/trade-requests/receipts/${id}`
  },
  tradeRequests: "/d/trade-requests",
  farmerRegistration: "/d/registration/farmer",
  traderRegistration: "/d/registration/trader",
  userMaster: "/d/admin/user",
  groupMaster: "/d/admin/groups",
  cropMaster: "/d/admin/crop",
  cropVarietyMaster: "/d/admin/crop-variety",
  commodityMaster: "/d/admin/commodity",
  commodityVarietyMaster: "/d/admin/commodity-variety",
  gradeMaster: "/d/admin/grade",
  vehicleMaster: "/d/admin/vehicle",
  mandiMaster: "/d/admin/mandi",
} as const

export const APIRoutes = {
  downloadTradeReceipt(id: string | number) {
    return `rest/trade/receipt_pdf/${id}/`
  },
  downloadPavti(hash: string) {
    return `rest/trade/receipt/${hash}`
  },
  downloadInvoice(id: string | number) {
    return `rest/trade/invoice_pdf/${id}/`
  },
}
