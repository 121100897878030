import { Envelope, Phone, User, UserCircle } from "@phosphor-icons/react"
import {
  Button,
  FormInput,
  FormQuerySelector,
  Toast,
} from "@vesatogo/grass-core"
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import { OperationContext } from "urql"
import { SectionCard } from "~/components/Registration/SectionCard"
import { FileUpload } from "~/components/dropzone/FileUploader/FileUploader"
import Codenames from "~/constants/Codenames"
import { AppRoutes } from "~/constants/routes"
import {
  Auth_Group_Constraint,
  Auth_Group_Update_Column,
  Base_User_Constraint,
  Base_User_Groups_Constraint,
  Base_User_Groups_Update_Column,
  Base_User_Update_Column,
  Business_Constraint,
  Business_Update_Column,
  Media_Constraint,
  Media_Update_Column,
  useAllBusinessTypesQuery,
  useAllGroupsQuery,
  useInsertBusinessDetailsMutation,
} from "~/generated/graphql"
import { getFileUploadProps, getInputProps } from "~/utils/form-helpers"

export type BusinessBasicDetailsype = {
  form?: any
  refetch: (opts?: Partial<OperationContext> | undefined) => void
  setActiveSection
  next
}

export const RegisteredTraderBasicDetails = ({
  form,
  refetch,
  next,
  setActiveSection,
}: BusinessBasicDetailsype) => {
  const navigate = useNavigate()

  const [, insertBusinessDetails] = useInsertBusinessDetailsMutation()
  const [{ data: traderGroup }] = useAllGroupsQuery({
    variables: {
      where: {
        name: {
          _eq: Codenames.TraderGroup,
        },
      },
    },
  })

  const onSubmit = async () => {
    const basicDetails = form?.values?.basic
    const isTrader = basicDetails?.groups?.filter(group => {
      return group?.group_id === traderGroup?.auth_group?.[0]?.id
    })
    const { data, error } = await insertBusinessDetails({
      object: {
        owner: {
          data: {
            id: form?.values?.id,
            first_name: basicDetails?.first_name || basicDetails?.business_name,
            last_name: basicDetails?.last_name || "",
            email: basicDetails?.email || basicDetails?.business_email || "",
            number: basicDetails?.number || basicDetails?.business_number,
            gender: basicDetails?.gender || "Male",
            date_of_birth: basicDetails?.date_of_birth || "01-01-1997",
            username: basicDetails?.number || basicDetails?.business_number,
            status: "active",
            groups: {
              data:
                [
                  ...(basicDetails?.groups?.map(group => {
                    return {
                      id: group?.id,
                      group: {
                        data: {
                          id: group?.group?.id,
                          name: group?.group?.name,
                        },
                        on_conflict: {
                          constraint: Auth_Group_Constraint.AuthGroupPkey,
                          update_columns: [
                            Auth_Group_Update_Column.Id,
                            Auth_Group_Update_Column.Name,
                          ],
                        },
                      },
                    }
                  }) || []),
                  !isTrader && {
                    group_id: traderGroup?.auth_group?.[0]?.id,
                  },
                ].filter?.(d => d) || [],
              on_conflict: {
                constraint: Base_User_Groups_Constraint.BaseUserGroupsPkey,
                update_columns: [
                  Base_User_Groups_Update_Column.Id,
                  Base_User_Groups_Update_Column.GroupId,
                ],
              },
            },
          },
          on_conflict: {
            constraint: Base_User_Constraint.BaseUserPkey,
            update_columns: [
              Base_User_Update_Column.FirstName,
              Base_User_Update_Column.Number,
              Base_User_Update_Column.Email,
              Base_User_Update_Column.Username,
              Base_User_Update_Column.LastName,
            ],
          },
        },
        id: basicDetails?.business_id,
        email: basicDetails?.business_email || "",
        number: basicDetails?.business_number,
        name: basicDetails?.business_name,
        kind_id: basicDetails?.business_type?.id,
        image: basicDetails?.business_image?.[0]?.url
          ? {
              data: {
                id: basicDetails?.business_image?.[0]?.id,
                kind: basicDetails?.business_image?.[0]?.kind || "image",
                url: basicDetails?.business_image?.[0]?.url,
                key: basicDetails?.business_image?.[0]?.key,
                is_encrypted: basicDetails?.business_image?.[0]?.is_encrypted,
              },
              on_conflict: {
                constraint: Media_Constraint.MediaPkey,
                update_columns: [
                  Media_Update_Column.Id,
                  Media_Update_Column.Kind,
                  Media_Update_Column.Url,
                  Media_Update_Column.Key,
                  Media_Update_Column.IsEncrypted,
                  Media_Update_Column.DocumentId,
                ],
              },
            }
          : undefined,
      },
      on_conflict: {
        constraint: Business_Constraint.BusinessPkey,
        update_columns: [
          Business_Update_Column.Id,
          Business_Update_Column.Name,
          Business_Update_Column.KindId,
          Business_Update_Column.Email,
          Business_Update_Column.Number,
          Business_Update_Column.OwnerId,
          Business_Update_Column.ImageId,
        ],
      },
    })
    if (error) {
      return toast.custom(
        <Toast
          intent="danger"
          title={"Something went wrong!" + error.message}
        />
      )
    } else {
      toast.custom(<Toast title={"Business saved successfully!"} />)
      navigate(
        `${AppRoutes.traderRegistration + "/registered"}/${
          data?.insert_business_one?.owner?.id
        }`
      )
      next && setActiveSection(next?.id)
    }
    refetch?.({
      requestPolicy: "network-only",
    })
  }

  return (
    <SectionCard
      cardClassName="h-[80vh]"
      icon={User}
      title={"Enter your personal details"}
      className="flex gap-4 flex-col justify-between h-full"
    >
      <div className="flex gap-4 items-center ">
        <div className="h-max">
          <FileUpload
            className="px-10"
            uploadPlaceHolder="Drop file here"
            uploadImage={
              <UserCircle className="text-gray-600" weight="fill" size={64} />
            }
            {...getFileUploadProps(form, "basic.business_image", 1)}
          />
        </div>
        <div className="grid grid-cols-2 gap-4 h-max w-full">
          <FormQuerySelector
            dataHook={useAllBusinessTypesQuery}
            data-testid="businessType"
            required
            label="Business Type"
            {...getInputProps(form, "basic.business_type")}
          />
          <FormInput
            data-testid="businessName"
            required
            label="Business Name"
            {...getInputProps(form, "basic.business_name")}
          />
          <FormInput
            data-testid="inputMobileNo"
            leftElement={<Phone />}
            required
            label="Business Mobile Number"
            maxLength={10}
            {...getInputProps(form, "basic.business_number")}
          />
          <FormInput
            data-testid="inputEmail"
            leftElement={<Envelope />}
            type="email"
            label="Business Email ID"
            {...getInputProps(form, "basic.business_email")}
          />
        </div>
      </div>
      <Button className="mb-10 w-fit self-end" text="Save" onClick={onSubmit} />
    </SectionCard>
  )
}
