import { Plus } from "@phosphor-icons/react"
import { Button, Card } from "@vesatogo/grass-core"
import classNames from "classnames"
import dayjs from "dayjs"
import { HTMLAttributes } from "react"
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { TradeKindsReadable } from "~/constants/Kinds"
import { useFinalTradeListQuery } from "~/generated/graphql"

interface ISidebarItemProps extends HTMLAttributes<HTMLDivElement> {
  active?: boolean
}

export function SideBarItem({
  active,
  className,
  ...props
}: ISidebarItemProps) {
  return (
    <div
      className={classNames(
        className,
        "flex justify-between items-center border-y px-4 py-2 text-green-600 hover:shadow-md transition-all",
        active ? "bg-green-100 font-600" : "hover:bg-green-50"
      )}
      {...props}
    />
  )
}

export function SideBar({
  id,
  kind,
  disableNew,
}: {
  id?: number
  kind: string
  disableNew?: boolean
}) {
  const params = useParams()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const [{ data }] = useFinalTradeListQuery({
    variables: { parentId: id },
    pause: !id,
    requestPolicy: "network-only",
  })
  return id ? (
    <Card className="border flex-[0_0_300px] rounded-lg overflow-hidden self-start">
      <SideBarItem
        className="border-green-200 bg-green-50"
        active={!searchParams.get("new") && id === Number(params.id)}
      >
        <Link to={`../${id}`} className="flex-1 block">
          {TradeKindsReadable[kind]} - #{id}
        </Link>
        {!searchParams.get("new") && id && !disableNew ? (
          <Button
            variant="outline"
            size="sm"
            onClick={() => navigate(`../${id}?new=true`)}
          >
            <Plus />
          </Button>
        ) : null}
      </SideBarItem>
      {searchParams.get("new") ? (
        <SideBarItem active={true}>New Hishob Patti</SideBarItem>
      ) : null}
      {data?.trade.map(item => (
        <Link to={`../${item.id}`} className="flex-1 block">
          <SideBarItem
            active={!searchParams.get("new") && item.id === Number(params.id)}
          >
            <span>Hishob Patti - #{item.id}</span>
            <span>{dayjs(item.start_date).format("D MMM 'YY")}</span>
          </SideBarItem>
        </Link>
      ))}
    </Card>
  ) : null
}
