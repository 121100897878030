import { FormGroup } from "@vesatogo/grass-core"
import { useState } from "react"
import Editor from "~/components/Editor/Editor"
import { FormCard } from "~/components/FormCard"
// import { useTncConfigVersionsQuery } from "../generated/graphql"
// import { TNC_CONFIG_DETAIL } from "../graphql/trade_template.gql"

type TermsAndConditionsProps = {
  isDisabled?: boolean
  seller_tnc?: any
  buyer_tnc?: any
  kind?: any
  mode?: any
  sharedDeptId?: any
  onChange: (path: "seller_tnc" | "buyer_tnc", value: any) => void
}
const TermsAndConditions = ({
  isDisabled,
  seller_tnc,
  buyer_tnc,
  // kind,
  // mode,
  // sharedDeptId,
  onChange,
}: TermsAndConditionsProps) => {
  // const commonVariables = {
  //   department: `${sharedDeptId}`,
  //   kind,
  //   mode,
  // }

  // const client = useClient()
  const [state, setState] = useState({
    buyer: null,
    seller: null,
  })
  // function handleTemplateChange(path: "buyer" | "seller") {
  //   return {
  //     value: state[path],
  //     variables: {
  //       ...commonVariables,
  //       role: path,
  //     },
  //     onChange: async value => {
  //       try {
  //         const { data } = await client
  //           .query(TNC_CONFIG_DETAIL, {
  //             ...commonVariables,
  //             version: value?.id,
  //             role: path,
  //           })
  //           .toPromise()

  //         const tnc = data?.trade_tnc_template_detail?.[path]
  //         onChange((path + "_tnc") as any, tnc)
  //         setState(prev => ({
  //           ...prev,
  //           [path]: value,
  //         }))
  //       } catch {}
  //     },
  //   }
  // }
  return (
    <FormCard title="Terms and Conditions" childrenClassName="flex gap-4">
      <FormGroup
        labelProps={{ className: "text-base" }}
        className={"w-full"}
        label={
          <div className="flex justify-between items-center px-3 gap-4">
            <div className="w-1/2">Farmer Terms</div>
            {/* {!isDisabled && (
              <QuerySelector
                dataHook={useTncConfigVersionsQuery}
                className="font-400 w-1/2"
                {...handleTemplateChange("seller")}
              />
            )} */}
          </div>
        }
      >
        <Editor
          disabled={isDisabled}
          value={seller_tnc}
          onEditorChange={value => onChange("seller_tnc", value)}
        />
      </FormGroup>
      <FormGroup
        labelProps={{ className: "text-base" }}
        className={"w-full"}
        label={
          <div className="flex justify-between items-center px-3 gap-4">
            <div>Trader Terms</div>
            {/* {!isDisabled && (
              <QuerySelector
                dataHook={useTncConfigVersionsQuery}
                className="font-400 w-1/2"
                {...handleTemplateChange("buyer")}
              />
            )} */}
          </div>
        }
      >
        <Editor
          disabled={isDisabled}
          value={buyer_tnc}
          onEditorChange={value => onChange("buyer_tnc", value)}
        />
      </FormGroup>
    </FormCard>
  )
}

export default TermsAndConditions
