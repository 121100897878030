import { useGoogleMap } from '@react-google-maps/api';
import React from 'react';
import { useMapControl } from '~/hooks/useMapControl';

type MapControlPosition =
  | 'TOP_CENTER'
  | 'TOP_LEFT'
  | 'TOP_RIGHT'
  | 'LEFT_TOP'
  | 'RIGHT_TOP'
  | 'LEFT_CENTER'
  | 'RIGHT_CENTER'
  | 'LEFT_BOTTOM'
  | 'RIGHT_BOTTOM'
  | 'BOTTOM_CENTER'
  | 'BOTTOM_LEFT'
  | 'BOTTOM_RIGHT';

type MapControlProps = {
  // Added strongly typed position strings to avoid bugs and improve developer experience
  position: MapControlPosition;
  children?: React.ReactNode;
};
const MapControl = ({ children, position }: MapControlProps) => {
  const map = useGoogleMap();

  useMapControl(map, window?.google?.maps.ControlPosition[position], children);
  return null;
};

export default MapControl;
