import { Address } from "~/components/Registration/FormSections/Address"
import { Certificates } from "~/components/Registration/FormSections/Certificates"
import { PlotAddress } from "~/components/Registration/FormSections/Farmer/PlotAddress"
import { FarmerTraderBasicDetails } from "~/components/Registration/FormSections/FarmerTraderBasicDetails"
import { PaymentDetails } from "~/components/Registration/FormSections/PaymentDetails"

export type FormTabType = {
  title: string
  id?: string
  component?: any
  isActive?: boolean
}
export const FarmerRegistrationFormSections: FormTabType[] = [
  {
    title: "Basic Details",
    id: "basic",
    component: FarmerTraderBasicDetails,
    isActive: true,
  },
  {
    title: "Address",
    id: "current_address",
    component: Address,
    isActive: false,
  },
  {
    title: "Bank Details",
    id: "payment",
    component: PaymentDetails,
    isActive: false,
  },
  {
    title: "KYC",
    id: "certificates",
    component: Certificates,
    isActive: false,
  },
  {
    title: "Plot Address (optional)",
    id: "plots",
    component: PlotAddress,
    isActive: false,
  },
]
