import {
  Bank,
  IdentificationCard,
  Plus,
  Trash,
  User,
} from "@phosphor-icons/react"
import { openConfirmDialog } from "@vesatogo/grass-confirm-modal"
import {
  Button,
  FormInput,
  FormQuerySelector,
  NoDataFound,
  Toast,
} from "@vesatogo/grass-core"
import { omit, pick } from "lodash-es"
import toast from "react-hot-toast"
import { OperationContext } from "urql"
import { SectionCard } from "~/components/Registration/SectionCard"
import { FileUpload } from "~/components/dropzone/FileUploader/FileUploader"
import {
  Base_User_Constraint,
  Base_User_Update_Column,
  Business_Constraint,
  Business_Update_Column,
  Media_Constraint,
  Media_Update_Column,
  Payment_Detail_Constraint,
  Payment_Detail_Update_Column,
  useAllPaymentMethodsQuery,
  useDeleteAttachmentMutation,
  useDeletePaymentDetailMutation,
  useInsertBaseUserMutation,
} from "~/generated/graphql"
import { getFileUploadProps, getInputProps } from "~/utils/form-helpers"

const DocContext = ({ items }) => {
  return (
    <div className="absolute backdrop-blur-sm saturate-50 z-10 top-4 left-4 right-4 rounded-md grid grid-cols-2 gap-3 text-base bg-gray-200 bg-opacity-50 p-1">
      {items
        ?.filter(item => !item.hide)
        ?.map(item => (
          <p className={item.className} key={item.id || item.value}>
            {item.label} - <span className="font-600">{item.value}</span>
          </p>
        ))}
    </div>
  )
}
export type PaymentDetailsType = {
  form: any
  refetch: (opts?: Partial<OperationContext> | undefined) => void
  entity?: "farmer" | "trader" | "business"
  setActiveSection: (string) => void
  next
}

export const PaymentDetails = ({
  form,
  refetch,
  entity = "farmer",
  setActiveSection,
  next,
}: PaymentDetailsType) => {
  const { values: state, setValues: setState } = form

  const [, insertBaseUserDetails] = useInsertBaseUserMutation()
  const [, deleteBank] = useDeletePaymentDetailMutation()
  const [, deleteAttachment] = useDeleteAttachmentMutation()

  function addBank() {
    setState({
      ...state,
      payment: [
        ...state.payment,
        {
          account_holder_name: state?.basic?.full_name,
          attachments: [],
        },
      ],
    })
  }

  const onSubmit = async () => {
    const basicDetails = state?.basic
    const banks = state?.payment
    const payment = {
      data: banks?.map(bank => {
        const isUPI = bank?.payment_method?.name === "UPI"
        return {
          ...omit(
            bank,
            "verify_account_number",
            "__typename",
            "payment_method",
            "base_user",
            "base_user_id"
          ),
          bank_name: !isUPI ? bank.bank_name : "",
          branch_name: !isUPI ? bank.branch_name : "",
          ifsc_code: !isUPI ? bank.ifsc_code : "",
          method_id: bank?.payment_method?.id,
          attachments: {
            data: banks?.[0]?.attachments?.map(attachment => {
              const url = attachment?.is_encrypted
                ? attachment?.private_url
                : attachment?.url
              return {
                ...pick(attachment, "kind", "id", "key", "is_encrypted"),
                kind: attachment?.kind || attachment?.media_kind,
                url: attachment?.is_encrypted ? undefined : url,
              }
            }),
            on_conflict: {
              constraint: Media_Constraint.MediaPkey,
              update_columns: [
                Media_Update_Column.Id,
                Media_Update_Column.Key,
                Media_Update_Column.Kind,
                Media_Update_Column.Url,
                Media_Update_Column.PaymentDetailId,
              ],
            },
          },
        }
      }),
      on_conflict: {
        constraint: Payment_Detail_Constraint.PaymentDetailPkey,
        update_columns: [
          Payment_Detail_Update_Column.AccountHolderName,
          Payment_Detail_Update_Column.AccountNumber,
          Payment_Detail_Update_Column.BankName,
          Payment_Detail_Update_Column.BranchName,
          Payment_Detail_Update_Column.Id,
          Payment_Detail_Update_Column.IfscCode,
          Payment_Detail_Update_Column.MethodId,
        ],
      },
    }
    const { data, error } = await insertBaseUserDetails({
      baseUserIn: {
        id: state?.id,
        first_name: basicDetails?.first_name,
        last_name: basicDetails?.last_name,
        email: basicDetails?.email || "",
        number: basicDetails?.number,
        status: "active",
        gender: basicDetails?.gender,
        date_of_birth: basicDetails?.date_of_birth || "01-01-1997",
        username: basicDetails?.number,
        payment_details: entity !== "business" ? payment : undefined,
        businesses_owned:
          entity === "business"
            ? {
                data: [
                  {
                    id: basicDetails?.business_id,
                    name: basicDetails?.business_name,
                    email: basicDetails?.business?.email || "",
                    kind_id: basicDetails?.business_type.id,
                    payment_details: payment,
                    number: basicDetails?.business_number,
                  },
                ],
                on_conflict: {
                  constraint: Business_Constraint.BusinessPkey,
                  update_columns: [Business_Update_Column.Id],
                },
              }
            : undefined,
      },
      on_conflict: {
        constraint: Base_User_Constraint.BaseUserPkey,
        update_columns: [Base_User_Update_Column.Id],
      },
    })

    if (error) {
      return toast.custom(
        <Toast
          intent="danger"
          title={"Something went wrong!" + error.message}
        />
      )
    } else {
      toast.custom(<Toast title={"User saved successfully!"} />)
      next && setActiveSection(next.id)
    }
  }

  return (
    <SectionCard
      icon={Bank}
      title={"Bank Details"}
      cardClassName="min-h-[80vh]"
      header={
        <Button
          className="mt-3"
          size="sm"
          variant="outline"
          leftIcon={<Plus size={18} />}
          text="Add Bank"
          onClick={() => {
            addBank()
          }}
          intent="primary"
        />
      }
      className="flex flex-col justify-between "
    >
      <div className="mt-4 ">
        {state?.payment?.length ? (
          state?.payment?.map((bank, idx) => {
            const isUPI = bank?.payment_method?.name === "UPI"
            const type = isUPI ? "UPI ID" : "Account Number"
            return (
              <div className="flex flex-col !h-full" key={idx}>
                <div className="flex justify-between gap-4 mb-4 items-center">
                  <div />
                  <Button
                    data-testid="bankButton"
                    className="self-end"
                    onClick={() => {
                      const onDelete = async () => {
                        const { error } = await deleteBank({
                          id: bank?.id,
                        })
                        if (error) {
                          return toast.custom(
                            <Toast
                              intent="danger"
                              title={"Something went wrong! " + error.message}
                            />
                          )
                        } else {
                          toast.custom(
                            <Toast
                              title={"Bank Detail deleted successfully!"}
                            />
                          )
                          refetch?.({
                            requestPolicy: "network-only",
                          })
                        }
                      }
                      if (bank?.id) {
                        openConfirmDialog({
                          isOpen: true,
                          onSubmit: onDelete,
                          confirmationButtonText: "Delete",
                          message:
                            "Are you sure you want to delete bank detail?",
                        })
                      } else {
                        const stateCopy = structuredClone(state)
                        stateCopy.payment.splice(idx, 1)
                        setState(stateCopy)
                      }
                    }}
                    variant="outline"
                    size="sm"
                    intent="danger"
                    leftIcon={<Trash className="!mr-0" />}
                  />
                </div>
                <div key={bank.id || `bank-${idx}`} className="flex gap-4">
                  <div className="h-max">
                    <FileUpload
                      className="uploadBankDoc"
                      summary={
                        <DocContext
                          items={[
                            {
                              label: type,
                              value: bank?.account_number,
                            },
                            {
                              label: "Account Holder",
                              value: bank?.account_holder_name,
                            },
                            {
                              label: "IFSC Code",
                              value: bank?.ifsc_code,
                              hide: isUPI,
                            },
                            {
                              label: "Bank Name",
                              value: bank?.bank_name,
                              hide: isUPI,
                            },
                            {
                              label: "Branch Name",
                              value: bank?.branch_name,
                              className: "col-span-2",
                              hide: isUPI,
                            },
                          ]}
                        />
                      }
                      {...getFileUploadProps(
                        form,
                        `payment.${idx}.attachments`,
                        1,
                        true
                      )}
                      onDelete={e => {
                        const onDelete = async () => {
                          const { error } = await deleteAttachment({
                            id: e.id,
                          })
                          if (error) {
                            return toast.custom(
                              <Toast
                                intent="danger"
                                title={"Something went wrong! " + error.message}
                              />
                            )
                          } else {
                            refetch?.({
                              requestPolicy: "network-only",
                            })
                            toast.custom(
                              <Toast title={"Certificate deleted!"} />
                            )
                          }
                        }
                        if (e.id) {
                          openConfirmDialog({
                            isOpen: true,
                            onSubmit: () => onDelete(),
                            confirmationButtonText: "Delete",
                            message:
                              "Are you sure you want to delete this attachment?",
                          })
                        }
                        refetch?.({
                          requestPolicy: "network-only",
                        })
                      }}
                    />
                  </div>
                  <div className="grid grid-cols-3 gap-4 h-max">
                    <FormInput
                      data-testid="inputAccountNo"
                      required
                      leftElement={<IdentificationCard />}
                      label={type}
                      {...getInputProps(form, `payment.${idx}.account_number`)}
                    />

                    <FormInput
                      data-testid="verifyAccountNo"
                      required
                      label={isUPI ? "Verify UPI ID" : "Verify Account Number"}
                      leftElement={<IdentificationCard />}
                      inputProps={{
                        onPaste: e => e.preventDefault(),
                      }}
                      {...getInputProps(
                        form,
                        `payment.${idx}.verify_account_number`
                      )}
                      error={`${
                        bank.account_number !== bank.verify_account_number
                          ? "The account number does not match"
                          : ""
                      }`}
                    />
                    <FormInput
                      data-testid="inputAccountHolderName"
                      required
                      leftElement={<User />}
                      label="Account Holder's Name"
                      {...getInputProps(
                        form,
                        `payment.${idx}.account_holder_name`
                      )}
                    />

                    <FormQuerySelector
                      id="selectPaymentMode"
                      dataHook={useAllPaymentMethodsQuery}
                      required
                      label="Payment Mod"
                      {...getInputProps(form, `payment.${idx}.payment_method`)}
                    />
                    {!isUPI && (
                      <>
                        <FormInput
                          data-testid="inputIfscCode"
                          maxLength={11}
                          className="uppercase"
                          required
                          label="IFSC Code"
                          value={bank.ifsc_code || ""}
                          onChange={async value => {
                            form.setFieldValue(
                              `payment.${idx}.ifsc_code`,
                              value
                            )
                            if (!value || value?.length !== 11) return
                            const res = await fetch(
                              `https://ifsc.razorpay.com/${value}`
                            )
                            const data = await res.json()
                            form.setFieldValue(
                              `payment.${idx}.ifsc_code`,
                              data.IFSC
                            )
                            form.setFieldValue(
                              `payment.${idx}.bank_name`,
                              data.BANK
                            )
                            form.setFieldValue(
                              `payment.${idx}.branch_name`,
                              data.BRANCH
                            )
                          }}
                        />

                        <FormInput
                          label="Bank Name"
                          {...getInputProps(form, `payment.${idx}.bank_name`)}
                        />

                        <FormInput
                          label="Branch Name"
                          {...getInputProps(form, `payment.${idx}.branch_name`)}
                        />
                      </>
                    )}
                  </div>
                </div>

                {idx !== state.payment.length - 1 && <hr className="my-5" />}
              </div>
            )
          })
        ) : (
          <NoDataFound title="You haven’t added a bank">
            <Button
              className="mt-3"
              size="sm"
              variant="outline"
              leftIcon={<Plus size={18} />}
              text="Add Bank"
              onClick={() => {
                addBank()
              }}
              intent="primary"
            />
          </NoDataFound>
        )}
      </div>
      <Button className="w-fit self-end" onClick={onSubmit} text="Save" />
    </SectionCard>
  )
}
