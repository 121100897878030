import { FileText, Plus, Trash } from "@phosphor-icons/react"
import { Polygon } from "@react-google-maps/api"
import { openConfirmDialog } from "@vesatogo/grass-confirm-modal"
import { Button, FormInput, NoDataFound, Toast } from "@vesatogo/grass-core"
import classNames from "classnames"
import dayjs from "dayjs"
import { get, omit, pick } from "lodash-es"
import { useRef } from "react"
import toast from "react-hot-toast"
import CertificatesList from "~/components/CertificatesList"
import AddressForm from "~/components/Registration/AddressForm"
import { CertificateCard } from "~/components/Registration/CertificateCard"
import { CollapsibleCard } from "~/components/Registration/CollapsibleCard"
import { SectionCard } from "~/components/Registration/SectionCard"
import {
  Address_Constraint,
  Address_Update_Column,
  Base_User_Constraint,
  Base_User_Update_Column,
  Document_Constraint,
  Document_Update_Column,
  Media_Constraint,
  Media_Update_Column,
  Plot_Constraint,
  Plot_Update_Column,
  useDeletePlotMutation,
  useInsertBaseUserMutation,
} from "~/generated/graphql"
import { getInputProps } from "~/utils/form-helpers"
import { jsonToPointMany, pointToJsonMany } from "~/utils/map-helpers"

export const PlotAddress = ({ form, refetch }) => {
  const polyRef = useRef<google.maps.Polygon>()
  const addPlot = () => {
    form.setValues({
      plots: [
        {
          address: {} as any,
          documents: [],
          area_catered: {
            coordinates: [],
            type: "Polygon",
          },
        },
        ...form.values?.plots,
      ],
    })
  }
  const [, insertBaseUserDetails] = useInsertBaseUserMutation()
  const [, deletePlot] = useDeletePlotMutation()

  const onSubmit = async () => {
    const basicDetails = form.values?.basic
    const plots = form.values?.plots
    const { data, error } = await insertBaseUserDetails({
      baseUserIn: {
        id: form.values?.id,
        first_name: basicDetails?.first_name,
        last_name: basicDetails?.last_name,
        email: basicDetails?.email || "",
        number: basicDetails?.number,
        status: "active",
        gender: basicDetails?.gender,
        date_of_birth: basicDetails?.date_of_birth || "01-01-1997",
        username: basicDetails?.number,

        plots: {
          data: plots?.map(plot => {
            return {
              id: plot?.id,
              tag: plot?.tag,
              name: plot?.name,
              area_catered: plot?.area_catered,
              documents:
                plot?.documents?.length > 0
                  ? {
                      data: plot?.documents?.map(document => {
                        return {
                          ...omit(
                            document,
                            "kind",
                            "__typename",
                            "attachments",
                            "created_by"
                          ),
                          attachments:
                            document?.attachments?.length !== 0
                              ? {
                                  data: document?.attachments?.map(
                                    attachment => {
                                      return {
                                        ...pick(
                                          attachment,
                                          "kind",
                                          "id",
                                          "is_encrypted",
                                          "url",
                                          "key"
                                        ),
                                      }
                                    }
                                  ),
                                  on_conflict: {
                                    constraint: Media_Constraint.MediaPkey,
                                    update_columns: [
                                      Media_Update_Column.Id,
                                      Media_Update_Column.Kind,
                                      Media_Update_Column.Url,
                                      Media_Update_Column.DocumentId,
                                    ],
                                  },
                                }
                              : undefined,
                          id: document?.id,
                          kind_id: document?.kind?.id,
                          issued_on: document?.issued_on
                            ? dayjs(document?.issued_on).format("YYYY-MM-DD")
                            : "01-01-1997",
                          valid_till: document?.valid_till
                            ? dayjs(document?.valid_till).format("YYYY-MM-DD")
                            : undefined,
                        }
                      }),
                      on_conflict: {
                        constraint: Document_Constraint.DocumentPkey,
                        update_columns: [
                          Document_Update_Column.BaseUserId,
                          Document_Update_Column.Id,
                          Document_Update_Column.IdNumber,
                          Document_Update_Column.IssuedOn,
                          Document_Update_Column.ValidTill,
                          Document_Update_Column.KindId,
                        ],
                      },
                    }
                  : undefined,
              address: {
                data: {
                  name: plot?.address?.name || "address",
                  ...omit(plot?.address, "__typename"),
                },
                on_conflict: {
                  update_columns: Object.values(Address_Update_Column).filter(
                    a => a !== Address_Update_Column.Id
                  ),
                  constraint: Address_Constraint.AddressPkey,
                },
              },
            }
          }),
          on_conflict: {
            constraint: Plot_Constraint.PlotPkey,
            update_columns: [
              Plot_Update_Column.Id,
              Plot_Update_Column.AreaCatered,
            ],
          },
        },
      },
      on_conflict: {
        constraint: Base_User_Constraint.BaseUserPkey,
        update_columns: [
          Base_User_Update_Column.Id,
          Base_User_Update_Column.Number,
          Base_User_Update_Column.FirstName,
          Base_User_Update_Column.LastName,
          Base_User_Update_Column.Email,
          Base_User_Update_Column.Gender,
        ],
      },
    })

    if (error) {
      return toast.custom(
        <Toast
          intent="danger"
          title={"Something went wrong!" + error.message}
        />
      )
    }
    return toast.custom(<Toast title={"User saved successfully!"} />)
  }
  return (
    <SectionCard
      title="Plot Address - (optional)"
      header={
        <Button
          text="Plot"
          variant="outline"
          size="sm"
          leftIcon={<Plus />}
          onClick={addPlot}
        />
      }
    >
      <div className="flex flex-col gap-4">
        {form.values?.plots?.length > 0 ? (
          form.values?.plots?.map((plot, idx) => {
            return (
              <CollapsibleCard
                title={plot?.name || "New Plot"}
                key={plot.id}
                header={
                  <Button
                    onClick={e => {
                      e.stopPropagation()
                      const onDelete = async () => {
                        const { error } = await deletePlot({
                          id: plot?.id,
                        })
                        if (error) {
                          return toast.custom(
                            <Toast
                              intent="danger"
                              title={"Something went wrong! " + error.message}
                            />
                          )
                        } else {
                          refetch?.({
                            requestPolicy: "network-only",
                          })
                          toast.custom(<Toast title={"Certificate deleted!"} />)
                        }
                      }
                      if (plot?.id) {
                        openConfirmDialog({
                          isOpen: true,
                          onSubmit: onDelete,
                          confirmationButtonText: "Delete",
                          message:
                            "Are you sure you want to delete this certificate?",
                        })
                      } else {
                        const stateCopy = structuredClone(form.values)
                        stateCopy.plots.splice(idx, 1)
                        form.setValues(stateCopy)
                      }
                    }}
                    variant="outline"
                    size="sm"
                    intent="danger"
                    leftIcon={<Trash className="!mr-0" />}
                  />
                }
              >
                <div className="flex flex-col gap-4">
                  <AddressForm
                    zoom={19}
                    useNestedFieldSet
                    showMap={true}
                    address={get(plot, `address`)}
                    compact={false}
                    formClassName={classNames("w-full", "!grid-cols-3")}
                    noteClassName={"col-span-3 text-sm"}
                    getInputProps={form.getInputProps}
                    addressKey={`plots.${idx}.address`}
                    setFieldValue={form.setFieldValue}
                    mapClassName="!w-full"
                    mapWidth="full"
                    className="flex-col"
                    onPolyComplete={({ coordinates }) => {
                      form.setFieldValue(
                        `plots.${idx}.area_catered.coordinates.0`,
                        jsonToPointMany(coordinates)
                      )
                    }}
                    polyChild={
                      <Polygon
                        onLoad={poly => {
                          polyRef.current = poly
                        }}
                        onMouseUp={e => {
                          const newPolygon = jsonToPointMany(
                            polyRef.current
                              ?.getPath()
                              ?.getArray()
                              ?.map(a => a.toJSON()) as any
                          )
                          form.setFieldValue(
                            `plots.${idx}.area_catered.coordinates.0`,
                            newPolygon
                          )
                        }}
                        editable
                        options={{
                          fillColor: form.values?.area?.color?.id || "yellow",
                          strokeColor: form.values?.area?.color?.id || "yellow",
                          fillOpacity: 0.3,
                          strokeOpacity: 0.3,
                        }}
                        path={
                          pointToJsonMany(
                            plot?.area_catered?.coordinates?.[0]
                          ) as any
                        }
                      />
                    }
                  >
                    <div className="flex ">
                      <FormInput
                        label="Tag Name"
                        required
                        className={"w-[40%]"}
                        {...getInputProps(form, `plots.${idx}.tag`)}
                      />
                      <FormInput
                        className="justify-end"
                        disabled={plot.id}
                        {...getInputProps(form, `plots.${idx}.name`)}
                      />
                    </div>
                  </AddressForm>

                  <div className="flex flex-col gap-4">
                    <SectionCard
                      title="Certificate Details - (optional)"
                      icon={FileText}
                      headerClassName="bg-gray-200 overflow-hidden "
                      cardClassName="overflow-hidden rounded-xl"
                      header={
                        <Button
                          text="Certificate"
                          leftIcon={<Plus />}
                          size="sm"
                          variant="outline"
                          onClick={() => {
                            form.setFieldValue(`plots.${idx}.documents`, [
                              ...plot?.documents,
                              { attachments: [] },
                            ])
                          }}
                        />
                      }
                    />
                    {
                      <CertificateCard
                        title="Certificate"
                        cardClassName="!overflow-visible min-h-[60vh]"
                      >
                        {plot?.documents?.length !== 0 ? (
                          <CertificatesList
                            refetch={refetch}
                            certificates={plot?.documents}
                            onChange={e => {
                              form.setFieldValue(`plots.${idx}.documents`, e)
                            }}
                            form={form}
                            plotIdx={idx}
                          />
                        ) : (
                          <NoDataFound />
                        )}
                      </CertificateCard>
                    }
                  </div>
                </div>
              </CollapsibleCard>
            )
          })
        ) : (
          <NoDataFound />
        )}
        <Button text="Save" className="self-end" onClick={onSubmit} />
      </div>
    </SectionCard>
  )
}
