import { useJsApiLoader } from "@react-google-maps/api"
import { FormInput, Toast } from "@vesatogo/grass-core"
import classNames from "classnames"
import { isPlainObject } from "lodash-es"
import { InferType } from "yup"
import MapAddress from "~/components/Map/MapAddress"
import { baseAddressModel } from "~/models/address"
import { Mohadi } from "~/utils/location.data"
import { gisToJson } from "~/utils/map-helpers"
export type AddressState = InferType<typeof baseAddressModel>
export type AddressFormProps = {
  getInputProps?: any
  setValues?: any
  setFieldValue?: any
  children?: React.ReactNode
  mapWidth?: string
  mapClassName?: string
  formClassName?: string
  disabled?: boolean
  className?: string
  onPolyComplete?: any
  address?: any
  addressKey?: string
  compact?: boolean
  noteClassName?: string
  showMap?: boolean
  useNestedFieldSet?: boolean
  polyChild?: any
  zoom?: number
}

const libraries: any = ["drawing", "places", "visualization"]

const AddressForm = ({
  className,
  children,
  formClassName,
  mapClassName,
  disabled,
  onPolyComplete,
  getInputProps,
  setValues,
  setFieldValue,
  mapWidth = "40rem",
  address,
  addressKey = "address",
  compact = false,
  noteClassName,
  showMap,
  useNestedFieldSet = false,
  polyChild,
  zoom = 7,
}: AddressFormProps) => {
  if (!isPlainObject(address)) return null
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: import.meta.env.VITE_MAP_API || "",
    libraries,
  })
  const renderMap = () => {
    return (
      <div className={classNames("flex gap-6", className)}>
        {compact || showMap === false ? null : (
          <MapAddress
            zoom={zoom}
            onPolyComplete={onPolyComplete}
            customMarker={true}
            className={classNames(
              "w-max shadow-sm overflow-hidden rounded-lg",
              mapClassName
            )}
            drawingMode={["polygon"]}
            mapClassName="!mr-0 !rounded-none"
            showFunctionalities
            value={address?.location ? gisToJson(address?.location) : Mohadi}
            handleLocationData={data => {
              if (disabled) return

              useNestedFieldSet
                ? setFieldValue(addressKey, { id: address?.id, ...data })
                : setValues(prev => ({
                    ...prev,
                    [addressKey]: { id: address?.id, ...data },
                  }))
            }}
            mapStyle={{
              height: "25rem",
              width: mapWidth,
            }}
            footer={
              address ? (
                <div className="p-2 rounded-lg gap-3">
                  <p>
                    <span className="font-500 text-gray-800">
                      {address?.local_area || "Area"} (
                      {address?.district || "district"})
                    </span>{" "}
                    {/* <span className="text-gray-600">
                    {address?.location?.coordinates?.[1]?.toFixed(6)},{" "}
                    {address?.location?.coordinates?.[0]?.toFixed(6)}
                  </span> */}
                  </p>
                  {/* <p className="max-w-[38rem]">{address.raw_address}</p> */}
                </div>
              ) : null
            }
          >
            {polyChild}
          </MapAddress>
        )}
        <div
          className={classNames(
            "grid gap-3 h-max",
            compact ? "grid-cols-1" : "grid-cols-2",
            formClassName
          )}
        >
          {children}
          <FormInput
            data-testid="inputArea"
            label="Area"
            required
            className={compact ? "col-span-2" : null}
            {...getInputProps(`${addressKey}.local_area`)}
            disabled={disabled}
          />
          <FormInput
            data-testid="inputVillage"
            label="Village"
            required
            {...getInputProps(`${addressKey}.village`)}
            disabled={disabled}
          />
          <FormInput
            data-testid="inputTaluka"
            label="Taluka"
            required
            {...getInputProps(`${addressKey}.taluka`)}
            disabled={disabled}
          />
          <FormInput
            data-testid="inputDistrict"
            label="District"
            required
            {...getInputProps(`${addressKey}.district`)}
            disabled={disabled}
          />
          <FormInput
            data-testid="inputState"
            label="State"
            {...getInputProps(`${addressKey}.state`)}
            disabled
          />
          <FormInput
            data-testid="inputCountry"
            label="Country"
            {...getInputProps(`${addressKey}.country`)}
            disabled
          />
          <FormInput
            data-testid="inputPincode"
            label="Pincode"
            required
            {...getInputProps(`${addressKey}.pincode`)}
            disabled={disabled}
          />
          {/* <FormQuerySelector
          data-testid="inputSubregion"
          label="Sub Region"
          {...getInputProps(`${addressKey}.sub_region`)}
          disabled={disabled}
        /> */}
          <div
            className={classNames(
              compact ? "col-span-2 mb-3" : "col-span-2",
              noteClassName
            )}
          >
            <Toast
              intent="info"
              title={
                <span>
                  Please select the location by dragging the pin or searching
                  via the search bar, the actual location <b>will not update</b>{" "}
                  by editing the fields
                </span>
              }
              variant="minimal"
              className="font-400 max-w-full"
            />
          </div>
        </div>
      </div>
    )
  }
  if (loadError) return <span>{loadError.message}</span>

  return isLoaded ? renderMap() : null
}

export default AddressForm
