import { useForm, yupResolver } from "@mantine/form"
import { openConfirmDialog } from "@vesatogo/grass-confirm-modal"
import {
  Button,
  Card,
  FormInput,
  FormQuerySelector,
  Modal,
  SpinnerOverlay,
  Toast,
} from "@vesatogo/grass-core"
import { useEffect } from "react"
import toast from "react-hot-toast"
import { useSearchParams } from "react-router-dom"
import { AddCompleteType } from "~/components/Admin/AddCompleteType"
import {
  Auth_Group_Constraint,
  Auth_Group_Permissions_Constraint,
  Auth_Group_Permissions_Update_Column,
  Auth_Group_Update_Column,
  useAllPermissionsQuery,
  useDeleteGroupPermissionMutation,
  useGroupDetailQuery,
  useInsertGroupMutation,
} from "~/generated/graphql"
import { groupModel } from "~/models/group"
import { getInputProps } from "~/utils/form-helpers"

const INITIAL_STATE = {}

export const AddGroupModal = ({ onAddComplete }: AddCompleteType) => {
  const [params, setParams] = useSearchParams()
  const groupId = params.get("id") || ""

  const form = useForm<any>({
    initialValues: INITIAL_STATE,
    validate: yupResolver(groupModel),
    validateInputOnChange: true,
    validateInputOnBlur: true,
    clearInputErrorOnChange: false,
  })

  const state = form.values
  const setState = form.setValues

  const [{ data, fetching }, refetch] = useGroupDetailQuery({
    variables: {
      id: parseInt(groupId),
    },
    pause: groupId === "new" || !Boolean(params.get("id")),
  })

  const [{ fetching: insertFetching }, insertGroup] = useInsertGroupMutation()
  const [{ fetching: deleteFetching }, deleteGroupPermission] =
    useDeleteGroupPermissionMutation()

  useEffect(() => {
    setState(val => ({
      ...val,
      name: data?.auth_group_by_pk?.name,
      permissions: data?.auth_group_by_pk?.permissions?.map(permission => {
        return {
          ...permission?.auth_permission,
          group_permission_id: permission?.id,
        }
      }),
    }))
  }, [data])

  const onClose = () => {
    setParams({})
    form?.reset()
  }

  const onSubmit = async () => {
    const { errors, hasErrors } = form?.validate()
    if (hasErrors) {
      return toast.custom(
        <Toast
          intent="danger"
          title={"Please resolve all errors before proceeding!"}
        />
      )
    }
    const { data, error } = await insertGroup({
      object: {
        id: groupId !== "new" ? parseInt(groupId) : undefined,
        name: state?.name,
        permissions: {
          data: state?.permissions?.map(permission => {
            return {
              permission_id: permission?.id,
              id: permission.group_permission_id,
            }
          }),
          on_conflict: {
            constraint:
              Auth_Group_Permissions_Constraint.AuthGroupPermissionsPkey as any,
            update_columns: [
              Auth_Group_Permissions_Update_Column.Id,
              Auth_Group_Permissions_Update_Column.PermissionId,
              Auth_Group_Permissions_Update_Column.GroupId,
            ],
          },
        },
      },
      on_conflict: {
        constraint: Auth_Group_Constraint.AuthGroupPkey,
        update_columns: [
          Auth_Group_Update_Column.Id,
          Auth_Group_Update_Column.Name,
        ],
      },
    })
    if (error) {
      return toast.custom(
        <Toast
          intent="danger"
          title={"Something went wrong! " + error.message}
        />
      )
    }
    onClose()
    toast.custom(
      <Toast
        title={
          groupId === "new"
            ? "Group added successfully!"
            : "Group updated successfully!"
        }
      />
    )
    onAddComplete?.({ requestPolicy: "network-only" })
  }
  return (
    <Modal
      bodyClassName="overflow-visible w-[40%]"
      title="Create Group"
      contentClassName="bg-gray-100"
      footer={
        <div className="flex justify-end gap-3">
          <Button size="sm" onClick={onClose} variant="outline">
            Cancel
          </Button>
          <Button loading={insertFetching} size="sm" onClick={onSubmit}>
            Save
          </Button>
        </div>
      }
      isOpen={Boolean(params.get("id"))}
      onClose={onClose}
    >
      <Card className="p-4">
        <SpinnerOverlay show={fetching} />
        <div className="flex flex-col gap-4">
          <FormInput
            label="Group Name"
            required
            helperText="Unique group name for grouping"
            placeholder="Enter"
            {...getInputProps(form, "name")}
          />
          <FormQuerySelector
            label="Available Permissions"
            required
            isMulti
            dataHook={useAllPermissionsQuery}
            value={form?.values.permissions}
            isLoading={deleteFetching}
            onBlur={form.validate}
            serverSideQuery
            error={form?.getInputProps("permissions")?.error}
            onChange={(e, action) => {
              if (
                action.action === "remove-value" &&
                action?.removedValue?.group_permission_id !== undefined
              ) {
                openConfirmDialog({
                  isOpen: true,
                  onSubmit() {
                    deleteGroupPermission({
                      id: action?.removedValue?.group_permission_id,
                    }).then(({ error }) => {
                      if (error) {
                        return toast.custom(
                          <Toast
                            intent="danger"
                            title={
                              "Please resolve all errors before proceeding!"
                            }
                          />
                        )
                      }
                      onClose()
                      toast?.success("Group permission removed!")
                      refetch({ requestPolicy: "network-only" })
                    })
                  },
                  confirmationButtonText: "Remove",
                  message: "Are you sure you want to remove group permission?",
                })
              } else {
                setState({ permissions: e })
              }
            }}
          />
        </div>
      </Card>
    </Modal>
  )
}
