import { useForm } from "@mantine/form"
import { CheckCircle, Percent, Plus } from "@phosphor-icons/react"
import {
  Breadcrumbs,
  Button,
  Flex,
  FormGroup,
  FormInput,
  Group,
  QuerySelector,
  Toast,
} from "@vesatogo/grass-core"
import { DateInput } from "@vesatogo/grass-dates"
import "@vesatogo/grass-dates/index.css"
import { inr } from "@vesatogo/utils"
import dayjs from "dayjs"
import { cloneDeep, debounce, set } from "lodash-es"
import { useEffect, useMemo, useState } from "react"
import { toast } from "react-hot-toast"
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom"
import CreateReceiptSummary from "~/components/CreateReceiptSummary"
import { FormCard } from "~/components/FormCard"
import HowIsItTraded from "~/components/HowIsItTraded"
import TermsAndConditions from "~/components/TermsAndConditions"
import TradeBuyer from "~/components/TradeBuyer"
import TradeExpenses, { EXPENSE } from "~/components/TradeExpenses"
import WhatIsBeingTraded from "~/components/WhatIsBeingTraded"
import { WhoIsTrading } from "~/components/WhoIsTrading"
import Codenames from "~/constants/Codenames"
import SharedEntityGroup from "~/constants/SharedEntityGroup"
import { AppRoutes } from "~/constants/routes"
import {
  ReceiptDetailQuery,
  useAddUpdateReceiptMutation,
  useAllVehiclesQuery,
  useCurrencyQuery,
  useReceiptDetailQuery,
} from "~/generated/graphql"
import {
  ReceiptState,
  Trade,
  TradeItem,
} from "~/pages/d/trade-requests/receipts/types"
import { expenseAmount, normalizeExpenses } from "~/utils/expenseAmount"
import { useDepartment } from "~/utils/useDepartment"

type SharedEntity = {
  group: SharedEntityGroup
}

export const TRADE_ITEM: Partial<TradeItem> = {
  grade: null,
}
const TRADE: Trade = {
  buyer: null,
  items: [TRADE_ITEM],
}

const INITIAL_STATE: ReceiptState = {
  mode: "direct",
  kind: "pro_forma",
  packaging: "raw_material",
  trades: [TRADE],
  start_date: new Date(),
  end_date: null,
  expiry: null,
  expenses: [EXPENSE],
  total_quantity: 0,
  total_amount: 0,
  seller_expense_amount: 0,
  seller_receivable_amount: 0,
}
const ReceiptDetail = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [{ fetching }, addUpdateReceipt] = useAddUpdateReceiptMutation()
  const { id: sharedDeptId } = useDepartment()

  const [{ data: sData }] = useCurrencyQuery({
    variables: {
      codename: Codenames.DefaultCurrency,
    },
  })

  const defaultCurrency = sData?.unit?.[0]
  const { id } = useParams()

  const form = useForm<ReceiptState>({ initialValues: INITIAL_STATE })

  const [isOpen, setOpen] = useState(false)

  const idNum = parseInt(id as any)

  const [{ data: dData }] = useReceiptDetailQuery({
    variables: { id: idNum },
    pause: !idNum,
    requestPolicy: "network-only",
  })

  const isDisabled = dData?.receipt_by_pk?.editable_trades?.aggregate?.count
    ? true
    : false
  function updateReceiptState(receipt?: ReceiptDetailQuery["receipt_by_pk"]) {
    if (!receipt) return
    form.setValues({
      id: receipt.id,
      buyer_tnc: receipt.buyer_tnc,
      seller_tnc: receipt.seller_tnc,
      kind: Boolean(searchParams.get("new")) ? "final" : receipt.kind,
      mode: receipt.mode,
      commodity: receipt.commodity,
      commodity_variety: receipt.commodity_variety,
      vehicle: receipt.vehicle,
      plot: receipt.plot,
      facilitator: receipt.facilitator,
      seller: receipt.seller,
      unit: receipt?.firstTrade?.[0]?.items?.[0]?.quantity_unit,
      rate_unit: receipt?.firstTrade?.[0]?.items?.[0]?.rate_unit,
      total_amount: receipt.total_amount,
      total_quantity: receipt.total_quantity,
      amount_deducted:
        (receipt.total_amount * receipt.standard_deduction) / 100,
      quantity_deducted:
        (receipt.total_quantity * receipt.standard_deduction) / 100,
      packaging: receipt.packaging as any,
      seller_receivable_amount: receipt.seller_receivable_amount || 0,
      seller_expense_amount: receipt.seller_expense_amount || 0,
      standard_deduction: receipt.standard_deduction,
      start_date: receipt.start_date && new Date(receipt.start_date),
      end_date: receipt.end_date && new Date(receipt.end_date),
      expiry: receipt.expiry && new Date(receipt.expiry),
      trades: receipt.trades?.length
        ? receipt.trades.map(trade => ({
            id: trade.id,
            buyer: trade?.buyer,
            total_amount: trade.total_amount,
            total_quantity: trade.total_quantity,
            items: trade.items?.length
              ? trade.items?.map(item => ({
                  id: item.id,
                  quantity: item.quantity,
                  rate: item.rate,
                  total_amount: item.total_amount,
                  grade: item.commodity_variety_grade,
                }))
              : [TRADE_ITEM],
          }))
        : [TRADE],

      expenses: normalizeExpenses(receipt.expenses),
      expense_amount: receipt.expense_amount,
    })
  }
  const receiptId = dData?.receipt_by_pk?.id

  useEffect(() => {
    if (receiptId) {
      updateReceiptState(dData?.receipt_by_pk)
    }
  }, [receiptId, searchParams.get("new")])

  useEffect(() => {
    if (
      dData &&
      sharedDeptId &&
      dData?.receipt_by_pk?.created_in?.id !== sharedDeptId
    ) {
      navigate(AppRoutes.tradeReceipts)
    }
  }, [sharedDeptId, dData?.receipt_by_pk?.created_in?.id])

  const runComputations = useMemo(
    () =>
      debounce(values => {
        const stateCopy = cloneDeep(values)
        let receiptTotalQty = 0,
          receiptTotalAmount = 0,
          totalExpenseAmount = 0,
          sellerExpenseAmount = 0
        for (let i = 0; i < stateCopy.trades.length; i++) {
          const trade = stateCopy.trades[i]
          let tradeTotalQty = 0,
            tradeTotalAmount = 0
          for (let j = 0; j < trade.items.length; j++) {
            const item = trade.items[j]
            let itemTotalAmount = 0
            tradeTotalQty += Number(item.quantity) || 0
            itemTotalAmount +=
              (Number(item.quantity) || 0) * (Number(item.rate) || 0)
            stateCopy.trades[i].items[j].total_amount = itemTotalAmount
            tradeTotalAmount += itemTotalAmount
          }
          stateCopy.trades[i].total_quantity = tradeTotalQty
          stateCopy.trades[i].total_amount = tradeTotalAmount
          receiptTotalQty += tradeTotalQty
          receiptTotalAmount += tradeTotalAmount
        }

        stateCopy.total_quantity = receiptTotalQty
        stateCopy.total_amount = receiptTotalAmount
        stateCopy.amount_deducted =
          stateCopy.total_amount *
          (Number(stateCopy.standard_deduction || 0) / 100)

        stateCopy.quantity_deducted =
          stateCopy.total_quantity *
          (Number(stateCopy.standard_deduction || 0) / 100)

        for (let j = 0; j < stateCopy.expenses.length; j++) {
          const expense = stateCopy.expenses[j]
          const expenseParams = {
            kind: expense?.kind?.id,
            buyerAmount: Number(expense.buyer?.value) || 0,
            sellerAmount: Number(expense.seller?.value) || 0,
            finalAmount: stateCopy.total_amount,
            finalQuantity:
              stateCopy.total_quantity - stateCopy.quantity_deducted,
            totalQuantity: stateCopy.total_quantity,
            trades: stateCopy.trades?.length,
          }
          expense.amount = expenseAmount(expenseParams)
          sellerExpenseAmount += expenseAmount({
            ...expenseParams,
            buyerAmount: 0,
          })
          totalExpenseAmount += expense.amount
        }
        stateCopy.expense_amount = totalExpenseAmount
        stateCopy.seller_expense_amount = sellerExpenseAmount
        stateCopy.seller_receivable_amount =
          (stateCopy.total_amount || 0) -
          (stateCopy.amount_deducted || 0) -
          (stateCopy.seller_expense_amount || 0)
        form.setValues(stateCopy)
      }, 500),
    []
  )
  function inputProps(name: string, sharedEntity?: SharedEntity) {
    return {
      ...form.getInputProps(name),
      disabled: isDisabled,
      onChange: async (e: any) => {
        const stateCopy = cloneDeep(form.values)
        let facilitator = stateCopy.seller?.companies?.[0]?.company
        if (name === "mode" && e === "facilitator" && facilitator) {
          set(stateCopy, "facilitator", facilitator)
        } else if (
          (name === "mode" && e === "direct") ||
          (name === "mode" && e === "facilitator" && !facilitator) ||
          (name === "seller" && !e?.companies?.length)
        ) {
          set(stateCopy, "facilitator", null)
          set(stateCopy, "mode", "direct")
        }
        set(stateCopy, name, e?.target ? e.target.value : e)
        runComputations(stateCopy)
        form.setValues(stateCopy)
      },
    }
  }

  function handleSubmit() {
    return form.onSubmit(async values => {
      try {
        const { data, error } = await addUpdateReceipt({
          input: {
            id: values.id as any,
            seller_id: values.seller?.id,
            created_in_id: sharedDeptId,
            kind: values.kind,
            mode: values.mode,
            vehicle_id: values.vehicle?.id,
            buyer_tnc: values.buyer_tnc,
            seller_tnc: values.seller_tnc,
            facilitator_id: values.facilitator?.id,
            commodity_id: values.commodity?.id,
            commodity_variety_id: values.commodity_variety?.id,
            plot_id: values.plot?.id,
            packaging: values.packaging,
            start_date: values.start_date
              ? dayjs(values.start_date).format("YYYY-MM-DD")
              : null,
            end_date: values.end_date
              ? dayjs(values.end_date).format("YYYY-MM-DD")
              : null,
            expiry: values.expiry
              ? dayjs(values.expiry).format("YYYY-MM-DD")
              : null,
            standard_deduction: values.standard_deduction,
            trades: values.trades?.length
              ? values.trades.map(trade => {
                  return {
                    id: trade.id as any,
                    buyer_id: trade.buyer?.id,
                    items: trade.items?.length
                      ? trade.items.map(item => {
                          return {
                            id: item.id as any,
                            quantity: Number(item.quantity),
                            rate: Number(item.rate),
                            unit_id: values.unit?.id,
                            currency_id: defaultCurrency?.id,
                            variety_grade_id: item.grade?.id,
                          }
                        })
                      : [],
                  }
                })
              : [],
            expenses: values?.expenses?.filter(i => i.seller || i.buyer)?.length
              ? values.expenses.reduce<any[]>((prev, expense) => {
                  const commonProperties = {
                    expense_parameter_id: expense.parameter?.id,
                    kind: expense.kind?.id,
                  }
                  if (expense.buyer?.value) {
                    prev.push({
                      ...commonProperties,
                      id: expense.buyer.id,
                      paid_by: "buyer",
                      value: expense.buyer.value,
                    })
                  }
                  if (expense.seller?.value) {
                    prev.push({
                      ...commonProperties,
                      id: expense.seller.id,
                      paid_by: "seller",
                      value: expense.seller.value,
                    })
                  }
                  return prev
                }, [])
              : undefined,
          },
        })
        if (data?.add_update_receipt?.receipt.id) {
          toast.custom(<Toast title="Receipt saved successfully" />)
          setOpen(false)
          navigate(AppRoutes.tradeReceipts)
        }

        if (error) {
          throw new Error(error.message)
        }
      } catch (error: any) {
        return toast.custom(<Toast title={error?.message} intent="danger" />)
      }
    })
  }

  const finalTotal =
    (form.values.total_amount || 0) - (form.values.amount_deducted || 0)

  return (
    <>
      <header className="bg-gray-100 shadow-sm p-3 border-b-gray-300 border-b flex justify-between">
        <Breadcrumbs
          items={[
            {
              text: "Receipts",
              link: AppRoutes.tradeReceipts,
              isActive: false,
            },
            {
              text: id?.toString() || "New",
              link: AppRoutes.tradeReceiptDetail(id || "new"),
              isActive: true,
            },
          ]}
          linkRenderer={(link, item) => {
            return <Link to={link as any}>{item}</Link>
          }}
        />
      </header>
      <Group className="flex gap-4 px-5 pt-8 pb-16 relative">
        <div className="w-[70%] mx-auto">
          <WhoIsTrading form={form} inputProps={inputProps} showBuyer={false} />
          <HowIsItTraded
            form={form}
            inputProps={inputProps}
            isDisabled={isDisabled}
          />
          <WhatIsBeingTraded
            form={form}
            inputProps={inputProps}
            isDisabled={isDisabled}
          />
          <FormCard title="When is it traded">
            <div className={"grid grid-cols-4 gap-4"}>
              <FormGroup required label="Start Date of Trade">
                <DateInput {...inputProps("start_date")} />
              </FormGroup>
              <FormGroup label="End Date of Trade">
                <DateInput {...inputProps("end_date")} />
              </FormGroup>
              <FormGroup label="Validity of Trade" required>
                <DateInput {...inputProps("expiry")} />
              </FormGroup>
              <FormGroup label="Vehicle">
                <QuerySelector
                  dataHook={useAllVehiclesQuery}
                  serverSideQuery
                  useWildCardsInQuery={false}
                  variables={{
                    first: 20,
                  }}
                  getOptionLabel={item => item.registration_number}
                  {...inputProps("vehicle")}
                />
              </FormGroup>
            </div>
          </FormCard>
          <FormCard
            childrenClassName={"!p-0"}
            title="Trade Particulars"
            action={
              <Button
                disabled={isDisabled}
                onClick={() => form.insertListItem("trades", TRADE)}
                leftIcon={<Plus />}
                size="sm"
              >
                Buyer
              </Button>
            }
          >
            <div className="p-4">
              {form.values.trades?.map((buyer, index) => {
                return (
                  <TradeBuyer
                    isDisabled={isDisabled}
                    runComputations={runComputations}
                    inputProps={inputProps}
                    index={index}
                    form={form}
                    defaultCurrency={defaultCurrency}
                    key={buyer.id}
                    items={buyer.items}
                  />
                )
              })}
              <hr className="border-gray-300" />
              <div className="grid grid-cols-4 gap-4 text-base p-4">
                <div>Total</div>
                <div></div>
                <div>
                  {form.values.total_quantity} {form.values.unit?.name || "na"}
                </div>
                <div className="flex justify-between">
                  <span>value:</span>
                  <span>{inr(form.values.total_amount || 0)}</span>
                </div>
              </div>
              <div className="grid grid-cols-4 gap-4 text-base p-4">
                <div className="col-span-2">
                  <FormInput
                    inline
                    labelProps={{
                      className: "text-base",
                    }}
                    label="Standard Deduction of Wt"
                    size="sm"
                    type="number"
                    rightElement={<Percent />}
                    {...inputProps("standard_deduction")}
                    min={0}
                    max={100}
                  />
                </div>
                <div className="text-red-500">
                  {form.values.quantity_deducted?.toFixed(2)}{" "}
                  {form.values.unit?.name || "na"}
                </div>
                <div className="flex justify-between">
                  <span></span>
                  <span className="text-red-500">
                    {inr(form.values.amount_deducted || 0)}
                  </span>
                </div>
              </div>
            </div>
            <footer className="grid grid-cols-4 gap-4 text-base p-4 bg-green-100">
              <div className="font-500">Final Total</div>
              <div></div>
              <div>
                {(form.values.total_quantity || 0) -
                  (form.values.quantity_deducted || 0)}{" "}
                {form.values.unit?.name || "na"}
              </div>
              <div className="flex justify-between pr-4">
                <span>value:</span>
                <span>{inr(finalTotal)}</span>
              </div>
            </footer>
          </FormCard>
          <TradeExpenses
            form={form}
            inputProps={inputProps}
            isDisabled={isDisabled}
            sharedDeptId={sharedDeptId}
            onExpenseTemplateChange={expenseConfig => {
              const stateCopy = cloneDeep(form.values)
              stateCopy.expenses = normalizeExpenses(
                expenseConfig.expense_configs || []
              )
              runComputations(stateCopy)
              form.setValues(stateCopy)
            }}
            onDeleteExpense={idx => {
              const stateCopy = cloneDeep(form.values)
              stateCopy.expenses.splice(idx, 1)
              runComputations(stateCopy)
              form.setValues(stateCopy)
            }}
          />
          <FormCard
            childrenClassName={"flex flex-col gap-2"}
            title="Seller Payment"
          >
            <Flex className="!justify-between">
              <span className="text-gray-700">Final Total</span>
              <span className="font-600">{inr(finalTotal)}</span>
            </Flex>
            <Flex className="!justify-between">
              <span className="text-gray-700">Seller Expense</span>
              <span className="font-600 text-red-400">
                {inr(form.values.seller_expense_amount || 0)}
              </span>
            </Flex>
            <hr className="border-dashed" />
            <Flex className="!justify-between">
              <span className="font-500">
                <span className="text-green-400 text-base">
                  Seller Payable Amount
                </span>{" "}
                <span className="text-sm text-gray-600">
                  (i.e. Final total - Seller expense)
                </span>
              </span>
              <span className="font-600">
                {inr(form.values?.seller_receivable_amount || 0)}
              </span>
            </Flex>
          </FormCard>
          <TermsAndConditions
            isDisabled={isDisabled}
            kind={form.values.kind}
            mode={form.values.mode}
            sharedDeptId={sharedDeptId}
            seller_tnc={form.values.seller_tnc}
            buyer_tnc={form.values.buyer_tnc}
            onChange={(path, value) => form.setFieldValue(path, value)}
          />
        </div>
      </Group>
      <footer className="fixed bg-white left-0 bottom-0 w-full p-2 shadow-sm flex justify-end gap-4">
        <Button
          onClick={() => {
            if (!form.values.seller?.id) {
              return toast.custom(
                <Toast
                  intent="danger"
                  title="Please select a farmer before proceeding"
                />
              )
            }

            if (!form.values.trades?.[0]?.items?.[0]?.grade?.id) {
              return toast.custom(
                <Toast
                  intent="danger"
                  title="Please input atleast one trade item before proceeding"
                />
              )
            }

            setOpen(true)
          }}
          loading={fetching}
          leftIcon={<CheckCircle />}
        >
          {isDisabled
            ? "View Summary"
            : `${form.values.id ? "Save" : "Create"} ${
                form.values.kind === "final" ? "Hishob Patti" : "Sauda Pavti"
              }`}
        </Button>
      </footer>
      <CreateReceiptSummary
        isLoading={fetching}
        receipt={form.values}
        primaryActionButtonProps={{
          text: "Save",
          disabled: isDisabled,
          leftIcon: <CheckCircle />,
          onClick: isDisabled ? undefined : (handleSubmit() as any),
          loading: fetching,
        }}
        isOpen={isOpen}
        onClose={() => setOpen(false)}
      />
    </>
  )
}

export default ReceiptDetail
