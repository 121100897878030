import { useLocalStorage } from "@mantine/hooks"
import * as Sentry from "@sentry/browser"
import { AppShell, Selector, UserProfileDrawer } from "@vesatogo/grass-core"
import classNames from "classnames"
import { useEffect } from "react"
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom"
import BizLogo from "~/assets/logo.svg"
import { AuthorizationProvider } from "~/components/AuthorizationProvider"
import { Permission } from "~/constants/Permissions"
import {
  useAllDepartmentsQuery,
  useAuthenticatedUserQuery,
} from "../generated/graphql"
import { dashboardRoutes } from "../routes/dashboard.routes"
import { useURQLClient } from "../services/urql.service"

const permissionsList = Object.values(Permission)

const DashboardLayout = ({ contentClassName = undefined }: any) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [{ data }] = useAuthenticatedUserQuery()
  const { resetClient } = useURQLClient()
  const me = data?.me
  const navLinks = dashboardRoutes
    .filter(route => !route.hide)
    .map(route => {
      return {
        renderLink: icon => <Link to={route.path?.toString()}>{icon}</Link>,
        id: route.path,
        title: route.title,
        icon: route.icon,
        isActive: pathname.includes(route.path),
      }
    })

  const [department, setDepartment] = useLocalStorage({ key: "department" })
  const [{ data: dData }] = useAllDepartmentsQuery()
  const departments = dData?.department || []
  useEffect(() => {
    if (pathname === "/d") {
      navigate("trade-requests")
    }
  }, [pathname, navigate])

  useEffect(() => {
    if (!localStorage.getItem("token") && !data?.me?.id) {
      navigate("/")
    }
  }, [data])

  useEffect(() => {
    if (me?.id) {
      Sentry.setUser({
        id: me.id,
        number: me.number,
        username: me.full_name as string,
        email: me.email,
        segment: import.meta.env.VITE_API_URL,
      })
    }
  }, [me])
  return (
    <AuthorizationProvider permissions={permissionsList}>
      <AppShell
        logoUrl={BizLogo}
        links={navLinks}
        className="[&>nav]:overflow-visible [&>nav]:z-20"
        headerClassName="flex items-center p-4 z-20 justify-between overflow-visible"
        contentClassName="text-gray-700 bg-gray-200"
        header={
          <div className="flex items-center justify-between w-full">
            <span className="pl-1 text-gray-800 font-700 text-lg">
              {navLinks.find(link => link.isActive)?.title}
            </span>
            <UserProfileDrawer
              version={import.meta.env.VITE_APP_VERSION || "na"}
              user={{
                name: me?.full_name as any,
                avatar: me?.image?.url as any,
                department: departments?.find(item => item?.id === department)
                  ?.name,
                phone: me?.number,
                uid: me?.id,
              }}
              onLogout={() => {
                localStorage.removeItem("token")
                resetClient()
                navigate("/")
              }}
            >
              <Selector
                options={departments}
                className="ml-auto mr-8 z-20"
                value={departments?.find(item => item?.id === department)}
                onChange={(value: any) => setDepartment(value?.id || "")}
                isClearable={false}
              />
            </UserProfileDrawer>
          </div>
        }
      >
        <main
          className={classNames(" text-gray-700 bg-gray-200", contentClassName)}
        >
          <Outlet />
        </main>
      </AppShell>
    </AuthorizationProvider>
  )
}

export default DashboardLayout
