import { useForm, yupResolver } from "@mantine/form"
import { openConfirmDialog } from "@vesatogo/grass-confirm-modal"
import {
  Button,
  Card,
  FormInput,
  FormQuerySelector,
  Modal,
  SpinnerOverlay,
  Toast,
} from "@vesatogo/grass-core"
import { FormDateInput } from "@vesatogo/grass-dates"
import dayjs from "dayjs"
import { omit, pick } from "lodash-es"
import { useEffect } from "react"
import toast from "react-hot-toast"
import { useSearchParams } from "react-router-dom"
import { FileUpload } from "~/components/dropzone/FileUploader/FileUploader"
import Codenames from "~/constants/Codenames"
import {
  Address_Constraint,
  Address_Update_Column,
  Auth_Group_Constraint,
  Auth_Group_Update_Column,
  Base_User_Constraint,
  Base_User_Groups_Constraint,
  Base_User_Groups_Update_Column,
  Base_User_Update_Column,
  Document_Constraint,
  Document_Update_Column,
  Media_Constraint,
  Media_Update_Column,
  Transport_Vehicle_Constraint,
  Transport_Vehicle_Update_Column,
  useAllGroupsQuery,
  useAllNonEmployeeUsersQuery,
  useBaseUserDetailQuery,
  useDeleteAttachmentMutation,
  useGetDocumentKindsQuery,
  useInsertVehicleMutation,
  useVehicleDetailsQuery,
} from "~/generated/graphql"
import { driverModel } from "~/models/driver"
import { getFileUploadProps, getInputProps } from "~/utils/form-helpers"

export const AddDriverModal = ({ refetch }) => {
  const [params, setParams] = useSearchParams()
  const vehicleId = params.get("vehicleId") || ""
  const formType = params?.get("form")
  const show = vehicleId !== "" && vehicleId !== "new" && formType === "driver"
  const [{ data: documentKinds }] = useGetDocumentKindsQuery()
  const [, deleteAttachment] = useDeleteAttachmentMutation()
  const driverLicenseKind = documentKinds?.document_kind?.filter(
    kind => kind?.codename === Codenames.DRIVER_LICENSE
  )[0]

  const form = useForm<any>({
    initialValues: {
      license: {
        photo: [],
      },
    },
    validate: yupResolver(driverModel),
    validateInputOnChange: true,
    validateInputOnBlur: true,
    clearInputErrorOnChange: false,
  })

  const state = form.values
  const setState = form.setValues

  const isDisabled = state?.user_search

  const [{ data }] = useVehicleDetailsQuery({
    variables: {
      id: parseInt(vehicleId),
    },
    pause: !show,
  })

  const [{ data: driverData, fetching }] = useBaseUserDetailQuery({
    variables: {
      id: state?.user_search?.id,
    },
    pause: !state?.user_search?.id,
  })

  const [{ fetching: insertFetching }, insertVehicle] =
    useInsertVehicleMutation()

  const [{ data: vehicleDriverGroup }] = useAllGroupsQuery({
    variables: {
      where: {
        name: {
          _eq: Codenames.VehicleDriverGroup,
        },
      },
    },
  })

  useEffect(() => {
    const driver =
      (state?.user_search?.id !== undefined && driverData?.base_user_by_pk) ||
      data?.transport_vehicle_by_pk?.driver
    const driverDoc = driver?.documents?.filter(
      doc => doc?.kind?.codename === Codenames.DRIVER_LICENSE
    )?.[0]
    setState(val => ({
      ...driver,
      first_name: driver?.first_name,
      last_name: driver?.last_name,
      status: driver?.status || "active",
      current_address: driver?.address,
      number: driver?.number,
      registration_number: data?.transport_vehicle_by_pk?.registration_number,
      vehicle_type: {
        name: data?.transport_vehicle_by_pk?.vehicle_type?.name,
        category: data?.transport_vehicle_by_pk?.vehicle_type?.category,
      },
      driver_id: state?.user_search !== null ? driver?.id : undefined,
      documents: {
        issued_on: driverDoc?.issued_on
          ? dayjs(driverDoc?.issued_on).format("YYYY-MM-DD")
          : undefined,
        valid_till: driverDoc?.valid_till
          ? dayjs(driverDoc?.valid_till).format("YYYY-MM-DD")
          : undefined,
        id_number: driverDoc?.id_number,
        attachments: driverDoc?.attachments || [],
      },
    }))
  }, [data, driverData, state?.user_search])

  const onClose = () => {
    setParams({})
    form?.reset()
  }

  const onSubmit = async () => {
    const { errors, hasErrors } = form?.validate()
    if (hasErrors) {
      return toast.custom(
        <Toast
          intent="danger"
          title={"Please resolve all errors before proceeding!"}
        />
      )
    }
    const { data: insertData, error } = await insertVehicle({
      object: {
        ...omit(
          data?.transport_vehicle_by_pk,
          "fuel_type",
          "created_at",
          "vehicle_type",
          "status",
          "owner",
          "__typename"
        ),
        id: vehicleId ? vehicleId : undefined,
        vehicle_type_id: data?.transport_vehicle_by_pk?.vehicle_type?.id,
        fuel_type_id: data?.transport_vehicle_by_pk?.fuel_type?.id,
        loading_capacity: 0,
        passenger_capacity: 0,

        driver: {
          data: {
            ...pick(
              state,
              "id",
              "username",
              "first_name",
              "last_name",
              "email",
              "number",
              "gender",
              "date_of_birth",
              "status"
            ),
            username: state?.driver_id ? state?.username : state?.number,
            gender: state?.gender || "Male",
            email: state?.email || "",
            date_of_birth: state?.date_of_birth || "01-01-1997",
            id: state?.driver_id,
            groups:
              data?.transport_vehicle_by_pk?.driver?.groups?.length !== 0
                ? {
                    data:
                      data?.transport_vehicle_by_pk?.driver?.groups?.map(
                        group => {
                          return {
                            id: group?.id,
                            group: {
                              data: {
                                id: group?.group?.id,
                                name: group?.group?.name,
                              },
                              on_conflict: {
                                constraint: Auth_Group_Constraint.AuthGroupPkey,
                                update_columns: [
                                  Auth_Group_Update_Column.Id,
                                  Auth_Group_Update_Column.Name,
                                ],
                              },
                            },
                          }
                        }
                      ) || [],
                    on_conflict: {
                      constraint:
                        Base_User_Groups_Constraint.BaseUserGroupsPkey,
                      update_columns: [
                        Base_User_Groups_Update_Column.Id,
                        Base_User_Groups_Update_Column.GroupId,
                      ],
                    },
                  }
                : vehicleDriverGroup?.auth_group?.[0]?.id !== undefined
                ? {
                    data: [
                      {
                        group_id: vehicleDriverGroup?.auth_group?.[0]?.id,
                      },
                    ],
                    on_conflict: {
                      constraint:
                        Base_User_Groups_Constraint.BaseUserGroupsPkey,
                      update_columns: [
                        Base_User_Groups_Update_Column.BaseuserId,
                        Base_User_Groups_Update_Column.GroupId,
                        Base_User_Groups_Update_Column.Id,
                      ],
                    },
                  }
                : undefined,
            documents: {
              data: state?.documents?.id_number
                ? [
                    {
                      id: data?.transport_vehicle_by_pk?.driver?.documents?.[0]
                        ?.id,
                      ...state?.documents,
                      kind_id: driverLicenseKind?.id,
                      id_number: state?.documents?.id_number,
                      issued_on: state?.documents?.issued_on
                        ? dayjs(state?.documents?.issued_on).format(
                            "YYYY_MM_DD"
                          )
                        : "01-01-1997",
                      attachments:
                        state?.documents?.attachments?.length > 0
                          ? {
                              data: [
                                {
                                  ...pick(
                                    state?.documents?.attachments?.[0],
                                    "kind",
                                    "url",
                                    "is_encrypted",
                                    "key",
                                    "id"
                                  ),
                                },
                              ],
                              on_conflict: {
                                constraint: Media_Constraint.MediaPkey,
                                update_columns: [
                                  Media_Update_Column.Id,
                                  Media_Update_Column.DocumentId,
                                ],
                              },
                            }
                          : undefined,
                    },
                  ]
                : [],
              on_conflict: {
                constraint: Document_Constraint.DocumentPkey,
                update_columns: [
                  Document_Update_Column.BaseUserId,
                  Document_Update_Column.Id,
                ],
              },
            },
            address: state?.current_address
              ? {
                  data: {
                    ...omit(state?.current_address, "__typename"),
                  },
                  on_conflict: {
                    constraint: Address_Constraint.AddressPkey,
                    update_columns: [Address_Update_Column.Country],
                  },
                }
              : undefined,
          },
          on_conflict: {
            constraint: Base_User_Constraint.BaseUserPkey,
            update_columns: [
              Base_User_Update_Column.AddressId,
              Base_User_Update_Column.Username,
            ],
          },
        },
      },
      on_conflict: {
        constraint: Transport_Vehicle_Constraint.TransportVehiclePkey,
        update_columns: [
          Transport_Vehicle_Update_Column.Id,
          Transport_Vehicle_Update_Column.DriverId,
        ],
      },
    })

    if (error) {
      return toast.custom(
        <Toast
          intent="danger"
          title={"Something went wrong! " + error.message}
        />
      )
    }
    if (insertData) {
      onClose()
      toast.custom(
        <Toast
          title={
            vehicleId === "new"
              ? "Vehicle added successfully!"
              : "Vehicle updated successfully!"
          }
        />
      )
    }
  }

  return (
    <Modal
      bodyClassName="overflow-visible w-[60%]"
      title="Driver details"
      contentClassName="bg-gray-100"
      footer={
        <div className="flex justify-end gap-3">
          <Button size="sm" onClick={onClose} variant="outline">
            Cancel
          </Button>
          <Button loading={insertFetching} size="sm" onClick={onSubmit}>
            Save
          </Button>
        </div>
      }
      isOpen={show}
      onClose={onClose}
    >
      <Card className="p-4">
        <div className="flex flex-col gap-6">
          <SpinnerOverlay show={fetching} />
          <Card className="p-2 !rounded-lg text-sm w-fit">
            Vehicle -{" "}
            <span className="text-gray-800 font-600">
              {state?.registration_number}{" "}
            </span>
            ({state?.vehicle_type?.name + " - " + state?.vehicle_type?.category}
            )
          </Card>
          <div className="flex flex-col gap-2">
            <FormQuerySelector
              label={
                <div>
                  Populate <span className="text-blue-300">this Driver </span>{" "}
                  from the list of existing users
                </div>
              }
              dataHook={useAllNonEmployeeUsersQuery}
              className={"w-full col-span-2 "}
              size="sm"
              variables={{
                where: {
                  groups: {
                    group: {
                      name: {
                        _in: [
                          Codenames.FarmerGroup,
                          Codenames.TraderGroup,
                          Codenames.VehicleDriverGroup,
                          Codenames.VehicleOwnerGroup,
                        ],
                      },
                    },
                  },
                },
              }}
              value={isDisabled}
              onChange={e => {
                if (e === null) setState({ user_search: undefined })
                setState({ user_search: e })
              }}
            />
            <div className="grid grid-cols-3 gap-4">
              <FormInput
                label="First Name"
                required
                disabled={isDisabled}
                {...getInputProps(form, "first_name")}
              />
              <FormInput
                label="Last Name"
                required
                disabled={isDisabled}
                {...getInputProps(form, "last_name")}
              />
              <FormInput
                label="Mobile Number"
                required
                disabled={isDisabled}
                {...getInputProps(form, "number")}
                maxLength={10}
              />
            </div>
          </div>
          <div className="text-sm hidden">
            Or{" "}
            <span
              className="text-blue-300 cursor-pointer"
              onClick={() => {
                setState({ driver_id: undefined, user_search: null })
              }}
            >
              click here
            </span>{" "}
            to create new
          </div>
          <h1 className="font-600">License Details</h1>
          <div className="grid grid-cols-3 gap-4">
            <div className="w-[30%]">
              <FileUpload
                disabled={isDisabled}
                {...getFileUploadProps(form, "documents.attachments", 1)}
                onDelete={e => {
                  const onDelete = async () => {
                    const { error } = await deleteAttachment({
                      id: e.id,
                    })
                    if (error) {
                      return toast.custom(
                        <Toast
                          intent="danger"
                          title={"Something went wrong! " + error.message}
                        />
                      )
                    } else {
                      refetch?.({
                        requestPolicy: "network-only",
                      })
                      toast.custom(<Toast title={"Certificate deleted!"} />)
                    }
                  }
                  if (e.id) {
                    openConfirmDialog({
                      isOpen: true,
                      onSubmit: () => onDelete(),
                      confirmationButtonText: "Delete",
                      message:
                        "Are you sure you want to delete this attachment?",
                    })
                  }
                  refetch?.({
                    requestPolicy: "network-only",
                  })
                }}
              />
            </div>

            <div className="col-span-2 grid grid-cols-2 gap-4">
              <FormInput
                label="License ID"
                disabled={isDisabled}
                {...getInputProps(form, "documents.id_number")}
              />
              <FormDateInput
                label="Issued On"
                disabled={isDisabled}
                {...getInputProps(form, "documents.issued_on")}
              />
              <FormDateInput
                label="Valid Till"
                disabled={isDisabled}
                {...getInputProps(form, "documents.valid_till")}
              />
            </div>
          </div>
        </div>
      </Card>
    </Modal>
  )
}
