import axios from "axios"
import { ReactNode } from "react"
import {
  FileInput,
  FileInputProps,
} from "~/components/dropzone/FileInput/FileInput"
export type FileUploadProps = FileInputProps & {
  className?: string
  media: any[]
  maxFiles?: number
  multiple?: boolean
  shouldEncrypt?: boolean
  access?: "public-read" | "authenticated-read" | "private"
  children?: ReactNode
  onChange: (newArray: any[]) => void
  getPreviewUrl?: (file: any) => string
  onDelete?: (file: any) => void
  endpoint?: string
  disabled?: boolean
  resolveSrc?: any
  uploadCardClassName?: string
  method?: "post" | "put"
  onError: (message: string) => void
  onSuccess?: (message: any) => void
  "data-testid"?: string
  environment?: string
  appName?: string
}

export const FileUpload = ({
  media,
  maxFiles = 8,
  onChange,
  multiple = true,
  access = "public-read",
  shouldEncrypt = false,
  onDelete,
  uploadCardClassName = "h-32 w-32",
  className,
  endpoint = `https://${import.meta.env.VITE_API_URL}/rest/core/uploader/`,
  onError,
  onSuccess,
  method = "put",
  environment = typeof process !== "undefined"
    ? process.env["NX_ENVIRONMENT"]
    : "",
  appName = "dashboard",
  ...props
}: FileUploadProps) => {
  const uploadFiles = async (files: File[]) => {
    try {
      if (!files?.length) return
      if (media.length + files.length > maxFiles) {
        onError(`Cannot upload more than ${maxFiles} files`)
        return
      }

      const path = [appName, environment].filter(str => str).join("/") + "/"

      const filesPromises = files.map(file => {
        const formData = new FormData()
        formData.append("file", file)
        formData.append(
          "is_encrypted",
          shouldEncrypt?.valueOf()?.toString() || "false"
        )
        formData.append("compress_file", "true")
        // formData.append("name", generateFilename(file))
        return method === "post"
          ? axios.post(endpoint, formData, {
              headers: {
                authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            })
          : axios.put(endpoint, formData, {
              headers: {
                authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            })
      })
      const allFilesPromise = Promise.all(filesPromises)
      const uploadedFiles = await allFilesPromise
      const newFiles = uploadedFiles
        .map(({ data }) => ({
          ...data,
          url: data?.media?.url,
          is_encrypted: data?.media?.is_encrypted,
          private_url: data?.media?.private_url,
          kind: data?.media?.kind,
          access: data?.media?.access,
          id: data?.media?.id,
          key: data?.media?.key,
        }))
        .concat(media)
      onChange(newFiles)
      onSuccess?.(uploadedFiles)
    } catch (err) {
      onError(`Something went wrong!  ${err}`)
    }
  }

  function handleDelete(file) {
    onDelete?.(file)
    onChange(media.filter(media => media.url !== file.url))
  }
  return (
    <FileInput
      className={className}
      uploadCardClassName={uploadCardClassName}
      maxFiles={maxFiles}
      multiple={multiple}
      media={media}
      onChange={files => uploadFiles(files)}
      onDelete={handleDelete}
      {...props}
    />
  )
}
