import * as Yup from "yup"

export const employeeModel = Yup.object().shape({
  first_name: Yup.string().required("Please enter Employee First name"),
  last_name: Yup.string().required("Please enter Employee Last name"),
  dob: Yup.date().required("Please enter Date of Birth"),
  number: Yup.string()
    .matches(/^(\+91[-\s]?)?[0]?(91)?[6789]\d{9}$/, "Invalid Phone Number")
    .required("No primary contact provided"),
  username: Yup.string().required("Please enter Username"),
  gender: Yup.object().required("Please select Gender"),
})
