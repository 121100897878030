import {
  GoogleMap,
  GoogleMapProps,
  useJsApiLoader,
} from "@react-google-maps/api"
import React from "react"
import classNames from "classnames"

type MapProps = GoogleMapProps & {
  onAutoComplete?: (address: any, coordinates: any) => void
  disabled?: boolean
  className?: string
  mapTypeId?: string
  children?: React.ReactNode
}

const libraries: any = ["drawing", "places", "visualization"]

const Map = ({
  children,
  onAutoComplete,
  disabled,
  mapTypeId,
  ...props
}: MapProps) => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: import.meta.env.VITE_MAP_API || "",
    libraries,
  })

  const renderMap = () => {
    return (
      <GoogleMap
        {...props}
        options={{
          disableDefaultUI: disabled,
          keyboardShortcuts: false,
          mapTypeId,
        }}
        mapContainerClassName={classNames("kt-map", props.className)}
        center={props.center}
        zoom={props.zoom}
      >
        {children}
      </GoogleMap>
    )
  }

  if (loadError) return <span>{loadError.message}</span>

  return isLoaded ? renderMap() : null
}

Map.defaultProps = {
  zoom: 15,
  mapTypeId: "hybrid",
}

export default React.memo(Map)
