import { Storefront } from "@phosphor-icons/react"
import {
  Button,
  Card,
  FormInput,
  FormQuerySelector,
  Toast,
} from "@vesatogo/grass-core"
import classNames from "classnames"
import { omit } from "lodash-es"
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import AddressForm from "~/components/Registration/AddressForm"
import { SectionCard } from "~/components/Registration/SectionCard"
import { MandiSectionCommonType } from "~/constants/registration/MandiRegistrationFormSections"
import {
  Address_Constraint,
  Address_Update_Column,
  Market_Constraint,
  Market_Update_Column,
  useInsertMarketMutation,
  useMarketKindsQuery,
} from "~/generated/graphql"
type MandiBasicDetailsProps = MandiSectionCommonType & {}
export const MandiBasicDetails = ({ form }: MandiBasicDetailsProps) => {
  const [{ fetching }, upsertMandi] = useInsertMarketMutation()
  const navigate = useNavigate()
  async function handleSubmit() {
    const basic = form.values.basic as any
    const id = form.values.id || undefined
    const { data, error } = await upsertMandi({
      object: {
        id,
        name: basic?.name,
        kind_id: basic?.kind?.id,
        address: form.values.basic?.address && {
          data: {
            name: "",
            local_area: "",
            ...omit(form.values.basic?.address, ["__typename"]),
          },
          on_conflict: {
            update_columns: Object.values(Address_Update_Column).filter(
              k => k !== Address_Update_Column.Id
            ),
            constraint: Address_Constraint.AddressPkey,
          },
        },
        is_active: true,
        description: "",
        area_color: "",
      },
      on_conflict: {
        update_columns: [
          Market_Update_Column.Name,
          Market_Update_Column.KindId,
          Market_Update_Column.AddressId,
        ],
        constraint: Market_Constraint.MarketPkey,
      },
    })
    if (error?.message)
      return toast.custom(<Toast intent="danger" title={error?.message} />)
    if (data?.insert_market_one?.id) {
      toast.custom(<Toast title={"Basic details saved successfully"} />)
      navigate(`/d/admin/mandi/${data.insert_market_one?.id}`)
      return
    }
  }
  return (
    <>
      <SectionCard
        cardClassName="min-h-[80vh] overflow-auto"
        icon={Storefront}
        title={"Basic Details"}
        className="flex gap-4 w-full"
      >
        <Card className="p-4 w-full">
          <div>
            <AddressForm
              useNestedFieldSet
              setFieldValue={form.setFieldValue}
              setValues={form.setValues}
              getInputProps={form.getInputProps}
              mapClassName="!w-full"
              mapWidth="full"
              address={form.values.basic?.address}
              addressKey={"basic.address"}
              formClassName={classNames("w-full", "!grid-cols-3")}
              className="flex-col"
              noteClassName={"col-span-3 "}
            >
              <div className="col-span-3 grid-cols-3 grid gap-3">
                <FormInput
                  label="Mandi Name"
                  required
                  {...form.getInputProps("basic.name")}
                />
                <FormQuerySelector
                  label="Type of Mandi"
                  required
                  dataHook={useMarketKindsQuery}
                  serverSideQuery
                  {...form.getInputProps("basic.kind")}
                />
              </div>
            </AddressForm>
          </div>
          <div className="flex gap-3 mt-3 justify-end">
            <Button loading={fetching} onClick={handleSubmit}>
              Save
            </Button>
          </div>
        </Card>
      </SectionCard>
    </>
  )
}
