import { MagnifyingGlass, Plus } from "@phosphor-icons/react"
import { Button, InputGroup } from "@vesatogo/grass-core"
import { TableView } from "@vesatogo/grass-table"
import { useSearchParams } from "react-router-dom"
import { AddDriverModal } from "~/components/Admin/AddDriverModal"
import { AddOwnerModal } from "~/components/Admin/AddOwnerModal"
import { AddVehicleModal } from "~/components/Admin/AddVehicleModal"
import NewTag from "~/components/Admin/NewTag"
import { StyledLink } from "~/components/Admin/StyledLink"
import { Order_By, useAllVehiclesQuery } from "~/generated/graphql"
import { config } from "~/pages/pavti/config"

const COMMON_FIELDS = []

export const UserDisplay = ({ user, type, onClick }) => {
  return (
    <span>
      <StyledLink onClick={onClick}>
        {user ? user.full_name : `+ Add ${type}`}
      </StyledLink>
      {user && user.number && <p className="mb-0">{user.number}</p>}
    </span>
  )
}

export const VehicleMaster = () => {
  const [params, setParams] = useSearchParams()
  const search = params.get("search")
  const page = Number(params.get("page")) || 1

  const [{ data: allVehicles }, refetch] = useAllVehiclesQuery({
    variables: {
      first: config.pageSize,
      skip: (page - 1) * config.pageSize,
      order_by: {
        created_at: Order_By.DescNullsLast,
      },
      where: {
        _or: [
          {
            registration_number: {
              _ilike: search ? `%${search}%` : "%%",
            },
          },
          {
            owner: {
              full_name: {
                _ilike: search ? `%${search}%` : "%%",
              },
            },
          },
        ],
      },
    },
  })

  const totalVehicles =
    allVehicles?.transport_vehicle_aggregate?.aggregate?.count || 0

  const COLUMNS = [
    // {
    //   accessor: "id",
    //   Header({ data }) {
    //     const selectable = data?.filter(d => d.id)
    //     const { selected, selectAll } = useTradeSelectorList()
    //     const isSelectAll =
    //       Object.keys(selected)?.length &&
    //       Object.keys(selected)?.length === selectable.length
    //     return (
    //       <Checkbox
    //         labelClassName="!mb-4"
    //         checked={isSelectAll ? true : false}
    //         onChange={() => {
    //           selectAll(selectable?.map(data => pick(data, COMMON_FIELDS)))
    //         }}
    //       />
    //     )
    //   },
    //   Cell({ value, row }) {
    //     const { selected, setSelected } = useTradeSelectorList()
    //     if (!row?.original?.id) return null

    //     return (
    //       <Checkbox
    //         checked={selected[value] ? true : false}
    //         onChange={() => {
    //           setSelected(pick(row.original, COMMON_FIELDS))
    //         }}
    //       />
    //     )
    //   },
    // },
    {
      Header: "Sr. No.",
      accessor: "id",
      className: "!text-center",
      Cell({ row }) {
        return parseInt(row?.id) + 1
      },
    },
    ,
    {
      Header: "Registration Number",
      accessor: "registration_number",
      Cell({ value, row }) {
        return (
          // <div
          //   className="cursor-pointer"
          //   onClick={() => {
          //     params.set("id", row?.original?.id)
          //     setParams(params)
          //   }}
          // >
          //   {value}
          // </div>
          <div
            className="cursor-pointer"
            onClick={() => {
              params.set(
                "vehicleId",
                row?.original?.id ? row?.original?.id : "new"
              )
              params.set("form", "vehicle")
              setParams(params)
            }}
          >
            <span>{value}</span>
            <NewTag
              createdAt={row.original.created_at}
              tolerance={7}
              intent="info"
            />
          </div>
        )
      },
    },
    {
      Header: "Owner",
      accessor: "owner",
      Cell({ value, row }) {
        return (
          <UserDisplay
            type="owner"
            user={value}
            onClick={() => {
              params.set(
                "vehicleId",
                row?.original?.id ? row?.original?.id : "new"
              )
              params.set("form", "owner")
              setParams(params)
            }}
          />
        )
      },
    },
    {
      Header: "Driver",
      accessor: "driver",
      Cell({ value, row }) {
        return (
          // <div className="flex flex-col">
          //   <p>{value?.fullName}</p>
          //   <p className="text-gray-500">{value?.number}</p>
          // </div>
          <UserDisplay
            type="driver"
            user={value}
            onClick={() => {
              params.set(
                "vehicleId",
                row?.original?.id ? row?.original?.id : "new"
              )
              params.set("form", "driver")
              setParams(params)
            }}
          />
        )
      },
    },
    {
      Header: "Specifications",
      accessor: "vehicle_type",
      Cell({ value }) {
        return (
          <div className="flex flex-col">
            <p>{value?.name + ` (${value?.category})`}</p>
            <p>
              <span className="text-gray-500">Capacity - </span>
              {value?.capacity + " KGM"}
            </p>
          </div>
        )
      },
    },
    {
      Header: "Estd. Average",
      accessor: "estimated_average",
      Cell({ value }) {
        return value + " km/l"
      },
    },

    // {
    //   Header: "Contract",
    //   accessor: "currentContract",
    //   Cell({ value }) {
    //     return (
    //       <span>
    //         <StyledLink
    //           onClick={() => {
    //             params.set("contractId", value?.id ? value?.id : "new")
    //             setParams(params)
    //           }}
    //         >
    //           {value ? value?.contractType.name : `+ Add contract`}
    //         </StyledLink>
    //       </span>
    //     )
    //   },
    // },

    // {
    //   Header: "Total Outstanding",
    //   accessor: "currentContract.totalOutstanding",
    //   Cell({ value }) {
    //     return value !== undefined ? "₹" + value : "-"
    //   },
    // },
    // {
    //   Header: "Alerts",
    //   accessor: "alerts",
    //   Cell({ value }) {
    //     return <ShowAlerts alerts={value} />
    //   },
    // },
  ]

  return (
    <>
      <header className="grid grid-cols-3 p-2 border-b-1 border-b-gray-300">
        <div className="self-center font-500 pl-4">Vehicle Master</div>
        <div className="w-[500px] flex items-center gap-3">
          <InputGroup
            className={"w-full"}
            inputProps={{
              value: search || "",
              placeholder: "Search",

              onChange(e) {
                params.set("search", e.target.value)
                setParams(params)
              },
            }}
            leftElement={<MagnifyingGlass />}
          />
        </div>
        <div className="text-end">
          <Button
            onClick={() => {
              params.set("vehicleId", "new")
              params.set("form", "vehicle")
              setParams(params)
            }}
            leftIcon={<Plus />}
          >
            Vehicle
          </Button>
        </div>
      </header>
      <TableView
        relative={false}
        paginationProps={{
          total: Math.ceil(totalVehicles / config.pageSize),
          page: page,
          onChange(page) {
            params.set("page", page.toString())
            params.set("search", "")
            setParams(params)
          },
        }}
        columns={COLUMNS}
        data={allVehicles?.transport_vehicle || []}
        meta={<>{totalVehicles} Vehicles</>}
        getCellProps={cell => {
          if (cell?.column?.Header === "Sr. No.") {
            return {
              className: "!text-center",
            }
          }
        }}
        className="!h-[calc(100vh-64px-57px)]"
      />
      <AddVehicleModal onAddComplete={refetch} />
      <AddOwnerModal />
      <AddDriverModal refetch={refetch} />
    </>
  )
}

export default VehicleMaster
