import { Button } from "@vesatogo/grass-core"

const MandiFormSections = ({ onSubmit, onReset, loading }: any) => {
  return (
    <div className="flex gap-3 justify-end">
      <Button
        loading={loading}
        onClick={onReset}
        variant="outline"
        className="hidden"
      >
        Reset
      </Button>
      <Button loading={loading} onClick={onSubmit}>
        Save
      </Button>
    </div>
  )
}

export default MandiFormSections
