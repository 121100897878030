import classNames from "classnames"

export type StyledLinkProps = {
  className?: string
  onClick?: () => any
  children?: any
}

export const StyledLink = ({
  className,
  onClick,
  children,
}: StyledLinkProps) => {
  return (
    <p
      className={classNames(
        "border-b-1 border-dashed  border-gray-600 text-gray-800 inline cursor-pointer ",
        className
      )}
      onClick={onClick}
    >
      {children}
    </p>
  )
}
