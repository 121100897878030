import dayjs from "dayjs"

/**
 *
 * @param timestamp Timestamp the compare to
 * @returns boolean
 * @description Checks if the timestamp is new given the tolerance unit and value
 */

export type ToleranceUnit = "minutes" | "hours" | "days"
const isNew = (
  timestamp: string | Date | number | null,
  tolerance: number,
  unit: ToleranceUnit = "minutes"
) => {
  if (!timestamp) return null
  return dayjs().diff(timestamp, unit) < tolerance
}

export default isNew
