enum Codenames {
  DefaultCurrency = "92da8cf5-28e0-481e-9bc7-519713e48c7e",
  PercentageUnit = "6f17f4c8-bde4-40e3-97c1-75c507564429",
  EagleTestRequest = "a23c8ca4-d161-4791-a549-ba774766ad49",
  TRADE_UNIT_WEIGHT = "3022ef73-1d09-47aa-8960-951d9d38e738",
  PAN_CARD = "cc3f9c28-696c-4b0b-b8af-4a9e65d821b8",
  PAN_CARD_BUSINESS = "6c335e96-e204-4bbd-bec0-5007c9c602fb",
  GST_BUSINESS = "f41ace9e-6fa4-4c2f-a2b9-88a320090771",
  GST_BASE_USER = "6f2fd713-1ce3-4540-ab67-3e2d9b8864df",
  SHOP_ACT = "81641f49-0952-4932-b268-989bdd3e1383",
  MARKET_LICENSE = "1bb3a32a-6473-4105-a099-8ba781c4666e",
  DRIVER_LICENSE = "4be0181d-7b06-4f21-a60f-aea63a7f8f4f",
  AADHAR = "be698d2f-53ea-4061-9d66-704fc12415e0",
  MH_CODE = "ffec1b4f-c229-42c0-a37b-ab4e47624425",
  GGN = "GGN",
  ACRE = "462f64f6-bb32-498e-bccf-ba964605fcd1",
  SHAREHOLDER_COMPANY = "81db4d53-ecff-43ba-b8c8-e17a21f7ad72",
  SUB_REGION = "2c197e31-6670-447f-a53f-1ce6705fbdb6",
  REGION_DISTRICT = "6d78ca7f-5943-42f2-9e89-810ec083584f",
  EmployeeGroup = "Employee",
  VehicleOwnerGroup = "Vehicle Owner",
  VehicleDriverGroup = "Vehicle Driver",
  FarmerGroup = "Farmer",
  TraderGroup = "Trader",
  BusinessPOC = "Business POC",
}

export default Codenames
