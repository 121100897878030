import { CheckCircle, Plus, Trash } from "@phosphor-icons/react"
import {
  Breadcrumbs,
  Button,
  Checkbox,
  FormGroup,
  FormInput,
  Group,
  InputGroup,
  QuerySelector,
  Tag,
  Text,
  Toast,
} from "@vesatogo/grass-core"
import { DateInput } from "@vesatogo/grass-dates"
import "@vesatogo/grass-dates/index.css"
import { inr } from "@vesatogo/utils"
import classNames from "classnames"
import dayjs from "dayjs"
import { cloneDeep, debounce, get, isEmpty, omit, set } from "lodash-es"
import { useEffect, useMemo, useState } from "react"
import toast from "react-hot-toast"
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom"

import { useForm } from "@mantine/form"
import SendConsent from "~/components/SendConsent"
import Codenames from "~/constants/Codenames"
import SharedEntityGroup from "~/constants/SharedEntityGroup"

import { FormCard } from "~/components/FormCard"
import HowIsItTraded from "~/components/HowIsItTraded"
import TermsAndConditions from "~/components/TermsAndConditions"
import TradeExpenses from "~/components/TradeExpenses"
import WhatIsBeingTraded from "~/components/WhatIsBeingTraded"
import { WhoIsTrading } from "~/components/WhoIsTrading"
import { TradeKinds } from "~/constants/Kinds"
import {
  BookingTradeSummaryQuery,
  Expense_Constraint,
  Expense_Update_Column,
  TradeDetailQuery,
  Trade_Item_Constraint,
  Trade_Item_Update_Column,
  useAllSiUnitsQuery,
  useAllVarietyGradesQuery,
  useAllVehiclesQuery,
  useBookingTradeSummaryQuery,
  useCurrencyQuery,
  useDeleteExpenseItemMutation,
  useDeleteTradeItemMutation,
  useIncrementTradeCounterMutation,
  useTradeDetailQuery,
  useUpsertTradeMutation,
} from "~/generated/graphql"
import { getBookingQuantity } from "~/utils/bookingPavtiUtils"
import { expenseAmount, normalizeExpenses } from "~/utils/expenseAmount"
import { useDepartment } from "../../../utils/useDepartment"
import { SideBar } from "./sections/SideBar"

const INITIAL_STATE = {
  mode: "direct",
  kind: "pro_forma",
  packaging: "raw_material",
  items: [{}],
  start_date: new Date(),
  end_date: null,
  expiry: null,
  expenses: [{}],
}

type SharedEntity = {
  group: SharedEntityGroup
}

const TradeRequestDetail = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [{ fetching, data: uData }, upsertTrade] = useUpsertTradeMutation()
  const [, deleteTradeItem] = useDeleteTradeItemMutation()
  const [, deleteExpenseItem] = useDeleteExpenseItemMutation()
  const [, incrementTradeCounter] = useIncrementTradeCounterMutation()
  const { id: sharedDeptId } = useDepartment()
  const [{ data: sData }] = useCurrencyQuery({
    variables: {
      codename: Codenames.DefaultCurrency,
    },
  })

  const defaultCurrency = sData?.unit?.[0]
  const { id } = useParams()
  const form = useForm<any>({
    initialValues: INITIAL_STATE,
  })

  const qcForm = useForm<any>({
    initialValues: {
      results: [],
    },
  })
  const { values: state, setValues: setState } = form
  const [isOpen, setOpen] = useState(false)
  const idNum = parseInt(id as any)

  const [removedTradeItems, setRemovedTradeItems] = useState<
    Record<string, any>
  >({})
  const [removedExpenseItems, setRemovedExpenseItems] = useState<
    Record<string, any>
  >({})
  const [{ data: dData }] = useTradeDetailQuery({
    variables: { id: idNum },
    pause: !idNum,
    requestPolicy: "network-only",
  })

  // Disable the form is the trade has a receipt associated with it because it will create mismatch in receipt calculations.
  const hasReceipt = dData?.trade_by_pk?.receipt_id

  function updateTradeState(
    trade?: TradeDetailQuery["trade_by_pk"],
    bookingGrades?: BookingTradeSummaryQuery
  ) {
    if (!trade) return

    console.log(trade.items[0].quantity_unit)
    const newState = {
      ...trade,
      kind: newHishobPatti ? TradeKinds.HishobPatti : trade.kind,
      start_date: trade.start_date && new Date(trade.start_date),
      end_date: trade.end_date && new Date(trade.end_date),
      expiry: trade.expiry && new Date(trade.expiry),
      quantity_unit: trade.items[0].quantity_unit,
      items: trade.items?.length
        ? trade.items.map(item => ({
            ...item,
            quantity: searchParams.get("new")
              ? getBookingQuantity(
                  bookingGrades,
                  item.commodity_variety_grade?.id
                )
              : item.quantity,
          }))
        : [{}],
      expenses: normalizeExpenses(trade.expenses),
      amount_deducted: (trade.total_amount * trade.standard_deduction) / 100,
      quantity_deducted:
        (trade.total_quantity * trade.standard_deduction) / 100,
    }

    setState(newState)
    runComputations(newState)
  }
  const tradeId = dData?.trade_by_pk?.id
  const isBookingTrade = state?.kind === TradeKinds.Booking
  // We use this directly becuase we dont want mutations of the state and we need the
  // last saved value
  const tradeOldKind = dData?.trade_by_pk?.kind

  // Is the trade confirmed by the admin
  const isTradeConfirmed = state?.status_id === "Approved"
  const newHishobPatti = Boolean(searchParams.get("new"))

  // Is the parent trade a Booking Patti
  const isParentBookingTrade =
    state?.parent_trade?.kind === TradeKinds.Booking ||
    (tradeOldKind === TradeKinds.Booking && newHishobPatti)

  // Parent Trade ID
  const parentTradeId = state?.parent_trade?.id

  const isHishobPatti = isTradeConfirmed && newHishobPatti

  const isInheritedHishobPatti =
    (state?.parent_trade || newHishobPatti) &&
    state?.kind === TradeKinds.HishobPatti

  // const isDisabled = hasReceipt ? true : isTradeConfirmed && !isHishobPatti

  // Disabled Conditions
  const isCompletelyDisabled =
    hasReceipt || (isTradeConfirmed && !newHishobPatti)
  const bookingSummaryId = parentTradeId
    ? parentTradeId
    : dData?.trade_by_pk?.trades?.length || newHishobPatti
    ? tradeId
    : undefined
  const [{ data: bookingData }] = useBookingTradeSummaryQuery({
    variables: {
      id: bookingSummaryId,
    },
    pause: !bookingSummaryId,
    requestPolicy: "network-only",
  })

  console.log(state, "BOOKING")

  const moreTrades = bookingSummaryId
    ? bookingData?.trade_grade_quantity_summary_report?.more_trade
    : true

  useEffect(() => {
    if (tradeId) {
      setRemovedTradeItems({})
      updateTradeState(dData?.trade_by_pk, bookingData)
    }
  }, [tradeId, bookingSummaryId, bookingData])

  useEffect(() => {
    if (uData?.insert_trade_one) {
      setRemovedTradeItems({})
      setRemovedExpenseItems({})
      updateTradeState(uData?.insert_trade_one)
    }
  }, [uData])

  useEffect(() => {
    if (
      dData &&
      sharedDeptId &&
      dData?.trade_by_pk?.created_in?.id !== sharedDeptId
    ) {
      navigate("/d/trade-requests")
    }
  }, [sharedDeptId, dData?.trade_by_pk?.created_in?.codename])
  const runComputations = useMemo(
    () =>
      debounce(values => {
        const stateCopy = cloneDeep(values)
        const trade = stateCopy
        let tradeTotalQty = 0,
          tradeTotalAmount = 0,
          totalExpenseAmount = 0
        for (let j = 0; j < trade.items.length; j++) {
          let itemTotalAmount = 0
          const item = trade.items[j]
          const quantity = Number(item.quantity) || 0
          const rate = Number(item.rate) || 0
          itemTotalAmount += quantity * rate
          stateCopy.items[j].total_amount = itemTotalAmount
          tradeTotalAmount += itemTotalAmount
          tradeTotalQty += quantity
        }
        stateCopy.total_quantity = tradeTotalQty
        stateCopy.total_amount = tradeTotalAmount
        // receiptTotalQty += tradeTotalQty
        // receiptTotalAmount += tradeTotalAmount

        stateCopy.amount_deducted =
          stateCopy.total_amount *
          (Number(stateCopy.standard_deduction || 0) / 100)

        stateCopy.quantity_deducted =
          stateCopy.total_quantity *
          (Number(stateCopy.standard_deduction || 0) / 100)

        for (let j = 0; j < stateCopy.expenses.length; j++) {
          const expense = stateCopy.expenses[j]
          const expenseParams = {
            kind: expense?.kind?.id,
            buyerAmount: Number(expense.buyer?.value) || 0,
            sellerAmount: Number(expense.seller?.value) || 0,
            finalAmount: stateCopy.total_amount,
            finalQuantity:
              stateCopy.total_quantity - stateCopy.quantity_deducted,
            totalQuantity: stateCopy.total_quantity,
          }
          expense.amount = expenseAmount(expenseParams)
          totalExpenseAmount += expense.amount
        }
        stateCopy.expense_amount = totalExpenseAmount
        form.setValues(stateCopy)
      }, 500),
    []
  )
  function inputProps(name: string, sharedEntity?: SharedEntity) {
    return {
      value: get(state, name, ""),
      onChange: async (e: any) => {
        const stateCopy = cloneDeep(state)
        let facilitator = stateCopy.seller?.companies?.[0]?.company
        if (name === "mode" && e === "facilitator" && facilitator) {
        } else if (
          (name === "mode" && e === "direct") ||
          (name === "mode" && e === "facilitator" && !facilitator) ||
          (name === "seller" && !e?.companies?.length)
        ) {
          set(stateCopy, "facilitator", null)
          set(stateCopy, "mode", "direct")
        }

        set(stateCopy, name, e?.target ? e.target.value : e)
        runComputations(stateCopy)
        setState(stateCopy)
      },
    }
  }
  const finalTotal =
    (form.values.total_amount || 0) - (form.values.amount_deducted || 0)

  console.log({
    state,
    isHishobPatti,
  })
  return (
    <>
      <header className="bg-gray-100 shadow-sm p-3 border-b-gray-300 border-b flex justify-between">
        <Breadcrumbs
          items={[
            {
              text: "Trade Requests",
              link: "/d/trade-requests",
              isActive: false,
            },
            {
              text: id?.toString() || "New",
              link: `/d/trade-requests/${id}`,
              isActive: true,
            },
          ]}
          linkRenderer={(link, item) => {
            return <Link to={link as any}>{item}</Link>
          }}
        />
        <Link to={`?timeline=${id}`}>Timeline</Link>
      </header>
      <Group className="flex gap-4 px-5 pt-8 pb-16 relative">
        <SideBar
          key={state?.id || `trades_${state?.trades?.length}`}
          id={bookingSummaryId}
          disableNew={!moreTrades}
          kind={state?.parent_trade?.kind || dData?.trade_by_pk?.kind}
        />
        <div className="w-[60%] mx-auto">
          <WhoIsTrading
            form={form}
            inputProps={inputProps}
            isDisabled={isCompletelyDisabled || isInheritedHishobPatti}
            disableBuyer={
              isCompletelyDisabled ||
              (isInheritedHishobPatti && !isParentBookingTrade)
            }
          />
          <HowIsItTraded
            form={form}
            inputProps={inputProps}
            showBookingKinds
            isDisabled={isCompletelyDisabled || isInheritedHishobPatti}
          />
          <WhatIsBeingTraded
            form={form}
            inputProps={inputProps}
            isDisabled={isCompletelyDisabled || isInheritedHishobPatti}
          />
          <FormCard title="When is it traded">
            <div className={"grid grid-cols-4 gap-4"}>
              <FormGroup required label="Start Date of Trade">
                <DateInput
                  {...inputProps("start_date")}
                  disabled={isCompletelyDisabled && !isHishobPatti}
                  popoverPanelClassName={classNames(
                    isCompletelyDisabled ? "hidden" : ""
                  )}
                />
              </FormGroup>
              <FormGroup label="End Date of Trade">
                <DateInput
                  {...inputProps("end_date")}
                  disabled={isCompletelyDisabled && !isHishobPatti}
                  popoverPanelClassName={classNames(
                    isCompletelyDisabled ? "hidden" : ""
                  )}
                />
              </FormGroup>
              {isHishobPatti ? null : (
                <FormGroup label="Validity of Trade" required>
                  <DateInput
                    {...inputProps("expiry")}
                    disabled={isCompletelyDisabled && !isHishobPatti}
                    popoverPanelClassName={classNames(
                      isCompletelyDisabled ? "hidden" : ""
                    )}
                  />
                </FormGroup>
              )}
              <FormGroup label="Vehicle">
                <QuerySelector
                  dataHook={useAllVehiclesQuery}
                  serverSideQuery
                  useWildCardsInQuery={false}
                  variables={{
                    first: 20,
                  }}
                  getOptionLabel={item => item.registration_number}
                  {...inputProps("vehicle")}
                  disabled={isCompletelyDisabled && !isHishobPatti}
                />
              </FormGroup>
            </div>
          </FormCard>
          <FormCard title="Trade Particulars">
            <div className="mb-3 border rounded-lg">
              <header className="flex justify-between items-center p-2">
                <h6>Add Multiple Grades</h6>
                {isCompletelyDisabled ||
                // isBookingTrade ||
                isParentBookingTrade ||
                isHishobPatti ? null : (
                  <Button
                    onClick={() => {
                      setState(prev => ({
                        ...prev,
                        items: prev.items?.length
                          ? [
                              {
                                quantity: 0,
                                commodity_variety_grade: null,
                                rate: 0,
                                rate_unit: prev.items?.[0]?.rate_unit,
                                quantity_unit: prev.items?.[0]?.quantity_unit,
                              },
                              ...prev.items,
                            ]
                          : [{}],
                      }))
                    }}
                    size="sm"
                    leftIcon={<Plus />}
                  >
                    Grade
                  </Button>
                )}
              </header>
              <hr />
              <div className="p-4 border-b">
                {state?.items?.map((item, index) => {
                  let itemRemoved = removedTradeItems[item.id] ? true : false

                  let remainingQuantity = getBookingQuantity(
                    bookingData,
                    item?.commodity_variety_grade?.id
                  )

                  const qcResult = qcForm.values.results.find(
                    result =>
                      result.template.grade.name ===
                      item.commodity_variety_grade?.name
                  )

                  const baseRateKey = qcResult ? "daily_rate" : "rate"
                  const dailyRate = Number(item.daily_rate) || 0
                  const evalutation = qcResult?.evaluations?.[0]
                  const recommendedDeducatedRate =
                    dailyRate - dailyRate * ((evalutation?.value || 0) / 100)

                  return (
                    <div
                      key={index}
                      className="grid grid-cols-4 gap-4 mb-4 last:mb-0"
                    >
                      <FormGroup label="Grade" className="flex-1">
                        <QuerySelector
                          dataHook={useAllVarietyGradesQuery}
                          variables={{ variety: state?.commodity_variety?.id }}
                          {...inputProps(
                            `items.${index}.commodity_variety_grade`
                          )}
                          isDisabled={
                            (isCompletelyDisabled && !isHishobPatti) ||
                            itemRemoved ||
                            isHishobPatti
                          }
                          isOptionDisabled={(opt: any) =>
                            state?.items?.find(
                              item =>
                                item.commodity_variety_grade?.id === opt.id
                            )
                          }
                        />
                      </FormGroup>
                      <div className="flex flex-col gap-3 w-full">
                        <FormGroup label="Rate" className="flex-1">
                          <InputGroup
                            inputProps={{
                              ...inputProps(`items.${index}.${baseRateKey}`),
                              onChange(e) {
                                if (baseRateKey === "daily_rate") {
                                  const daily_rate = Number(e.target.value) || 0
                                  const newRate =
                                    daily_rate -
                                    daily_rate *
                                      ((evalutation?.value || 0) / 100)
                                  form.setFieldValue(
                                    `items.${index}.rate`,
                                    newRate
                                  )
                                  form.setFieldValue(
                                    `items.${index}.daily_rate`,
                                    e.target.value
                                  )
                                  form.setFieldValue(
                                    `items.${index}.total_amount`,
                                    newRate * (item.quantity || 0)
                                  )
                                } else {
                                  form.setFieldValue(
                                    `items.${index}.rate`,
                                    e.target.value
                                  )
                                }
                              },
                              name: `items.${index}`,
                              type: "number",
                            }}
                            disabled={
                              (isCompletelyDisabled && !isHishobPatti) ||
                              itemRemoved
                            }
                            className="self-end w-full"
                            rightElement={
                              <Tag className="mr-1" variant="minimal">
                                {defaultCurrency?.name || "INR"}
                              </Tag>
                            }
                          />
                        </FormGroup>
                      </div>
                      <FormGroup
                        label={
                          bookingSummaryId
                            ? `Quantity (Max: ${remainingQuantity})`
                            : "Quantity"
                        }
                        className="flex-1"
                      >
                        <InputGroup
                          className="vt-weight-listner"
                          inputProps={{
                            ...inputProps(`items.${index}.quantity`),
                            id: "vt_weight_listener",
                            type: "number",
                            className: "mr-24",
                            onChange: e => {
                              let value = Number(e.target.value)
                              let original = Number(remainingQuantity)
                              let result =
                                bookingSummaryId && value > original
                                  ? original
                                  : e.target.value
                              inputProps(`items.${index}.quantity`).onChange(
                                result
                              )
                            },
                          }}
                          disabled={
                            (isCompletelyDisabled && !isHishobPatti) ||
                            itemRemoved
                          }
                          rightElement={
                            <QuerySelector
                              components={{
                                IndicatorSeparator: () => null,
                                ClearIndicator: () => null,
                              }}
                              getControlStyles={prev => {
                                return {
                                  ...prev,
                                  border: "none",
                                }
                              }}
                              dataHook={useAllSiUnitsQuery}
                              variables={{ type: ["Weight", "SKU"] }}
                              isDisabled={
                                (isCompletelyDisabled && !isHishobPatti) ||
                                itemRemoved ||
                                index > 0
                              }
                              {...inputProps(`quantity_unit`, {
                                group: SharedEntityGroup.KiteSiUnit,
                              })}
                            />
                          }
                        />
                      </FormGroup>
                      <div className="flex gap-4">
                        <div className="flex flex-col gap-3">
                          <FormInput
                            label="Total Amount"
                            className="flex-[0.75] text-right"
                            value={inr(
                              (item.quantity || 0) * (item[baseRateKey] || 0)
                            )}
                            disabled
                          />
                          {qcResult && (
                            <FormInput
                              label="Amount after QC deduc."
                              className="flex-[0.75] text-right"
                              value={inr(item.total_amount)}
                              disabled
                              helperText={`Amount after system QC deduc. : ${inr(
                                recommendedDeducatedRate * (item.quantity || 0)
                              )}`}
                            />
                          )}
                        </div>
                        <div className="flex self-start justify-center">
                          {isBookingTrade ||
                          isParentBookingTrade ? null : !isTradeConfirmed ? (
                            <button
                              className="text-red-500 pt-5"
                              onClick={async () => {
                                if (item.id) {
                                  await deleteTradeItem({ id: item.id })
                                }

                                const stateCopy = cloneDeep(state)
                                stateCopy.items.splice(index, 1)
                                stateCopy.items = stateCopy.items?.length
                                  ? stateCopy.items
                                  : [{}]
                                setState(stateCopy)
                                runComputations(stateCopy)
                              }}
                            >
                              <Trash height={24} width={24} />
                            </button>
                          ) : isHishobPatti ? (
                            <Checkbox
                              key={itemRemoved.toString()}
                              checked={itemRemoved}
                              onChange={() => {
                                setRemovedTradeItems(prev => {
                                  if (itemRemoved) {
                                    delete prev[item.id]
                                  } else {
                                    prev[item.id] = true
                                  }
                                  return prev
                                })
                              }}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
              <div>
                <Group className="flex items-center gap-4 p-3 border-b">
                  <Text className="flex-[2_1_0%]">Total</Text>
                  <Text className="flex-1 text-right">
                    {state.total_quantity} {state?.quantity_unit?.name || "na"}
                  </Text>
                  <Text className="flex-[0.75] text-right">
                    {inr(state.total_amount)}
                  </Text>
                  <span className="w-10" />
                </Group>
                <Group className="flex items-center gap-4 p-3 border-b">
                  <Group className="flex gap-2 items-center flex-1">
                    <Text>Standard Deduction</Text>
                  </Group>
                  <Group className="flex gap-2 items-center flex-1">
                    <InputGroup
                      inputProps={{
                        ...inputProps("standard_deduction"),
                        max: 100,
                        min: 0,
                        type: "number",
                        placeholder: "Value in %",
                        className: "text-right",
                      }}
                      className="flex-1"
                      disabled={isCompletelyDisabled}
                      rightElement={<span className="mr-2">%</span>}
                    />
                  </Group>
                  <Text className="flex-1 text-right">
                    {state.quantity_deducted?.toFixed?.(2)}{" "}
                    {state?.quantity_unit?.name || "na"}
                  </Text>
                  <Text className="flex-[0.75] text-right">
                    {inr(state.amount_deducted)}
                  </Text>
                  <span className="w-10" />
                </Group>
                <Group className="flex items-center gap-4 p-3 bg-gray-200">
                  <Text className="flex-[2_1_0%]">Final</Text>
                  <Text className="flex-1 text-right">
                    {(
                      (form.values.total_quantity || 0) -
                      (form.values.quantity_deducted || 0)
                    )?.toFixed(2)}{" "}
                    {state?.quantity_unit?.name || "na"}
                  </Text>
                  <Text className="flex-[0.75] text-right">
                    {inr(finalTotal)}
                  </Text>
                  <span className="w-10" />
                </Group>
              </div>
            </div>
          </FormCard>
          <TradeExpenses
            form={form}
            inputProps={inputProps}
            isDisabled={isCompletelyDisabled}
            sharedDeptId={sharedDeptId}
            onExpenseTemplateChange={expenseConfig => {
              const stateCopy = cloneDeep(form.values)

              console.log(expenseConfig)
              for (const expense of stateCopy.expenses) {
                setRemovedExpenseItems(prev => {
                  if (expense?.buyer?.id) {
                    prev[expense.buyer.id] = true
                  }
                  if (expense?.seller?.id) {
                    prev[expense.seller.id] = true
                  }
                  return prev
                })
              }
              stateCopy.expenses = normalizeExpenses(
                expenseConfig.expense_configs || [],
                true
              )
              runComputations(stateCopy)
              form.setValues(stateCopy)
            }}
            onDeleteExpense={(idx, expense) => {
              const stateCopy = cloneDeep(form.values)
              stateCopy.expenses.splice(idx, 1)

              setRemovedExpenseItems(prev => {
                if (expense?.buyer?.id) {
                  prev[expense.buyer.id] = true
                }
                if (expense?.seller?.id) {
                  prev[expense.seller.id] = true
                }
                return prev
              })
              form.setValues(stateCopy)
              runComputations(stateCopy)
            }}
          />
          <TermsAndConditions
            isDisabled={isCompletelyDisabled}
            kind={form.values.kind}
            mode={form.values.mode}
            sharedDeptId={sharedDeptId}
            seller_tnc={form.values.seller_tnc}
            buyer_tnc={form.values.buyer_tnc}
            onChange={(path, value) => form.setFieldValue(path, value)}
          />
        </div>
      </Group>
      <footer className="fixed bg-white left-0 bottom-0 w-full p-2 shadow-sm flex justify-end gap-4">
        {moreTrades &&
        isCompletelyDisabled &&
        state.kind !== "final" &&
        !hasReceipt ? (
          <Button
            onClick={() => setSearchParams({ new: "true" })}
            className="!bg-cyan-400"
            leftIcon={<CheckCircle />}
          >
            Generate Hishob Patti
          </Button>
        ) : null}
        {isCompletelyDisabled ? null : (
          <Button
            loading={fetching}
            onClick={async () => {
              const std = Number(state?.standard_deduction)
              if (!isEmpty(removedExpenseItems))
                await deleteExpenseItem({
                  ids: Object.keys(removedExpenseItems),
                })
              const quantityUnit = state?.quantity_unit
              const { data: iData, error } = await upsertTrade({
                input: {
                  id: id === "new" || isHishobPatti ? undefined : state?.id,
                  parent_trade_id: isHishobPatti ? state?.id : null,
                  packaging: state?.packaging,
                  kind: isHishobPatti ? TradeKinds.HishobPatti : state?.kind,
                  mode: state?.mode,
                  standard_deduction: std ?? null,
                  buyer_tnc: state?.buyer_tnc,
                  seller_tnc: state?.seller_tnc,
                  vehicle_id: state?.vehicle?.id,
                  status_id: "Draft",
                  start_date:
                    state?.start_date &&
                    dayjs(state?.start_date).format("YYYY-MM-DD"),
                  end_date:
                    state?.end_date &&
                    dayjs(state?.end_date).format("YYYY-MM-DD"),
                  expiry:
                    state?.expiry && dayjs(state?.expiry).format("YYYY-MM-DD"),
                  facilitator_id: state?.facilitator?.id,
                  created_in_id: sharedDeptId,
                  commodity_id: state?.commodity?.id,
                  commodity_variety_id: state?.commodity_variety?.id,
                  plot_id: state?.plot?.id,
                  seller_id: state?.seller?.id,
                  buyer_id: state?.buyer?.id,
                  trade_amounts_counter: 0,
                  items: state?.items?.filter(i => !isEmpty(i))?.length
                    ? {
                        data: state?.items
                          ?.filter(item => !removedTradeItems[item.id])
                          .map(item => {
                            const qcResult = qcForm.values.results.find(
                              result =>
                                result.template.grade.name ===
                                item.commodity_variety_grade.name
                            )
                            return {
                              ...(!isHishobPatti && { id: item.id }),
                              result_id: item?.result_id || qcResult?.id,
                              rate: item.rate,
                              daily_rate:
                                item.daily_rate || item.daily_rate === 0
                                  ? Number(item.daily_rate)
                                  : undefined,
                              rate_unit_id: quantityUnit?.id,
                              quantity: item.quantity,
                              quantity_unit_id: quantityUnit?.id,
                              variety_grade_id:
                                item.commodity_variety_grade?.id,
                              currency_id: defaultCurrency?.id,
                            }
                          }),
                        on_conflict: {
                          constraint: Trade_Item_Constraint.TradeItemPkey,
                          update_columns: [
                            Trade_Item_Update_Column.VarietyGradeId,
                            Trade_Item_Update_Column.Rate,
                            Trade_Item_Update_Column.DailyRate,
                            Trade_Item_Update_Column.RateUnitId,
                            Trade_Item_Update_Column.Quantity,
                            Trade_Item_Update_Column.QuantityUnitId,
                            Trade_Item_Update_Column.CurrencyId,
                          ],
                        },
                      }
                    : undefined,
                  expenses: state?.expenses?.filter(i => i.seller || i.buyer)
                    ?.length
                    ? {
                        data: state?.expenses?.reduce((prev, expense) => {
                          const commonProperties = {
                            expense_parameter_id: expense.parameter?.id,
                            kind: expense.kind?.id,
                          }
                          if (expense.buyer?.value) {
                            prev.push({
                              ...commonProperties,
                              ...(!isHishobPatti && { id: expense.buyer.id }),
                              paid_by: "buyer",
                              value: expense.buyer.value,
                            })
                          }
                          if (expense.seller?.value) {
                            prev.push({
                              ...commonProperties,
                              ...(!isHishobPatti && {
                                id: expense.seller.id,
                              }),
                              paid_by: "seller",
                              value: expense.seller.value,
                            })
                          }
                          return prev
                        }, []),
                        on_conflict: {
                          constraint: Expense_Constraint.ExpensePkey,
                          update_columns: [
                            Expense_Update_Column.ExpenseParameterId,
                            Expense_Update_Column.Kind,
                            Expense_Update_Column.PaidBy,
                            Expense_Update_Column.Value,
                          ],
                        },
                      }
                    : undefined,
                },
              })
              if (error || !iData) {
                return toast.custom(
                  <Toast
                    title={"Unable to create the trade request"}
                    intent="danger"
                  />
                )
              }
              if (iData?.insert_trade_one?.id) {
                await incrementTradeCounter({ id: iData.insert_trade_one.id })
                toast.custom(<Toast title={"Trade created successfully!"} />)
                navigate(`/d/trade-requests/${iData?.insert_trade_one?.id}`)
                setOpen(true)
              }
            }}
            leftIcon={<CheckCircle />}
          >
            {state?.id ? "Save" : "Create"}{" "}
            {state?.kind === TradeKinds.HishobPatti || isHishobPatti
              ? "Hishob Patti"
              : state?.kind === TradeKinds.SaudaPavti
              ? "Sauda Pavti"
              : state?.kind === TradeKinds.Booking
              ? "Booking Patti"
              : null}
          </Button>
        )}
      </footer>
      {id && id !== "new" && (
        <SendConsent
          kind={state.kind}
          buyer={state.buyer}
          seller={state.seller}
          tradeId={id}
          isOpen={isOpen}
          onClose={() => setOpen(false)}
        />
      )}
    </>
  )
}

export default TradeRequestDetail
