import { Modal, Toast } from "@vesatogo/grass-core"
import { isArray, isBoolean, omit } from "lodash-es"
import { useEffect, useMemo, useState } from "react"
import { toast } from "react-hot-toast"
import { useSearchParams } from "react-router-dom"
import TraderSummary from "~/components/TraderSummary"
import Codenames from "~/constants/Codenames"
import { useGenerateInvoicesMutation } from "~/generated/graphql"
import { downloadInvoice } from "~/pages/d/trade-requests/invoices"
import { useTradeSelectorList } from "~/store/trade-selector.store"
import { useDepartment } from "~/utils/useDepartment"

type TraderSummaryProps = {}

const GenerateInvoices = ({}: TraderSummaryProps) => {
  const [params, setParams] = useSearchParams()
  const { id: departmentId } = useDepartment()
  const [expanded, setExpanded] = useState({})
  const isOpen = params.get("action") === "generate-invoice"

  const selected = useTradeSelectorList(state => state.selected)
  const reset = useTradeSelectorList(state => state.reset)
  const [, generateInvoicesMutation] = useGenerateInvoicesMutation()
  const traderGroup = useMemo(() => {
    let traders = {}

    for (const tradeId in selected) {
      if (Object.prototype.hasOwnProperty.call(selected, tradeId)) {
        const tradeBuyer = selected[tradeId].buyer
        const trade = {
          ...omit(selected[tradeId], ["buyer"]),
        }
        if (isArray(traders[tradeBuyer.id]?.trades)) {
          traders[tradeBuyer.id].trades.push(trade)
        } else {
          traders[tradeBuyer?.id] = {
            ...traders?.[tradeBuyer?.id],
            trader: tradeBuyer,
            trades: [trade],
          }
        }
      }
    }

    return Object.keys(traders).map(traderId => {
      let totalQuantity = 0,
        totalAmount = 0,
        totalExpense = 0,
        standard_deduction = 0
      const trades = traders[traderId].trades
      for (const trade of trades) {
        totalQuantity += trade.total_quantity
        totalAmount += trade.total_amount
        totalExpense += trade.expense_amount
        standard_deduction += trade.standard_deduction
      }

      const avg_standard_deduction = standard_deduction / trades.length
      return {
        ...traders[traderId],
        standard_deduction: avg_standard_deduction,
        expense_amount: totalExpense,
        total_quantity:
          totalQuantity - totalQuantity * (avg_standard_deduction / 100),
        total_amount:
          totalAmount - totalAmount * (avg_standard_deduction / 100),
        quantity_unit: trades[0]?.item_units?.[0]?.quantity_unit,
        rate_unit: trades[0]?.item_units?.[0]?.rate_unit,
      }
    })
  }, [selected])

  const [selectedTraders, setSelectedTraders] = useState(
    traderGroup?.map(trader => trader.trader.id) || []
  )

  useEffect(() => {
    setSelectedTraders(traderGroup?.map(trader => trader.trader.id) || [])
  }, [traderGroup])
  async function generateInvoices(onCompleted?: (invoice: any) => void) {
    try {
      const allTradeIds = traderGroup?.reduce((acc, trader) => {
        const tradeIds: string[] = []
        // Only include trades of selected traders
        if (selectedTraders?.includes(trader?.trader?.id)) {
          for (const trade of trader.trades) {
            // if (trade?.status_id !== Codenames.GenerateInward)
            //   throw new Error(
            //     "Please select only the trades with status 'Generate Inward'"
            //   )
            tradeIds.push(trade.id)
          }
        }
        return [...acc, ...tradeIds]
      }, [])

      // if (!allTradeIds?.length)
      //   throw new Error(
      //     "Please select atleast one trade with status 'Generate Inward'"
      //   )
      const { data, error } = await generateInvoicesMutation({
        trade_ids: allTradeIds,
        created_in_id: departmentId,
      })

      const invoices = data?.generate_invoices?.invoices
      if (invoices?.[0] || isArray(invoices)) {
        onCompleted?.(invoices?.[0])
        return toast.custom(
          <Toast
            title={`${invoices?.length} invoices generated successfully!`}
          />
        )
      }
      if (error) throw new Error(error.message)
    } catch (error: any) {
      return toast.custom(<Toast intent="danger" title={error?.message} />)
    }
  }

  function handleClose() {
    params.delete("action")
    setParams(params)
  }
  return (
    <Modal
      title="Generate Invoice for Traders"
      bodyClassName="w-[60%]"
      isOpen={isOpen}
      onClose={() => {
        params.delete("action")
        setParams(params)
      }}
      secondaryActionButtonProps={{
        text: "Cancel",
        onClick: handleClose,
      }}
      primaryActionButtonProps={{
        text:
          traderGroup?.length === 1
            ? "Generate and Download Invoice"
            : "Generate Invoices",
        onClick: () => {
          if (traderGroup?.length === 1) {
            generateInvoices(async invoice => {
              params.set("updatedAt", Date.now().toString())
              setParams(params)
              if (invoice?.id) {
                downloadInvoice(invoice?.id)()
              }
              reset()
              handleClose()
            })
          } else {
            generateInvoices(() => {
              params.set("updatedAt", Date.now().toString())
              setParams(params)
              reset()
              handleClose()
            })
          }
        },
      }}
    >
      <>
        {traderGroup?.map(trader => {
          const user = trader?.trader
          const quantity_unit = trader?.quantity_unit
          const rate_unit = trader?.rate_unit
          const isExpanded = isBoolean(expanded[trader?.trader?.id])
            ? expanded[trader?.trader?.id]
            : true

          return (
            <TraderSummary
              isSelected={selectedTraders?.includes(trader?.trader?.id)}
              trader={user}
              rate_unit={rate_unit}
              quantity_unit={quantity_unit}
              isExpanded={isExpanded}
              onSelect={() => {
                setSelectedTraders(prev => {
                  if (prev.includes(trader?.trader?.id)) {
                    return prev.filter(id => id !== trader?.trader?.id)
                  }
                  return [...prev, trader?.trader?.id]
                })
              }}
              onExpandToggle={() => {
                setExpanded(prev => {
                  return {
                    ...prev,
                    [trader?.trader?.id]: isBoolean(prev[trader?.trader?.id])
                      ? !prev[trader?.trader?.id]
                      : false,
                  }
                })
              }}
              expense_amount={trader?.expense_amount}
              standard_deduction={trader?.standard_deduction}
              total_amount={trader?.total_amount}
              total_quantity={trader?.total_quantity}
              trades={trader?.trades as any}
            />
          )
        })}
      </>
    </Modal>
  )
}

export default GenerateInvoices
