import { Plus, Trash } from "@phosphor-icons/react"
import {
  Selector,
  Button,
  Note,
  Tag,
  FormQuerySelector,
  FormSelector,
  FormInput,
  NoDataFound,
  Flex,
} from "@vesatogo/grass-core"
import { inr } from "@vesatogo/utils"
import classNames from "classnames"
import { cloneDeep } from "lodash-es"
import { useState } from "react"
import { useClient } from "urql"
import { FormCard } from "~/components/FormCard"
import { EXPENSE_KINDS } from "~/constants/static.items"
import { useExpenseParametersQuery } from "~/generated/graphql"

export type Expense = {
  parameter: any
  seller: {
    id?: any
    paid_by: "seller"
    parameter: any
    kind: any
    value: any
    amount: any
  } | null
  buyer: {
    id?: any
    paid_by: "buyer"
    parameter: any
    kind: any
    value: any
    amount: any
  } | null
  kind: any
  amount: any
}

export const EXPENSE: Expense = {
  parameter: null,
  kind: null,
  amount: 0,
  seller: {
    paid_by: "seller",
    value: null,
    amount: null,
    kind: null,
    parameter: null,
  },
  buyer: {
    paid_by: "buyer",
    value: null,
    amount: null,
    kind: null,
    parameter: null,
  },
}

export type TradeExpensesProps = {
  isDisabled?: boolean
  form: any
  sharedDeptId: string
  inputProps: any
  onExpenseTemplateChange?: (expenseConfig: any) => void
  onDeleteExpense?: (idx: number, expense: any) => void
}
const TradeExpenses = ({
  form,
  sharedDeptId,
  isDisabled,
  inputProps,
  onExpenseTemplateChange,
  onDeleteExpense,
}: TradeExpensesProps) => {
  const [expenseTemplate, setExpenseTemplate] = useState<any>()
  const client = useClient()
  // const [{ data: expenseConfigList }] = useExpenseConfigVersionsQuery({
  //   variables: {
  //     department: `${sharedDeptId}`,
  //     kind: form.values.kind,
  //     mode: form.values.mode,
  //   },
  //   pause: !sharedDeptId || !form.values.mode || !form.values.kind,
  //   requestPolicy: "network-only",
  // })
  return (
    <FormCard
      title="Trade Expenses"
      action={
        <Flex>
          {/* {!isDisabled && onExpenseTemplateChange && (
            <Selector
              value={expenseTemplate}
              options={expenseConfigList?.expense_config_versions?.map(
                item => ({ id: item?.version_id, name: item?.name })
              )}
              className="font-400 min-w-[15rem]"
              onChange={async (item: any) => {
                try {
                  const { data: expenseConfig } = await client
                    .query<ExpenseConfigsQuery, ExpenseConfigsQueryVariables>(
                      EXPENSE_CONFIGS,
                      {
                        version: item?.id,
                        department: `${sharedDeptId}`,
                        kind: form.values.kind,
                        mode: form.values.mode,
                      }
                    )
                    .toPromise()
                  if (expenseConfig?.expense_configs?.length) {
                    onExpenseTemplateChange(expenseConfig)
                  }
                } catch {
                } finally {
                  setExpenseTemplate(item)
                }
              }}
            />
          )} */}
          <Button
            className="w-full"
            disabled={isDisabled}
            onClick={() => form.insertListItem("expenses", { ...EXPENSE })}
            leftIcon={<Plus />}
            size="sm"
          >
            Expense
          </Button>
        </Flex>
      }
    >
      {form.values?.trades?.length > 0 &&
        form.values?.__typename !== "trade" && (
          <Note
            className="mb-3"
            intent="info"
            title="All expenses are individually applied to every trade"
          />
        )}
      {form.values?.expenses?.length ? (
        <table className="expenses-table w-full">
          <thead className="bg-green-100 text-sm rounded">
            <tr>
              <th className="p-1 w-[25%]">Expense Parameter</th>
              <th className="p-1 w-[25%]">Expense Type</th>
              <th className="p-1">Farmer Charge</th>
              <th className="p-1">Trader Charge</th>
              <th className="p-1 text-right">Amount</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {form.values.expenses?.map((expense, idx) => {
              const rightElement = expense?.kind?.short && (
                <Tag variant="minimal" className="mr-2">
                  {expense?.kind?.id === "flat"
                    ? `x${form.values.trades?.length || 1}`
                    : expense?.kind?.short}
                </Tag>
              )

              return (
                <tr key={expense.parameter?.id || `exp_${idx}`}>
                  <td>
                    <FormQuerySelector
                      className="w-full"
                      isOptionDisabled={(opt: any) => {
                        return form.values.expenses?.find(
                          exp => Number(exp.parameter?.id) === Number(opt.id)
                        )
                          ? true
                          : false
                      }}
                      disabled={isDisabled}
                      dataHook={useExpenseParametersQuery}
                      {...inputProps(`expenses.${idx}.parameter`)}
                    />
                  </td>
                  <td>
                    <FormSelector
                      className="w-full"
                      // options={EXPENSE_KINDS.filter(kind => kind.id !== "flat")}
                      options={EXPENSE_KINDS}
                      disabled={isDisabled}
                      {...inputProps(`expenses.${idx}.kind`)}
                    />
                  </td>
                  <td>
                    <FormInput
                      type={"number"}
                      className="text-right"
                      rightElement={rightElement}
                      {...inputProps(`expenses.${idx}.seller.value`)}
                      disabled={isDisabled}
                    />
                  </td>
                  <td>
                    <FormInput
                      type={"number"}
                      className="text-right"
                      rightElement={rightElement}
                      {...inputProps(`expenses.${idx}.buyer.value`)}
                      disabled={isDisabled}
                    />
                  </td>
                  <td className="text-right flex-1">{inr(expense.amount)}</td>
                  <td>
                    <button
                      disabled={isDisabled}
                      className={classNames(
                        "w-10 flex justify-center",
                        isDisabled ? "text-gray-600" : "text-red-500"
                      )}
                      onClick={() => onDeleteExpense?.(idx, expense)}
                    >
                      <Trash height={24} width={24} className="!mr-0" />
                    </button>
                  </td>
                </tr>
              )
            })}
          </tbody>
          <tfoot>
            <tr className="border-t border-gray-300 border-dashed">
              <td colSpan={2}>Total Expense</td>
              <td></td>
              <td></td>
              <td className="text-right font-500">
                {inr(form.values.expense_amount || 0)}
              </td>
              <td></td>
            </tr>
          </tfoot>
        </table>
      ) : (
        <NoDataFound
          className="min-h-[11rem]"
          title="There are no expenses added yet"
        >
          <Button
            onClick={() => form.insertListItem("expenses", EXPENSE)}
            leftIcon={<Plus />}
            size="sm"
          >
            Expense
          </Button>
        </NoDataFound>
      )}
    </FormCard>
  )
}

export default TradeExpenses
