import * as Yup from "yup"

export const groupModel = Yup.object().shape({
  name: Yup.string().required("Please enter Group name"),
  permissions: Yup.array()
    .of(
      Yup.object().shape({
        codename: Yup.string().required(),
        id: Yup.number().required(),
        name: Yup.string().required(),
      })
    )
    .required("Please select permissions"),
})
