import { useForm } from "@mantine/form"
import { CheckCircle, XCircle } from "@phosphor-icons/react"
import {
  Button,
  ButtonProps,
  Flex,
  FormSelector,
  Modal,
  Toast,
} from "@vesatogo/grass-core"
import classNames from "classnames"
import toast from "react-hot-toast"
import { useSearchParams } from "react-router-dom"
import { useAuthenticatedUserQuery } from "~/generated/graphql"
export type VerifyAssetProps = {
  is_verified: null | boolean
  is_archived?: null | boolean
  id: number
  useUpdateHook?: any
  onCompleted?: (value: boolean, remark?: any) => void
  className?: any
  verifyText?: React.ReactNode
  rejectText?: React.ReactNode
  size?: ButtonProps["size"]
  /**
   * Always allow the user to verify/reject even when not in approval mode
   */
  allowAlways?: boolean
  children?: React.ReactNode
  type?: "user" | "asset"
}

const REMARKS = [
  {
    id: "Incorrect Data",
  },
]
const VerifyAsset = ({
  is_verified,
  useUpdateHook,
  id,
  onCompleted,
  className,
  verifyText = "Verify",
  rejectText = "Reject",
  children,
  size,
}: VerifyAssetProps) => {
  const [{ data }] = useAuthenticatedUserQuery()
  const currentUserId = data?.me?.id
  const form = useForm<any>({
    initialValues: {
      remark: null,
      is_verified,
    },
  })
  const [params, setParams] = useSearchParams()
  const [, updateAsset] = useUpdateHook()
  return (
    <Flex className={classNames("!justify-between w-full", className)}>
      <Button
        disabled={is_verified === false}
        onClick={() => form.setFieldValue("is_verified", false)}
        leftIcon={<XCircle />}
        intent="danger"
        variant="outline"
        size={size}
      >
        {rejectText}
      </Button>
      <Flex>
        <Button
          disabled={is_verified === true}
          onClick={async e => {
            e.preventDefault()
            e.stopPropagation()
            const { data } = await updateAsset({
              pk_columns: {
                id,
              },
              _set: {
                is_verified: true,
                verified_by_id: currentUserId,
                verification_remark: "",
              },
              _inc: {},
            })

            if (data) {
              toast.custom(<Toast title="Asset verified successfully" />)
              form.setFieldValue("is_verified", true)
              params.set("update", Date.now().toString())
              setParams(params)
              onCompleted?.(true)
            }
          }}
          leftIcon={<CheckCircle />}
          variant="outline"
          size={size}
        >
          {verifyText}
        </Button>
        {children}
      </Flex>
      <Modal
        overflow
        onClose={() => form.setFieldValue("is_verified", is_verified)}
        title="Reject Asset"
        isOpen={form.values.is_verified === false && is_verified !== false}
        primaryActionButtonProps={{
          text: "Confirm",
          async onClick(e) {
            e.preventDefault()
            e.stopPropagation()

            const { data } = await updateAsset({
              pk_columns: {
                id,
              },
              _set: {
                is_verified: false,
                verified_by_id: currentUserId,
                verification_remark:
                  form.values.remark?.id || form.values.remark?.label,
              },
              _inc: {},
            })
            if (data) {
              toast.custom(<Toast title="Asset rejected successfully" />)
              form.setFieldValue("is_verified", false)
              params.set("update", Date.now().toString())
              setParams(params)
              onCompleted?.(false, form.values.remark)
            }
          },
        }}
      >
        <FormSelector
          isCreatable
          options={REMARKS}
          getOptionLabel={item => {
            if (item.__isNew__) return item.label
            return item.id
          }}
          className="w-full"
          label="Rejection Remark"
          helperText="Useful for the creator to make changes"
          {...form.getInputProps("remark")}
        />
      </Modal>
    </Flex>
  )
}

export default VerifyAsset
