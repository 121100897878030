import classNames from "classnames"
import React, { useEffect, useState } from "react"
import { GeocodedAddressType } from "~/utils/map-helpers"
import LocationMap, { MapStyleConfig } from "./LocationMap"
import MapAutocomplete from "./MapAutocomplete"
import MapControl from "./MapControl"

type MapAddressProps = {
  showFunctionalities: boolean
  handleLocationData?: (data: GeocodedAddressType) => void
  value?: any
  mapStyle: MapStyleConfig
  className?: string
  mapClassName?: string
  customMarker?: boolean
  children?: React.ReactNode
  footer?: React.ReactNode
  drawingMode?: any
  onPolyComplete?: any
  zoom?: number
}

const MapAddress = ({
  showFunctionalities = true,
  handleLocationData,
  value,
  mapStyle,
  className,
  mapClassName,
  customMarker,
  children,
  drawingMode,
  onPolyComplete,
  footer,
  zoom = 7,
}: MapAddressProps) => {
  const [center, setCenter] = useState<any>(value)

  function handleMapChange(address: GeocodedAddressType) {
    if (!address) return
    setCenter(address.location.coordinates)
    handleLocationData?.(address)
  }

  useEffect(() => {
    if (value) {
      setCenter(value)
    }
  }, [value])

  return (
    <div className={classNames("h-full w-full", className)}>
      <LocationMap
        className={mapClassName}
        disabled={!showFunctionalities}
        onChange={handleMapChange}
        center={center}
        mapStyle={mapStyle}
        customMarker={customMarker}
        drawingMode={drawingMode}
        onPolyComplete={onPolyComplete}
        zoom={zoom}
      >
        {showFunctionalities ? (
          <MapControl position="TOP_CENTER">
            <MapAutocomplete onChange={handleMapChange} />
          </MapControl>
        ) : null}
        {children}
      </LocationMap>
      {footer}
    </div>
  )
}

export default MapAddress
