const UserDetailsCard = ({ user }: any) => {
  if (!user) return null
  return (
    <div className="bg-green-100 rounded p-2 flex flex-col gap-2">
      <div>
        <span className="text-gray-600">Phone no: </span>
        {user.number || "na"}
      </div>
      <div>
        <span className="text-gray-600">Address: </span>
        {user.address?.raw_address ||
          user?.businesses_owned?.[0]?.address?.raw_address ||
          "na"}
      </div>
      <div>
        <span className="text-gray-600">Companies: </span>
        {user.companies?.map(c => c.company?.name).join(", ") || "na"}
      </div>
    </div>
  )
}

export default UserDetailsCard
