import { useForm, yupResolver } from "@mantine/form"
import {
  Button,
  Card,
  FormInput,
  FormQuerySelector,
  Modal,
  SpinnerOverlay,
  Toast,
} from "@vesatogo/grass-core"
import { pick } from "lodash-es"
import { useEffect } from "react"
import toast from "react-hot-toast"
import { useSearchParams } from "react-router-dom"
import { AddCompleteType } from "~/components/Admin/AddCompleteType"
import { FileUpload } from "~/components/dropzone/FileUploader/FileUploader"
import {
  Commodity_Constraint,
  Commodity_Update_Column,
  Commodity_Variety_Constraint,
  Commodity_Variety_Update_Column,
  Crop_Variety_Constraint,
  Crop_Variety_Update_Column,
  Media_Constraint,
  Media_Update_Column,
  useAllCommoditiesNewQuery,
  useAllCropVarietiesQuery,
  useCommodityVarietyDetailQuery,
  useInsertCommodityVarietyMutation,
} from "~/generated/graphql"
import { commodityVarietyModel } from "~/models/commodity_variety"
import { getFileUploadProps, getInputProps } from "~/utils/form-helpers"

const INITIAL_STATE = {
  photo: [],
}

export const AddCommodityVarietyModal = ({
  onAddComplete,
}: AddCompleteType) => {
  const [params, setParams] = useSearchParams()
  const commodityVarietyId = params?.get("id") || ""

  const form = useForm<any>({
    initialValues: INITIAL_STATE,
    validate: yupResolver(commodityVarietyModel),
    validateInputOnChange: true,
    validateInputOnBlur: true,
    clearInputErrorOnChange: false,
  })

  const state = form.values
  const setState = form.setValues

  const [{ data, fetching }] = useCommodityVarietyDetailQuery({
    variables: {
      id: parseInt(commodityVarietyId),
    },
    pause:
      !Boolean(parseInt(commodityVarietyId)) || commodityVarietyId === "new",
  })

  const [{ fetching: insertFetching }, insertCommodityVariety] =
    useInsertCommodityVarietyMutation()

  useEffect(() => {
    setState(val => ({
      name: data?.commodity_variety_by_pk?.name,
      codename: data?.commodity_variety_by_pk?.codename,
      photo: data?.commodity_variety_by_pk?.image
        ? [data?.commodity_variety_by_pk?.image]
        : [],
      commodity: data?.commodity_variety_by_pk?.commodity,
      crop_variety: data?.commodity_variety_by_pk?.crop_variety,
    }))
  }, [data])

  const onClose = () => {
    setParams({})
    form?.reset()
  }

  const onSubmit = async () => {
    const { errors, hasErrors } = form?.validate()
    if (hasErrors) {
      return toast.custom(
        <Toast
          intent="danger"
          title={"Please resolve all errors before proceeding!"}
        />
      )
    }
    const { data: insertData, error } = await insertCommodityVariety({
      object: {
        codename: state?.codename,
        commodity: {
          data: {
            codename: state?.commodity?.codename,
            id: state?.commodity?.id,
            name: state?.commodity?.name,
          },
          on_conflict: {
            constraint: Commodity_Constraint.CommodityPkey,
            update_columns: [
              Commodity_Update_Column.Codename,
              Commodity_Update_Column.Name,
            ],
          },
        },
        crop_variety: {
          data: {
            id: state?.crop_variety?.id,
            name: state?.crop_variety?.name,
            codename: state?.crop_variety?.codename,
            crop_id: state?.crop_variety?.crop?.id,
          },
          on_conflict: {
            constraint: Crop_Variety_Constraint.CropVarietyPkey,
            update_columns: [
              Crop_Variety_Update_Column.Name,
              Crop_Variety_Update_Column.Id,
            ],
          },
        },
        name: state?.name,
        id: commodityVarietyId !== "new" ? commodityVarietyId : undefined,
        image: state?.photo[0]
          ? {
              data: {
                ...pick(
                  state?.photo[0],
                  "kind",
                  "url",
                  "key",
                  "is_encrypted",
                  "id"
                ),
              },
              on_conflict: {
                constraint: Media_Constraint.MediaPkey,
                update_columns: [Media_Update_Column.Url],
              },
            }
          : undefined,
      },
      on_conflict: {
        constraint: Commodity_Variety_Constraint.CommodityVarietyPkey,
        update_columns: [
          Commodity_Variety_Update_Column.Codename,
          Commodity_Variety_Update_Column.Name,
          Commodity_Variety_Update_Column.ImageId,
          Commodity_Variety_Update_Column.CommodityId,
          Commodity_Variety_Update_Column.CropVarietyId,
        ],
      },
    })
    if (error) {
      return toast.custom(
        <Toast
          intent="danger"
          title={"Something went wrong! " + error.message}
        />
      )
    }
    if (insertData) {
      onClose()
      toast.custom(
        <Toast
          title={
            commodityVarietyId === "new"
              ? "Comodity Variety added successfully!"
              : "Commodity Variety updated successfully!"
          }
        />
      )
      onAddComplete({ requestPolicy: "network-only" })
    }
  }

  return (
    <Modal
      bodyClassName="overflow-visible w-[60%]"
      title="Commodity Variety"
      contentClassName="bg-gray-100"
      footer={
        <div className="flex justify-end gap-3">
          <Button size="sm" onClick={onClose} variant="outline">
            Cancel
          </Button>
          <Button loading={insertFetching} size="sm" onClick={onSubmit}>
            Save
          </Button>
        </div>
      }
      isOpen={Boolean(params.get("id"))}
      onClose={onClose}
    >
      <SpinnerOverlay show={fetching} />
      <Card className="p-4 flex gap-20">
        <div>
          <FileUpload
            {...getFileUploadProps(form, "photo", 1)}
            appName="mandi"
            showPreviewForDocument
          />
        </div>
        <div className="grid grid-cols-2 gap-4 w-full">
          <FormInput
            label="Commodity Variety Name"
            required
            placeholder="Enter Name"
            {...getInputProps(form, "name")}
          />
          <FormInput
            label="Code Name"
            placeholder="Code Name"
            {...getInputProps(form, "codename")}
          />
          <FormQuerySelector
            label="Commodity"
            required
            dataHook={useAllCommoditiesNewQuery}
            serverSideQuery
            {...getInputProps(form, "commodity")}
          />
          <FormQuerySelector
            dataHook={useAllCropVarietiesQuery}
            label="Crop Variety"
            serverSideQuery
            required
            {...getInputProps(form, "crop_variety")}
          />
        </div>
      </Card>
    </Modal>
  )
}
