import { Icon, IconBase, IconWeight } from "@phosphor-icons/react"
import React, { forwardRef } from "react"

// Define your custom weights and SVG contents
const customWeights: Map<IconWeight, React.ReactElement> = new Map([
  [
    "fill",
    <>
      <path d="M124.139 98.2468C85.3652 94.5803 70.6858 85.0133 61.481 49.8839C60.9303 47.7821 60.403 45.908 59.9017 44.2424C51.0567 37.5038 53.088 21.6069 59.9017 44.2424C61.2838 45.2954 62.9315 46.1247 64.8679 46.5485C80.109 49.8839 108.79 52.0977 124.139 68.2284C124.788 51.6142 168.168 18.1979 193.57 24.8686C218.972 31.5393 196.957 29.8717 196.957 29.8717C185.646 62.8859 172.439 73.6848 134.299 76.5668V146.61C161.771 102.6 180.81 91.2389 218.972 86.573C235.09 78.1088 234.902 80.9373 225.746 93.2437C212.693 167.024 191.592 170.004 134.299 182.43V238.332C134.299 238.332 131.31 240 129.219 240C127.128 240 124.139 238.332 124.139 238.332V201.643C67.8651 181.629 39.2933 187.187 32.6923 113.256C31.1491 101.477 31.9153 99.417 37.7727 108.253C80.5207 110.19 98.8011 124.75 124.139 168.29V98.2468Z" />
    </>,
  ],
  // Replace with your custom thin icon content
  [
    "regular",
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M132 64.0009C134.209 64.0009 136 66.248 136 69.0199V234.982C136 237.754 134.209 240.001 132 240.001C129.791 240.001 128 237.754 128 234.982V69.0199C128 66.248 129.791 64.0009 132 64.0009Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M32 88.0009L40.2693 96.4556C40.8029 97.0012 43.5773 99.4055 47.2293 101.662C51.0063 103.996 54.776 105.571 57.5119 105.571C61.1181 105.571 66.7275 107.241 72.7336 109.73C78.9766 112.318 86.3428 116.087 93.6977 120.873C108.215 130.318 123.579 144.306 129.278 161.785C133.452 174.588 135.304 182.915 135.831 188.207C136.089 190.797 136.074 193.05 135.631 194.863C135.296 196.23 133.925 200.001 129.849 200.001C120.856 200.001 106.461 198.634 92.1183 194.535C77.9008 190.472 62.9416 183.502 53.7293 171.728C44.8554 160.387 39.464 145.975 36.2763 132.848C33.0797 119.684 32 107.397 32 99.9576V88.0009ZM42.2423 110.154C42.8185 116.055 43.8918 123.102 45.6789 130.461C48.6915 142.867 53.6341 155.75 61.2944 165.54C68.6164 174.898 81.2144 181.134 94.7262 184.996C106.316 188.308 117.995 189.707 126.262 190.024C126.245 189.775 126.222 189.504 126.193 189.211C125.773 184.994 124.18 177.473 120.087 164.918C115.452 150.7 102.398 138.274 88.4965 129.229C81.6423 124.77 74.7994 121.275 69.0937 118.91C63.1513 116.447 59.0726 115.476 57.5119 115.476C51.9916 115.476 46.3003 112.659 42.2423 110.154ZM126.21 192.544C126.21 192.543 126.211 192.54 126.213 192.533L126.21 192.544Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M232 64.0009L223.731 73.0595C223.197 73.6441 220.423 76.2201 216.771 78.638C212.994 81.1387 209.224 82.8256 206.488 82.8256C202.882 82.8256 197.273 84.6153 191.266 87.2826C185.023 90.0551 177.657 94.0934 170.302 99.2206C155.785 109.341 140.421 124.328 134.722 143.056C130.548 156.772 128.696 165.695 128.169 171.365C127.911 174.139 127.926 176.553 128.369 178.496C128.704 179.961 130.075 184.001 134.151 184.001C143.144 184.001 157.539 182.536 171.882 178.145C186.099 173.792 201.058 166.323 210.271 153.709C219.145 141.557 224.536 126.116 227.724 112.051C230.92 97.947 232 84.782 232 76.8117V64.0009ZM221.758 87.7368C221.181 94.0593 220.108 101.609 218.321 109.494C215.308 122.786 210.366 136.589 202.706 147.079C195.384 157.105 182.786 163.787 169.274 167.924C157.684 171.472 146.005 172.972 137.738 173.311C137.755 173.044 137.778 172.754 137.807 172.44C138.227 167.922 139.82 159.863 143.913 146.412C148.548 131.179 161.602 117.865 175.504 108.174C182.358 103.396 189.201 99.6518 194.906 97.1179C200.849 94.4788 204.927 93.4384 206.488 93.4384C212.008 93.4384 217.7 90.4206 221.758 87.7368ZM137.79 176.011C137.79 176.011 137.789 176.007 137.787 175.999L137.79 176.011Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M75.2512 44.6881C75.7323 46.0465 76.2972 47.5355 76.9148 49.0915C78.8581 53.9874 81.2049 59.25 82.7683 62.5879L82.8351 62.7307L82.8941 62.8776C88.1152 75.8831 97.2298 84.9198 106.74 90.9552C113.707 95.3767 120.738 98.0894 126.27 99.5317V75.2714C126.27 73.6326 125.621 72.2272 123.761 70.7779C121.756 69.216 118.737 67.9728 115.105 67.078C107.899 65.303 100.001 65.2896 97.605 65.2896C94.8292 65.2896 92.5757 63.8274 91.003 62.4634C89.3882 61.0629 87.926 59.2682 86.6683 57.59C85.8224 56.4612 84.937 55.2014 84.1112 54.0263C83.7254 53.4774 83.3527 52.947 83.003 52.4571C81.823 50.8038 80.7954 49.4491 79.8493 48.439C78.6091 47.115 76.9229 45.7589 75.2512 44.6881ZM76.012 33.2021C79.636 34.7496 83.7768 37.7066 86.6205 40.7424C88.0694 42.2892 89.4368 44.1304 90.6518 45.8327C91.1034 46.4654 91.5273 47.069 91.9368 47.652C92.7034 48.7435 93.4194 49.763 94.1716 50.7666C95.3089 52.2842 96.2417 53.3657 97.022 54.0424C97.4246 54.3917 97.664 54.5258 97.7588 54.5717C100.359 54.5721 109.036 54.6003 117.228 56.6185C121.38 57.6413 125.845 59.2745 129.379 62.0272C133.057 64.8925 136 69.2398 136 75.2714V112.001L130.55 111.274C123.543 110.339 112.614 107.052 101.882 100.242C91.1487 93.43 80.3396 82.9101 74.0557 67.3614C72.4263 63.8755 70.021 58.477 68 53.3852C66.978 50.8106 66.0246 48.24 65.3174 45.9983C64.6887 44.0055 64 41.5219 64 39.4772C64 37.9168 64.3786 36.0497 65.634 34.4785C66.8754 32.9246 68.459 32.3064 69.7192 32.0994C71.9925 31.7261 74.3299 32.4838 76.012 33.2021Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M195.499 35.4194C194.964 36.6419 194.336 37.9821 193.65 39.3825C191.491 43.7887 188.883 48.5251 187.146 51.5292L187.072 51.6577L187.007 51.79C181.205 63.4949 171.078 71.6279 160.512 77.0598C152.77 81.0392 144.958 83.4806 138.811 84.7786V62.9444C138.811 61.4695 139.532 60.2046 141.599 58.9002C143.827 57.4945 147.182 56.3756 151.217 55.5703C159.223 53.9728 167.999 53.9608 170.661 53.9608C173.745 53.9608 176.249 52.6448 177.997 51.4172C179.791 50.1567 181.416 48.5415 182.813 47.0311C183.753 46.0152 184.737 44.8813 185.654 43.8237C186.083 43.3297 186.497 42.8524 186.886 42.4115C188.197 40.9235 189.338 39.7043 190.39 38.7952C191.768 37.6036 193.641 36.3831 195.499 35.4194ZM194.653 25.082C190.627 26.4748 186.026 29.136 182.866 31.8683C181.256 33.2604 179.737 34.9174 178.387 36.4495C177.885 37.0189 177.414 37.5622 176.959 38.0869C176.107 39.0692 175.312 39.9868 174.476 40.8901C173.212 42.2559 172.176 43.2292 171.309 43.8383C170.862 44.1526 170.596 44.2733 170.49 44.3147C167.601 44.3149 157.96 44.3403 148.858 46.1568C144.245 47.0773 139.283 48.5472 135.357 51.0246C131.27 53.6033 128 57.5159 128 62.9444V96.0009L134.055 95.3464C141.841 94.5049 153.985 91.5473 165.908 85.4179C177.835 79.2871 189.845 69.8192 196.827 55.8253C198.637 52.6881 201.31 47.8294 203.556 43.2468C204.691 40.9297 205.75 38.616 206.536 36.5986C207.235 34.8051 208 32.5698 208 30.7295C208 29.3252 207.579 27.6448 206.184 26.2307C204.805 24.8323 203.046 24.2758 201.645 24.0896C199.119 23.7536 196.522 24.4355 194.653 25.082Z"
      />
    </>,
  ], // Replace with your custom regular icon content
])

// Define your custom icon component
const CropIcon: Icon = forwardRef((props, ref) => (
  <IconBase ref={ref} {...props} weights={customWeights} />
))

export default CropIcon
