import { Plus, Trash } from "@phosphor-icons/react"
import { openConfirmDialog } from "@vesatogo/grass-confirm-modal"
import { Button, FormInput, NoDataFound, Toast } from "@vesatogo/grass-core"
import { FormDateInput } from "@vesatogo/grass-dates"
import classNames from "classnames"
import dayjs from "dayjs"
import { get, omit, pick } from "lodash-es"
import { useState } from "react"
import toast from "react-hot-toast"
import { OperationContext } from "urql"
import AddressForm from "~/components/Registration/AddressForm"
import { CertificateCard } from "~/components/Registration/CertificateCard"
import { CollapsibleCard } from "~/components/Registration/CollapsibleCard"
import { SectionCard } from "~/components/Registration/SectionCard"
import { FileUpload } from "~/components/dropzone/FileUploader/FileUploader"
import Codenames from "~/constants/Codenames"
import {
  Address_Constraint,
  Address_Update_Column,
  Base_User_Constraint,
  Base_User_Update_Column,
  Business_Constraint,
  Business_Update_Column,
  Document_Constraint,
  Document_Update_Column,
  InputMaybe,
  Media_Constraint,
  Media_Update_Column,
  Shop_Arr_Rel_Insert_Input,
  Shop_Constraint,
  Shop_Update_Column,
  useDeleteAttachmentMutation,
  useDeleteShopMutation,
  useGetDocumentKindsQuery,
  useInsertBaseUserMutation,
} from "~/generated/graphql"
import { getFileUploadProps, getInputProps } from "~/utils/form-helpers"

export type ShopAddressType = {
  form: any
  refetch: (opts?: Partial<OperationContext> | undefined) => void
  entity: "farmer" | "trader" | "business"
}

export const ShopAddress = ({ form, refetch, entity }: ShopAddressType) => {
  const { values: state, setValues: setState } = form
  const [isSubmitted, setSubmitted] = useState(false)

  function addShop() {
    setState({
      ...state,
      shops: [
        ...state.shops,
        {
          address: {} as any,
          gstin: {
            attachments: [],
          },
          shop_act: {
            attachments: [],
          },
        },
      ],
    })
  }

  const [, insertBaseUserDetails] = useInsertBaseUserMutation()
  const [, deleteShop] = useDeleteShopMutation()
  const [, deleteAttachment] = useDeleteAttachmentMutation()
  const [{ data: documentKinds }] = useGetDocumentKindsQuery()

  const onSubmit = async () => {
    setSubmitted(true)
    const basicDetails = state?.basic
    const shops = state?.shops
    const shopsData: InputMaybe<Shop_Arr_Rel_Insert_Input> | undefined = {
      data:
        shops?.length > 0
          ? shops?.map(shop => {
              return {
                ...omit(shop, "gstin", "shop_act", "__typename"),
                id: shop?.id,
                name: shop?.name,
                documents: {
                  data: [
                    shop?.gstin?.id_number && {
                      ...omit(shop?.gstin, "kind", "__typename", "created_by"),
                      kind_id: documentKinds?.document_kind?.filter(
                        kind => kind?.codename === Codenames.GST_BASE_USER
                      )[0]?.id,
                      issued_on: shop?.gstin?.issued_on
                        ? dayjs(shop?.gstin?.issued_on).format("YYYY-MM-DD")
                        : "01-01-1997",
                      attachments:
                        shop?.gstin?.attachments?.length !== 0
                          ? {
                              data: shop?.gstin?.attachments?.map(
                                attachment => {
                                  return {
                                    ...pick(
                                      attachment,
                                      "kind",
                                      "id",
                                      "key",
                                      "url",
                                      "is_encrypted"
                                    ),
                                  }
                                }
                              ),
                              on_conflict: {
                                constraint: Media_Constraint.MediaPkey,
                                update_columns: [
                                  Media_Update_Column.Id,
                                  Media_Update_Column.Kind,
                                  Media_Update_Column.Url,
                                  Media_Update_Column.Key,
                                  Media_Update_Column.IsEncrypted,
                                  Media_Update_Column.DocumentId,
                                ],
                              },
                            }
                          : undefined,
                    },
                    shop?.shop_act?.id_number && {
                      ...omit(
                        shop?.shop_act,
                        "kind",
                        "__typename",
                        "created_by"
                      ),
                      kind_id: documentKinds?.document_kind?.filter(
                        kind => kind?.codename === Codenames.SHOP_ACT
                      )[0]?.id,
                      issued_on: shop?.shop_act?.issued_on
                        ? dayjs(shop?.shop_act?.issued_on).format("YYYY-MM-DD")
                        : "01-01-1997",

                      attachments:
                        shop?.shop_act?.attachments?.length !== 0
                          ? {
                              data: shop?.shop_act?.attachments?.map(
                                attachment => {
                                  return {
                                    ...pick(
                                      attachment,
                                      "kind",
                                      "id",
                                      "key",
                                      "url",
                                      "is_encrypted"
                                    ),
                                    kind:
                                      attachment?.kind ||
                                      attachment?.media_kind,
                                  }
                                }
                              ),
                              on_conflict: {
                                constraint: Media_Constraint.MediaPkey,
                                update_columns: [
                                  Media_Update_Column.Id,
                                  Media_Update_Column.Kind,
                                  Media_Update_Column.Url,
                                  Media_Update_Column.Key,
                                  Media_Update_Column.IsEncrypted,
                                  Media_Update_Column.DocumentId,
                                ],
                              },
                            }
                          : undefined,
                    },
                  ].filter(d => d),
                  on_conflict: {
                    constraint: Document_Constraint.DocumentPkey,
                    update_columns: [
                      Document_Update_Column.BaseUserId,
                      Document_Update_Column.Id,
                      Document_Update_Column.IdNumber,
                      Document_Update_Column.IssuedOn,
                      Document_Update_Column.ValidTill,
                      Document_Update_Column.KindId,
                    ],
                  },
                },
                address: {
                  data: {
                    name: shop?.address?.name || "address",
                    ...omit(shop?.address, "__typename"),
                  },
                  on_conflict: {
                    update_columns: Object.values(Address_Update_Column).filter(
                      a => a !== Address_Update_Column.Id
                    ),
                    constraint: Address_Constraint.AddressPkey,
                  },
                },
              }
            })
          : undefined,
      on_conflict: {
        constraint: Shop_Constraint.ShopPkey,
        update_columns: [Shop_Update_Column.Id, Shop_Update_Column.AddressId],
      },
    }

    const { data, error } = await insertBaseUserDetails({
      baseUserIn: {
        id: state?.id,
        first_name: basicDetails?.first_name,
        last_name: basicDetails?.last_name,
        email: basicDetails?.email || "",
        number: basicDetails?.number,
        gender: basicDetails?.gender,
        status: "active",
        date_of_birth: basicDetails?.date_of_birth || "01-01-1997",
        username: basicDetails?.number,

        shops_owned: entity !== "business" ? shopsData : undefined,
        businesses_owned:
          entity === "business"
            ? {
                data: [
                  {
                    id: basicDetails?.business_id,
                    name: basicDetails?.business_name,
                    email: basicDetails?.business?.email || "",
                    kind_id: basicDetails?.business_type.id,
                    number: basicDetails?.business_number,
                    shops_owned: shopsData,
                  },
                ],
                on_conflict: {
                  constraint: Business_Constraint.BusinessPkey,
                  update_columns: [Business_Update_Column.Id],
                },
              }
            : undefined,
      },
      on_conflict: {
        constraint: Base_User_Constraint.BaseUserPkey,
        update_columns: [
          Base_User_Update_Column.Id,
          Base_User_Update_Column.Number,
          Base_User_Update_Column.FirstName,
          Base_User_Update_Column.LastName,
          Base_User_Update_Column.Email,
          Base_User_Update_Column.Gender,
        ],
      },
    })

    if (error) {
      return toast.custom(
        <Toast
          intent="danger"
          title={"Something went wrong!" + error.message}
        />
      )
    }
    return toast.custom(<Toast title={"User saved successfully!"} />)
  }

  return (
    <SectionCard
      title="Shop Address - (optional)"
      header={
        <Button
          text="Shop"
          variant="outline"
          size="sm"
          leftIcon={<Plus />}
          onClick={addShop}
        />
      }
    >
      <div className="flex flex-col gap-4">
        {state?.shops?.length > 0 ? (
          state.shops
            ?.sort((a, b) => a?.id - b?.id)
            ?.map((shop, idx) => {
              return (
                <CollapsibleCard
                  title={shop?.name}
                  key={shop.id}
                  header={
                    <Button
                      onClick={e => {
                        e.stopPropagation()
                        const onDelete = async () => {
                          const { error } = await deleteShop({
                            id: shop?.id,
                          })
                          if (error) {
                            return toast.custom(
                              <Toast
                                intent="danger"
                                title={"Something went wrong! " + error.message}
                              />
                            )
                          } else {
                            refetch?.({
                              requestPolicy: "network-only",
                            })
                            toast.custom(
                              <Toast title={"Certificate deleted!"} />
                            )
                          }
                        }
                        if (shop?.id) {
                          openConfirmDialog({
                            isOpen: true,
                            onSubmit: onDelete,
                            confirmationButtonText: "Delete",
                            message:
                              "Are you sure you want to delete this certificate?",
                          })
                          refetch?.({
                            requestPolicy: "network-only",
                          })
                        } else {
                          const stateCopy = structuredClone(state)
                          stateCopy.shops.splice(idx, 1)
                          setState(stateCopy)
                        }
                      }}
                      variant="outline"
                      size="sm"
                      intent="danger"
                      leftIcon={<Trash className="!mr-0" />}
                    />
                  }
                >
                  <div className="flex flex-col gap-4">
                    <AddressForm
                      zoom={15}
                      useNestedFieldSet
                      showMap={true}
                      address={get(shop, `address`)}
                      compact={false}
                      formClassName={classNames("w-full", "!grid-cols-3")}
                      noteClassName={"col-span-3 text-sm"}
                      getInputProps={form.getInputProps}
                      addressKey={`shops.${idx}.address`}
                      setFieldValue={form.setFieldValue}
                      mapClassName="!w-full"
                      mapWidth="full"
                      className="flex-col"
                    >
                      <FormInput
                        label="Shop Name"
                        required
                        {...getInputProps(form, `shops.${idx}.name`)}
                      />
                    </AddressForm>

                    <div className="flex flex-col gap-4">
                      <CertificateCard
                        title={"GSTIN"}
                        className="grid grid-cols-2 gap-4"
                      >
                        <div className="flex gap-4">
                          <div>
                            <FileUpload
                              {...getFileUploadProps(
                                form,
                                `shops.${idx}.gstin.attachments`,
                                1,
                                true
                              )}
                              onDelete={e => {
                                const onDelete = async () => {
                                  const { error } = await deleteAttachment({
                                    id: e.id,
                                  })
                                  if (error) {
                                    return toast.custom(
                                      <Toast
                                        intent="danger"
                                        title={
                                          "Something went wrong! " +
                                          error.message
                                        }
                                      />
                                    )
                                  } else {
                                    refetch?.({
                                      requestPolicy: "network-only",
                                    })
                                    toast.custom(
                                      <Toast title={"Certificate deleted!"} />
                                    )
                                  }
                                }
                                if (e.id) {
                                  openConfirmDialog({
                                    isOpen: true,
                                    onSubmit: () => onDelete(),
                                    confirmationButtonText: "Delete",
                                    message:
                                      "Are you sure you want to delete this attachment?",
                                  })
                                }
                                refetch?.({
                                  requestPolicy: "network-only",
                                })
                              }}
                            ></FileUpload>
                            <div className="italic text-sm  text-center text-green-600 underline">
                              Add photo
                            </div>
                          </div>
                        </div>
                        <div>
                          <FormInput
                            label="GSTIN"
                            {...getInputProps(
                              form,
                              `shops.${idx}.gstin.id_number`
                            )}
                          ></FormInput>
                        </div>
                      </CertificateCard>
                      <CertificateCard
                        title={"Shop ACT"}
                        className="grid grid-cols-2 gap-4"
                        cardClassName="overflow-visible"
                      >
                        <div className="flex gap-4 !self-center">
                          <div>
                            <FileUpload
                              {...getFileUploadProps(
                                form,
                                `shops.${idx}.shop_act.attachments`,
                                1,
                                true
                              )}
                              onDelete={e => {
                                const onDelete = async () => {
                                  const { error } = await deleteAttachment({
                                    id: e.id,
                                  })
                                  if (error) {
                                    return toast.custom(
                                      <Toast
                                        intent="danger"
                                        title={
                                          "Something went wrong! " +
                                          error.message
                                        }
                                      />
                                    )
                                  } else {
                                    refetch?.({
                                      requestPolicy: "network-only",
                                    })
                                    toast.custom(
                                      <Toast title={"Certificate deleted!"} />
                                    )
                                  }
                                }
                                if (e.id) {
                                  openConfirmDialog({
                                    isOpen: true,
                                    onSubmit: () => onDelete(),
                                    confirmationButtonText: "Delete",
                                    message:
                                      "Are you sure you want to delete this attachment?",
                                  })
                                }
                                refetch?.({
                                  requestPolicy: "network-only",
                                })
                              }}
                            ></FileUpload>
                            <div className="italic text-sm text-center text-green-600 underline">
                              Add photo
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col gap-4">
                          <FormInput
                            label="Shop Act no."
                            {...getInputProps(
                              form,
                              `shops.${idx}.shop_act.id_number`
                            )}
                          ></FormInput>
                          <FormDateInput
                            label="Issued on"
                            {...getInputProps(
                              form,
                              `shops.${idx}.shop_act.issued_on`
                            )}
                          ></FormDateInput>
                          <FormDateInput
                            label="Valid till"
                            {...getInputProps(
                              form,
                              `shops.${idx}.shop_act.valid_till`
                            )}
                          ></FormDateInput>
                        </div>
                      </CertificateCard>
                    </div>
                  </div>
                </CollapsibleCard>
              )
            })
        ) : (
          <NoDataFound />
        )}
        <Button
          disabled={state?.shops?.length === 0}
          text="Save"
          className="self-end"
          onClick={onSubmit}
        />
      </div>
    </SectionCard>
  )
}
