import { Files } from "@phosphor-icons/react"
import { openConfirmDialog } from "@vesatogo/grass-confirm-modal"
import { Button, FormInput, Toast } from "@vesatogo/grass-core"
import dayjs from "dayjs"
import { omit, pick } from "lodash-es"
import toast from "react-hot-toast"
import { OperationContext } from "urql"
import { CertificateCard } from "~/components/Registration/CertificateCard"
import { SectionCard } from "~/components/Registration/SectionCard"
import { FileUpload } from "~/components/dropzone/FileUploader/FileUploader"
import Codenames from "~/constants/Codenames"
import {
  Base_User_Constraint,
  Base_User_Update_Column,
  Document_Constraint,
  Document_Update_Column,
  Media_Constraint,
  Media_Update_Column,
  useDeleteAttachmentMutation,
  useGetDocumentKindsQuery,
  useInsertBaseUserMutation,
} from "~/generated/graphql"
import { getFileUploadProps, getInputProps } from "~/utils/form-helpers"

export type CertificatesType = {
  form: any
  refetch: (opts?: Partial<OperationContext> | undefined) => void
  setActiveSection: (string) => void
  next
  entity
}

export const Certificates = ({
  form,
  refetch,
  setActiveSection,
  next,
  entity,
}: CertificatesType) => {
  const { values: state } = form
  const [, insertBaseUserDetails] = useInsertBaseUserMutation()
  const [, deleteAttachment] = useDeleteAttachmentMutation()
  const [{ data: documentKinds }] = useGetDocumentKindsQuery()
  const aadharCardKind = documentKinds?.document_kind?.filter(
    kind => kind?.codename === Codenames.AADHAR
  )[0]
  const panCardKind = documentKinds?.document_kind?.filter(
    kind => kind?.codename === Codenames.PAN_CARD
  )[0]

  const onSubmit = async () => {
    const basicDetails = state?.basic
    const { data, error } = await insertBaseUserDetails({
      baseUserIn: {
        id: state?.id,
        first_name: basicDetails?.first_name,
        last_name: basicDetails?.last_name,
        email: basicDetails?.email || "",
        number: basicDetails?.number,
        gender: basicDetails?.gender,
        status: "active",
        date_of_birth: basicDetails?.date_of_birth || "01-01-1997",
        username: basicDetails?.number,
        documents: {
          data: [
            state?.certificates?.aadhar?.id_number && {
              ...omit(
                state?.certificates?.aadhar,
                "kind",
                "__typename",
                "created_by"
              ),
              kind_id: aadharCardKind?.id,
              issued_on:
                dayjs(state?.certificates?.aadhar?.issued_on).format(
                  "YYYY-MM-DD"
                ) || "01-01-1997",
              attachments: {
                data: state?.certificates?.aadhar?.attachments?.map(
                  attachment => {
                    return {
                      ...pick(
                        attachment,
                        "url",
                        "kind",
                        "id",
                        "key",
                        "is_encrypted"
                      ),
                    }
                  }
                ),
                on_conflict: {
                  constraint: Media_Constraint.MediaPkey,
                  update_columns: [
                    Media_Update_Column.Id,
                    Media_Update_Column.Kind,
                    Media_Update_Column.Url,
                    Media_Update_Column.Key,
                    Media_Update_Column.IsEncrypted,
                    Media_Update_Column.DocumentId,
                  ],
                },
              },
            },
            state?.certificates?.pan?.id_number && {
              ...omit(
                state?.certificates?.pan,
                "kind",
                "__typename",
                "created_by"
              ),
              kind_id: panCardKind?.id,
              issued_on:
                dayjs(state?.certificates?.pan?.issued_on).format(
                  "YYYY-MM-DD"
                ) || "01-01-1997",

              attachments: {
                data: state?.certificates?.pan?.attachments?.map(attachment => {
                  return {
                    ...pick(
                      attachment,
                      "url",
                      "kind",
                      "id",
                      "key",
                      "is_encrypted"
                    ),
                    kind: attachment?.kind || attachment?.media_kind,
                  }
                }),
                on_conflict: {
                  constraint: Media_Constraint.MediaPkey,
                  update_columns: [
                    Media_Update_Column.Id,
                    Media_Update_Column.Kind,
                    Media_Update_Column.Url,
                    Media_Update_Column.Key,
                    Media_Update_Column.IsEncrypted,
                    Media_Update_Column.DocumentId,
                  ],
                },
              },
            },
          ]?.filter(d => d),
          on_conflict: {
            constraint: Document_Constraint.DocumentPkey,
            update_columns: [
              Document_Update_Column.BaseUserId,
              Document_Update_Column.Id,
              Document_Update_Column.IdNumber,
            ],
          },
        },
      },
      on_conflict: {
        constraint: Base_User_Constraint.BaseUserPkey,
        update_columns: [Base_User_Update_Column.Id],
      },
    })

    if (error) {
      return toast.custom(
        <Toast
          intent="danger"
          title={"Something went wrong!" + error.message}
        />
      )
    } else {
      toast.custom(<Toast title={"User saved successfully!"} />)
      next && setActiveSection(next.id)
    }
  }

  const onDelete = async id => {
    const { error } = await deleteAttachment({
      id: id,
    })
    if (error) {
      return toast.custom(
        <Toast
          intent="danger"
          title={"Something went wrong! " + error.message}
        />
      )
    }
    refetch({
      requestPolicy: "network-only",
    })
  }
  return (
    <>
      <SectionCard
        icon={Files}
        title={"KYC Details"}
        cardClassName="h-[80vh] "
        headerClassName="border-b-1 border-gray-300"
        className="flex flex-col justify-between gap-4 h-full"
      >
        <div className="flex flex-col gap-4 ">
          <CertificateCard
            title={"Aadhar Card"}
            className="grid grid-cols-2 gap-4"
          >
            <div className="flex gap-4">
              <div>
                <FileUpload
                  {...getFileUploadProps(
                    form,
                    "certificates.aadhar.attachments",
                    2,
                    true
                  )}
                  appName="mandi"
                  onDelete={e => {
                    if (e.id) {
                      openConfirmDialog({
                        isOpen: true,
                        onSubmit: () => onDelete(e.id),
                        confirmationButtonText: "Delete",
                        message:
                          "Are you sure you want to delete this attachment?",
                      })
                    }
                  }}
                />

                <div className="italic text-sm text-center text-green-600 underline">
                  Please add 1 front and 1 backside Photo
                </div>
              </div>
            </div>
            <div>
              <FormInput
                // className={"w-[50%]"}
                label="Aadhar Card no."
                required
                {...getInputProps(form, "certificates.aadhar.id_number")}
                maxLength={12}
              ></FormInput>
            </div>
          </CertificateCard>
          <CertificateCard
            title={"PAN Card"}
            className="grid grid-cols-2 gap-4"
          >
            <div className="flex gap-4">
              <div>
                <FileUpload
                  {...getFileUploadProps(
                    form,
                    "certificates.pan.attachments",
                    1,
                    true
                  )}
                  onDelete={e => {
                    if (e.id) {
                      openConfirmDialog({
                        isOpen: true,
                        onSubmit: () => onDelete(e.id),
                        confirmationButtonText: "Delete",
                        message:
                          "Are you sure you want to delete this attachment?",
                      })
                    }
                    refetch?.({
                      requestPolicy: "network-only",
                    })
                  }}
                ></FileUpload>
                <div className="italic text-sm  text-center text-green-600 underline">
                  Add photo
                </div>
              </div>
            </div>
            <div>
              <FormInput  
                label="PAN Card no."
                required={entity !== "farmer"}
                // className={"w-[50%]"}
                {...getInputProps(form, "certificates.pan.id_number")}
                maxLength={10}
              ></FormInput>
            </div>
          </CertificateCard>
        </div>
        <Button
          className="mb-10 w-fit self-end"
          onClick={onSubmit}
          text="Save"
        />
      </SectionCard>
    </>
  )
}
