import { Icon, IconBase, IconWeight } from "@phosphor-icons/react"
import React, { forwardRef } from "react"

// Define your custom weights and SVG contents
const customWeights: Map<IconWeight, React.ReactElement> = new Map([
  [
    "fill",
    <>
      <path d="M96.5953 54.3225C96.5953 54.3225 169.39 143.286 183.101 243.538L96.5953 54.3225Z" />
      <path d="M173.662 221.458C173.662 221.458 143.41 179.141 115.003 183.31C86.5953 187.48 89.089 212.451 89.089 212.451C89.089 212.451 103.651 175.622 136.31 199.622C168.969 223.623 173.662 221.458 173.662 221.458Z" />
      <path d="M166.003 180.52C166.003 180.52 200.016 163.931 175.093 112.833C175.093 112.833 149.414 146.508 166.003 180.52Z" />
      <path d="M163.867 179.382C163.867 179.382 159.71 141.678 103.312 148.034C104.181 149.818 126.323 183.461 163.867 179.382Z" />
      <path d="M152.232 142.965C152.232 142.965 183.511 121.582 151.317 74.6228C149.713 75.4052 130.849 111.687 152.232 142.965Z" />
      <path d="M150.018 142.072C150.018 142.072 140.288 105.505 85.478 119.982C85.478 119.982 113.451 151.802 150.018 142.072Z" />
      <path d="M130.088 104.743C130.088 104.743 153.764 92.6106 128.07 55.3299C128.07 55.3299 113.012 79.8633 130.088 104.743Z" />
      <path d="M126.493 106.496C126.493 106.496 119.412 79.7213 75.6737 91.3681C75.6737 91.3681 97.2553 114.234 126.493 106.496Z" />
      <path d="M116.06 75.9819C116.06 75.9819 135.729 59.6871 107.071 24.8403C107.071 24.8403 96.9816 52.6715 116.06 75.9819Z" />
      <path d="M110.675 78.4298C110.675 78.4298 100.387 50.0415 57.8848 65.4344C58.891 68.264 82.2091 88.558 110.675 78.4298Z" />
      <path d="M98.42 56.5436C98.42 56.5436 103.481 26.7969 58.7401 19.3634C59.3311 23.5429 68.6733 51.4828 98.42 56.5436Z" />
      <path d="M179.009 223.296C179.009 223.296 213.56 166.39 179.345 85.0768L165.326 56.3338C165.326 56.3338 207.736 106.81 207.383 142.563C207.031 178.315 179.009 223.296 179.009 223.296Z" />
    </>,
  ],
  // Replace with your custom thin icon content
  [
    "regular",
    <>
      <path d="M102.585 45.7503C102.584 45.7489 102.583 45.7479 99.4875 48.281L96.3915 50.8137L96.4005 50.8247L96.4354 50.8677L96.5826 51.0496C96.7145 51.2132 96.9133 51.4612 97.1751 51.7911C97.6988 52.4509 98.4741 53.4385 99.469 54.7348C101.459 57.3277 104.327 61.1548 107.815 66.0648C114.794 75.8868 124.248 90.0297 134.128 107.282C153.917 141.839 175.273 188.631 182.03 238.038L189.956 236.954C183.003 186.11 161.106 138.294 141.07 103.307C131.037 85.7866 121.436 71.4227 114.337 61.4312C110.786 56.4344 107.86 52.528 105.815 49.8641C104.793 48.532 103.991 47.5104 103.442 46.8181C103.167 46.4719 102.955 46.2081 102.811 46.0289L102.645 45.824L102.601 45.7697L102.585 45.7503Z" />
      <path d="M116.824 181.501C117.36 181.401 117.911 181.309 118.476 181.226C124.502 180.342 130.877 181.891 137.271 185.055C143.655 188.215 149.793 192.858 155.194 197.745C157.659 199.976 159.947 202.237 162.012 204.399C156.954 201.258 150.293 196.768 141.571 190.358C132.9 183.985 125.036 181.369 117.994 181.46C117.6 181.466 117.21 181.479 116.824 181.501ZM91.9813 206.41C88.001 206.807 88.0008 206.805 88.0006 206.803L87.9989 206.785L87.9959 206.753L87.9881 206.662C87.9822 206.59 87.9751 206.493 87.9677 206.374C87.9531 206.137 87.9377 205.809 87.9303 205.402C87.9153 204.59 87.9318 203.457 88.0506 202.095C88.2871 199.386 88.9364 195.691 90.6144 191.814C94.0742 183.82 101.695 175.604 117.314 173.311C125.492 172.111 133.551 174.288 140.819 177.885C148.097 181.487 154.848 186.642 160.562 191.813C166.291 196.999 171.087 202.3 174.446 206.296C176.128 208.297 177.458 209.98 178.371 211.169C178.828 211.764 179.182 212.236 179.424 212.563L179.702 212.943L179.777 213.047L179.799 213.076L179.805 213.085C179.806 213.086 179.809 213.09 176.555 215.416L179.805 213.085L182.621 217.024L178.23 219.049L176.555 215.416C178.23 219.049 178.218 219.054 178.205 219.06L178.181 219.071L178.132 219.092L178.032 219.134C177.965 219.16 177.898 219.185 177.829 219.208C177.693 219.255 177.554 219.295 177.413 219.328C177.131 219.394 176.841 219.435 176.545 219.454C175.959 219.492 175.316 219.446 174.598 219.316C173.184 219.06 171.231 218.432 168.43 217.122C162.825 214.5 153.243 208.863 136.833 196.804C129.175 191.176 122.972 189.396 118.098 189.46C113.236 189.523 109.191 191.428 105.851 194.113C102.474 196.827 99.9169 200.266 98.1826 203.112C97.3245 204.52 96.6901 205.746 96.2753 206.609C96.0683 207.039 95.9173 207.376 95.8216 207.596C95.7738 207.706 95.7398 207.787 95.7198 207.835L95.701 207.881L95.6979 207.889L89.6392 223.212L88.0006 206.803L91.9813 206.41Z" />
      <path d="M174.805 104.366L177.986 106.791C174.805 104.366 174.806 104.364 174.805 104.366L174.8 104.372L174.793 104.382L174.771 104.41L174.701 104.504C174.642 104.583 174.561 104.694 174.458 104.837C174.252 105.121 173.962 105.532 173.604 106.059C172.889 107.113 171.901 108.639 170.776 110.571C168.53 114.428 165.714 119.942 163.436 126.583C158.891 139.834 156.377 157.937 165.3 176.232L167.054 179.828L170.649 178.074L168.896 174.479C170.649 178.074 170.646 178.076 170.649 178.074L170.661 178.068L170.676 178.061L170.716 178.041L170.832 177.981C170.926 177.933 171.05 177.867 171.202 177.783C171.508 177.615 171.926 177.374 172.435 177.054C173.452 176.415 174.836 175.461 176.395 174.15C179.509 171.532 183.361 167.459 186.348 161.619C192.427 149.733 194.469 131.462 181.581 105.038L178.738 99.2085L174.805 104.366ZM170.66 168.508C170.851 168.355 171.046 168.195 171.246 168.027C173.731 165.938 176.824 162.67 179.225 157.976C183.488 149.641 185.943 136.044 177.366 115.158C175.374 118.643 172.964 123.462 171.003 129.178C167.228 140.186 165.205 154.22 170.66 168.508Z" />
      <path d="M100.124 138.653L102.609 143.746C103.044 144.639 104.845 147.376 107.68 150.778C110.604 154.286 114.824 158.74 120.257 163.01C131.118 171.546 147.055 179.505 167.191 177.317L171.174 176.884L170.735 172.902L166.759 173.34C170.735 172.902 170.735 172.905 170.735 172.902L170.734 172.888L170.732 172.871L170.726 172.827L170.71 172.698C170.696 172.594 170.676 172.455 170.648 172.282C170.592 171.938 170.506 171.462 170.377 170.874C170.119 169.7 169.687 168.072 168.98 166.16C167.567 162.34 165.034 157.333 160.549 152.548C151.42 142.806 134.928 134.73 105.756 138.018L100.124 138.653ZM161.737 169.668C161.656 169.431 161.57 169.187 161.477 168.936C160.348 165.885 158.316 161.864 154.712 158.018C148.291 151.167 136.279 144.251 113.608 145.394C113.68 145.481 113.752 145.568 113.826 145.656C116.471 148.83 120.296 152.866 125.2 156.72C134.135 163.742 146.462 170.026 161.737 169.668Z" />
      <path d="M155.558 63.4734L152.456 64.9861C151.664 65.3726 151.159 65.944 151.028 66.0915C151.024 66.0958 151.021 66.0997 151.018 66.1032C150.796 66.353 150.601 66.6145 150.442 66.8429C150.116 67.3089 149.769 67.8806 149.416 68.5149C148.702 69.7974 147.853 71.5416 146.974 73.6581C145.216 77.8945 143.283 83.768 142.104 90.6401C139.749 104.372 140.362 122.417 151.823 139.181L154.08 142.483L157.382 140.226L155.125 136.924C157.382 140.226 157.38 140.228 157.382 140.226L157.393 140.218L157.407 140.209L157.444 140.183L157.55 140.108C157.636 140.046 157.749 139.963 157.888 139.857C158.166 139.646 158.545 139.346 159.003 138.956C159.917 138.175 161.149 137.028 162.501 135.503C165.204 132.457 168.424 127.863 170.529 121.644C174.811 108.989 174.156 90.6017 157.509 66.3195L155.558 63.4734ZM155.995 130.768C156.165 130.586 156.339 130.394 156.517 130.194C158.673 127.764 161.26 124.077 162.951 119.08C165.982 110.124 166.402 96.1254 154.497 76.404C154.453 76.5094 154.408 76.6162 154.363 76.7245C152.801 80.4884 151.054 85.7869 149.989 91.9928C148.047 103.312 148.411 117.379 155.995 130.768Z" />
      <path d="M85.3661 116.581L88.3703 113.94C85.3661 116.581 85.3648 116.58 85.3661 116.581L85.3715 116.587L85.3795 116.596L85.4032 116.623L85.4815 116.71C85.5473 116.783 85.6402 116.885 85.7596 117.014C85.9985 117.272 86.3437 117.637 86.7906 118.092C87.6839 119.001 88.9859 120.27 90.6591 121.753C93.9992 124.714 98.8516 128.558 104.914 132.09C117.011 139.14 134.264 145.131 153.939 139.896L157.804 138.867L156.776 135.002L152.91 136.03C156.776 135.002 156.777 135.005 156.776 135.002L156.772 134.989L156.768 134.972L156.756 134.929L156.72 134.804C156.691 134.704 156.65 134.569 156.597 134.403C156.491 134.071 156.335 133.614 156.119 133.053C155.689 131.933 155.019 130.391 154.035 128.609C152.068 125.048 148.819 120.481 143.673 116.417C133.201 108.147 115.699 102.585 87.3488 110.073L81.0964 111.724L85.3661 116.581ZM147.392 133.148C147.279 132.931 147.159 132.707 147.032 132.477C145.462 129.634 142.853 125.964 138.715 122.695C131.368 116.893 118.525 111.841 96.4353 116.18C99.4606 118.819 103.716 122.133 108.942 125.178C118.985 131.031 132.333 135.744 147.392 133.148Z" />
      <path d="M127.553 47.196L130.962 49.2884C127.553 47.196 127.554 47.1942 127.553 47.196L127.549 47.2035L127.543 47.2133L127.527 47.2397L127.479 47.3196C127.44 47.3848 127.388 47.4738 127.324 47.5858C127.196 47.8098 127.02 48.1262 126.808 48.5291C126.385 49.3344 125.816 50.4884 125.196 51.9434C123.96 54.847 122.507 58.9865 121.628 63.9704C119.863 73.98 120.414 87.4617 129.682 100.965L131.673 103.866L134.804 102.261L132.98 98.7016C134.804 102.261 134.801 102.263 134.804 102.261L134.817 102.255L134.832 102.247L134.869 102.228L134.968 102.174C135.045 102.132 135.144 102.077 135.262 102.007C135.499 101.868 135.815 101.674 136.191 101.419C136.941 100.911 137.939 100.158 139.027 99.1293C141.204 97.0702 143.772 93.871 145.38 89.3031C148.662 79.9753 147.527 66.2732 134.256 47.0185L130.768 41.9582L127.553 47.196ZM134.032 92.8209C135.374 91.437 136.858 89.4207 137.833 86.6474C139.714 81.3026 140.035 72.1089 131.555 57.6412C130.787 59.7908 130.028 62.4042 129.507 65.3598C128.171 72.9364 128.395 82.708 134.032 92.8209Z" />
      <path d="M75.657 88.0722L78.5659 85.3266C75.657 88.0722 75.6557 88.0708 75.657 88.0722L75.6625 88.078L75.6701 88.086L75.6912 88.1081L75.7572 88.1767C75.8118 88.2329 75.8875 88.3102 75.9838 88.4067C76.1764 88.5997 76.4518 88.8702 76.8066 89.2046C77.5158 89.8732 78.5449 90.8002 79.8667 91.8782C82.505 94.03 86.3395 96.8084 91.1525 99.3342C100.772 104.383 114.524 108.525 130.408 104.322L134.274 103.299L133.252 99.4326L129.385 100.455C133.252 99.4326 133.252 99.4359 133.252 99.4326L133.248 99.419L133.244 99.4029L133.233 99.3632L133.202 99.2546C133.177 99.1695 133.143 99.0593 133.1 98.9259C133.014 98.6593 132.889 98.2995 132.717 97.8633C132.374 96.9921 131.843 95.8084 131.057 94.4503C129.485 91.7334 126.879 88.2917 122.722 85.2833C114.259 79.1589 100.23 75.4186 77.5367 81.4613L71.0481 83.1891L75.657 88.0722ZM123.592 97.576C122.433 95.7893 120.664 93.6691 118.031 91.7641C112.792 87.9724 103.479 84.5813 87.2473 87.4783C89.315 88.9987 91.8813 90.682 94.87 92.2504C102.324 96.1623 112.233 99.2793 123.592 97.576Z" />
      <path d="M106.202 17.4356L109.963 18.7988C106.202 17.4356 106.203 17.4337 106.202 17.4356L106.2 17.4433L106.196 17.4536L106.186 17.4818L106.155 17.5688C106.131 17.6402 106.097 17.7383 106.056 17.862C105.974 18.1095 105.863 18.46 105.731 18.9054C105.468 19.7959 105.123 21.0689 104.776 22.6602C104.084 25.8373 103.377 30.3158 103.303 35.5726C103.157 46.0897 105.555 59.8883 115.856 72.474L118.405 75.5879L121.503 73.0208L118.952 69.9405C121.503 73.0208 121.501 73.0232 121.503 73.0208L121.515 73.0112L121.528 73L121.561 72.9728L121.646 72.8991C121.711 72.8414 121.795 72.7664 121.894 72.674C122.092 72.4894 122.353 72.2347 122.658 71.9091C123.268 71.2586 124.063 70.3196 124.894 69.0852C126.561 66.6113 128.382 62.9444 129.155 58.0629C130.722 48.1689 127.861 34.2649 113.052 16.2581L108.594 10.8375L106.202 17.4356ZM119.049 63.3261C112.778 53.7672 111.19 43.7521 111.303 35.6841C111.342 32.8262 111.596 30.2285 111.927 28.007C121.121 41.3052 122.205 50.806 121.253 56.8115C120.82 59.5489 119.943 61.713 119.049 63.3261Z" />
      <path d="M55.6758 56.9861L57.0082 60.733C57.2912 61.5288 57.7654 62.1837 58.0576 62.5619C58.4131 63.0221 58.8409 63.5001 59.3093 63.9782C60.2508 64.9394 61.4986 66.0455 63.0012 67.2066C66.0103 69.5318 70.1895 72.1916 75.2718 74.4146C85.4458 78.8646 99.4694 81.6501 114.908 76.1569L118.699 74.8082L117.328 71.0255L113.568 72.3883C117.328 71.0255 117.329 71.0284 117.328 71.0255L117.324 71.0131L117.318 70.9982L117.304 70.9604L117.263 70.8541C117.231 70.77 117.186 70.6594 117.13 70.5247C117.017 70.2554 116.854 69.8889 116.637 69.4436C116.203 68.5542 115.545 67.3428 114.615 65.9605C112.758 63.1996 109.785 59.7128 105.305 56.7738C96.1517 50.7686 81.5867 47.6021 59.4149 55.6319L55.6758 56.9861ZM107.669 69.9791C96.4422 72.691 86.2869 70.5007 78.4777 67.085C74.268 65.2437 70.7922 63.0657 68.2811 61.1727C84.903 56.5033 94.9968 59.5789 100.917 63.4627C104.082 65.5397 106.255 67.9748 107.669 69.9791Z" />
      <path d="M56.9082 8.48218L57.6717 13.882C58.0739 16.7261 60.863 25.379 67.3432 33.9873C73.9275 42.7337 84.529 51.7043 100.641 54.4455L104.585 55.1164L105.256 51.173L101.312 50.5021C105.256 51.173 105.255 51.1762 105.256 51.173L105.258 51.16L105.26 51.1443L105.267 51.1044L105.284 50.9917C105.297 50.9022 105.313 50.7841 105.33 50.6389C105.365 50.3488 105.404 49.9499 105.435 49.4554C105.496 48.4676 105.52 47.0898 105.392 45.4297C105.136 42.1139 104.264 37.6187 101.803 32.8618C96.7752 23.142 85.6175 13.2521 62.2879 9.376L56.9082 8.48218ZM97.3707 45.5342C86.2932 42.4703 78.7398 35.8245 73.7346 29.1758C70.8547 25.3502 68.8694 21.5904 67.5792 18.6165C84.2336 22.7154 91.4685 30.2954 94.6973 36.5374C96.4393 39.905 97.1295 43.0961 97.3707 45.5342Z" />
      <path d="M150.38 22.8418L178.594 80.6885C195.322 120.504 195.201 154.226 190.962 177.933C188.839 189.812 185.677 199.194 183.064 205.577C181.757 208.767 180.589 211.205 179.759 212.827C179.343 213.638 179.013 214.245 178.792 214.64C178.681 214.837 178.598 214.981 178.545 215.071L178.49 215.166L178.482 215.178C178.483 215.178 178.481 215.18 178.708 215.317L185.296 219.369L181.901 217.254C185.296 219.369 185.296 219.37 185.296 219.369L185.323 219.327L185.389 219.22L185.635 218.816C185.849 218.464 186.159 217.949 186.551 217.284C187.336 215.954 188.451 214.024 189.788 211.598C192.46 206.751 196.026 199.911 199.606 191.932C206.71 176.095 214.09 155.291 214.275 136.561C214.371 126.885 211.587 116.472 207.564 106.472C203.525 96.4317 198.135 86.5598 192.782 77.8971C187.424 69.2244 182.06 61.6959 178.039 56.337C176.027 53.6554 174.346 51.5117 173.165 50.0337C172.574 49.2945 172.108 48.7215 171.787 48.3305L171.417 47.8824L171.319 47.7645L171.293 47.7327L171.285 47.7236C171.284 47.7227 171.281 47.7192 168.219 50.2923L171.285 47.7236L150.38 22.8418ZM190.886 90.4231C194.322 96.5441 197.542 102.994 200.142 109.458C203.971 118.977 206.356 128.281 206.276 136.482C206.178 146.403 203.825 157.186 200.494 167.543C202.644 146.642 201.25 120.355 190.886 90.4231Z" />
    </>,
  ], // Replace with your custom regular icon content
])

// Define your custom icon component
const CropVarietyIcon: Icon = forwardRef((props, ref) => (
  <IconBase ref={ref} {...props} weights={customWeights} />
))

export default CropVarietyIcon
