import { UseFormReturnType } from "@mantine/form"
import { Button, FormInput, FormQuerySelector, Tag } from "@vesatogo/grass-core"
import { inr } from "@vesatogo/utils"
import { cloneDeep } from "lodash-es"
import { CaretCircleUp, Plus, Trash } from "@phosphor-icons/react"
import React, { useState } from "react"
import SharedEntityGroup from "~/constants/SharedEntityGroup"
import { ReceiptState } from "~/pages/d/trade-requests/receipts/types"
import {
  Unit,
  useAllSiUnitsQuery,
  useAllVarietyGradesQuery,
  useUsersQuery,
} from "../generated/graphql"
import { QuerySelector } from "./QuerySelector"

type TradeBuyerProps = {
  defaultCurrency?: Partial<Unit>
  items: any[]
  form: UseFormReturnType<ReceiptState, (values: ReceiptState) => ReceiptState>
  index: number
  inputProps?: any
  runComputations: (state: ReceiptState) => void
  isDisabled?: boolean
}
const TradeBuyer = ({
  defaultCurrency,
  items,
  index,
  form,
  inputProps,
  runComputations,
  isDisabled,
}: TradeBuyerProps) => {
  const [isOpen, setOpen] = useState(true)

  return (
    <div className="border rounded-lg mb-3">
      <div className="flex gap-2 w-full rounded-t-lg bg-yellow-100 p-2 text-left text-sm font-medium text-gray-600 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
        <div className="flex justify-between items-center gap-4 w-full">
          <div className="flex justify-center items-center gap-2">
            <CaretCircleUp
              onClick={() => setOpen(prev => !prev)}
              className={`${
                isOpen ? "rotate-180 transform" : ""
              } h-6 w-6 cursor-pointer hover:opacity-80`}
            />
            <span className="text-base">Select Buyer</span>
            <FormQuerySelector
              isOptionDisabled={(option: any) => {
                return form.values.trades.find(
                  trade => trade.buyer?.id === option?.id
                )
              }}
              className="min-w-[18rem]"
              serverSideQuery
              dataHook={useUsersQuery}
              variables={{ groups: ["Trader"] }}
              getOptionLabel={({ is_verified, full_name }) => {
                return `${full_name} | ${
                  is_verified === false
                    ? "Rejected"
                    : is_verified === true
                    ? "Verified"
                    : "Pending Verification"
                }`
              }}
              {...inputProps(`trades.${index}.buyer`, {
                group: SharedEntityGroup.KiteUser,
              })}
              // isDisabled={isDisabled || isBookingTrade}
            />
          </div>
          <div className="flex items-center gap-3">
            <Button
              variant="ghost"
              leftIcon={<Trash />}
              onClick={() => {
                const stateCopy = cloneDeep(form.values)
                stateCopy.trades.splice(index, 1)
                runComputations(stateCopy)
                form.setValues(stateCopy)
              }}
              intent="danger"
              size="sm"
              disabled={isDisabled}
            >
              Delete Buyer
            </Button>
            <Button
              onClick={() => form.insertListItem(`trades.${index}.items`, {})}
              variant="ghost"
              leftIcon={<Plus />}
              size="sm"
              disabled={isDisabled}
            >
              Grade
            </Button>
          </div>
        </div>
      </div>
      {isOpen && (
        <>
          <div className="grid grid-cols-4 gap-4 p-4">
            {items?.map?.((item, itemIndex) => {
              return (
                <React.Fragment key={item.id || itemIndex}>
                  <FormQuerySelector
                    label="Grade"
                    dataHook={useAllVarietyGradesQuery}
                    {...inputProps(`trades.${index}.items.${itemIndex}.grade`, {
                      group: SharedEntityGroup.KiteGrade,
                    })}
                    variables={{ variety: form.values?.commodity_variety?.id }}
                    isOptionDisabled={(opt: any) =>
                      items?.find(item => item.grade?.id === opt.id)
                    }
                  />

                  <FormInput
                    label="Rate"
                    type={"number"}
                    rightElement={
                      <Tag className="mr-2" variant="minimal">
                        {defaultCurrency?.name || "INR"}
                      </Tag>
                    }
                    {...inputProps(`trades.${index}.items.${itemIndex}.rate`)}
                  />

                  <FormInput
                    type={"number"}
                    label={"Quantity"}
                    className="vt-weight-listner"
                    inputProps={{
                      id: "vt_weight_listener",
                    }}
                    rightElement={
                      <QuerySelector
                        components={{
                          IndicatorSeparator: () => null,
                          ClearIndicator: () => null,
                        }}
                        getControlStyles={prev => {
                          return {
                            ...prev,
                            border: "none",
                          }
                        }}
                        dataHook={useAllSiUnitsQuery}
                        variables={{ type: ["Weight", "SKU"] }}
                        {...inputProps(`unit`, {
                          group: SharedEntityGroup.KiteSiUnit,
                        })}
                        disabled={
                          isDisabled
                            ? true
                            : itemIndex === 0 && index === 0
                            ? false
                            : true
                        }
                      />
                    }
                    {...inputProps(
                      `trades.${index}.items.${itemIndex}.quantity`
                    )}
                  />
                  <div className="flex items-center gap-4">
                    <FormInput
                      disabled
                      label="Material Cost"
                      value={inr(item.total_amount || 0)}
                      rightElement={
                        <Tag className="mr-2" variant="minimal">
                          {defaultCurrency?.name || "INR"}
                        </Tag>
                      }
                    />

                    <button
                      disabled={isDisabled}
                      onClick={() =>
                        form.removeListItem(`trades.${index}.items`, itemIndex)
                      }
                      className="w-max"
                    >
                      <Trash
                        className={isDisabled ? "text-gray-600" : "text-red"}
                        size={28}
                      />
                    </button>
                  </div>
                </React.Fragment>
              )
            })}
          </div>
          <hr />
          <div className="grid grid-cols-4 gap-4 text-base text-gray-800 p-4 bg-gray-100">
            <div className="col-span-2">Trade Total</div>
            <div>
              {form.values.trades[index].total_quantity || 0}{" "}
              {form.values.unit?.name || "na"}
            </div>
            <div className="flex justify-between">
              <span></span>
              <span>{inr(form.values.trades[index].total_amount || 0)}</span>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default TradeBuyer
