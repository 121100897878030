import { MagnifyingGlass, Plus } from "@phosphor-icons/react"
import { Avatar, Button, InputGroup, Tag } from "@vesatogo/grass-core"
import { TableView } from "@vesatogo/grass-table"
import { useSearchParams } from "react-router-dom"
import { AddCropModal } from "~/components/Admin/AddCropModal"
import { Order_By, useAllCropsQuery } from "~/generated/graphql"
import { config } from "~/pages/pavti/config"

const COMMON_FIELDS = []

export const CropMaster = () => {
  const [params, setParams] = useSearchParams()
  const search = params.get("search")
  const page = Number(params.get("page")) || 1

  const [{ data: allCrops }, refetch] = useAllCropsQuery({
    variables: {
      first: config?.pageSize,
      skip: (page - 1) * config?.pageSize,
      where: {
        _or: [
          { name: { _ilike: search ? `%${search}%` : "%%" } },
          { codename: { _ilike: search ? `%${search}%` : "%%" } },
        ],
      },
      order_by: {
        name: Order_By.AscNullsLast,
      },
    },
  })
  const totalCrops = allCrops?.crop_aggregate?.aggregate?.count || 0

  const COLUMNS = [
    // {
    //   accessor: "id",
    //   Header({ data }) {
    //     const selectable = data?.filter(d => d.id)
    //     const { selected, selectAll } = useTradeSelectorList()
    //     const isSelectAll =
    //       Object.keys(selected)?.length &&
    //       Object.keys(selected)?.length === selectable.length
    //     return (
    //       <Checkbox
    //         labelClassName="!mb-4"
    //         checked={isSelectAll ? true : false}
    //         onChange={() => {
    //           selectAll(selectable?.map(data => pick(data, COMMON_FIELDS)))
    //         }}
    //       />
    //     )
    //   },
    //   Cell({ value, row }) {
    //     const { selected, setSelected } = useTradeSelectorList()
    //     if (!row?.original?.id) return null
    //     return (
    //       <Checkbox
    //         checked={selected[value] ? true : false}
    //         onChange={() => {
    //           setSelected(pick(row.original, COMMON_FIELDS))
    //         }}
    //       />
    //     )
    //   },
    // },
    {
      Header: "Sr. No.",
      className: "!text-center",
      accessor: "id",
      Cell({ row }) {
        return parseInt(row?.id) + 1
      },
    },
    {
      Header: "Crop Name",
      accessor: "name",
      className: "text-center",
      Cell({ value, row }) {
        return (
          <div className="flex gap-2">
            <Avatar
              className="relative text-center"
              name={value}
              src={row?.original?.image?.url}
            />
            <div
              className="cursor-pointer border-b-1 border-dashed border-gray-600 inline self-center"
              onClick={() => {
                params.set("id", row?.original?.id)
                setParams(params)
              }}
            >
              {value}
            </div>
          </div>
        )
      },
    },
    {
      Header: "Code Name",
      accessor: "codename",
      Cell({ value }) {
        return value
      },
    },

    {
      Header: "Crop Variety",
      accessor: "crop_varieties",
      Cell({ value }) {
        return (
          <div className="flex  gap-2">
            {value?.map(group => {
              return (
                <Tag intent="info" variant="minimal" className="!rounded-xl">
                  {group?.name}
                </Tag>
              )
            })}
          </div>
        )
      },
    },
  ]

  return (
    <>
      <header className="flex justify-between p-2 border-b-1 border-b-gray-300">
        <div className="self-center font-500 pl-4">Crop Master</div>
        <div className="w-[500px] flex items-center gap-3">
          <InputGroup
            className={"w-full"}
            inputProps={{
              value: search || "",
              placeholder: "Search",

              onChange(e) {
                params.set("search", e.target.value)
                setParams(params)
              },
            }}
            leftElement={<MagnifyingGlass />}
          />
        </div>
        <div className="flex gap-3">
          <Button
            onClick={() => {
              params.set("id", "new")
              setParams(params)
            }}
            leftIcon={<Plus />}
          >
            Crop
          </Button>
        </div>
      </header>
      <TableView
        relative={false}
        paginationProps={{
          total: Math.ceil(totalCrops / config.pageSize),
          page: page,
          onChange(page) {
            params.set("page", page.toString())
            params.set("search", "")
            setParams(params)
          },
        }}
        columns={COLUMNS}
        data={allCrops?.crop || []}
        meta={<>{totalCrops} Crops</>}
        getCellProps={cell => {
          if (cell?.column?.Header === "Sr. No.") {
            return {
              className: "!text-center",
            }
          }
        }}
        className="!h-[calc(100vh-64px-57px)]"
      />
      <AddCropModal onAddComplete={refetch} />
    </>
  )
}

export default CropMaster
