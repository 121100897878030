import { Tag, TagProps } from "@vesatogo/grass-core"
import classNames from "classnames"
import React, { useMemo } from "react"
import isNew, { ToleranceUnit } from "~/utils/is-new"

interface NewTagProps extends TagProps {
  /**
   * The timestamp of the newly created object
   */

  createdAt: string | null
  /**
   * The amount of days after the creation to consider the object new
   * eg. a tolerance of 7 will mean that the object is new event after 7 days since its
   * creation
   * @default 7
   */
  tolerance?: number
  /**
   * The text to display as a new object
   * @default "New!"
   */

  /**
   * @description The unit of time to use for the tolerance
   * @default "days"
   */
  toleranceUnit?: ToleranceUnit
  text?: string
}
const NewTag = ({
  className,
  createdAt,
  text,
  tolerance = 7,
  toleranceUnit = "days",
  ...otherProps
}: NewTagProps) => {
  const isObjNew = useMemo(
    () => isNew (createdAt, tolerance, toleranceUnit),
    [createdAt, tolerance, toleranceUnit]
  )
  return (
    <>
      {isObjNew && createdAt && (
        <Tag
          className={classNames("ml-3 !rounded-xl", className)}
          variant="minimal"
          intent="primary"
          {...otherProps}
        >
          {text}
        </Tag>
      )}
    </>
  )
}

NewTag.defaultProps = {
  text: "New!",
}
export default NewTag
