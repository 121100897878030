import { Files } from "@phosphor-icons/react"
import { openConfirmDialog } from "@vesatogo/grass-confirm-modal"
import { Button, FormInput, Toast } from "@vesatogo/grass-core"
import dayjs from "dayjs"
import { pick } from "lodash-es"
import toast from "react-hot-toast"
import { OperationContext } from "urql"
import { CertificateCard } from "~/components/Registration/CertificateCard"
import { SectionCard } from "~/components/Registration/SectionCard"
import { FileUpload } from "~/components/dropzone/FileUploader/FileUploader"
import Codenames from "~/constants/Codenames"
import {
  Base_User_Constraint,
  Base_User_Update_Column,
  Business_Constraint,
  Business_Update_Column,
  Document_Arr_Rel_Insert_Input,
  Document_Constraint,
  Document_Update_Column,
  InputMaybe,
  Media_Constraint,
  Media_Update_Column,
  useDeleteAttachmentMutation,
  useGetDocumentKindsQuery,
  useInsertBaseUserMutation,
} from "~/generated/graphql"
import { getFileUploadProps, getInputProps } from "~/utils/form-helpers"

export type DocumentsType = {
  form: any
  refetch: (opts?: Partial<OperationContext> | undefined) => void
  docs: string[]
  entity?: "farmer" | "trader" | "business"
  setActiveSection: (string) => void
  next
}
export const Documents = ({
  form,
  refetch,
  docs,
  entity = "farmer",
  setActiveSection,
  next,
}: DocumentsType) => {
  const [, insertUserDocs] = useInsertBaseUserMutation()
  const [{ data: documentKinds }] = useGetDocumentKindsQuery()
  const [, deleteAttachment] = useDeleteAttachmentMutation()

  const onSubmit = async () => {
    const state = form?.values
    const basic = form?.values?.basic
    const documentData: InputMaybe<Document_Arr_Rel_Insert_Input> | undefined =
      {
        data: docs
          ?.map(doc => {
            return (
              state?.documents?.[doc]?.id_number && {
                id: state?.documents?.[doc]?.id,
                issued_on: state?.documents?.[doc]?.issued_on
                  ? dayjs(state?.documents?.[doc]?.issued_on).format(
                      "YYYY-MM-DD"
                    )
                  : "01-01-1997",
                attachments: state?.documents?.[doc]?.attachments?.length
                  ? {
                      data: state?.documents?.[doc]?.attachments?.map(
                        attachment => {
                          return {
                            ...pick(
                              attachment,
                              "key",
                              "id",
                              "url",
                              "is_encrypted",
                              "kind"
                            ),
                          }
                        }
                      ),
                      on_conflict: {
                        constraint: Media_Constraint.MediaPkey,
                        update_columns: [
                          Media_Update_Column.Id,
                          Media_Update_Column.Kind,
                          Media_Update_Column.Url,
                          Media_Update_Column.Key,
                          Media_Update_Column.Kind,
                          Media_Update_Column.DocumentId,
                        ],
                      },
                    }
                  : undefined,
                kind_id: documentKinds?.document_kind?.filter(
                  kind =>
                    kind?.codename ===
                    (doc === "gstin"
                      ? Codenames.GST_BASE_USER
                      : doc === "license"
                      ? Codenames?.MARKET_LICENSE
                      : doc === "pan"
                      ? Codenames.PAN_CARD
                      : false)
                )[0]?.id,
                id_number: state?.documents?.[doc]?.id_number,
              }
            )
          })
          ?.filter(d => d),
        on_conflict: {
          constraint: Document_Constraint.DocumentPkey,
          update_columns: [
            Document_Update_Column.Id,
            Document_Update_Column.IdNumber,
            Document_Update_Column.IssuedOn,
          ],
        },
      }
    const { data, error } = await insertUserDocs({
      baseUserIn: {
        id: state?.id,
        first_name: basic?.first_name,
        last_name: basic?.last_name,
        email: basic?.email || "",
        number: basic?.number,
        gender: basic?.gender,
        status: "active",
        date_of_birth: basic?.date_of_birth || "01-01-1997",
        username: basic?.number,
        documents: entity !== "business" ? documentData : undefined,
        businesses_owned:
          entity === "business"
            ? {
                data: [
                  {
                    id: basic?.business_id,
                    name: basic?.business_name,
                    email: basic?.business?.email || "",
                    kind_id: basic?.business_type.id,
                    number: basic?.business_number,
                    documents: documentData,
                  },
                ],
                on_conflict: {
                  constraint: Business_Constraint.BusinessPkey,
                  update_columns: [Business_Update_Column.Id],
                },
              }
            : undefined,
      },
      on_conflict: {
        constraint: Base_User_Constraint.BaseUserPkey,
        update_columns: [Base_User_Update_Column.Id],
      },
    })
    if (error) {
      return toast.custom(
        <Toast
          intent="danger"
          title={"Something went wrong!" + error.message}
        />
      )
    } else {
      refetch({
        requestPolicy: "network-only",
      })
      toast.custom(<Toast title={"User Documents saved successfully!"} />)
      next && setActiveSection(next.id)
    }
  }
  return (
    <SectionCard
      icon={Files}
      title={"Document Detail"}
      cardClassName="min-h-[80vh] overflow-auto"
      headerClassName="border-b-1 border-gray-300"
      className="flex flex-col gap-4 justify-between"
    >
      <div className="flex flex-col gap-4">
        <CertificateCard
          title={"GSTIN"}
          className="grid grid-cols-2 gap-4"
          cardClassName={!docs.includes("gstin") ? "hidden" : ""}
        >
          <div className="flex gap-4">
            <div>
              <FileUpload
                {...getFileUploadProps(
                  form,
                  "documents.gstin.attachments",
                  1,
                  true
                )}
                onDelete={e => {
                  const onDelete = async () => {
                    const { error } = await deleteAttachment({
                      id: e.id,
                    })
                    if (error) {
                      return toast.custom(
                        <Toast
                          intent="danger"
                          title={"Something went wrong! " + error.message}
                        />
                      )
                    } else {
                      refetch?.({
                        requestPolicy: "network-only",
                      })
                      toast.custom(<Toast title={"Certificate deleted!"} />)
                    }
                  }
                  if (e.id) {
                    openConfirmDialog({
                      isOpen: true,
                      onSubmit: () => onDelete(),
                      confirmationButtonText: "Delete",
                      message:
                        "Are you sure you want to delete this attachment?",
                    })
                  }
                  refetch?.({
                    requestPolicy: "network-only",
                  })
                }}
              ></FileUpload>
              <div className="italic text-sm text-center text-green-600 underline">
                Add photo
              </div>
            </div>
          </div>
          <div>
            <FormInput
              label="GSTIN"
              {...getInputProps(form, "documents.gstin.id_number")}
            ></FormInput>
          </div>
        </CertificateCard>
        <CertificateCard
          title={"License"}
          className="grid grid-cols-2 gap-4"
          cardClassName={!docs.includes("license") ? "hidden" : ""}
        >
          <div className="flex gap-4">
            <div>
              <FileUpload
                {...getFileUploadProps(
                  form,
                  "documents.license.attachments",
                  1,
                  true
                )}
                onDelete={e => {
                  const onDelete = async () => {
                    const { error } = await deleteAttachment({
                      id: e.id,
                    })
                    if (error) {
                      return toast.custom(
                        <Toast
                          intent="danger"
                          title={"Something went wrong! " + error.message}
                        />
                      )
                    } else {
                      refetch?.({
                        requestPolicy: "network-only",
                      })
                      toast.custom(<Toast title={"Certificate deleted!"} />)
                    }
                  }
                  if (e.id) {
                    openConfirmDialog({
                      isOpen: true,
                      onSubmit: () => onDelete(),
                      confirmationButtonText: "Delete",
                      message:
                        "Are you sure you want to delete this attachment?",
                    })
                  }
                  refetch?.({
                    requestPolicy: "network-only",
                  })
                }}
              ></FileUpload>
              <div className="italic text-sm  text-center text-green-600 underline">
                Add photo
              </div>
            </div>
          </div>
          <div>
            <FormInput
              label="License no."
              required={entity === "business"}
              {...getInputProps(form, "documents.license.id_number")}
            ></FormInput>
          </div>
        </CertificateCard>
        <CertificateCard
          title={"PAN Card"}
          className="grid grid-cols-2 gap-4"
          cardClassName={!docs.includes("pan") ? "hidden" : ""}
        >
          <div className="flex gap-4">
            <div>
              <FileUpload
                {...getFileUploadProps(
                  form,
                  "documents.pan.attachments",
                  1,
                  true
                )}
                onDelete={e => {
                  const onDelete = async () => {
                    const { error } = await deleteAttachment({
                      id: e.id,
                    })
                    if (error) {
                      return toast.custom(
                        <Toast
                          intent="danger"
                          title={"Something went wrong! " + error.message}
                        />
                      )
                    } else {
                      refetch?.({
                        requestPolicy: "network-only",
                      })
                      toast.custom(<Toast title={"Certificate deleted!"} />)
                    }
                  }
                  if (e.id) {
                    openConfirmDialog({
                      isOpen: true,
                      onSubmit: () => onDelete(),
                      confirmationButtonText: "Delete",
                      message:
                        "Are you sure you want to delete this attachment?",
                    })
                  }
                  refetch?.({
                    requestPolicy: "network-only",
                  })
                }}
              ></FileUpload>
              <div className="italic text-sm  text-center text-green-600 underline">
                Add photo
              </div>
            </div>
          </div>
          <div>
            <FormInput
              maxLength={10}
              label="PAN Card no."
              required
              {...getInputProps(form, "documents.pan.id_number")}
            ></FormInput>
          </div>
        </CertificateCard>
      </div>
      <Button text="Save" onClick={onSubmit} className="w-fit self-end" />
    </SectionCard>
  )
}
