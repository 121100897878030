import {
  Circle,
  MagnifyingGlass,
  Plus,
  User,
  UsersThree,
} from "@phosphor-icons/react"
import {
  Avatar,
  Button,
  InputGroup,
  OverflowMenu,
  SegmentedControl,
  Tooltip,
} from "@vesatogo/grass-core"
import { TableView } from "@vesatogo/grass-table"
import dayjs from "dayjs"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import Codenames from "~/constants/Codenames"
import { AppRoutes } from "~/constants/routes"
import { Order_By, useAllFarmersQuery } from "~/generated/graphql"
import { config } from "~/pages/pavti/config"

const COMMON_FIELDS = []

export const FarmerRegistration = () => {
  const navigate = useNavigate()
  const [params, setParams] = useSearchParams()
  const search = params.get("search")
  const page = Number(params.get("page")) || 1

  const [{ data: allFarmersData }] = useAllFarmersQuery({
    variables: {
      first: config.pageSize,
      skip: (page - 1) * config.pageSize,
      order_by: {
        created_at: Order_By.DescNullsLast,
        full_name: Order_By.AscNullsLast,
      },
      where: {
        groups: {
          group: {
            name: {
              _eq: Codenames.FarmerGroup,
            },
          },
        },
        _or: [
          {
            full_name: {
              _ilike: search ? `%${search}%` : "%%",
            },
          },
          {
            number: {
              _ilike: search ? `%${search}%` : "%%",
            },
          },
        ],
      },
    },
  })
  const totalFarmers =
    allFarmersData?.base_user_aggregate?.aggregate?.count || 0

  const COLUMNS = [
    // {
    //   accessor: "id",
    //   Header({ data }) {
    //     const selectable = data?.filter(d => d.id)
    //     const { selected, selectAll } = useTradeSelectorList()
    //     const isSelectAll =
    //       Object.keys(selected)?.length &&
    //       Object.keys(selected)?.length === selectable.length
    //     return (
    //       <Checkbox
    //         labelClassName="!mb-4"
    //         checked={isSelectAll ? true : false}
    //         onChange={() => {
    //           selectAll(selectable?.map(data => pick(data, COMMON_FIELDS)))
    //         }}
    //       />
    //     )
    //   },
    //   Cell({ value, row }) {
    //     const { selected, setSelected } = useTradeSelectorList()
    //     if (!row?.original?.id) return null

    //     return (
    //       <Checkbox
    //         checked={selected[value] ? true : false}
    //         onChange={() => {
    //           setSelected(pick(row.original, COMMON_FIELDS))
    //         }}
    //       />
    //     )
    //   },
    // },
    {
      Header: "Sr. No.",
      className: "!text-center",
      accessor: "id",
      Cell({ row }) {
        return parseInt(row?.id) + 1
      },
    },
    {
      Header: "Created on",
      accessor: "created_at",
      Cell({ value }) {
        return (
          <div className="">
            {dayjs(value).format("DD MMM 'YY")}
            <div className="text-gray-400">{dayjs(value).format("h:mm A")}</div>
          </div>
        )
      },
    },
    {
      Header: "Farmer",
      accessor: "full_name",
      Cell({ value, row }) {
        return (
          <Link to={`${AppRoutes.farmerRegistration}/${row?.original?.id}`}>
            {value}
          </Link>
        )
      },
    },
    {
      Header: "Village",
      accessor: "address",
      Cell({ value }) {
        return value?.village
      },
    },
    {
      Header: "Plots",
      accessor: "plots",
      Cell({ value }) {
        return <OverflowMenu visibleItems={1} items={value}></OverflowMenu>
      },
    },
    {
      Header: "Aadhar Card",
      accessor: "aadhar",
      Cell({ value }) {
        return value?.[0]?.id_number
      },
    },
    {
      Header: "Pan Card",
      accessor: "pan",
      Cell({ value }) {
        return value?.[0]?.id_number
      },
    },
    {
      Header: "Status",
      accessor: "is_verified",
      Cell({ value }) {
        return (
          <div className="flex gap-1 items-center">
            <Circle
              size={14}
              weight="fill"
              className={
                value === null
                  ? "text-yellow-400"
                  : value
                  ? "text-green -400"
                  : "text-red-400"
              }
            />{" "}
            {value === null ? "Pending" : value ? "Confirm" : "Rejected"}
          </div>
        )
      },
    },
    {
      Header: "Remarks",
      accessor: "verification_remark",
      Cell({ value }) {
        return value
      },
    },
    {
      Header: "Created By",
      accessor: "created_by",
      Cell({ value, row }) {
        if (!value) return "--"
        return (
          <Tooltip
            content={
              <div>
                <p>{value?.full_name || "na"}</p>
                <p className="text-gray-400">
                  {dayjs(row?.original?.created_at).format(
                    "DD MMM 'YY | h:mm a"
                  )}
                </p>
              </div>
            }
          >
            <Avatar
              className="relative text-center"
              name={value?.name}
              src={value?.image?.url}
            />
          </Tooltip>
        )
      },
    },
  ]

  return (
    <>
      <header className="flex justify-between p-2 border-b-1 border-b-gray-300">
        <SegmentedControl
          value={"farmer"}
          onChange={value => {
            if (value === "trader") navigate(AppRoutes.traderRegistration)
          }}
          data={[
            {
              label: "Farmer",
              value: "farmer",
              icon: <User className="mr-1" weight="fill" size={20} />,
            },
            {
              label: "Trader",
              value: "trader",
              icon: <UsersThree className="mr-1" size={20} />,
            },
          ]}
        />
        <div className="w-[500px] flex items-center gap-3">
          <InputGroup
            className={"w-full"}
            inputProps={{
              value: search || "",
              placeholder: "Search",

              onChange(e) {
                params.set("search", e.target.value)
                setParams(params)
              },
            }}
            leftElement={<MagnifyingGlass />}
          />
        </div>
        <div className="flex gap-3">
          <Button
            onClick={() => navigate(`${AppRoutes.farmerRegistration}/new`)}
            leftIcon={<Plus />}
          >
            Farmer Reg.
          </Button>
        </div>
      </header>
      <TableView
        relative={false}
        paginationProps={{
          total: Math.ceil(totalFarmers / config.pageSize),
          page: page,
          onChange(page) {
            params.set("page", page.toString())
            params.set("search", "")
            setParams(params)
          },
        }}
        getCellProps={cell => {
          if (cell?.column?.Header === "Sr. No.") {
            return {
              className: "!text-center",
            }
          }
        }}
        columns={COLUMNS}
        data={allFarmersData?.base_user || []}
        meta={<>{totalFarmers} farmers</>}
        className="!h-[calc(100vh-64px-57px)]"
      />
    </>
  )
}

export default FarmerRegistration
