import { useForm, yupResolver } from "@mantine/form"
import {
  Button,
  Card,
  FormInput,
  FormQuerySelector,
  Modal,
  SpinnerOverlay,
  Toast,
} from "@vesatogo/grass-core"
import { pick } from "lodash-es"
import { useEffect } from "react"
import toast from "react-hot-toast"
import { useSearchParams } from "react-router-dom"
import { AddCompleteType } from "~/components/Admin/AddCompleteType"
import { FileUpload } from "~/components/dropzone/FileUploader/FileUploader"
import {
  Crop_Variety_Constraint,
  Crop_Variety_Update_Column,
  Media_Constraint,
  Media_Update_Column,
  useCropVarietyDetailQuery,
  useCropsListQuery,
  useInsertCropVarietyMutation,
} from "~/generated/graphql"
import { cropVarietyModel } from "~/models/crop_variety"
import { getFileUploadProps, getInputProps } from "~/utils/form-helpers"

const INITIAL_STATE = {
  photo: [],
}

export const AddCropVarietyModal = ({ onAddComplete }: AddCompleteType) => {
  const [params, setParams] = useSearchParams()
  const cropVarietyId = params?.get("id") || ""

  const form = useForm<any>({
    initialValues: INITIAL_STATE,
    validate: yupResolver(cropVarietyModel),
    validateInputOnChange: true,
    validateInputOnBlur: true,
    clearInputErrorOnChange: false,
  })

  const state = form.values
  const setState = form.setValues

  const [{ data, fetching }] = useCropVarietyDetailQuery({
    variables: {
      id: cropVarietyId,
    },
    pause: cropVarietyId === "new" || !Boolean(cropVarietyId),
  })

  const [{ fetching: insertFetching }, insertCropVariety] =
    useInsertCropVarietyMutation()

  useEffect(() => {
    setState({
      name: data?.crop_variety_by_pk?.name,
      codename: data?.crop_variety_by_pk?.codename,
      crop: data?.crop_variety_by_pk?.crop,
      photo: data?.crop_variety_by_pk?.image
        ? [data?.crop_variety_by_pk?.image]
        : [],
    })
  }, [data])

  const onClose = () => {
    setParams({})
    form?.reset()
  }

  const onSubmit = async () => {
    const { errors, hasErrors } = form?.validate()
    if (hasErrors) {
      return toast.custom(
        <Toast
          intent="danger"
          title={"Please resolve all errors before proceeding!"}
        />
      )
    }
    const { data: insertData, error } = await insertCropVariety({
      object: {
        id: cropVarietyId !== "new" ? cropVarietyId : undefined,
        name: state?.name,
        codename: state?.codename,
        crop_id: state?.crop?.id,
        image: state?.photo[0]
          ? {
              data: {
                ...pick(
                  state?.photo[0],
                  "kind",
                  "url",
                  "key",
                  "is_encrypted",
                  "id"
                ),
              },
              on_conflict: {
                constraint: Media_Constraint.MediaPkey,
                update_columns: [Media_Update_Column.Url],
              },
            }
          : undefined,
      },
      on_conflict: {
        constraint: Crop_Variety_Constraint.CropVarietyPkey,
        update_columns: [
          Crop_Variety_Update_Column.Codename,
          Crop_Variety_Update_Column.Name,
          Crop_Variety_Update_Column.ImageId,
          Crop_Variety_Update_Column.Id,
        ],
      },
    })
    if (error) {
      return toast.custom(
        <Toast
          intent="danger"
          title={"Something went wrong! " + error.message}
        />
      )
    }
    if (insertData) {
      onClose()
      toast.custom(
        <Toast
          title={
            cropVarietyId === "new"
              ? "Crop Variety added successfully!"
              : "Crop Variety updated successfully!"
          }
        />
      )
      onAddComplete?.({
        requestPolicy: "network-only",
      })
    }
  }

  return (
    <Modal
      bodyClassName="overflow-visible w-[60%]"
      title="Crop Variety"
      contentClassName="bg-gray-100"
      footer={
        <div className="flex justify-end gap-3">
          <Button size="sm" onClick={onClose} variant="outline">
            Cancel
          </Button>
          <Button loading={insertFetching} size="sm" onClick={onSubmit}>
            Save
          </Button>
        </div>
      }
      isOpen={Boolean(params.get("id"))}
      onClose={onClose}
    >
      <Card className="p-4 flex gap-20">
        <SpinnerOverlay show={fetching} />
        <div>
          <FileUpload {...getFileUploadProps(form, "photo", 1)} />
        </div>
        <div className="grid grid-cols-2 gap-4 w-full">
          <FormInput
            label="Crop Variety Name"
            required
            placeholder="Enter Name"
            {...getInputProps(form, "name")}
          />
          <FormInput
            label="Code Name"
            placeholder="Code Name"
            {...getInputProps(form, "codename")}
          />
          <FormQuerySelector
            dataHook={useCropsListQuery}
            serverSideQuery
            label="Crop Name"
            required
            {...getInputProps(form, "crop")}
          />
        </div>
      </Card>
    </Modal>
  )
}
