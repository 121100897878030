import { MapPinLine } from "@phosphor-icons/react"
import { Button, SpinnerOverlay, Toast } from "@vesatogo/grass-core"
import { omit } from "lodash-es"
import toast from "react-hot-toast"
import AddressForm from "~/components/Registration/AddressForm"
import { SectionCard } from "~/components/Registration/SectionCard"
import {
  Address_Constraint,
  Address_Update_Column,
  Base_User_Constraint,
  Base_User_Update_Column,
  Business_Constraint,
  Business_Update_Column,
  useInsertBaseUserMutation,
} from "~/generated/graphql"

export type AddressProps = {
  form: any
  addressKey?: string
  entity?: "farmer" | "trader" | "business"
  setActiveSection: (string) => void
  next
}

export const Address = ({
  form,
  entity = "farmer",
  setActiveSection,
  next,
}: AddressProps) => {
  const { values: state } = form
  const [{ fetching }, insertBaseUserDetails] = useInsertBaseUserMutation()

  const onSubmit = async () => {
    const basicDetails = state?.basic
    const address = {
      data: {
        ...omit(state?.current_address, "__typename"),
        name: state?.current_address?.name || state?.current_address?.village,
      },
      on_conflict: {
        constraint: Address_Constraint.AddressPkey,
        update_columns: [
          Address_Update_Column.Country,
          Address_Update_Column.Village,
          Address_Update_Column.LocalArea,
          Address_Update_Column.Taluka,
          Address_Update_Column.District,
          Address_Update_Column.State,
          Address_Update_Column.Pincode,
          Address_Update_Column.Name,
        ],
      },
    }
    const { data, error } = await insertBaseUserDetails({
      baseUserIn: {
        id: state?.id,
        first_name: basicDetails?.first_name,
        last_name: basicDetails?.last_name,
        email: basicDetails?.email || "",
        number: basicDetails?.number,
        gender: basicDetails?.gender,
        status: "active",
        date_of_birth: basicDetails?.date_of_birth || "01-01-1997",
        username: basicDetails?.number,
        address: entity !== "business" ? address : undefined,
        businesses_owned:
          entity === "business"
            ? {
                data: [
                  {
                    id: basicDetails?.business_id,
                    name: basicDetails?.business_name,
                    email: basicDetails?.business?.email || "",
                    kind_id: basicDetails?.business_type.id,
                    address: address,
                    number: basicDetails?.business_number,
                  },
                ],
                on_conflict: {
                  constraint: Business_Constraint.BusinessPkey,
                  update_columns: [
                    Business_Update_Column.Id,
                    Business_Update_Column.AddressId,
                  ],
                },
              }
            : undefined,
      },
      on_conflict: {
        constraint: Base_User_Constraint.BaseUserPkey,
        update_columns: [
          Base_User_Update_Column.Id,
          Base_User_Update_Column.AddressId,
        ],
      },
    })

    if (error) {
      return toast.custom(
        <Toast
          intent="danger"
          title={"Something went wrong!" + error.message}
        />
      )
    } else {
      toast.custom(<Toast title={"User saved successfully!"} />)
      next && setActiveSection(next.id)
    }
  }

  return (
    <>
      <SpinnerOverlay show={fetching} />
      <SectionCard
        icon={MapPinLine}
        title={"Address Details"}
        cardClassName="h-[80vh] "
        className="flex flex-col justify-between  h-full"
      >
        <AddressForm
          setValues={form.setValues}
          getInputProps={form.getInputProps}
          mapClassName="!w-full"
          mapWidth="100%"
          address={state.current_address as any}
          addressKey={"current_address"}
          formClassName="w-full"
        ></AddressForm>
        <Button
          className="mb-10 w-fit self-end"
          onClick={onSubmit}
          text="Save"
        />
      </SectionCard>
    </>
  )
}
