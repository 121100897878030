import { Address } from "~/components/Registration/FormSections/Address"
import { Certificates } from "~/components/Registration/FormSections/Certificates"
import { FarmerTraderBasicDetails } from "~/components/Registration/FormSections/FarmerTraderBasicDetails"
import { PaymentDetails } from "~/components/Registration/FormSections/PaymentDetails"
import { Documents } from "~/components/Registration/FormSections/Trader/Documents"
import { ShopAddress } from "~/components/Registration/FormSections/Trader/ShopAddress"

export type FormTabType = {
  title: string
  id?: string
  component?: any
  isActive?: boolean
}
export const NonRegisteredTraderFormSections: FormTabType[] = [
  {
    title: "Basic Details",
    id: "basic",
    component: FarmerTraderBasicDetails,
    isActive: true,
  },
  {
    title: "Address",
    id: "current_address",
    component: Address,
    isActive: false,
  },
  {
    title: "KYC",
    id: "certificates",
    component: Certificates,
    isActive: false,
  },
  {
    title: "Bank Details",
    id: "payment",
    component: PaymentDetails,
    isActive: false,
  },
  {
    title: "Document",
    id: "documents",
    component: Documents,
    isActive: false,
  },

  {
    title: "Shop Address (optional)",
    id: "shops",
    component: ShopAddress,
    isActive: false,
  },
]
