import { useDebouncedValue, useLocalStorage } from "@mantine/hooks"
import {
  DotsThreeVertical,
  List,
  MagnifyingGlass,
  Plus,
  Receipt,
} from "@phosphor-icons/react"
import {
  Avatar,
  AvatarGroup,
  Button,
  InputGroup,
  Menu,
  MenuItem,
  Popover,
  SegmentedControl,
  Toast,
  Tooltip,
} from "@vesatogo/grass-core"
import { Cell, TableView } from "@vesatogo/grass-table"
import { inr, minifyUuid } from "@vesatogo/utils"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import toast from "react-hot-toast"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { useAuthorization } from "~/components/AuthorizationProvider"
import { Permission } from "~/constants/Permissions"
import { OrdersCustom } from "~/constants/icons"
import { APIRoutes, AppRoutes } from "~/constants/routes"
import { TRADE_KINDS } from "~/constants/static.items"
import {
  Order_By,
  Receipt_Bool_Exp,
  UsersQuery,
  useAllReceiptsQuery,
  useAuthenticatedUserQuery,
} from "~/generated/graphql"
import { config } from "~/pages/pavti/config"
import { downloadPdf } from "~/utils/downloadPdf"

import ReceiptsFilter from "../ReceiptsFilter"
export function downLoadReceipt(id: string | number) {
  return () => {
    const token = localStorage.getItem("token")
    downloadPdf(APIRoutes.downloadTradeReceipt(id), token).catch(err => {
      return toast.custom(
        <Toast title={"Unable to download the receipt"} intent="danger" />
      )
    })
  }
}
const COLUMNS = [
  {
    Header: <div className="pl-3">Receipt No</div>,
    accessor: "name",
    Cell({ row: { original } }) {
      if (!original.id) return "--"
      return (
        <div className="pl-4">
          <Link
            className="link-text text-left"
            to={AppRoutes.tradeReceiptDetail(original.id)}
          >
            Receipt #{original.id}
          </Link>
        </div>
      )
    },
  },
  {
    Header: "Type",
    accessor: "kind",
    Cell({ value }) {
      const kind = TRADE_KINDS.find(k => k.id === value)
      return kind?.name || "--"
    },
  },
  {
    Header: "Tracking code",
    accessor: "tracking_code",
    Cell({ value }) {
      if (!value) return "--"
      return minifyUuid(value)
    },
  },
  {
    Header: "Created On",
    accessor: "created_at",
    Cell({ value }) {
      if (!value) return "--"
      return (
        <Cell
          title={dayjs(value).format("D MMM 'YY")}
          subtitle={dayjs(value).format("hh:mm a")}
        />
      )
    },
  },
  {
    Header: "Farmer",
    accessor: "seller",
    Cell({ value }) {
      if (!value) return "--"
      return <Cell title={value.full_name} />
    },
  },
  {
    Header: "Traders",
    accessor: "trades",
    Cell({ value }) {
      if (!value?.length) return "--"
      return (
        <AvatarGroup>
          {value.map(value => {
            const user = value?.buyer
            if (!user) return null
            return (
              <Tooltip
                content={
                  <div>
                    <div>{user.full_name}</div>
                  </div>
                }
                key={value.id}
              >
                <Avatar name={user.full_name} src={user?.image?.url} />
              </Tooltip>
            )
          })}
        </AvatarGroup>
      )
    },
  },
  {
    Header: "Total Quantity",
    accessor: "total_quantity",
    Cell({ value, row: { original } }) {
      if (!value && value !== 0) return "--"
      return (
        <span>
          {value || "0"}{" "}
          <span className="text-gray-500">
            {original.trades?.[0]?.items[0]?.quantity_unit?.name || "na"}
          </span>
        </span>
      )
    },
  },
  {
    Header: "Total Amount",
    accessor: "total_amount",
    Cell({ value }) {
      if (!value) return "--"
      return <Cell title={inr(value)} />
    },
  },
  {
    Header: "Placed By",
    accessor: "created_by",
    Cell({ value, row }) {
      if (!value) return "--"
      return (
        <Tooltip
          content={
            <div>
              <p>{value?.full_name || "na"}</p>
              <p className="text-gray-400">
                {dayjs(row?.original?.created_at).format("DD MMM 'YY | h:mm a")}
              </p>
            </div>
          }
        >
          <Avatar
            className="relative text-center"
            name={value?.full_name}
            src={value?.image?.url}
          />
        </Tooltip>
      )
    },
  },
  {
    Header: "",
    accessor: "updated_at",
    Cell({ row: { original } }) {
      const navigate = useNavigate()

      return (
        <Popover
          minimal
          arrow={false}
          className="p-0"
          trigger="mouseenter"
          interactive
          animation="fade"
          theme="light-border"
          placement="bottom"
          content={
            <Menu className="max-h-52">
              <MenuItem
                onClick={() => {
                  navigate(AppRoutes.tradeReceiptDetail(original.id))
                }}
              >
                View Details
              </MenuItem>
              {/* <MenuItem onClick={downLoadReceipt(original.id)}>
                Download Receipt
              </MenuItem> */}
            </Menu>
          }
        >
          <button className="hover:bg-gray-300 rounded">
            <DotsThreeVertical size={20} />
          </button>
        </Popover>
      )
    },
  },
]

export type FilterType = {
  sb_seller?: UsersQuery["base_user"][0]
  sb_buyer?: UsersQuery["base_user"][0]
  com_commodity?: any
  com_variety?: any
  oth_mode?: any
  oth_kind?: any
  oth_status?: any
  oth_facilitator?: any
  oth_created_at?: any
}
const TradeReceipts = () => {
  const [params, setParams] = useSearchParams()
  const navigate = useNavigate()
  const [{ data: aData }] = useAuthenticatedUserQuery()
  const search = params.get("search")
  const updatedAt = params.get("updatedAt")
  const page = Number(params.get("page")) || 1
  const [filter, setFilter] = useState<FilterType>({})
  const [debouncedSearch] = useDebouncedValue(search?.trim(), config.debounce)
  const canViewTrade = useAuthorization(Permission.ViewTrade)
  const canViewInvoice = useAuthorization(Permission.ViewInvoice)
  const buyerCode = filter.sb_buyer?.id
  const sellerCode = filter.sb_seller?.id
  const commodityCode = filter.com_commodity?.id
  const varietyCode = filter.com_variety?.id
  const facilitatorCode = filter.oth_facilitator?.id
  const [department] = useLocalStorage({ key: "department" })

  const createdAt = filter?.oth_created_at
  const whereClause: Receipt_Bool_Exp = {
    ...(debouncedSearch
      ? Number(debouncedSearch)
        ? { id: { _eq: Number(debouncedSearch) } }
        : { tracking_code: { _ilike: `%${debouncedSearch}%` } }
      : {}),
    kind: { _eq: filter.oth_kind },
    created_at: createdAt
      ? {
          _lte: dayjs(createdAt).endOf("day").toISOString(),
          _gte: dayjs(createdAt).startOf("day").toISOString(),
        }
      : undefined,
    created_in_id: {
      _eq: department,
    },
    seller_id: {
      _eq: sellerCode,
    },
    commodity_id: {
      _eq: commodityCode,
    },
    commodity_variety_id: {
      _eq: varietyCode,
    },
    mode: {
      _eq: filter.oth_mode,
    },
    facilitator_id: {
      _eq: facilitatorCode,
    },
    trades: {
      buyer_id: {
        _eq: buyerCode,
      },
    },
  }

  const [{ data, fetching }, refetch] = useAllReceiptsQuery({
    variables: {
      limit: config.pageSize,
      offset: (page - 1) * config.pageSize,
      order_by: { created_at: Order_By.DescNullsLast },
      where: whereClause,
    },
    pause: !department,
    requestPolicy: "network-only",
  })

  const totalReceipts = data?.receipt_aggregate?.aggregate?.count || 0
  useEffect(() => {
    if (updatedAt) {
      refetch()
      params.delete("updatedAt")
      setParams(params)
    }
  }, [updatedAt])

  return (
    <>
      <header className="flex justify-between p-2 border-b-1 border-b-gray-300">
        <SegmentedControl
          value={"receipts"}
          onChange={value => {
            if (value === "trades" && canViewTrade) {
              navigate(AppRoutes.tradeRequests)
            } else if (value === "invoices" && canViewInvoice) {
              navigate(AppRoutes.tradeInvoices)
            }
          }}
          data={[
            canViewTrade && {
              label: "Trades",
              value: "trades",
              icon: <List className="mr-1" />,
            },
            {
              label: "Receipts",
              value: "receipts",
              icon: <Receipt className="mr-1" />,
            },
            canViewInvoice && {
              label: "Invoices",
              value: "invoices",
              icon: <OrdersCustom className="mr-1" />,
            },
          ].filter(b => b)}
        />
        <div className="w-[500px] flex items-center gap-3">
          <InputGroup
            className={"w-full"}
            inputProps={{
              value: search || "",
              placeholder: "Search by tracking code",
              onChange(e) {
                params.set("search", e.target.value)
                setParams(params)
              },
            }}
            leftElement={<MagnifyingGlass />}
          />
          <ReceiptsFilter filter={filter} setFilter={setFilter} />
        </div>
        <div className="flex gap-3">
          {!department ? null : (
            <Button onClick={() => navigate("new")} leftIcon={<Plus />}>
              Receipt
            </Button>
          )}
        </div>
      </header>
      <TableView
        relative={false}
        className="!h-[calc(100vh-64px-57px)]"
        paginationProps={{
          total: Math.ceil(totalReceipts / config.pageSize),
          page: page,
          onChange(page) {
            params.set("page", page.toString())
            params.set("search", "")
            setParams(params)
          },
        }}
        isLoading={fetching}
        columns={COLUMNS}
        data={data?.receipt || []}
        meta={<>{totalReceipts} receipts</>}
      />
    </>
  )
}

export default TradeReceipts
