import { Files } from "@phosphor-icons/react"
import { openConfirmDialog } from "@vesatogo/grass-confirm-modal"
import { FormInput, FormQuerySelector, Toast } from "@vesatogo/grass-core"
import { FormDateInput } from "@vesatogo/grass-dates"
import dayjs from "dayjs"
import { pick } from "lodash-es"
import toast from "react-hot-toast"
import MandiFormSections from "~/components/Registration/FormSections/Mandi/MandiFormSections"
import { SectionCard } from "~/components/Registration/SectionCard"
import { FileUpload } from "~/components/dropzone/FileUploader/FileUploader"
import { MandiSectionCommonType } from "~/constants/registration/MandiRegistrationFormSections"
import {
  Document_Constraint,
  Document_Update_Column,
  Market_Constraint,
  Market_Update_Column,
  Media_Constraint,
  Media_Update_Column,
  useDeleteAttachmentMutation,
  useGetMandiDocumentKindsQuery,
  useInsertMarketMutation,
} from "~/generated/graphql"
import { getFileUploadProps } from "~/utils/form-helpers"

type LicenseType = MandiSectionCommonType & {}
export const License = ({ form, refetch }: LicenseType) => {
  const [{ fetching }, upsertMarket] = useInsertMarketMutation()
  const [, deleteAttachment] = useDeleteAttachmentMutation()
  async function handleSubmit() {
    try {
      const { data, error } = await upsertMarket({
        object: {
          id: form.values.id,
          is_active: true,
          name: "",
          description: "",
          poc_id: form.values?.poc?.id,
          address_id: form.values?.basic?.address?.id,
          kind_id: form.values?.basic?.kind?.id,
          area_color: "",
          documents: {
            data: [
              {
                id: form.values?.license?.id,
                kind_id: form.values?.license?.kind?.id,
                id_number: form.values?.license?.id_number,
                valid_till: form.values?.license?.valid_till
                  ? dayjs(form.values?.license?.valid_till).format("YYYY-MM-DD")
                  : undefined,
                issued_on: form.values?.license?.issued_on
                  ? dayjs(form.values?.license?.issued_on).format("YYYY-MM-DD")
                  : "01-01-1997",
                attachments: {
                  data: form?.values?.license?.attachments?.map(attachment => ({
                    ...pick(
                      attachment,
                      "url",
                      "key",
                      "is_encrypted",
                      "kind",
                      "id"
                    ),
                  })),
                  on_conflict: {
                    update_columns: [
                      Media_Update_Column.Url,
                      Media_Update_Column.DocumentId,
                    ],
                    constraint: Media_Constraint.MediaPkey,
                  },
                },
              },
            ],
            on_conflict: {
              update_columns: [
                Document_Update_Column.BaseUserId,
                Document_Update_Column.IssuedOn,
                Document_Update_Column.ValidTill,
                Document_Update_Column.IdNumber,
                Document_Update_Column.KindId,
              ],
              constraint: Document_Constraint.DocumentPkey,
            },
          },
        },
        on_conflict: {
          update_columns: [Market_Update_Column.IsActive],
          constraint: Market_Constraint.MarketPkey,
        },
      })

      if (data?.insert_market_one) {
        return toast.custom(
          <Toast title="License Details Saved Successfully!" />
        )
      } else
        return toast.custom(
          <Toast
            intent="danger"
            title={"Something went wrong! " + error?.message}
          />
        )
    } catch (error: any) {
      return toast.custom(
        <Toast
          intent="danger"
          title={"Please resolve all errors before proceeding!"}
        />
      )
    }
  }
  return (
    <>
      <SectionCard
        icon={Files}
        title={"License Details"}
        cardClassName="h-[80vh] overflow-auto"
        headerClassName="border-b-1 border-gray-300"
        className="flex flex-col gap-4"
      >
        {/* <CertificateCard title={"PAN Card"} className="grid grid-cols-2 gap-4"> */}
        <div className="grid grid-cols-3 gap-4">
          <div className="flex gap-4">
            <div>
              <FileUpload
                {...getFileUploadProps(form, "license.attachments", 1, true)}
                appName="mandi"
                onDelete={e => {
                  const onDelete = async () => {
                    const { error } = await deleteAttachment({
                      id: e.id,
                    })
                    if (error) {
                      return toast.custom(
                        <Toast
                          intent="danger"
                          title={"Something went wrong! " + error.message}
                        />
                      )
                    } else {
                      refetch?.({
                        requestPolicy: "network-only",
                      })
                      toast.custom(<Toast title={"Certificate deleted!"} />)
                    }
                  }
                  if (e.id) {
                    openConfirmDialog({
                      isOpen: true,
                      onSubmit: () => onDelete(),
                      confirmationButtonText: "Delete",
                      message:
                        "Are you sure you want to delete this attachment?",
                    })
                  }
                  refetch?.({
                    requestPolicy: "network-only",
                  })
                }}
              />
            </div>
          </div>
          <div className="col-span-2 grid grid-cols-2 gap-4">
            <FormQuerySelector
              label="License Type"
              required
              dataHook={useGetMandiDocumentKindsQuery}
              {...form.getInputProps("license.kind")}
            />
            <FormInput
              label="Number"
              required
              placeholder="Enter number"
              {...form.getInputProps("license.id_number")}
            />
            <FormDateInput
              label="License Issue date (optional)"
              placeholder="Date"
              {...form.getInputProps("license.issued_on")}
            />
            <FormDateInput
              label="License End date (optional)"
              placeholder="Date"
              {...form.getInputProps("license.valid_till")}
            />
          </div>
          {/* </CertificateCard> */}
        </div>
        <MandiFormSections loading={fetching} onSubmit={handleSubmit} />
      </SectionCard>
    </>
  )
}
