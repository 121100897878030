import { Checkbox, Modal, RadioGroup, Toast } from "@vesatogo/grass-core"
import { useState } from "react"
import toast from "react-hot-toast"
import PavtiNote from "~/components/PavtiNote"
import { useUpdateTradeMutation } from "../generated/graphql"

type SendConsentType = {
  isOpen: boolean
  onClose: () => void
  kind: string
  seller?: any
  buyer?: any
  tradeId: string
}
const SendConsent = ({ isOpen, onClose, kind, tradeId }: SendConsentType) => {
  const [{ fetching: uFetching }, updateTrade] = useUpdateTradeMutation()
  const [status, setStatus] = useState<"Draft" | "Approved">("Draft")

  return (
    <Modal
      title="Alert"
      isOpen={isOpen}
      onClose={onClose}
      primaryActionButtonProps={{
        text: "Submit",
        async onClick() {
          const { data } = await updateTrade({
            id: tradeId,
            _set: { status_id: status },
          })
          if (data?.update_trade_by_pk) {
            toast.custom(
              <Toast title={`Trade request status changed to '${status}'`} />
            )
          }

          onClose()
        },
        loading: uFetching,
      }}
      secondaryActionButtonProps={{
        text: "Cancel",
        onClick: onClose,
      }}
    >
      <PavtiNote kind={kind} />
      <RadioGroup
        value={status}
        onChange={e => setStatus(e as any)}
        vertical
        className={"w-full mt-3"}
      >
        <Checkbox type={"radio"} value="Draft" label="Save to Draft" />
        <Checkbox type={"radio"} value="Approved" label="Approve Trade" />
      </RadioGroup>
    </Modal>
  )
}

export default SendConsent
