import { Envelope, Phone, User, UserCircle } from "@phosphor-icons/react"
import { openConfirmDialog } from "@vesatogo/grass-confirm-modal"
import {
  Button,
  Checkbox,
  FormInput,
  RadioGroup,
  SpinnerOverlay,
  Toast,
} from "@vesatogo/grass-core"
import { pick } from "lodash-es"
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import { OperationContext } from "urql"
import { SectionCard } from "~/components/Registration/SectionCard"
import { FileUpload } from "~/components/dropzone/FileUploader/FileUploader"
import Codenames from "~/constants/Codenames"
import { AppRoutes } from "~/constants/routes"
import {
  Auth_Group_Constraint,
  Auth_Group_Update_Column,
  Base_User_Constraint,
  Base_User_Groups_Constraint,
  Base_User_Groups_Update_Column,
  Base_User_Update_Column,
  Media_Constraint,
  Media_Update_Column,
  useAllGroupsQuery,
  useDeleteAttachmentMutation,
  useInsertBaseUserMutation,
} from "~/generated/graphql"
import { getFileUploadProps, getInputProps } from "~/utils/form-helpers"

export type FarmerBasicDetailsype = {
  form?: any
  entity: "farmer" | "trader"
  refetch: (opts?: Partial<OperationContext> | undefined) => void
  setActiveSection: (string) => void
  next
}
export const FarmerTraderBasicDetails = ({
  form,
  entity,
  refetch,
  setActiveSection,
  next,
}: FarmerBasicDetailsype) => {
  const navigate = useNavigate()

  const [{ fetching }, insertBaseUserDetails] = useInsertBaseUserMutation()
  const [, deleteAttachment] = useDeleteAttachmentMutation()

  const [{ data: farmerGroup }] = useAllGroupsQuery({
    variables: {
      where: {
        name: {
          _eq:
            entity === "farmer" ? Codenames.FarmerGroup : Codenames.TraderGroup,
        },
      },
    },
  })
  const { values: state } = form

  const onSubmit = async () => {
    const basicDetails = state?.basic
    const isFarmer = basicDetails?.groups?.filter(group => {
      return group?.group_id === farmerGroup?.auth_group?.[0]?.id
    })
    const { data, error } = await insertBaseUserDetails({
      baseUserIn: {
        id: state?.id,
        first_name: basicDetails?.first_name,
        middle_name: basicDetails?.middle_name,
        last_name: basicDetails?.last_name,
        email: basicDetails?.email || "",
        number: basicDetails?.number,
        status: "active",
        gender: basicDetails?.gender,
        date_of_birth: basicDetails?.date_of_birth || "01-01-1997",
        username: basicDetails?.number,
        image:
          basicDetails.photo?.length !== 0
            ? {
                data: {
                  ...pick(basicDetails.photo[0], "url", "kind", "id", "key"),
                },
                on_conflict: {
                  constraint: Media_Constraint.MediaPkey,
                  update_columns: [
                    Media_Update_Column.Id,
                    Media_Update_Column.Url,
                    Media_Update_Column.Kind,
                  ],
                },
              }
            : undefined,
        groups: {
          data:
            [
              ...(basicDetails?.groups?.map(group => {
                return {
                  id: group?.id,
                  group: {
                    data: {
                      id: group?.group?.id,
                      name: group?.group?.name,
                    },
                    on_conflict: {
                      constraint: Auth_Group_Constraint.AuthGroupPkey,
                      update_columns: [
                        Auth_Group_Update_Column.Id,
                        Auth_Group_Update_Column.Name,
                      ],
                    },
                  },
                }
              }) || []),
              !isFarmer && {
                group_id: farmerGroup?.auth_group?.[0]?.id,
              },
            ].filter?.(d => d) || [],
          on_conflict: {
            constraint: Base_User_Groups_Constraint.BaseUserGroupsPkey,
            update_columns: [
              Base_User_Groups_Update_Column.Id,
              Base_User_Groups_Update_Column.GroupId,
            ],
          },
        },
      },
      on_conflict: {
        constraint: Base_User_Constraint.BaseUserPkey,
        update_columns: [
          Base_User_Update_Column.Id,
          Base_User_Update_Column.Number,
          Base_User_Update_Column.FirstName,
          Base_User_Update_Column.LastName,
          Base_User_Update_Column.MiddleName,
          Base_User_Update_Column.Email,
          Base_User_Update_Column.Gender,
          Base_User_Update_Column.ImageId,
        ],
      },
    })

    if (error) {
      return toast.custom(
        <Toast
          intent="danger"
          title={"Something went wrong!" + error.message}
        />
      )
    } else {
      toast.custom(<Toast title={"User saved successfully!"} />)
      navigate(
        `${
          entity === "farmer"
            ? AppRoutes.farmerRegistration
            : AppRoutes.traderRegistration + "/non-registered"
        }/${data?.insert_base_user_one?.id}`
      )
      next && setActiveSection(next.id)
    }
  }

  return (
    <>
      <SectionCard
        cardClassName="h-[80vh]"
        icon={User}
        title={"Enter your personal details"}
        className="!h-full"
      >
        <SpinnerOverlay show={fetching} />
        <div className="flex flex-col justify-between gap-4 h-full">
          <div className="flex gap-4 ">
            <div className="h-mx">
              <FileUpload
                // appName="vesapublic"
                uploadPlaceHolder="Drop file here"
                uploadImage={
                  <UserCircle
                    className="text-gray-600"
                    weight="fill"
                    size={64}
                  />
                }
                {...getFileUploadProps(form, "basic.photo", 1)}
                onDelete={e => {
                  const onDelete = async () => {
                    const { error } = await deleteAttachment({
                      id: e.id,
                    })
                    if (error) {
                      return toast.custom(
                        <Toast
                          intent="danger"
                          title={"Something went wrong! " + error.message}
                        />
                      )
                    } else {
                      refetch?.({
                        requestPolicy: "network-only",
                      })
                      toast.custom(<Toast title={"Photo deleted!"} />)
                    }
                  }
                  if (e.id) {
                    openConfirmDialog({
                      isOpen: true,
                      onSubmit: () => onDelete(),
                      confirmationButtonText: "Delete",
                      message: "Are you sure you want to delete image?",
                    })
                  }
                  refetch?.({
                    requestPolicy: "network-only",
                  })
                }}
              />
            </div>
            <div className="grid grid-cols-2 gap-4 h-max w-full">
              <FormInput
                data-testid="firstName"
                required
                label="First Name"
                {...getInputProps(form, "basic.first_name")}
              />
              <FormInput
                data-testid="middleName"
                label="Middle Name"
                {...getInputProps(form, "basic.middle_name")}
              />
              <FormInput
                data-testid="lastName"
                required
                label="Last Name"
                {...getInputProps(form, "basic.last_name")}
              />

              <FormInput
                data-testid="inputEmail"
                leftElement={<Envelope />}
                type="email"
                label="Email"
                {...getInputProps(form, "basic.email")}
              />
              <FormInput
                data-testid="inputMobileNo"
                leftElement={<Phone />}
                required
                label="Mobile Number"
                maxLength={10}
                {...getInputProps(form, "basic.number")}
              />
              <RadioGroup
                label="Gender"
                {...getInputProps(form, "basic.gender")}
                className={"justify-evenly"}
              >
                <Checkbox type="radio" value={"Male"}>
                  Male
                </Checkbox>
                <Checkbox type="radio" value={"Female"}>
                  Female
                </Checkbox>
              </RadioGroup>
            </div>
          </div>
          <Button
            text="Save"
            className="h-fit w-fit self-end mb-10"
            onClick={onSubmit}
          />
        </div>
      </SectionCard>
    </>
  )
}
