import { MagnifyingGlass, Plus } from "@phosphor-icons/react"
import { Avatar, Button, InputGroup, Tag } from "@vesatogo/grass-core"
import { TableView } from "@vesatogo/grass-table"
import { useSearchParams } from "react-router-dom"
import { AddUserModal } from "~/components/Admin/AddUserModal"
import { Order_By, useAllEmployeesQuery } from "~/generated/graphql"
import { config } from "~/pages/pavti/config"

const COMMON_FIELDS = []

export const UserMaster = () => {
  const [params, setParams] = useSearchParams()
  const search = params.get("search")
  const page = Number(params.get("page")) || 1

  const [{ data: allUsers, fetching }, refetch] = useAllEmployeesQuery({
    variables: {
      first: config.pageSize,
      skip: (page - 1) * config.pageSize,
      order_by: {
        created_at: Order_By.DescNullsLast,
        full_name: Order_By.AscNullsLast,
      },
      where: {
        groups: { group: { name: { _eq: "Employee" } } },
        _or: [
          { full_name: { _ilike: search ? `%${search}%` : "%%" } },
          { number: { _ilike: search ? `%${search}%` : "%%" } },
        ],
      },
    },
  })
  const totalReceipts = allUsers?.base_user_aggregate?.aggregate?.count || 0

  const COLUMNS = [
    // {
    //   accessor: "id",
    //   Header({ data }) {
    //     const selectable = data?.filter(d => d.id)
    //     const { selected, selectAll } = useTradeSelectorList()
    //     const isSelectAll =
    //       Object.keys(selected)?.length &&
    //       Object.keys(selected)?.length === selectable.length
    //     return (
    //       <Checkbox
    //         labelClassName="!mb-4"
    //         checked={isSelectAll ? true : false}
    //         onChange={() => {
    //           selectAll(selectable?.map(data => pick(data, COMMON_FIELDS)))
    //         }}
    //       />
    //     )
    //   },
    //   Cell({ value, row }) {
    //     const { selected, setSelected } = useTradeSelectorList()
    //     if (!row?.original?.id) return null

    //     return (
    //       <Checkbox
    //         checked={selected[value] ? true : false}
    //         onChange={() => {
    //           setSelected(pick(row.original, COMMON_FIELDS))
    //         }}
    //       />
    //     )
    //   },
    // },
    {
      Header: "Sr. No.",
      accessor: "id",
      className: "!text-center",
      Cell({ row }) {
        return parseInt(row?.id) + 1
      },
    },
    {
      Header: "Name",
      accessor: "name",
      Cell({ value, row }) {
        return (
          <div className="flex gap-2">
            <Avatar
              className="relative text-center"
              name={row?.original?.full_name}
              src={row?.original?.image?.url}
            />
            <div
              className="cursor-pointer border-b-1 border-dashed border-gray-600 inline self-center"
              onClick={() => {
                params.set("id", row?.original?.id)
                setParams(params)
              }}
            >
              {value}
            </div>
          </div>
        )
      },
    },
    {
      Header: "Username",
      accessor: "username",
      Cell({ value }) {
        return value
      },
    },
    {
      Header: "Mobile number",
      accessor: "number",
      Cell({ value }) {
        return value
      },
    },
    {
      Header: "Markets",
      accessor: "markets_assigned",
      Cell({ value }) {
        return (
          <div className="flex gap-2">
            {value?.map(group => {
              return (
                <Tag intent="info" variant="minimal" className="!rounded-xl">
                  {group?.market?.name}
                </Tag>
              )
            })}
          </div>
        )
      },
    },
    {
      Header: "Groups",
      accessor: "groups",
      Cell({ value }) {
        return (
          <div className="flex gap-2">
            {value?.map(group => {
              return (
                <Tag intent="info" variant="minimal" className="!rounded-xl">
                  {group?.group?.name}
                </Tag>
              )
            })}
          </div>
        )
      },
    },
  ]

  return (
    <>
      <header className="grid grid-cols-3 p-2 border-b-1 border-b-gray-300">
        <div className="self-center font-500 pl-4">User Master</div>
        <div className="w-[500px] flex items-center gap-3">
          <InputGroup
            className={"w-full"}
            inputProps={{
              value: search || "",
              placeholder: "Search",

              onChange(e) {
                params.set("search", e.target.value)
                setParams(params)
              },
            }}
            leftElement={<MagnifyingGlass />}
          />
        </div>
        <div className="text-end">
          <Button
            onClick={() => {
              params.set("id", "new")
              setParams(params)
            }}
            leftIcon={<Plus />}
          >
            User
          </Button>
        </div>
      </header>
      <TableView
        relative={false}
        paginationProps={{
          total: Math.ceil(totalReceipts / config.pageSize),
          page: page,
          onChange(page) {
            params.set("page", page.toString())
            params.set("search", "")
            setParams(params)
          },
        }}
        isLoading={fetching}
        columns={COLUMNS}
        data={allUsers?.base_user || []}
        meta={<>{totalReceipts} Users</>}
        getCellProps={cell => {
          if (cell?.column?.Header === "Sr. No.") {
            return {
              className: "!text-center",
            }
          }
        }}
        className="!h-[calc(100vh-64px-57px)]"
      />
      <AddUserModal onAddComplete={refetch} />
    </>
  )
}

export default UserMaster
