import { Card } from "@vesatogo/grass-core"
import classNames from "classnames"

type FormCardProps = {
  children?: React.ReactNode
  title?: string
  className?: any
  action?: React.ReactNode
  childrenClassName?: any
}

export const FormCard = ({
  children,
  title,
  className,
  action,
  childrenClassName,
}: FormCardProps) => {
  return (
    <Card className={classNames("!rounded-md mb-4", className)}>
      <div className="p-4 text-green font-600 flex justify-between items-center">
        <div className="flex">
          <svg
            className="mr-2"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.4532 7.38013C11.9476 8.6269 14.3771 7.45863 14.3383 5.49004C14.3378 4.85175 14.084 4.23973 13.6327 3.78839C13.1814 3.33705 12.5693 3.08328 11.931 3.08281C9.65801 3.06992 8.65747 6.02203 10.4532 7.38013Z"
              fill="currentColor"
            />
            <path
              d="M8.02648 11.1874C8.1703 11.4163 8.36995 11.6049 8.60669 11.7356C8.84343 11.8662 9.10948 11.9345 9.37986 11.934H14.4822C14.7509 11.9315 15.0147 11.8617 15.2495 11.7311C15.4843 11.6004 15.6827 11.4131 15.8265 11.1861C15.9703 10.9592 16.0551 10.6998 16.0729 10.4317C16.0908 10.1636 16.0413 9.89523 15.9289 9.65117C15.5486 8.85343 14.9376 8.18823 14.175 7.7417C13.5784 8.33515 12.7725 8.67037 11.931 8.67506C11.089 8.66767 10.2828 8.33294 9.68318 7.7417C8.61865 8.40402 7.2182 9.85731 8.02648 11.1874Z"
              fill="currentColor"
            />
            <path
              d="M16.2281 6.57125C17.7243 7.81766 20.1662 6.66143 20.1249 4.68116C20.1249 4.27332 20.0213 3.87214 19.8238 3.5153C19.6263 3.15846 19.3414 2.85766 18.9958 2.64113C18.6501 2.42461 18.2552 2.29946 17.8479 2.27744C17.4407 2.25542 17.0345 2.33724 16.6675 2.51523C16.3006 2.69322 15.9849 2.96155 15.75 3.29501C15.5152 3.62847 15.369 4.01613 15.325 4.42161C15.2811 4.82708 15.3409 5.23707 15.4989 5.61309C15.6569 5.9891 15.9078 6.31881 16.2281 6.57125V6.57125Z"
              fill="currentColor"
            />
            <path
              d="M16.9827 9.14962C17.1624 9.52594 17.2542 9.9382 17.2511 10.3552C17.2498 10.608 17.2131 10.8593 17.1422 11.1019C17.1863 11.121 17.2341 11.1303 17.2822 11.1291H20.265C20.5354 11.1296 20.8015 11.0613 21.0382 10.9307C21.2749 10.8 21.4746 10.6114 21.6184 10.3824C22.4282 9.05248 21.0311 7.59562 19.9656 6.93677C18.705 8.22889 16.6818 8.0894 15.4311 6.95224C15.0779 7.10741 15.7256 7.35082 15.8782 7.59406C16.3352 8.04348 16.7091 8.57009 16.9827 9.14962Z"
              fill="currentColor"
            />
            <path
              d="M4.66249 6.57514C4.96446 6.81201 5.3187 6.97324 5.69565 7.04538C6.0726 7.11753 6.46134 7.09849 6.82944 6.98987C7.19754 6.88125 7.53433 6.68618 7.81173 6.42095C8.08912 6.15571 8.29907 5.82799 8.42407 5.46513C8.54907 5.10226 8.5855 4.71477 8.53031 4.33496C8.47512 3.95516 8.32992 3.59405 8.10681 3.28177C7.88371 2.96949 7.58916 2.71508 7.24776 2.53976C6.90635 2.36444 6.52797 2.2733 6.14417 2.27393C5.64729 2.28212 5.16497 2.44308 4.76276 2.73496C4.36056 3.02684 4.05796 3.43547 3.89611 3.90533C3.73425 4.37518 3.72098 4.88348 3.85809 5.36114C3.99521 5.83881 4.27606 6.26267 4.66249 6.57514Z"
              fill="currentColor"
            />
            <path
              d="M3.59673 11.1292H6.57959C6.62871 11.128 6.67731 11.1188 6.72346 11.1019C6.6491 10.86 6.6111 10.6083 6.61069 10.3552C6.59985 9.29511 7.2676 8.32665 7.98738 7.59405C8.14341 7.34778 8.78133 7.10784 8.43076 6.95242C7.18166 8.09817 5.13411 8.22144 3.89619 6.93286C2.8309 7.59663 1.43372 9.05127 2.24333 10.3825C2.38716 10.6114 2.58681 10.8001 2.82356 10.9307C3.0603 11.0613 3.32635 11.1296 3.59673 11.1292Z"
              fill="currentColor"
            />
            <path
              d="M22.4437 16.2259C22.364 16.0114 22.206 15.8349 22.0015 15.7323C21.7969 15.6296 21.561 15.6083 21.3414 15.6727L15.4964 17.4014C15.3593 17.8437 15.0733 18.2248 14.6869 18.4799C14.3005 18.735 13.8377 18.8483 13.3772 18.8007L10.1638 18.4793C10.1104 18.4743 10.0586 18.4587 10.0112 18.4336C9.96384 18.4084 9.92193 18.3742 9.88787 18.3327C9.85381 18.2913 9.82827 18.2436 9.81273 18.1923C9.7972 18.1409 9.79196 18.087 9.79732 18.0337C9.80268 17.9803 9.81855 17.9286 9.84399 17.8814C9.86944 17.8342 9.90396 17.7925 9.94559 17.7586C9.98721 17.7248 10.0351 17.6996 10.0865 17.6844C10.1379 17.6691 10.1919 17.6642 10.2452 17.6699L13.4585 17.9912C13.7405 18.0166 14.0223 17.941 14.2537 17.7778C14.4851 17.6147 14.651 17.3747 14.7218 17.1006C14.7927 16.8265 14.7638 16.5361 14.6405 16.2813C14.5171 16.0265 14.3072 15.8238 14.0483 15.7094L9.38287 13.7122C8.91268 13.5719 8.41886 13.5288 7.93148 13.5855C7.44409 13.6421 6.97332 13.7973 6.54783 14.0417L1.5 16.9581L3.49717 20.4195L11.7461 21.6723C12.7282 21.8228 13.7328 21.6561 14.6137 21.1965L22.0166 17.3405C22.2106 17.2378 22.3608 17.0683 22.4394 16.8634C22.5179 16.6584 22.5194 16.4319 22.4437 16.2259Z"
              fill="currentColor"
            />
          </svg>

          {title}
        </div>
        {action}
      </div>
      {children && (
        <>
          <hr />
          <div className={classNames("p-4", childrenClassName)}>{children}</div>
        </>
      )}
    </Card>
  )
}
