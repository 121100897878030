import { MagnifyingGlass, Plus } from "@phosphor-icons/react"
import { Avatar, Button, InputGroup } from "@vesatogo/grass-core"
import { TableView } from "@vesatogo/grass-table"
import { useSearchParams } from "react-router-dom"
import { AddCommodityVarietyModal } from "~/components/Admin/AddCommodityVarietyModal"
import { useAllCommodityVarietiesNewQuery } from "~/generated/graphql"
import { config } from "~/pages/pavti/config"

const COMMON_FIELDS = []

export const CommodityVarietyMaster = () => {
  const [params, setParams] = useSearchParams()
  const search = params.get("search")
  const page = Number(params.get("page")) || 1

  const [{ data: allCommodityVarieties, fetching }, refetch] =
    useAllCommodityVarietiesNewQuery({
      variables: {
        first: config.pageSize,
        skip: (page - 1) * config.pageSize,
        query: search ? `%${search}%` : "%%",
      },
    })
  const totalCommodityVarieties =
    allCommodityVarieties?.commodity_variety_aggregate?.aggregate?.count || 0

  const COLUMNS = [
    // {
    //   accessor: "id",
    //   Header({ data }) {
    //     const selectable = data?.filter(d => d.id)
    //     const { selected, selectAll } = useTradeSelectorList()
    //     const isSelectAll =
    //       Object.keys(selected)?.length &&
    //       Object.keys(selected)?.length === selectable.length
    //     return (
    //       <Checkbox
    //         labelClassName="!mb-4"
    //         checked={isSelectAll ? true : false}
    //         onChange={() => {
    //           selectAll(selectable?.map(data => pick(data, COMMON_FIELDS)))
    //         }}
    //       />
    //     )
    //   },
    //   Cell({ value, row }) {
    //     const { selected, setSelected } = useTradeSelectorList()
    //     if (!row?.original?.id) return null

    //     return (
    //       <Checkbox
    //         checked={selected[value] ? true : false}
    //         onChange={() => {
    //           setSelected(pick(row.original, COMMON_FIELDS))
    //         }}
    //       />
    //     )
    //   },
    // },
    {
      Header: "Sr. No.",
      accessor: "id",
      className: "!text-center",
      Cell({ row }) {
        return parseInt(row?.id) + 1
      },
    },
    {
      Header: "Commodity Variety",
      accessor: "name",
      Cell({ value, row }) {
        return (
          <div className="flex gap-2">
            <Avatar
              className="relative text-center"
              name={value}
              src={row?.original?.image?.url}
            />
            <div>
              <div
                className="cursor-pointer border-b-1 border-dashed border-gray-600 inline"
                onClick={() => {
                  params.set("id", row?.original?.id)
                  setParams(params)
                }}
              >
                {value}
              </div>
              <div className="text-gray-500">
                {row?.original?.commodity?.name}
              </div>
            </div>
          </div>
        )
      },
    },
    {
      Header: "Code Name",
      accessor: "codename",
      Cell({ value }) {
        return value
      },
    },
    {
      Header: "Crop Variety Name",
      accessor: "crop_variety",
      Cell({ value }) {
        return value?.name
      },
    },
  ]

  return (
    <>
      <header className="flex justify-between p-2 border-b-1 border-b-gray-300">
        <div className="self-center font-500 pl-4">
          Commodity Variety Master
        </div>
        <div className="w-[500px] flex items-center gap-3">
          <InputGroup
            className={"w-full"}
            inputProps={{
              value: search || "",
              placeholder: "Search",

              onChange(e) {
                params.set("search", e.target.value)
                setParams(params)
              },
            }}
            leftElement={<MagnifyingGlass />}
          />
        </div>
        <div className="flex gap-3">
          <Button
            onClick={() => {
              params.set("id", "new")
              setParams(params)
            }}
            leftIcon={<Plus />}
          >
            Commodity Variety
          </Button>
        </div>
      </header>
      <TableView
        relative={false}
        paginationProps={{
          total: Math.ceil(totalCommodityVarieties / config.pageSize),
          page: page,
          onChange(page) {
            params.set("page", page.toString())
            params.set("search", "")
            setParams(params)
          },
        }}
        getCellProps={cell => {
          if (cell?.column?.Header === "Sr. No.") {
            return {
              className: "!text-center",
            }
          }
        }}
        columns={COLUMNS}
        isLoading={fetching}
        data={allCommodityVarieties?.commodity_variety || []}
        meta={<>{totalCommodityVarieties} Commodity Varieties</>}
        className="!h-[calc(100vh-64px-57px)]"
      />

      <AddCommodityVarietyModal onAddComplete={refetch} />
    </>
  )
}

export default CommodityVarietyMaster
