import toast from "react-hot-toast"

export const getInputProps = (form: any, values: any) => {
  return {
    ...form.getInputProps(values),
  }
}

export const getFileUploadProps = (
  form: any,
  key: string,
  maxFiles = 2,
  shouldEncrypt = false
) => {
  const inputProps = getInputProps(form, key)
  return {
    media: inputProps.value,
    onChange: inputProps.onChange,
    maxFiles,
    shouldEncrypt,
    onError: toast.error,
    // decryptMediaMutation: DECRYPT_MEDIA,
    endpoint: `https://${import.meta.env.VITE_API_URL}/rest/core/uploader/`,
  }
}
