import dayjs from "dayjs"
import { userTypeMapper } from "./data"
import { userType } from "./types"

export function timeFormat(time: number) {
  let result = {
    hours: Math.floor(time / 3600),
    minutes: Math.floor((time % 3600) / 60),
    seconds: Math.floor((time % 3600) % 60),
  }
  return result
}

export function getHashValue(hash?: string) {
  if (!hash) return null
  try {
    let decoded = window.atob(hash)
    let data = decoded.split("|")
    return {
      lang: data[0],
      tradeId: data[1],
      userType: data[2] as userType,
      phone: data[3],
    }
  } catch (error) {
    return null
  }
}

export function getUser(id?: string) {
  const userData = [
    {
      id: "12345",
      number: 9876543210,
      lang: "en",
      type: "trader",
      otp: 202201,
    },
    {
      id: "54321",
      number: 1234567890,
      lang: "hi",
      type: "farmer",
      otp: 202202,
    },
  ]
  return userData.find(user => user.id === id) || null
}

export const getLocalStorage = (key: string) => {
  try {
    return JSON.parse(localStorage.getItem(key) || "")
  } catch (error) {
    return null
  }
}

export function switchUserType(userType, condition: boolean = true) {
  return condition ? userType : userType === "s" ? "b" : "s"
}

export function getVerificationColor(
  status?: boolean | null,
  darkBg: boolean = false
) {
  return status === null
    ? `text-gray-700 ${darkBg ? "bg-gray-500" : "bg-gray-300"}`
    : status === undefined
    ? ""
    : status
    ? `text-green-400 ${darkBg ? "bg-green" : "bg-green-100"}`
    : `text-red-400 ${darkBg ? "bg-red" : "bg-red-100"}`
}

export function getUserType(type: "b" | "s", opposite: boolean = false) {
  return userTypeMapper[opposite ? (type === "b" ? "s" : "b") : type]
}

export function formatDate(date: string) {
  return dayjs(date).format("DD MMM YYYY")
}
