import { times } from "lodash-es"
import { TradeKinds, TradeKindsReadable } from "~/constants/Kinds"

export const EXPENSE_KINDS = [
  { id: "flat", name: "Flat", short: "Rs" },
  { id: "amount_percent", name: "Amount Percent", short: "%" },
  { id: "cost_per_unit", name: "Cost Per Unit", short: "Per Unit" },
  {
    id: "deducted_cost_per_unit",
    name: "Deducted Cost Per Unit",
    short: "Per Unit",
  },
]

export const TRADE_KINDS = Object.values(TradeKinds).map(value => ({
  id: value,
  name: TradeKindsReadable[value],
}))

export const COLOURS = [
  {
    id: "#CD5C5C",
    name: "Indian Red",
  },
  {
    id: "#F08080",
    name: "Light Coral",
  },
  {
    id: "#FA8072",
    name: "Salmon",
  },
  {
    id: "#E9967A",
    name: "Dark Salmon",
  },
  {
    id: "#FFA07A",
    name: "Light Salmon",
  },
  {
    id: "#DC143C",
    name: "Crimson",
  },
  {
    id: "#FF0000",
    name: "Red",
  },
  {
    id: "#B22222",
    name: "Fire Brick",
  },
  {
    id: "#8B0000",
    name: "Dark Red",
  },
  {
    id: "#FFC0CB",
    name: "Pink",
  },
  {
    id: "#FFB6C1",
    name: "Light Pink",
  },
  {
    id: "#FF69B4",
    name: "Hot Pink",
  },
  {
    id: "#FF1493",
    name: "Deep Pink",
  },
  {
    id: "#C71585",
    name: "Medium Violet Red",
  },
  {
    id: "#DB7093",
    name: "Pale Violet Red",
  },
  {
    id: "#FF7F50",
    name: "Coral",
  },
  {
    id: "#FF6347",
    name: "Tomato",
  },
  {
    id: "#FF4500",
    name: "Orange Red",
  },
  {
    id: "#FF8C00",
    name: "Dark Orange",
  },
  {
    id: "#FFA500",
    name: "Orange",
  },
  {
    id: "#FFD700",
    name: "Gold",
  },

  {
    id: "#FFEFD5",
    name: "Papaya Whip",
  },
  {
    id: "#FFE4B5",
    name: "Moccasin",
  },
  {
    id: "#FFDAB9",
    name: "Peach Puff",
  },
  {
    id: "#EEE8AA",
    name: "Pale Goldenrod",
  },
  {
    id: "#F0E68C",
    name: "Khaki",
  },
  {
    id: "#BDB76B",
    name: "Dark Khaki",
  },
  {
    id: "#E6E6FA",
    name: "Lavender",
  },
  {
    id: "#D8BFD8",
    name: "Thistle",
  },
  {
    id: "#DDA0DD",
    name: "Plum",
  },
  {
    id: "#EE82EE",
    name: "Violet",
  },
  {
    id: "#DA70D6",
    name: "Orchid",
  },
  {
    id: "#FF00FF",
    name: "Fuchsia",
  },
  {
    id: "#BA55D3",
    name: "Medium Orchid",
  },
  {
    id: "#9370DB",
    name: "Medium Purple",
  },
  {
    id: "#9966CC",
    name: "Amethyst",
  },
  {
    id: "#8A2BE2",
    name: "Blue Violet",
  },
  {
    id: "#9400D3",
    name: "Dark Violet",
  },
  {
    id: "#9932CC",
    name: "Dark Orchid",
  },
  {
    id: "#8B008B",
    name: "Dark Magenta",
  },
  {
    id: "#800080",
    name: "Purple",
  },
  {
    id: "#4B0082",
    name: "Indigo",
  },
  {
    id: "#6A5ACD",
    name: "Slate Blue",
  },
  {
    id: "#483D8B",
    name: "Dark Slate Blue",
  },
  {
    id: "#7B68EE",
    name: "Medium Slate Blue",
  },
  {
    id: "#ADFF2F",
    name: "Green Yellow",
  },
  {
    id: "#7FFF00",
    name: "Chartreuse",
  },
  {
    id: "#7CFC00",
    name: "Lawn Green",
  },
  {
    id: "#00FF00",
    name: "Lime",
  },
  {
    id: "#32CD32",
    name: "Lime Green",
  },
  {
    id: "#98FB98",
    name: "Pale Green",
  },
  {
    id: "#90EE90",
    name: "Light Green",
  },
  {
    id: "#00FA9A",
    name: "Medium Spring Green",
  },
  {
    id: "#00FF7F",
    name: "Spring Green",
  },
  {
    id: "#3CB371",
    name: "Medium Sea Green",
  },
  {
    id: "#2E8B57",
    name: "Sea Green",
  },
  {
    id: "#228B22",
    name: "Forest Green",
  },
  {
    id: "#008000",
    name: "Green",
  },
  {
    id: "#006400",
    name: "Dark Green",
  },
  {
    id: "#9ACD32",
    name: "Yellow Green",
  },
  {
    id: "#6B8E23",
    name: "Olive Drab",
  },
  {
    id: "#808000",
    name: "Olive",
  },
  {
    id: "#556B2F",
    name: "Dark Olive Green",
  },
  {
    id: "#66CDAA",
    name: "Medium Aquamarine",
  },
  {
    id: "#8FBC8F",
    name: "Dark Sea Green",
  },
  {
    id: "#20B2AA",
    name: "Light Sea Green",
  },
  {
    id: "#008B8B",
    name: "Dark Cyan",
  },
  {
    id: "#008080",
    name: "Teal",
  },
  {
    id: "#00FFFF",
    name: "Aqua",
  },
  {
    id: "#E0FFFF",
    name: "Light Cyan",
  },
  {
    id: "#AFEEEE",
    name: "Pale Turquoise",
  },
  {
    id: "#7FFFD4",
    name: "Aquamarine",
  },
  {
    id: "#40E0D0",
    name: "Turquoise",
  },
  {
    id: "#48D1CC",
    name: "Medium Turquoise",
  },
  {
    id: "#00CED1",
    name: "Dark Turquoise",
  },
  {
    id: "#5F9EA0",
    name: "Cadet Blue",
  },
  {
    id: "#4682B4",
    name: "Steel Blue",
  },
  {
    id: "#B0C4DE",
    name: "Light Steel Blue",
  },
  {
    id: "#B0E0E6",
    name: "Powder Blue",
  },
  {
    id: "#ADD8E6",
    name: "Light Blue",
  },
  {
    id: "#87CEEB",
    name: "Sky Blue",
  },
  {
    id: "#87CEFA",
    name: "Light Sky Blue",
  },
  {
    id: "#00BFFF",
    name: "Deep Sky Blue",
  },
  {
    id: "#1E90FF",
    name: "Dodger Blue",
  },
  {
    id: "#6495ED",
    name: "Cornflower Blue",
  },
  {
    id: "#4169E1",
    name: "Royal Blue",
  },
  {
    id: "#0000FF",
    name: "Blue",
  },
  {
    id: "#0000CD",
    name: "Medium Blue",
  },
  {
    id: "#00008B",
    name: "Dark Blue",
  },
  {
    id: "#000080",
    name: "Navy",
  },
  {
    id: "#191970",
    name: "Midnight Blue",
  },
  {
    id: "#FFEBCD",
    name: "Blanched Almond",
  },
  {
    id: "#FFE4C4",
    name: "Bisque",
  },
  {
    id: "#FFDEAD",
    name: "Navajo White",
  },
  {
    id: "#F5DEB3",
    name: "Wheat",
  },
  {
    id: "#DEB887",
    name: "Burly Wood",
  },
  {
    id: "#D2B48C",
    name: "Tan",
  },
  {
    id: "#BC8F8F",
    name: "Rosy Brown",
  },
  {
    id: "#F4A460",
    name: "Sandy Brown",
  },
  {
    id: "#DAA520",
    name: "Goldenrod",
  },
  {
    id: "#B8860B",
    name: "Dark Goldenrod",
  },
  {
    id: "#CD853F",
    name: "Peru",
  },
  {
    id: "#D2691E",
    name: "Chocolate",
  },
  {
    id: "#8B4513",
    name: "Saddle Brown",
  },
  {
    id: "#A0522D",
    name: "Sienna",
  },
  {
    id: "#A52A2A",
    name: "Brown",
  },
  {
    id: "#800000",
    name: "Maroon",
  },
  {
    id: "#2F4F4F",
    name: "Dark Slate Gray",
  },
]

const generateHourList = () => {
  const hoursList: any[] = []
  for (let hour = 0; hour < 24; hour++) {
    const formattedHour = hour.toString().padStart(2, "0")
    const hourName =
      (hour === 0 ? "12" : hour <= 12 ? hour : hour - 12) +
      (hour < 12 ? " AM" : " PM")

    const hourObject = {
      id: `${formattedHour}:00:00`,
      name: hourName,
      hour: hour,
    }

    hoursList.push(hourObject)
  }
  return hoursList
}

export const TIME_SLOTS = generateHourList()
