import * as Yup from "yup"
import { baseAddressModel } from "~/models/address"

export const ownerModel =
  baseAddressModel &&
  Yup.object().shape({
    first_name: Yup.string().required("Please enter Owner First name"),
    last_name: Yup.string().required("Please enter Owner Last name"),
    number: Yup.string()
      .matches(/^(\+91[-\s]?)?[0]?(91)?[6789]\d{9}$/, "Invalid Phone Number")
      .required("No primary contact provided"),
    address: baseAddressModel,
  })
