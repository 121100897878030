import { useDebouncedValue, useLocalStorage } from "@mantine/hooks"
import {
  DotsThreeVertical,
  List,
  MagnifyingGlass,
  Plus,
  Receipt,
  Timer,
} from "@phosphor-icons/react"
import {
  Avatar,
  Button,
  Checkbox,
  InputGroup,
  Menu,
  MenuItem,
  Popover,
  SegmentedControl,
  Toast,
  Tooltip,
} from "@vesatogo/grass-core"
import { Cell, TableView } from "@vesatogo/grass-table"
import { inr, minifyUuid } from "@vesatogo/utils"
import dayjs from "dayjs"
import { merge, pick } from "lodash-es"
import { useEffect, useMemo, useState } from "react"
import toast from "react-hot-toast"
import {
  Link,
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom"
import { UseQueryArgs } from "urql"
import { useAuthorization } from "~/components/AuthorizationProvider"
import GenerateInvoices from "~/components/GenerateInvoices"
import TradeActions from "~/components/TradeActions"
import TradeKindTag from "~/components/TradeKindTag"
import { TradeKinds } from "~/constants/Kinds"
import { Permission } from "~/constants/Permissions"
import { siteConfig } from "~/constants/config"
import { OrdersCustom } from "~/constants/icons"
import { APIRoutes, AppRoutes } from "~/constants/routes"
import {
  AllTradeRequestsQueryVariables,
  Order_By,
  Trade_Bool_Exp,
  UsersQuery,
  useAllTradeRequestsQuery,
  useAllTradeRequestsStatusSubscription,
  useAuthenticatedUserQuery,
  useBulkUpdateTradeMutation,
  useTradeHashesQuery,
} from "~/generated/graphql"
import TradeRequestsFilter from "~/pages/d/trade-requests/TradeRequestsFilter"
import UpdateTradeStatus from "~/pages/d/trade-requests/UpdateTradeStatus"
import { useTradeSelectorList } from "~/store/trade-selector.store"
import { downloadPdf } from "~/utils/downloadPdf"

const COMMON_FIELDS = [
  "id",
  "status_id",
  "buyer",
  "seller",
  "standard_deduction",
  "total_amount",
  "total_quantity",
  "expense_amount",
  "item_units",
  "items",
]
const COLUMNS = [
  {
    accessor: "id",
    Header({ data }) {
      const selectable = data?.filter(d => d.id)
      const { selected, selectAll } = useTradeSelectorList()
      const isSelectAll =
        Object.keys(selected)?.length &&
        Object.keys(selected)?.length === selectable.length
      return (
        <Checkbox
          labelClassName="!mb-4"
          checked={isSelectAll ? true : false}
          onChange={() => {
            selectAll(selectable?.map(data => pick(data, COMMON_FIELDS)))
          }}
        />
      )
    },
    Cell({ value, row }) {
      const { selected, setSelected } = useTradeSelectorList()
      if (!row?.original?.id) return null

      return (
        <Checkbox
          checked={selected[value] ? true : false}
          onChange={() => {
            setSelected(pick(row.original, COMMON_FIELDS))
          }}
        />
      )
    },
  },
  {
    Header: <div className="pl-3">Pavti No</div>,
    accessor: "name",
    Cell({ row: { original } }) {
      if (!original.id) return "--"
      return (
        <div className="pl-4">
          <Link
            className="link-text text-left"
            to={`/d/trade-requests/${original.id}`}
          >
            Pavti #{original.id}
          </Link>
        </div>
      )
    },
  },
  {
    Header: "Pavti Type",
    accessor: "kind",
    Cell({ value }) {
      // const kind = TRADE_KINDS.find(k => k.id === value)
      // if (!kind?.name) return "--"

      return <TradeKindTag kind={value as any} />
    },
  },
  {
    Header: "Trade start date",
    accessor: "start_date",
    Cell({ value }) {
      if (!value) return "--"
      return (
        <Cell
          title={dayjs(value).format("D MMM 'YY")}
          subtitle={dayjs(value).format("hh:mm a")}
        />
      )
    },
  },
  {
    Header: "Tracking code",
    accessor: "tracking_code",
    Cell({ value }) {
      if (!value) return "--"
      return minifyUuid(value)
    },
  },
  {
    Header: "Created On",
    accessor: "created_at",
    Cell({ value }) {
      if (!value) return "--"
      return (
        <Cell
          title={dayjs(value).format("D MMM 'YY")}
          subtitle={dayjs(value).format("hh:mm a")}
        />
      )
    },
  },
  {
    Header: "Farmer",
    accessor: "seller",
    Cell({ value }) {
      if (!value) return "--"
      return <Cell title={value.full_name} />
    },
  },
  {
    Header: "Trader",
    accessor: "buyer",
    Cell({ value }) {
      if (!value) return "--"
      return <Cell title={value.full_name} />
    },
  },
  {
    Header: "Quantity",
    accessor: "items_aggregate.aggregate.sum.quantity",
    Cell({ value, row: { original } }) {
      if (!value) return "--"

      return (
        <span>
          {value || "0"}{" "}
          <span className="text-gray-500">
            {original.item_units?.[0]?.quantity_unit?.name || "na"}
          </span>
        </span>
      )
    },
  },
  {
    Header: "Rate",
    accessor: "item_units",
    Cell({ value, row: { original } }) {
      if (!value) return "--"
      return (
        <Cell
          title={`${inr(original.items_aggregate.aggregate.avg.rate)} / ${
            value[0]?.rate_unit?.name || "na"
          }`}
          subtitle={inr(original.total_amount)}
        />
      )
    },
  },
  {
    Header: "Receipt",
    accessor: "receipt_id",
    Cell({ value }) {
      if (!value) return "--"
      return (
        <Link
          className="link-text text-left"
          to={AppRoutes.tradeReceiptDetail(value)}
        >
          Receipt #{value}
        </Link>
      )
    },
  },
  {
    Header: "invoice",
    accessor: "invoice",
    Cell({ value }) {
      if (!value) return "--"
      return <Cell title={`#${value?.id}`} subtitle={value?.status_id} />
    },
  },
  {
    Header: "Placed By",
    accessor: "created_by",
    Cell({ value, row }) {
      if (!value) return "--"
      console.log(value)
      return (
        <Tooltip
          content={
            <div>
              <p>{value?.full_name || "na"}</p>
              <p className="text-gray-400">
                {dayjs(row?.original?.created_at).format("DD MMM 'YY | h:mm a")}
              </p>
            </div>
          }
        >
          <Avatar
            className="relative text-center"
            name={value?.full_name}
            src={value?.image?.url}
          />
        </Tooltip>
      )
    },
  },
  {
    Header: "Status",
    accessor: "status_id",
    Cell({ row: { original }, value }) {
      return (
        <Cell
          title={
            value ? (
              <span className="flex gap-1 items-center">
                {value}{" "}
                {original?.status_processing && (
                  <Tooltip content="Status change in progress">
                    <Timer size={16} className="text-blue-300" weight="fill" />
                  </Tooltip>
                )}
              </span>
            ) : (
              "na"
            )
          }
          subtitle={dayjs(original.updated_at).format("D MMM 'YY | hh:mm A")}
        />
      )
    },
  },
  {
    Header: "",
    accessor: "updated_at",
    Cell({ row: { original } }) {
      const [params, setParams] = useSearchParams()
      const navigate = useNavigate()
      const location = useLocation()
      const [isShown, setShown] = useState(false)
      const [{ data }] = useTradeHashesQuery({
        variables: {
          id: original.id,
        },
        pause: !isShown,
      })

      function handleOpenPdf(key: "seller_hash" | "buyer_hash") {
        return async () => {
          let token = localStorage.getItem("token")
          downloadPdf(
            APIRoutes.downloadPavti(data?.trade_by_pk?.[key] as string),
            token
          ).catch(err => {
            return toast.custom(
              <Toast title={"Unable to download the receipt"} intent="danger" />
            )
          })
        }
      }
      return (
        <Popover
          onShow={() => setShown(true)}
          minimal
          arrow={false}
          className="p-0"
          trigger="mouseenter"
          interactive
          animation="fade"
          theme="light-border"
          placement="bottom"
          content={
            <Menu className="max-h-52">
              {/* <MenuItem
                onClick={() => {
                  params.set("timeline", original.id)
                  setParams(params)
                }}
              >
                Timeline
              </MenuItem> */}
              <MenuItem
                onClick={() => {
                  navigate(`/d/trade-requests/${original.id}`)
                }}
              >
                View Details
              </MenuItem>
              <MenuItem onClick={handleOpenPdf("seller_hash")}>
                Download Farmer Receipt
              </MenuItem>
              <MenuItem onClick={handleOpenPdf("buyer_hash")}>
                Download Trader Receipt
              </MenuItem>
              <MenuItem
                onClick={() => {
                  params.set("update", original.id)
                  navigate(
                    {
                      pathname: location.pathname,
                      search: `?${createSearchParams(params)}`,
                    },
                    {
                      state: {
                        status_id: original.status_id,
                        status_processing: original.status_processing,
                      },
                    }
                  )
                }}
              >
                Update Status
              </MenuItem>
              <MenuItem
                disabled={
                  // Can add hishob patti if
                  // 1. Trade is already not a hishob patti
                  // 2. Trade status is verified
                  original?.kind === TradeKinds.HishobPatti
                    ? true
                    : original?.status_id !== "Approved"
                }
                onClick={() => {
                  navigate(`${original.id}?new=true`)
                }}
              >
                Add Hishob Patti
              </MenuItem>
            </Menu>
          }
        >
          <button className="hover:bg-gray-300 rounded">
            <DotsThreeVertical size={20} />
          </button>
        </Popover>
      )
    },
  },
]

export type FilterType = {
  sb_seller?: UsersQuery["base_user"][0]
  sb_buyer?: UsersQuery["base_user"][0]
  com_commodity?: any
  com_variety?: any
  oth_mode?: any
  oth_kind?: any
  oth_status?: any
  oth_facilitator?: any
  oth_created_at?: any
}
const AllTradeRequests = () => {
  const [params, setParams] = useSearchParams()
  const navigate = useNavigate()
  const [{ data: aData }] = useAuthenticatedUserQuery()

  const search = params.get("search")
  const updatedAt = params.get("updatedAt")
  const page = Number(params.get("page")) || 1
  const [filter, setFilter] = useState<FilterType>({})
  const [debouncedSearch] = useDebouncedValue(
    search?.trim(),
    siteConfig.debounce
  )
  const canViewInvoice = useAuthorization(Permission.ViewInvoice)
  const canViewTrades = useAuthorization(Permission.ViewTrade)
  const buyerCode = filter.sb_buyer?.id
  const sellerCode = filter.sb_seller?.id
  const commodityCode = filter.com_commodity?.id
  const varietyCode = filter.com_variety?.id
  const facilitatorCode = filter.oth_facilitator?.id
  const [department] = useLocalStorage({ key: "department" })

  const createdAt = filter?.oth_created_at
  const whereClause: Trade_Bool_Exp = {
    ...(debouncedSearch
      ? Number(debouncedSearch)
        ? { id: { _eq: Number(debouncedSearch) } }
        : { tracking_code: { _ilike: `%${debouncedSearch}%` } }
      : {}),
    kind: { _eq: filter.oth_kind },
    created_at: createdAt
      ? {
          _lte: dayjs(createdAt).endOf("day").toISOString(),
          _gte: dayjs(createdAt).startOf("day").toISOString(),
        }
      : undefined,
    created_in_id: {
      _eq: department,
    },
    facilitator_id: {
      _eq: facilitatorCode,
    },
    buyer_id: {
      _eq: buyerCode,
    },

    seller_id: {
      _eq: sellerCode,
    },
    commodity_id: {
      _eq: commodityCode,
    },
    commodity_variety_id: {
      _eq: varietyCode,
    },
    mode: {
      _eq: filter.oth_mode,
    },
    facilitator: facilitatorCode && {
      id: {
        _eq: facilitatorCode,
      },
    },
    status_id: {
      _eq: filter.oth_status?.id,
    },
  }

  const tradeVariables: Omit<
    UseQueryArgs<AllTradeRequestsQueryVariables>,
    "query"
  > = {
    variables: {
      limit: siteConfig.pageSize,
      offset: (page - 1) * siteConfig.pageSize,
      order_by: { created_at: Order_By.DescNullsLast },
      where: whereClause,
    },
    pause: !department,
    requestPolicy: "network-only",
  }

  useEffect(() => {
    if (!canViewTrades) {
      navigate(AppRoutes.tradeReceipts)
    }
  }, [canViewTrades])
  const [{ data: qData, fetching }, refetch] =
    useAllTradeRequestsQuery(tradeVariables)

  const [{ data: sData }] =
    useAllTradeRequestsStatusSubscription(tradeVariables)

  const data = useMemo(() => merge(qData, sData), [qData, sData])
  const totalTrades = data?.trade_aggregate?.aggregate?.count || 0
  useEffect(() => {
    if (updatedAt) {
      refetch()
      params.delete("updatedAt")
      setParams(params)
    }
  }, [updatedAt])
  return (
    <>
      <header className="flex justify-between p-2 border-b-1 border-b-gray-300">
        <SegmentedControl
          value={"trades"}
          onChange={value => {
            console.log(value)
            if (value === "receipts") {
              navigate(AppRoutes.tradeReceipts)
            } else if (value === "invoices" && canViewInvoice) {
              navigate(AppRoutes.tradeInvoices)
            }
          }}
          data={[
            {
              label: "Trades",
              value: "trades",
              icon: <List className="mr-1" />,
            },
            {
              label: "Receipts",
              value: "receipts",
              icon: <Receipt className="mr-1" />,
            },
            canViewInvoice && {
              label: "Invoices",
              value: "invoices",
              icon: <OrdersCustom className="mr-1" />,
            },
          ].filter(b => b)}
        />
        <div className="w-[500px] flex items-center gap-3">
          <InputGroup
            className={"w-full"}
            inputProps={{
              value: search || "",
              placeholder: "Search by tracking code",
              onChange(e) {
                params.set("search", e.target.value)
                setParams(params)
              },
            }}
            leftElement={<MagnifyingGlass />}
          />
          <TradeRequestsFilter filter={filter} setFilter={setFilter} />
        </div>
        <div className="flex gap-3">
          <TradeActions />
          {!department ? null : (
            <Button
              onClick={() => navigate("/d/trade-requests/new")}
              leftIcon={<Plus />}
            >
              Trade Request
            </Button>
          )}
        </div>
      </header>
      <TableView
        relative={false}
        className="!h-[calc(100vh-64px-57px)]"
        paginationProps={{
          total: Math.ceil(totalTrades / siteConfig.pageSize),
          page: page,
          onChange(page) {
            params.set("page", page.toString())
            params.set("search", "")
            setParams(params)
          },
        }}
        isLoading={fetching}
        columns={COLUMNS}
        data={data?.trade || []}
        meta={<>{totalTrades} trade requests</>}
      />
      <UpdateTradeStatus
        bulkUpdateHook={useBulkUpdateTradeMutation}
        storeListHook={useTradeSelectorList}
      />
      <GenerateInvoices />
    </>
  )
}

export default AllTradeRequests
