import { UseFormReturnType } from "@mantine/form"
import { Address } from "~/components/Registration/FormSections/Address"
import { PaymentDetails } from "~/components/Registration/FormSections/PaymentDetails"
import { Documents } from "~/components/Registration/FormSections/Trader/Documents"
import { POCDetail } from "~/components/Registration/FormSections/Trader/POCDetail"
import { RegisteredTraderBasicDetails } from "~/components/Registration/FormSections/Trader/Registered/RegisteredTraderBasicDetails"
import { ShopAddress } from "~/components/Registration/FormSections/Trader/ShopAddress"
import { REGISTERED_TRADER_INITIAL_STATE } from "~/pages/d/registration/trader/registered/[id]"

export type RegisteredTraderSectionCommonType = {
  form: UseFormReturnType<Partial<typeof REGISTERED_TRADER_INITIAL_STATE>>
  entity?: "farmer" | "trader" | "business"
}
export type FormTabType = {
  title: string
  id?: string
  component?: any
  isActive?: boolean
}
export const RegisteredTraderFormSections: FormTabType[] = [
  {
    title: "Basic Details",
    id: "basic",
    component: RegisteredTraderBasicDetails,
    isActive: true,
  },
  {
    title: "Address",
    id: "current_address",
    component: Address,
    isActive: false,
  },
  {
    title: "Documents",
    id: "documents",
    component: Documents,
    isActive: false,
  },
  {
    title: "Bank Details",
    id: "payment",
    component: PaymentDetails,
    isActive: false,
  },
  {
    title: "POC (optional)",
    id: "poc",
    component: POCDetail,
    isActive: false,
  },
  {
    title: "Shop Address (optional)",
    id: "shops",
    component: ShopAddress,
    isActive: false,
  },
]
