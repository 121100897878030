import axios from "axios"
import { isArray, isNumber } from "lodash-es"

export const pointToGis = (latitude: number, longitude: number) => ({
  type: "Point",
  crs: {
    type: "name",
    properties: {
      name: "urn:ogc:def:crs:EPSG::4326",
    },
  },
  coordinates: [longitude, latitude],
})

export const gisToPoint = (data: object) => [
  data?.["coordinates"][1],
  data?.["coordinates"][0],
]

export function pointToJson(point: [number, number]) {
  if (!point || !point.length) return null
  return { lat: point[1], lng: point[0] }
}

export const gisToJson = (data: object) => ({
  lat: data?.["coordinates"]?.[1],
  lng: data?.["coordinates"]?.[0],
})

export const rad = x => (x * Math.PI) / 180
export function getDistance(p1, p2) {
  if (p1 && p2 && p1.lat && p2.lat && p1.lng && p2.lng) {
    var R = 6378137 // Earth’s mean radius in meter
    var dLat = rad(p2.lat - p1.lat)
    var dLong = rad(p2.lng - p1.lng)
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(rad(p1.lat)) *
        Math.cos(rad(p2.lat)) *
        Math.sin(dLong / 2) *
        Math.sin(dLong / 2)
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    var d = R * c
    return d // returns the distance in meter
  }
  return null
}

export function pointToJsonMany(arr: [number, number][]) {
  if (!arr || !arr.length) return []

  return arr
    .map(p => {
      if (!isArray(p)) return undefined
      return {
        lat: p[1],
        lng: p[0],
      }
    })
    .filter(p => p)
}

export function jsonToPointMany(arr: { lat: number; lng: number }[]) {
  if (!arr || !arr.length) return []
  return arr
    .map(a => {
      if (!isNumber(a.lat) || !isNumber(a.lng)) return undefined
      return [a.lng, a.lat]
    })
    .filter(p => p)
}

export type GeocodedAddressType = {
  raw_address: string
  pincode: string
  country: string
  state: string
  district: string
  village: string
  taluka: string
  location: any
  local_area: string
}
function parseAddress(response) {
  if (!response?.results) return null
  const results = response.results
  const parsedAddress: GeocodedAddressType = {
    raw_address: "",
    pincode: "",
    country: "",
    state: "",
    district: "",
    village: "",
    taluka: "",
    location: response.location,
    local_area: "",
  }
  //   Parse address
  for (let i = 0; i < results.length; i++) {
    const result = results[i]
    const addressComponents = result.address_components
    if (i === 0) {
      parsedAddress.raw_address = result.formatted_address
    }

    for (let j = 0; j < addressComponents.length; j++) {
      const addressComponent = addressComponents[j]
      if (i === 0) {
        if (addressComponent.types.includes("postal_code")) {
          parsedAddress.pincode = addressComponent.long_name
        }

        if (addressComponent.types.includes("country")) {
          parsedAddress.country = addressComponent.long_name
        }

        if (addressComponent.types.includes("administrative_area_level_1")) {
          parsedAddress.state = addressComponent.long_name
        }

        if (addressComponent.types.includes("administrative_area_level_3")) {
          parsedAddress.district = addressComponent.long_name
        }
        if (addressComponent.types.includes("locality")) {
          parsedAddress.village = addressComponent.long_name
          parsedAddress.local_area = addressComponent.long_name
        }

        if (addressComponent.types.includes("sublocality_level_1")) {
          parsedAddress.village = addressComponent.long_name
        }
      }
      if (addressComponent.types.includes("administrative_area_level_4")) {
        parsedAddress.taluka = addressComponent.long_name
        continue
      }
    }
  }
  return parsedAddress
}
export async function geoCoder(lat: number, lng: number) {
  if (!lat || !lng) return

  const response = await axios.get(
    "https://maps.googleapis.com/maps/api/geocode/json",
    {
      params: {
        key: import.meta.env.VITE_MAP_API,
        latlng: `${lat},${lng}`,
      },
    }
  )

  return parseAddress({ ...response.data, location: pointToGis(lat, lng) })
}

export function jsonToGis(arr) {
  if (!arr) return []
  return arr?.map?.(a => [a?.lng, a?.lat])
}
