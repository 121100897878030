export const IMAGE_DETAIL = /* GraphQL */ `
  query ImageDetail($id: bigint!) {
    media_by_pk(id: $id) {
      id
      private_url
      url
      key
      kind
      is_encrypted
      payment_detail_id
    }
  }
`
