import { Autocomplete, AutocompleteProps } from "@react-google-maps/api"
import { InputGroup } from "@vesatogo/grass-core"
import { MagnifyingGlass } from "@phosphor-icons/react"
import { useRef } from "react"
import toast from "react-hot-toast"
import { GeocodedAddressType, geoCoder } from "~/utils/map-helpers"

type MapAutocompleteProps = Omit<AutocompleteProps, "children"> & {
  onChange: (address: GeocodedAddressType, raw?: object) => void
}
const MapAutocomplete = ({ onChange, ...props }: MapAutocompleteProps) => {
  const autoCompleteRef: any = useRef()

  return (
    <Autocomplete
      {...props}
      onLoad={autocomplete => {
        autoCompleteRef.current = autocomplete
      }}
      onPlaceChanged={async () => {
        try {
          const place =
            autoCompleteRef.current && autoCompleteRef.current.getPlace()
          if (!place) throw new Error("Requested place not found")

          const coOrds: [number, number] = place.name
            ?.split(",")
            ?.map(coord => Number(coord.trim()))

          const validCoords = coOrds?.every(
            coord => (coord || coord === 0) && typeof coord === "number"
          )
          if (validCoords) {
            const newPos = new window.google.maps.LatLng(...coOrds).toJSON()

            if (!newPos?.lat) throw new Error("Unable to geocode the location")
            onChange?.((await geoCoder(newPos.lat, newPos.lng)) as any, place)
          } else {
            const placeCoords = place?.geometry?.location?.toJSON()
            onChange?.(
              (await geoCoder(placeCoords.lat, placeCoords.lng)) as any,
              place
            )
          }
        } catch (error: any) {
          return toast.error(error?.message)
        }
      }}
    >
      <InputGroup
        data-testid="mapAutocomplete"
        leftElement={<MagnifyingGlass size={16} />}
        className="mt-4 ml-16 w-11/12"
      />
    </Autocomplete>
  )
}

export default MapAutocomplete
