import { useForm, yupResolver } from "@mantine/form"
import {
  Button,
  Card,
  FormGroup,
  FormInput,
  FormQuerySelector,
  Modal,
  SpinnerOverlay,
  Toast,
} from "@vesatogo/grass-core"
import { FormDateInput } from "@vesatogo/grass-dates"
import { omit } from "lodash-es"
import { useEffect } from "react"
import toast from "react-hot-toast"
import { useSearchParams } from "react-router-dom"
import { AddCompleteType } from "~/components/Admin/AddCompleteType"
import {
  Transport_Vehicle_Constraint,
  Transport_Vehicle_Update_Column,
  useAllVehicleFuelTypesQuery,
  useAllVehicleTypesQuery,
  useInsertVehicleMutation,
  useVehicleDetailsQuery,
} from "~/generated/graphql"
import { vehicleModel } from "~/models/vehicle"
import { getInputProps } from "~/utils/form-helpers"

export const AddVehicleModal = ({ onAddComplete }: AddCompleteType) => {
  const [params, setParams] = useSearchParams()
  const vehicleId = params.get("vehicleId") || ""
  const formType = params?.get("form")
  const show = vehicleId !== "" && formType === "vehicle"

  const form = useForm<any>({
    initialValues: {},
    validate: yupResolver(vehicleModel),
    validateInputOnChange: true,
    validateInputOnBlur: true,
    clearInputErrorOnChange: false,
  })

  const state = form.values
  const setState = form.setValues

  const [{ data, fetching }] = useVehicleDetailsQuery({
    variables: {
      id: parseInt(vehicleId),
    },
    pause: !show,
  })

  const [{ fetching: insertFetching }, insertVehicle] =
    useInsertVehicleMutation()

  useEffect(() => {
    setState(val => ({
      ...val,
      registration_number: data?.transport_vehicle_by_pk?.registration_number,
      vehicle_type: data?.transport_vehicle_by_pk?.vehicle_type,
      model_name: data?.transport_vehicle_by_pk?.model_name,
      estimated_average: data?.transport_vehicle_by_pk?.estimated_average,
      purchase_date: data?.transport_vehicle_by_pk?.date_bought,
      chassis_number: data?.transport_vehicle_by_pk?.chassis_number,
      fuel_tank_capacity: data?.transport_vehicle_by_pk?.fuel_tank_capacity,
      fuel_type: data?.transport_vehicle_by_pk?.fuel_type,
    }))
  }, [data])

  const onClose = () => {
    setParams({})
    form?.reset()
  }

  const onSubmit = async () => {
    const { errors, hasErrors } = form?.validate()
    if (hasErrors) {
      return toast.custom(
        <Toast
          intent="danger"
          title={"Please resolve all errors before proceeding!"}
        />
      )
    }
    const { data: insertData, error } = await insertVehicle({
      object: {
        ...omit(
          data?.transport_vehicle_by_pk,
          "fuel_type",
          "created_at",
          "vehicle_type",
          "status",
          "__typename",
          "owner",
          "driver"
        ),
        id: vehicleId !== "new" ? vehicleId : undefined,
        registration_number: state?.registration_number,
        vehicle_type_id: state?.vehicle_type?.id,
        model_name: state?.model_name,
        estimated_average: state?.estimated_average,
        date_bought: state?.purchase_date,
        chassis_number: state?.chassis_number,
        fuel_type_id: state?.fuel_type?.id,
        fuel_tank_capacity: state?.fuel_tank_capacity || 0,
        loading_capacity: 0,
        passenger_capacity: 0,
      },
      on_conflict: {
        constraint: Transport_Vehicle_Constraint.TransportVehiclePkey,
        update_columns: [
          Transport_Vehicle_Update_Column.Id,
          Transport_Vehicle_Update_Column.RegistrationNumber,
          Transport_Vehicle_Update_Column.ChassisNumber,
          Transport_Vehicle_Update_Column.DateBought,
          Transport_Vehicle_Update_Column.EstimatedAverage,
          Transport_Vehicle_Update_Column.FuelTankCapacity,
          Transport_Vehicle_Update_Column.FuelTypeId,
          Transport_Vehicle_Update_Column.LoadingCapacity,
          Transport_Vehicle_Update_Column.ModelName,
          Transport_Vehicle_Update_Column.OwnerId,
          Transport_Vehicle_Update_Column.VehicleTypeId,
        ],
      },
    })

    if (error) {
      return toast.custom(
        <Toast
          intent="danger"
          title={"Something went wrong! " + error.message}
        />
      )
    }
    if (insertData) {
      onClose()
      toast.custom(
        <Toast
          title={
            vehicleId === "new"
              ? "Vehicle added successfully!"
              : "Vehicle updated successfully!"
          }
        />
      )
      onAddComplete?.({
        requestPolicy: "network-only",
      })
    }
  }

  return (
    <Modal
      bodyClassName="overflow-visible w-[60%]"
      title="Enter your personal details"
      contentClassName="bg-gray-100"
      footer={
        <div className="flex justify-end gap-3">
          <Button size="sm" onClick={onClose} variant="outline">
            Cancel
          </Button>
          <Button loading={insertFetching} size="sm" onClick={onSubmit}>
            Save
          </Button>
        </div>
      }
      isOpen={show}
      onClose={onClose}
    >
      <SpinnerOverlay show={fetching} />
      <Card className="p-4">
        <div className="flex flex-col gap-4">
          <Card className="p-2 flex gap-4 !rounded-lg bg-gray-100 w-fit">
            <span>
              <span className="text-gray-600"> Owner name -</span>{" "}
              {data?.transport_vehicle_by_pk?.owner?.full_name || "NA"}
            </span>
            <span>
              <span className="text-gray-600"> Driver name -</span>{" "}
              {data?.transport_vehicle_by_pk?.driver?.full_name || "NA"}
            </span>
          </Card>
          <div className="grid grid-cols-3 gap-4 w-full">
            <FormInput
              label="Registration Number"
              required
              {...getInputProps(form, "registration_number")}
              placeholder="Enter Number"
            />
            <FormQuerySelector
              dataHook={useAllVehicleTypesQuery}
              serverSideQuery
              label="Vehicle Type"
              required
              {...getInputProps(form, "vehicle_type")}
            />
            <FormInput
              label="Model Name"
              {...getInputProps(form, "model_name")}
              placeholder="Enter Name"
              maxLength={10}
            />
            <FormInput
              label="Estd. Average"
              required
              {...getInputProps(form, "estimated_average")}
              placeholder="Enter Average"
            />
            <FormDateInput
              label="Purchase Date"
              required
              {...getInputProps(form, "purchase_date")}
              placeholder="Date"
            />
            <FormInput
              label="Chassis Number"
              {...getInputProps(form, "chassis_number")}
              placeholder="Enter Number"
            />
            <FormQuerySelector
              dataHook={useAllVehicleFuelTypesQuery}
              label="Fuel Type"
              {...getInputProps(form, "fuel_type")}
              placeholder="Select"
            />
            <FormInput
              label="Fuel Tank Capacity"
              {...getInputProps(form, "fuel_tank_capacity")}
              placeholder="Enter Capacity"
            />
            <FormInput
              label="Mandi"
              required
              className={"hidden"}
              {...getInputProps(form, "mandi")}
              placeholder="Select"
            />
            <FormGroup
              label="Dimensions"
              className={"col-span-2 hidden"}
              helperText="Length (L) x Breadth (B) x Height (H)"
            >
              <div className="flex gap-4">
                <FormInput
                  {...getInputProps(form, "length")}
                  className={"inline w-fit"}
                  placeholder="L"
                />
                <FormInput
                  {...getInputProps(form, "bredth")}
                  className={"inline w-fit"}
                  placeholder="B"
                />
                <FormInput
                  {...getInputProps(form, "height")}
                  className={"inline w-fit"}
                  placeholder="H"
                />
                <FormInput
                  {...getInputProps(form, "unit")}
                  className={"inline w-fit"}
                />
              </div>
            </FormGroup>
          </div>
        </div>
      </Card>
    </Modal>
  )
}
