import { useForm } from "@mantine/form"
import { CheckCircle } from "@phosphor-icons/react"
import { Breadcrumbs } from "@vesatogo/grass-core"
import classNames from "classnames"
import { isArray } from "lodash-es"
import { useEffect, useMemo, useState } from "react"
import { Link, useParams } from "react-router-dom"
import VerifyAsset from "~/components/VerifyAsset"
import Codenames from "~/constants/Codenames"
import { NonRegisteredTraderFormSections } from "~/constants/registration/NonRegisteredTraderFormSections"
import { AppRoutes } from "~/constants/routes"
import {
  useBaseUserDetailQuery,
  useUpdateBaseUserMutation,
} from "~/generated/graphql"

export const TRADER_INITIAL_STATE = {
  id: null as any,
  is_verified: null as any,
  current_address: {},
  documents: {
    license: {
      id_number: "",
    } as any,
    gstin: {
      id_number: "",
    } as any,
  },
  payment: {} as any,

  basic: {
    photo: [],
  } as any,
  certificates: {
    aadhar: {
      id_number: "",
      front: [],
      back: [],
    } as any,
    pan: {
      id_number: "",
    } as any,
  },
  shops: [
    {
      address: {} as any,
      gstin: {
        id_number: "",
      },
      shop_act: {
        id_number: "",
      },
    },
  ] as any,
}

export const NonRegisteredTraderDetails = () => {
  const { id } = useParams()
  const traderId = Number(id) ? Number(id) : null

  const [activeSection, setActiveSection] = useState<any>()

  const form = useForm<Partial<typeof TRADER_INITIAL_STATE>>({
    initialValues: TRADER_INITIAL_STATE,
    validateInputOnChange: false,
    validateInputOnBlur: true,
    clearInputErrorOnChange: false,
  })

  const [{ data, fetching }, refetch] = useBaseUserDetailQuery({
    variables: {
      id: traderId,
    },
    pause: !traderId,
  })

  useEffect(() => {
    const aadhar = data?.base_user_by_pk?.documents?.find(
      doc => doc?.kind?.codename === Codenames.AADHAR
    )
    const pan = data?.base_user_by_pk?.documents?.find(
      doc => doc?.kind?.codename === Codenames.PAN_CARD
    )
    const gstin = data?.base_user_by_pk?.documents?.find(
      doc => doc?.kind?.codename === Codenames.GST_BASE_USER
    )
    const marketLicense = data?.base_user_by_pk?.documents?.find(
      doc => doc?.kind?.codename === Codenames.MARKET_LICENSE
    )
    const trader = data?.base_user_by_pk
    form.setValues({
      id: trader?.id,
      is_verified: trader?.is_verified,
      basic: {
        id: trader?.id,
        first_name: trader?.first_name,
        middle_name: trader?.middle_name,
        full_name: trader?.full_name,
        last_name: trader?.last_name,
        number: trader?.number,
        email: trader?.email,
        gender: trader?.gender,
        photo: trader?.image ? [trader?.image] : [],
        groups: trader?.groups,
      },
      documents: {
        gstin:
          {
            ...gstin,
            attachments: gstin?.attachments?.length ? gstin?.attachments : [],
          } || {},
        license:
          {
            ...marketLicense,
            attachments: marketLicense?.attachments?.length
              ? marketLicense?.attachments
              : [],
          } || {},
      },
      certificates: {
        aadhar:
          {
            ...aadhar,
            attachments: aadhar?.attachments?.length ? aadhar?.attachments : [],
          } || {},
        pan:
          {
            ...pan,
            attachments: pan?.attachments?.length ? pan?.attachments : [],
          } || {},
      },
      current_address: trader?.address || ({} as any),
      payment: trader?.payment_details?.map(payment_detail => {
        return {
          ...payment_detail,
          verify_account_number: payment_detail.account_number,
        }
      }),
      shops: trader?.shops_owned?.map(shop => {
        return {
          ...shop,
          address: shop?.address || ({} as any),
          gstin: {
            ...shop?.documents?.filter(
              doc => doc?.kind?.codename === Codenames?.GST_BASE_USER
            )[0],
            attachments:
              shop?.documents?.filter(
                doc => doc?.kind?.codename === Codenames?.GST_BASE_USER
              )[0]?.attachments || [],
          },
          shop_act: {
            ...shop?.documents?.filter(
              doc => doc?.kind?.codename === Codenames?.SHOP_ACT
            )[0],
            attachments:
              shop?.documents?.filter(
                doc => doc?.kind?.codename === Codenames?.SHOP_ACT
              )[0]?.attachments || [],
          },
        }
      }),
    })
  }, [data])

  useEffect(() => {
    setActiveSection("basic")
  }, [])

  const sectionsFilledMap = useMemo(() => {
    return NonRegisteredTraderFormSections.reduce((prev, section) => {
      return {
        ...prev,
        [section.id as any]:
          section.id === "documents" || section.id === "shops"
            ? true
            : section.id === "certificates"
            ? form.values?.[section?.id as any]?.aadhar?.id &&
              form.values?.[section?.id as any]?.pan?.id
              ? true
              : false
            : isArray(form.values?.[section?.id as any])
            ? form.values?.[section?.id as any]?.[0]?.id
            : form.values?.[section?.id as any]?.id || false,
      }
    }, {})
  }, [fetching, form.values])
  const allSectionsFilled = Object.values(sectionsFilledMap)?.every(e => e)

  return (
    <>
      <header className="bg-gray-100 shadow-sm p-3 border-b-gray-300 border-b flex justify-between">
        <Breadcrumbs
          items={[
            {
              text: "Trader",
              link: AppRoutes.traderRegistration,
              isActive: false,
            },
            {
              text: data?.base_user_by_pk?.full_name || "New",
              link: `${AppRoutes.traderRegistration}/non-registered/new`,
              isActive: true,
            },
          ]}
          linkRenderer={(link, item) => {
            return <Link to={link as any}>{item}</Link>
          }}
        />
      </header>
      <div className="p-4 ">
        <div className="max-w-6xl mx-auto p-4 flex gap-4 w-full">
          <div className="w-[20%] flex flex-col justify-between rounded-xl bg-white py-4 h-[80vh] overflow-auto">
            <div className="">
              {NonRegisteredTraderFormSections?.map(section => {
                const isSectionFilled =
                  section.id === "certificates"
                    ? form.values?.[section?.id as any]?.aadhar?.id &&
                      form.values?.[section?.id as any]?.pan?.id
                      ? true
                      : false
                    : isArray(form.values?.[section?.id as any])
                    ? form.values?.[section?.id as any]?.[0]?.id
                    : form.values?.[section?.id as any]?.id || false
                return (
                  <div
                    key={section?.id}
                    className={classNames(
                      "p-2 flex gag-2 items-center justify-between",
                      activeSection === section.id && "text-green bg-gray-200",
                      !form.values.id && section.id !== "basic"
                        ? "pointer-events-none text-gray-300"
                        : "cursor-pointer"
                    )}
                    onClick={() => {
                      setActiveSection(section.id)
                    }}
                  >
                    {section.title}{" "}
                    {isSectionFilled && (
                      <CheckCircle
                        className="text-green"
                        weight="fill"
                        size={20}
                      />
                    )}
                  </div>
                )
              })}
            </div>
          </div>
          <div className="w-[80%]">
            {NonRegisteredTraderFormSections?.map((section, idx) => {
              const Component = section.component
              if (section.id === activeSection)
                return (
                  <Component
                    refetch={refetch}
                    form={form}
                    setActiveSection={setActiveSection}
                    docs={["gstin", "license"]}
                    next={NonRegisteredTraderFormSections[idx + 1]}
                  />
                )
            })}
          </div>
        </div>
      </div>
      {allSectionsFilled && (
        <div className="bg-white p-3">
          <VerifyAsset
            id={form?.values?.id as any}
            is_verified={form?.values?.is_verified}
            useUpdateHook={useUpdateBaseUserMutation}
          />
        </div>
      )}
    </>
  )
}

export default NonRegisteredTraderDetails
