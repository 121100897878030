import { useEffect } from "react"
import { render } from "react-dom"

export const useMapControl = (map, controlPosition, children) => {
  useEffect(() => {
    if (map && controlPosition) {
      render(
        <div
          ref={el => {
            map.controls[controlPosition].clear()
            map.controls[controlPosition].push(el)
          }}
        >
          {children}
        </div>,
        document.createElement("div")
      )
    }
    return () => {
      if (map && controlPosition) {
        map.controls[controlPosition].clear()
      }
    }
  }, [map, controlPosition, children])
}
