import { useForm, yupResolver } from "@mantine/form"
import {
  Button,
  Card,
  FormInput,
  FormQuerySelector,
  Modal,
  SpinnerOverlay,
  Toast,
} from "@vesatogo/grass-core"
import { pick } from "lodash-es"
import { useEffect } from "react"
import toast from "react-hot-toast"
import { useSearchParams } from "react-router-dom"
import { AddCompleteType } from "~/components/Admin/AddCompleteType"
import { FileUpload } from "~/components/dropzone/FileUploader/FileUploader"
import {
  Commodity_Variety_Constraint,
  Commodity_Variety_Grade_Constraint,
  Commodity_Variety_Grade_Update_Column,
  Commodity_Variety_Update_Column,
  Media_Constraint,
  Media_Update_Column,
  useAllCommodityVarietiesNewQuery,
  useCommodityVarietyGradeDetailQuery,
  useInsertCommodityVarietyGradeMutation,
} from "~/generated/graphql"
import { commodtyVarietyGradeModel } from "~/models/commodity_variety_grade"
import { getFileUploadProps, getInputProps } from "~/utils/form-helpers"

const INITIAL_STATE = {
  photo: [],
}

export const AddCommodityVarietyGradeModal = ({
  onAddComplete,
}: AddCompleteType) => {
  const [params, setParams] = useSearchParams()
  const commodityVarietyGradeId = params?.get("id") || ""

  const form = useForm<any>({
    initialValues: INITIAL_STATE,
    validate: yupResolver(commodtyVarietyGradeModel),
    validateInputOnChange: true,
    validateInputOnBlur: true,
    clearInputErrorOnChange: false,
  })

  const state = form.values
  const setState = form.setValues

  const [{ data, fetching }] = useCommodityVarietyGradeDetailQuery({
    variables: {
      id: commodityVarietyGradeId,
    },
    pause:
      commodityVarietyGradeId === "new" || !Boolean(commodityVarietyGradeId),
  })

  const [{ fetching: insertFetching }, insertCommodityVarietyGrade] =
    useInsertCommodityVarietyGradeMutation()

  useEffect(() => {
    setState(val => ({
      name: data?.commodity_variety_grade_by_pk?.name,
      specifications: data?.commodity_variety_grade_by_pk?.specifications,
      commodity_variety: data?.commodity_variety_grade_by_pk?.variety,
      codename: data?.commodity_variety_grade_by_pk?.codename,
      photo: data?.commodity_variety_grade_by_pk?.image
        ? [data?.commodity_variety_grade_by_pk?.image]
        : [],
    }))
  }, [data])

  const onClose = () => {
    setParams({})
    form?.reset()
  }

  const onSubmit = async () => {
    const { errors, hasErrors } = form?.validate()
    if (hasErrors) {
      return toast.custom(
        <Toast
          intent="danger"
          title={"Please resolve all errors before proceeding!"}
        />
      )
    }
    const { data: insertData, error } = await insertCommodityVarietyGrade({
      object: {
        codename: state?.codename,
        name: state?.name,
        specifications: state?.specifications || "",
        image: state?.photo[0]
          ? {
              data: {
                ...pick(
                  state?.photo[0],
                  "kind",
                  "url",
                  "key",
                  "is_encrypted",
                  "id"
                ),
              },
              on_conflict: {
                constraint: Media_Constraint.MediaPkey,
                update_columns: [
                  Media_Update_Column.Id,
                  Media_Update_Column.Kind,
                  Media_Update_Column.Url,
                ],
              },
            }
          : null,
        id:
          commodityVarietyGradeId !== "new"
            ? commodityVarietyGradeId
            : undefined,
        variety: {
          data: {
            codename: state?.commodity_variety?.codename,
            commodity_id: state?.commodity_variety?.commodity?.id,
            id: state?.commodity_variety?.id,
            name: state?.commodity_variety?.name,
          },
          on_conflict: {
            constraint: Commodity_Variety_Constraint.CommodityVarietyPkey,
            update_columns: [
              Commodity_Variety_Update_Column.Codename,
              Commodity_Variety_Update_Column.Id,
              Commodity_Variety_Update_Column.Name,
              Commodity_Variety_Update_Column.CommodityId,
              Commodity_Variety_Update_Column.CropVarietyId,
            ],
          },
        },
      },
      on_conflict: {
        constraint:
          Commodity_Variety_Grade_Constraint.CommodityVarietyGradePkey,
        update_columns: [
          Commodity_Variety_Grade_Update_Column.Codename,
          Commodity_Variety_Grade_Update_Column.Name,
          Commodity_Variety_Grade_Update_Column.ImageId,
          Commodity_Variety_Grade_Update_Column.Specifications,
          Commodity_Variety_Grade_Update_Column.VarietyId,
          Commodity_Variety_Grade_Update_Column.Id,
        ],
      },
    })
    if (error) {
      return toast.custom(
        <Toast
          intent="danger"
          title={"Something went wrong! " + error.message}
        />
      )
    }
    if (insertData) {
      onClose()
      toast.custom(
        <Toast
          title={
            commodityVarietyGradeId === "new"
              ? "Grade added successfully!"
              : "Grade updated successfully!"
          }
        />
      )
      onAddComplete({ requestPolicy: "network-only" })
    }
  }
  return (
    <Modal
      bodyClassName="overflow-visible w-[60%]"
      title="Grade"
      contentClassName="bg-gray-100"
      footer={
        <div className="flex justify-end gap-3">
          <Button size="sm" onClick={onClose} variant="outline">
            Cancel
          </Button>
          <Button loading={insertFetching} size="sm" onClick={onSubmit}>
            Save
          </Button>
        </div>
      }
      isOpen={Boolean(params.get("id"))}
      onClose={onClose}
    >
      <SpinnerOverlay show={fetching} />
      <Card className="p-4 flex gap-20">
        <div>
          <FileUpload {...getFileUploadProps(form, "photo", 1)} />
        </div>
        <div className="grid grid-cols-2 gap-4 w-full">
          <FormInput
            label="Grade Name"
            required
            placeholder="Enter Name"
            {...getInputProps(form, "name")}
          />
          <FormInput
            label="Code Name"
            placeholder="Code Name"
            {...getInputProps(form, "codename")}
          />
          <FormQuerySelector
            label="Commodity Variety"
            required
            dataHook={useAllCommodityVarietiesNewQuery}
            serverSideQuery
            {...getInputProps(form, "commodity_variety")}
          />
          <FormInput
            label="Specifications"
            {...getInputProps(form, "specifications")}
          />
        </div>
      </Card>
    </Modal>
  )
}
