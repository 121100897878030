import { Icon, IconBase, IconWeight } from "@phosphor-icons/react"
import React, { forwardRef } from "react"

// Define your custom weights and SVG contents
const customWeights: Map<IconWeight, React.ReactElement> = new Map([
  [
    "fill",
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M53.3832 128.823V98.3926H69.3832V128.823H53.3832Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M186.617 128.823V98.3926H202.617V128.823H186.617Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M69.8318 32H118.729V90.4615C118.729 99.2981 111.566 106.462 102.729 106.462H85.8318C76.9952 106.462 69.8318 99.2981 69.8318 90.4615V32ZM85.8318 48V90.4615H102.729V48H85.8318Z"
      />
      <path d="M40 136.616C40 132.198 43.5817 128.616 48 128.616H208C212.418 128.616 216 132.198 216 136.616V142.154C216 146.573 212.418 150.154 208 150.154H48C43.5817 150.154 40 146.573 40 142.154V136.616Z" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M32 136.616C32 127.779 39.1634 120.616 48 120.616H208C216.837 120.616 224 127.779 224 136.616V142.154C224 150.991 216.837 158.154 208 158.154H48C39.1635 158.154 32 150.991 32 142.154V136.616ZM208 136.616H48V142.154H208V136.616Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M39.4018 142.205H216.598V208C216.598 216.836 209.435 224 200.598 224H55.4018C46.5653 224 39.4018 216.836 39.4018 208V142.205ZM55.4018 158.205V208H200.598V158.205H55.4018Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M137.271 32H186.168V90.4615C186.168 99.2981 179.005 106.462 170.168 106.462H153.271C144.434 106.462 137.271 99.2981 137.271 90.4615V32ZM153.271 48V90.4615H170.168V48H153.271Z"
      />
      <path d="M109 32H147V98C147 102.418 143.418 106 139 106H117C112.582 106 109 102.418 109 98V32Z" />
      <path d="M176 32H212C216.418 32 220 35.5817 220 40V90C220 98.8366 212.837 106 204 106H184C179.582 106 176 102.418 176 98V32Z" />
      <path d="M80 32H44C39.5817 32 36 35.5817 36 40V90C36 98.8366 43.1634 106 52 106H72C76.4183 106 80 102.418 80 98V32Z" />
      <path d="M32 137C32 128.163 39.1634 121 48 121H208C216.837 121 224 128.163 224 137V142C224 150.837 216.837 158 208 158H48C39.1634 158 32 150.837 32 142V137Z" />
    </>,
  ],
  // Replace with your custom thin icon content
  [
    "regular",
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M36.1122 44C36.1122 37.3726 41.4848 32 48.1122 32H85.0094V90.3925C85.0094 99.2291 77.8459 106.393 69.0094 106.393H52.1122C43.2756 106.393 36.1122 99.2291 36.1122 90.3925V44ZM52.1122 48V90.3925L69.0094 90.3925V48H52.1122Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M69.8318 32H118.729V90.4615C118.729 99.2981 111.566 106.462 102.729 106.462H85.8318C76.9952 106.462 69.8318 99.2981 69.8318 90.4615V32ZM85.8318 48V90.4615H102.729V48H85.8318Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M103.551 32H152.449V90.3925C152.449 99.2291 145.285 106.393 136.449 106.393H119.551C110.715 106.393 103.551 99.2291 103.551 90.3925V32ZM119.551 48V90.3925L136.449 90.3925V48H119.551Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M32 136.616C32 127.779 39.1634 120.616 48 120.616H208C216.837 120.616 224 127.779 224 136.616V142.154C224 150.991 216.837 158.154 208 158.154H48C39.1635 158.154 32 150.991 32 142.154V136.616ZM208 136.616H48V142.154H208V136.616Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M39.4018 142.205H216.598V208C216.598 216.836 209.435 224 200.598 224H55.4018C46.5653 224 39.4018 216.836 39.4018 208V142.205ZM55.4018 158.205V208H200.598V158.205H55.4018Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M137.271 32H186.168V90.4615C186.168 99.2981 179.005 106.462 170.168 106.462H153.271C144.434 106.462 137.271 99.2981 137.271 90.4615V32ZM153.271 48V90.4615H170.168V48H153.271Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M170.991 32H207.888C214.515 32 219.888 37.3726 219.888 44V90.3925C219.888 99.2291 212.724 106.393 203.888 106.393H186.991C178.154 106.393 170.991 99.2291 170.991 90.3925V32ZM186.991 48V90.3925L203.888 90.3925V48H186.991Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M53.3832 128.823V98.3926H69.3832V128.823H53.3832Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M186.617 128.823V98.3926H202.617V128.823H186.617Z"
      />
    </>,
  ], // Replace with your custom regular icon content
])

// Define your custom icon component
const MandiIcon: Icon = forwardRef((props, ref) => (
  <IconBase ref={ref} {...props} weights={customWeights} />
))

export default MandiIcon
