import classNames from "classnames"
import {
  SectionCard,
  SectionCardType,
} from "~/components/Registration/SectionCard"

export type CertificateCardType = {
  children?: any
}

export const CertificateCard = ({
  children,
  title,
  cardClassName,
  className,
  header,
  headerClassName,
  icon,
}: CertificateCardType & SectionCardType) => {
  return (
    <SectionCard
      icon={icon}
      title={title}
      headerClassName={classNames(
        "!bg-gray-100 border-b-1 border-gray-300",
        headerClassName
      )}
      cardClassName={classNames(
        "overflow-hidden border-1 border-gray-300 !shadow-none",
        cardClassName
      )}
      className={className}
      header={header}
    >
      {children}
    </SectionCard>
  )
}
