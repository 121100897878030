import { useForm, yupResolver } from "@mantine/form"
import {
  Button,
  Card,
  FormInput,
  FormQuerySelector,
  Modal,
  SpinnerOverlay,
  Toast,
} from "@vesatogo/grass-core"
import { pick } from "lodash-es"
import { useEffect } from "react"
import toast from "react-hot-toast"
import { useSearchParams } from "react-router-dom"
import { AddCompleteType } from "~/components/Admin/AddCompleteType"
import { FileUpload } from "~/components/dropzone/FileUploader/FileUploader"
import {
  Crop_Constraint,
  Crop_Group_Constraint,
  Crop_Group_Update_Column,
  Crop_Update_Column,
  Media_Constraint,
  Media_Update_Column,
  useAllCropGroupsQuery,
  useCropDetailQuery,
  useInsertCropMutation,
} from "~/generated/graphql"
import { cropModel } from "~/models/crop"
import { getFileUploadProps, getInputProps } from "~/utils/form-helpers"

const INITIAL_STATE = {
  photo: [],
}

export const AddCropModal = ({ onAddComplete }: AddCompleteType) => {
  const [params, setParams] = useSearchParams()
  const cropId = params?.get("id") || ""

  const form = useForm<any>({
    initialValues: INITIAL_STATE,
    validate: yupResolver(cropModel),
    validateInputOnChange: true,
    validateInputOnBlur: true,
    clearInputErrorOnChange: false,
  })

  const state = form.values
  const setState = form.setValues

  const [{ data, fetching }] = useCropDetailQuery({
    variables: {
      id: cropId,
    },
    pause: cropId === "new" || !Boolean(params.get("id")),
  })

  useEffect(() => {
    setState({
      name: data?.crop_by_pk?.name,
      codename: data?.crop_by_pk?.codename,
      photo: data?.crop_by_pk?.image ? [data?.crop_by_pk?.image] : [],
      crop_group: data?.crop_by_pk?.crop_group,
    })
  }, [data])

  const [{ fetching: insertFetching }, insertCrop] = useInsertCropMutation()

  const onClose = () => {
    setParams({})
    form?.reset()
  }

  const onSubmit = async () => {
    const { errors, hasErrors } = form?.validate()
    if (hasErrors) {
      return toast.custom(
        <Toast
          intent="danger"
          title={"Please resolve all errors before proceeding!"}
        />
      )
    }
    const { data: insertData, error } = await insertCrop({
      object: {
        id: cropId !== "new" ? cropId : undefined,
        name: state?.name,
        codename: state?.codename,
        crop_group: state?.crop_group?.id
          ? {
              data: {
                id: state?.crop_group?.id,
                name: state?.crop_group?.name,
                codename: state?.crop_group?.codename,
                description: state?.crop_group?.description || "",
                is_perennial: state?.crop_group?.is_perennial || false,
              },
              on_conflict: {
                constraint: Crop_Group_Constraint.CropGroupPkey,
                update_columns: [
                  Crop_Group_Update_Column.Id,
                  Crop_Group_Update_Column.Codename,
                  Crop_Group_Update_Column.Description,
                  Crop_Group_Update_Column.IsPerennial,
                  Crop_Group_Update_Column.Name,
                ],
              },
            }
          : undefined,
        image: state?.photo[0]
          ? {
              data: {
                ...pick(
                  state?.photo[0],
                  "kind",
                  "url",
                  "key",
                  "is_encrypted",
                  "id"
                ),
              },
              on_conflict: {
                constraint: Media_Constraint.MediaPkey,
                update_columns: [Media_Update_Column.Url],
              },
            }
          : undefined,
      },
      on_conflict: {
        constraint: Crop_Constraint.CropPkey,
        update_columns: [
          Crop_Update_Column.Codename,
          Crop_Update_Column.ImageId,
          Crop_Update_Column.Name,
          Crop_Update_Column.Id,
          Crop_Update_Column.GroupId,
        ],
      },
    })
    if (error) {
      return toast.custom(
        <Toast
          intent="danger"
          title={"Something went wrong! " + error.message}
        />
      )
    }
    if (insertData) {
      onClose()
      toast.custom(
        <Toast
          title={
            cropId === "new"
              ? "Crop added successfully!"
              : "Crop updated successfully!"
          }
        />
      )
      onAddComplete?.({ requestPolicy: "network-only" })
    }
  }
  return (
    <Modal
      bodyClassName="overflow-visible w-[60%]"
      title="Crop"
      contentClassName="bg-gray-100"
      footer={
        <div className="flex justify-end gap-3">
          <Button size="sm" onClick={onClose} variant="outline">
            Cancel
          </Button>
          <Button loading={insertFetching} size="sm" onClick={onSubmit}>
            Save
          </Button>
        </div>
      }
      isOpen={Boolean(params.get("id"))}
      onClose={onClose}
    >
      <Card className="p-4 flex gap-20">
        <SpinnerOverlay show={fetching} />
        <div>
          <FileUpload {...getFileUploadProps(form, "photo", 1)} />
        </div>
        <div className="grid grid-cols-2 gap-4 w-full">
          <FormInput
            label="Crop Name"
            required
            placeholder="Enter Name"
            {...getInputProps(form, "name")}
          />
          <FormInput
            label="Code Name (optional)"
            placeholder="Code Name"
            {...getInputProps(form, "codename")}
          />
          <FormQuerySelector
            label="Crop Group"
            required
            {...getInputProps(form, "crop_group")}
            serverSideQuery
            dataHook={useAllCropGroupsQuery}
          />
        </div>
      </Card>
    </Modal>
  )
}
