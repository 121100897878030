import { FormQuerySelector } from "@vesatogo/grass-core"
import { FormCard } from "~/components/FormCard"
import UserDetailsCard from "~/components/UserDetailsCard"
import SharedEntityGroup from "~/constants/SharedEntityGroup"
import { useUsersQuery } from "~/generated/graphql"

type WhoIsTradingProps = {
  showBuyer?: boolean
  inputProps?: any
  form?: any
  isDisabled?: boolean
  disableBuyer?: boolean
}

function getOptionLabel({ is_verified, full_name }) {
  return `${full_name} | ${
    is_verified === false
      ? "Rejected"
      : is_verified === true
      ? "Verified"
      : "Pending Verification"
  }`
}
export const WhoIsTrading = ({
  form,
  inputProps,
  showBuyer = true,
  isDisabled,
  disableBuyer,
}: WhoIsTradingProps) => {
  return (
    <FormCard title="Who is trading">
      <div className="flex gap-4">
        <div className={"w-1/2"}>
          <FormQuerySelector
            required
            isDisabled={isDisabled}
            label="Farmer"
            className={"mb-2"}
            serverSideQuery
            dataHook={useUsersQuery}
            variables={{ groups: ["Farmer", "Trader"] }}
            getOptionLabel={getOptionLabel}
            {...inputProps("seller", {
              group: SharedEntityGroup.KiteUser,
            })}
          />
          <UserDetailsCard user={form.values.seller} />
        </div>
        {showBuyer && (
          <div className={"w-1/2"}>
            <FormQuerySelector
              required={true}
              isDisabled={disableBuyer ?? isDisabled}
              label="Trader"
              className={"mb-2"}
              serverSideQuery
              dataHook={useUsersQuery}
              variables={{ groups: ["Trader"] }}
              getOptionLabel={getOptionLabel}
              {...inputProps("buyer", {
                group: SharedEntityGroup.KiteUser,
              })}
            />

            <UserDetailsCard user={form.values.buyer} />
          </div>
        )}
      </div>
    </FormCard>
  )
}
