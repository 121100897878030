import { MapPin } from "@phosphor-icons/react"
import { Polygon } from "@react-google-maps/api"
import { FormSelector, Toast } from "@vesatogo/grass-core"
import { useRef } from "react"
import toast from "react-hot-toast"
import CustomDrawingManager from "~/components/Map/DrawingManager"
import Map from "~/components/Map/Map"
import MandiFormSections from "~/components/Registration/FormSections/Mandi/MandiFormSections"
import { SectionCard } from "~/components/Registration/SectionCard"
import { MandiSectionCommonType } from "~/constants/registration/MandiRegistrationFormSections"
import { COLOURS } from "~/constants/static.items"
import { useUpdateMarketMutation } from "~/generated/graphql"
import { Mohadi } from "~/utils/location.data"
import { jsonToPointMany, pointToJsonMany } from "~/utils/map-helpers"

type AreaServedType = MandiSectionCommonType & {}
export const AreaServed = ({ form }: AreaServedType) => {
  const polyRef = useRef<google.maps.Polygon>()
  const areasPolygon = form?.values?.area?.area_catered?.coordinates?.[0]
  const [{ fetching }, updateMarket] = useUpdateMarketMutation()
  async function handleSubmit() {
    const areaPolygon = areasPolygon?.[0]
    try {
      if (!areaPolygon) throw new Error("Please select a valid area polygon")
      if (!form.values.area?.color?.id)
        throw new Error("Please select a valid color")

      const { data } = await updateMarket({
        pk_columns: {
          id: form.values?.id,
        },
        _set: {
          area_catered: form?.values?.area?.area_catered,
          area_color: form.values.area?.color?.id,
        },
        _inc: {},
      })
      if (data?.update_market_by_pk) {
        toast.custom(<Toast title="Area Served Updated Successfully" />)
      }
    } catch (error: any) {
      toast.custom(<Toast intent="danger" title={error.message} />)
    }
  }
  return (
    <SectionCard
      icon={MapPin}
      title={"Area Served"}
      cardClassName="h-[80vh] overflow-auto"
      headerClassName="border-b-1 border-gray-300"
      className="flex flex-col gap-4"
    >
      <SectionCard
        title="Color"
        className="grid grid-cols-3 gap-4 "
        cardClassName="!rounded-lg"
        headerClassName="bg-gray-200"
      >
        <FormSelector
          getOptionLabel={item => (
            <span className="flex gap-1 items-center">
              <div
                style={{
                  height: 12,
                  width: 12,
                  background: item.id,
                  borderRadius: 4,
                }}
              />{" "}
              {item.name}
            </span>
          )}
          label="Color"
          required
          options={COLOURS}
          {...form.getInputProps("area.color")}
        />
        <Toast
          intent="info"
          title={
            <div>
              Please Note:{" "}
              <span>
                All polygons under this Mandi will be colored in this shade.
              </span>
            </div>
          }
          variant="minimal"
          className="font-400 max-w-full text-sm col-span-2 max-h-10 self-end"
        />
      </SectionCard>
      <SectionCard
        className="!p-0"
        title="Create Map"
        subtitle="The boundaries created here will allow users to place orders in this areas only."
        headerClassName="bg-gray-200"
        cardClassName="!rounded-lg"
      >
        <Map
          center={
            form?.values?.area?.area_catered?.coordinates?.[0]?.length
              ? {
                  lat: form?.values?.area?.area_catered
                    ?.coordinates?.[0]?.[0]?.[0]?.[1],
                  lng: form?.values?.area?.area_catered
                    ?.coordinates?.[0]?.[0]?.[0]?.[0],
                }
              : Mohadi
          }
          zoom={7}
          id="map-2"
          mapContainerStyle={{
            height: "25rem",
            width: "100%",
            borderBottomLeftRadius: "0.5rem",
            borderBottomRightRadius: "0.5rem",
          }}
        >
          <Polygon
            onLoad={poly => {
              polyRef.current = poly
            }}
            onMouseUp={e => {
              const newPolygon = jsonToPointMany(
                polyRef.current
                  ?.getPath()
                  ?.getArray()
                  ?.map(a => a.toJSON()) as any
              )
              form.setFieldValue(
                "area.area_catered.coordinates.0.0",
                newPolygon
              )
            }}
            editable
            options={{
              fillColor: form.values?.area?.color?.id || "yellow",
              strokeColor: form.values?.area?.color?.id || "yellow",
              fillOpacity: 0.3,
              strokeOpacity: 0.3,
            }}
            path={pointToJsonMany(areasPolygon?.[0]) as any}
          />
          <CustomDrawingManager
            onPolyComplete={({ coordinates }) => {
              form.setFieldValue(
                "area.area_catered.coordinates.0.0",
                jsonToPointMany(coordinates)
              )
            }}
            drawingMode={["polygon"]}
          />
        </Map>
      </SectionCard>
      <MandiFormSections onSubmit={handleSubmit} loading={fetching} />
    </SectionCard>
  )
}
