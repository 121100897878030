import { DrawingManager } from '@react-google-maps/api';
import { v4 as uuid4 } from 'uuid';

export interface CustomDrawingManagerProps {
  onPolyComplete?: any;
  onCircleComplete?: any;
  onRectangleComplete?: any;
  drawingMode?: any;
}

const CustomDrawingManager = ({
  onPolyComplete,
  onCircleComplete,
  onRectangleComplete,
  drawingMode
}: CustomDrawingManagerProps) => {
  function handlePolylineComplete(e) {
    // Hide the default polygon, since we are going to map our own state based polygons
    e.setVisible(false);

    const bounds = new window.google.maps.LatLngBounds();
    const path = e.getPath();
    const area = window.google.maps.geometry.spherical.computeArea(path);
    const polyArray: any[] = [];
    for (let i = 0; i < path.getLength(); i++) {
      const latlng = path.getAt(i)?.toJSON();
      bounds.extend(latlng);
      polyArray.push(latlng);
    }
    const center = bounds?.getCenter()?.toJSON();

    onPolyComplete &&
      onPolyComplete({
        // Helps us close the polygon
        coordinates: polyArray.concat(polyArray?.[0]),
        area,
        center,
        id: uuid4()
      });
  }

  function handleCircleComplete(e) {
    onCircleComplete &&
      onCircleComplete({ radius: e.radius, center: e.getCenter()?.toJSON() });
  }

  function handleRectangleComplete(e) {
    const NE = e.bounds.getNorthEast();
    const SW = e.bounds.getSouthWest();
    // North West
    const NW = new window.google.maps.LatLng(NE.lat(), SW.lng());
    // South East
    const SE = new window.google.maps.LatLng(SW.lat(), NE.lng());

    onRectangleComplete &&
      onRectangleComplete({
        NE: NE.toJSON(),
        SW: SW.toJSON(),
        NW: NW.toJSON(),
        SE: SE.toJSON()
      });
  }

  return (
    <DrawingManager
      options={{
        drawingControlOptions: {
          drawingModes: drawingMode,
          position: window.google.maps.ControlPosition.RIGHT_BOTTOM
        }
      }}
      onRectangleComplete={handleRectangleComplete}
      onPolygonComplete={handlePolylineComplete}
      onPolylineComplete={handlePolylineComplete}
      onCircleComplete={handleCircleComplete}
    />
  );
};

export default CustomDrawingManager;
