import { UseFormReturnType } from "@mantine/form"
import { OperationContext } from "urql"
import { AreaServed } from "~/components/Registration/FormSections/Mandi/AreaServed"
import { License } from "~/components/Registration/FormSections/Mandi/License"
import { MandiBasicDetails } from "~/components/Registration/FormSections/Mandi/MandiBasicDetails"
import Operational from "~/components/Registration/FormSections/Mandi/Operational"
import { POCDetail } from "~/components/Registration/FormSections/Trader/POCDetail"
import { MANDI_INITIAL_STATE } from "~/pages/d/admin/mandi-master/[id]"

export type FormTabType = {
  title: string
  id: string
  component?: any
  isActive?: boolean
}

export type MandiSectionCommonType = {
  form: UseFormReturnType<Partial<typeof MANDI_INITIAL_STATE>>
  entity?: "farmer" | "trader" | "business"
  refetch?: (opts?: Partial<OperationContext> | undefined) => void
}
export const MandiRegistrationFormSections: FormTabType[] = [
  {
    title: "Basic Details",
    id: "basic",
    component: MandiBasicDetails,
    isActive: true,
  },
  {
    title: "POC Details (optional)",
    id: "poc",
    component: POCDetail,
  },
  {
    title: "Operational Details",
    id: "operational",
    component: Operational,
  },
  {
    title: "License Details",
    id: "license",
    component: License,
  },
  {
    title: "Area Served",
    id: "area",
    component: AreaServed,
  },
]
