import { useDebouncedValue } from "@mantine/hooks"
import { Circle, MagnifyingGlass, Plus } from "@phosphor-icons/react"
import { Avatar, Button, InputGroup, Tooltip } from "@vesatogo/grass-core"
import { Cell, TableView } from "@vesatogo/grass-table"
import dayjs from "dayjs"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { siteConfig } from "~/constants/config"
import { AppRoutes } from "~/constants/routes"
import { Order_By, useMarketListQuery } from "~/generated/graphql"
import { config } from "~/pages/pavti/config"
import { formatDate } from "~/pages/pavti/lib/utils"

export const MandiMaster = () => {
  const navigate = useNavigate()
  const [params, setParams] = useSearchParams()
  const search = params.get("search")
  const page = Number(params.get("page")) || 1
  const [debouncedSearch] = useDebouncedValue(
    search?.trim(),
    siteConfig.debounce
  )
  const [{ data, fetching }] = useMarketListQuery({
    variables: {
      limit: config.pageSize,
      offset: (page - 1) * config.pageSize,
      order_by: {
        created_at: Order_By.DescNullsLast,
        name: Order_By.AscNullsLast,
      },
      where: {
        name: {
          _ilike: debouncedSearch ? `%${debouncedSearch}%` : "%%",
        },
      },
    },
  })
  const totalMandis = data?.market_aggregate?.aggregate?.count || 0

  const COLUMNS = [
    {
      accessor: "id",
      className: "!text-center",
      Header: "Sr. No.",
      Cell({ row }) {
        return row.index + 1
      },
    },
    {
      Header: "Mandi Name",
      accessor: "name",
      Cell({ value, row: { original } }) {
        return (
          <Link
            className="link-text text-left"
            to={`${AppRoutes.mandiMaster}/${original.id}`}
          >
            {value}
          </Link>
        )
      },
    },
    {
      Header: "Mandi Type",
      accessor: "market_kind",
      Cell({ value }) {
        if (!value) return "na"
        return value?.name
      },
    },

    {
      Header: "Address",
      accessor: "address",
      Cell({ value }) {
        let address = ""
        if (value?.village) address += value?.village
        if (value?.taluka) address += ", " + value?.taluka
        return address
      },
    },
    {
      Header: "POC Name",
      accessor: "poc.full_name",
      Cell({ value }) {
        return <Cell title={value} />
      },
    },
    {
      Header: "POC number",
      accessor: "poc.number",
      Cell({ value }) {
        return <Cell title={value} />
      },
    },
    {
      Header: "License",
      accessor: "documents",
      Cell({ value }) {
        const privateMarketplace = value?.find(v =>
          v?.kind?.name?.includes("Private")
        )
        const doc = privateMarketplace
        if (!doc) return "--"
        return (
          <Cell
            title={doc?.kind?.name}
            subtitle={formatDate(doc?.valid_till)}
          />
        )
      },
    },
    {
      Header: "Verification Status",
      accessor: "is_verified",
      Cell({ value }) {
        return (
          <div className="flex gap-1 items-center">
            <Circle
              size={14}
              weight="fill"
              className={
                value === null
                  ? "text-yellow-400"
                  : value
                  ? "text-green -400"
                  : "text-red-400"
              }
            />{" "}
            {value === null ? "Pending" : value ? "Verified" : "Not Verified"}
          </div>
        )
      },
    },
    {
      Header: "Created By",
      accessor: "created_by",
      Cell({ value, row }) {
        if (!value) return "--"
        return (
          <Tooltip
            content={
              <div>
                <p>{value?.full_name || "na"}</p>
                <p className="text-gray-400">
                  {dayjs(row?.original?.created_at).format(
                    "DD MMM 'YY | h:mm a"
                  )}
                </p>
              </div>
            }
          >
            <Avatar
              className="relative text-center"
              name={value?.full_name}
              src={value?.image?.url}
            />
          </Tooltip>
        )
      },
    },
  ]

  return (
    <>
      <header className="flex justify-between p-2 border-b-1 border-b-gray-300">
        <div className="self-center font-500 pl-4">Mandis</div>
        <div className="w-[500px] flex items-center gap-3">
          <InputGroup
            className={"w-full"}
            inputProps={{
              value: search || "",
              placeholder: "Search",

              onChange(e) {
                params.set("search", e.target.value)
                setParams(params)
              },
            }}
            leftElement={<MagnifyingGlass />}
          />
        </div>
        <div className="flex gap-3">
          <Button onClick={() => navigate("new")} leftIcon={<Plus />}>
            Mandi
          </Button>
        </div>
      </header>
      <TableView
        isLoading={fetching}
        relative={false}
        paginationProps={{
          total: Math.ceil(totalMandis / config.pageSize),
          page: page,
          onChange(page) {
            params.set("page", page.toString())
            params.set("search", "")
            setParams(params)
          },
        }}
        columns={COLUMNS}
        getCellProps={cell => {
          if (cell?.column?.Header === "Sr. No.") {
            return {
              className: "!text-center",
            }
          }
        }}
        data={data?.market || []}
        meta={<>{totalMandis} Mandis</>}
      />
    </>
  )
}

export default MandiMaster
