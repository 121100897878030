import * as Yup from "yup"

export const baseAddressModel = Yup.object().shape({
  village: Yup.string()
    .min(2, "minimum 2 characters required")
    .required("Please enter the village name"),
  taluka: Yup.string().required("Please select a taluka"),
  local_area: Yup.string().required("Please enter local area"),
  district: Yup.string().required("Please select a district"),
  pincode: Yup.string()
    .matches(/^[0-9]{6}$/, "Please enter valid pincode")
    .transform(v => (v === "" ? null : v))
    .required("Please provide the pincode"),
})
