import { Hourglass, Plus, Trash } from "@phosphor-icons/react"
import {
  Button,
  Checkbox,
  FormInput,
  FormSelector,
  Toast,
} from "@vesatogo/grass-core"
import { omit } from "lodash-es"
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import MandiFormSections from "~/components/Registration/FormSections/Mandi/MandiFormSections"
import { SectionCard } from "~/components/Registration/SectionCard"
import { MandiSectionCommonType } from "~/constants/registration/MandiRegistrationFormSections"
import { TIME_SLOTS } from "~/constants/static.items"
import {
  Time_Slot_Constraint,
  Time_Slot_Update_Column,
  useDeleteTimeSlotMutation,
  useInsertTimeSlotsMutation,
  useUpdateMarketMutation,
} from "~/generated/graphql"

const DayAbbreviation = {
  mon: "Monday",
  tue: "Tuesday",
  wed: "Wednesday",
  thu: "Thursday",
  fri: "Friday",
  sat: "Saturday",
  sun: "Sunday",
}

const Operational = ({ form }: MandiSectionCommonType) => {
  const days = omit(form.values.operational, "timeSlots")

  const timeSlots = form.values?.operational?.timeSlots
  const navigate = useNavigate()
  const [{ fetching }, updateMarket] = useUpdateMarketMutation()
  const [, deleteTimeSlot] = useDeleteTimeSlotMutation()
  const [, insertTimeSlots] = useInsertTimeSlotsMutation()
  async function handleSubmit() {
    const id = form.values.id || undefined
    const { data, error } = await updateMarket({
      pk_columns: {
        id,
      },
      _set: {
        ...days,
      },
      _inc: {},
    })
    if (!timeSlots?.length) return
    await insertTimeSlots({
      objects: timeSlots.map(ts => ({
        id: ts.id,
        from_time: ts.from_time?.id,
        to_time: ts.to_time?.id,
        name: ts.name,
        market_id: ts?.market_id,
      })),
      on_conflict: {
        constraint: Time_Slot_Constraint.TimeSlotPkey,
        update_columns: [
          Time_Slot_Update_Column.FromTime,
          Time_Slot_Update_Column.ToTime,
          Time_Slot_Update_Column.Name,
          Time_Slot_Update_Column.MarketId,
        ],
      },
    })
    if (error?.message)
      return toast.custom(<Toast intent="danger" title={error?.message} />)
    if (data?.update_market_by_pk?.id) {
      toast.custom(<Toast title={"Operational Details saved successfully"} />)
      navigate(`/d/admin/mandi/${data.update_market_by_pk?.id}`)
      return
    }
  }
  return (
    <SectionCard
      cardClassName="min-h-[80vh] overflow-auto"
      icon={Hourglass}
      title={"Operational Detail"}
      className="flex flex-col gap-4"
    >
      <SectionCard
        title="Days Of Operation"
        className="flex gap-4"
        cardClassName="!rounded-lg"
        headerClassName="bg-gray-200"
      >
        {Object.keys(days).map(key => {
          return (
            <Checkbox
              value={key}
              label={DayAbbreviation[key]}
              checked={days[key]}
              onChange={(checked: any) => {
                form.setFieldValue(`operational.${key}`, checked)
              }}
            />
          )
        })}
      </SectionCard>
      <SectionCard
        title="Operational Slots"
        className="flex flex-col gap-4"
        cardClassName="!rounded-lg"
        headerClassName="bg-gray-200"
        header={
          <Button
            leftIcon={<Plus />}
            variant="ghost"
            size="sm"
            onClick={() => {
              form.insertListItem("operational.timeSlots", {
                market_id: form.values.id,
                from_time: timeSlots?.length
                  ? timeSlots?.[timeSlots?.length - 1]?.to_time
                  : null,
                codename: Date.now(),
              })
            }}
          >
            Time Slot
          </Button>
        }
      >
        {timeSlots?.length ? (
          timeSlots?.map((ts, idx) => {
            return (
              <div key={ts.id || ts.codename} className="flex gap-3">
                <FormInput
                  className={"w-2/3"}
                  label="Name"
                  placeholder="Time Slot Name"
                  {...form.getInputProps(`operational.timeSlots.${idx}.name`)}
                />
                <div className="flex gap-3 w-full">
                  <FormSelector
                    required
                    className={"w-full"}
                    label="From"
                    options={TIME_SLOTS}
                    {...form.getInputProps(
                      `operational.timeSlots.${idx}.from_time`
                    )}
                  />
                  <FormSelector
                    required
                    className={"w-full"}
                    label="To"
                    options={TIME_SLOTS}
                    {...form.getInputProps(
                      `operational.timeSlots.${idx}.to_time`
                    )}
                    isOptionDisabled={(option: any) => {
                      return option.hour <= ts.from_time?.hour
                    }}
                  />
                </div>
                <div className="flex items-end w-full">
                  <Button
                    onClick={async () => {
                      if (ts.id) {
                        await deleteTimeSlot({ id: ts.id })
                      } else {
                        form.removeListItem("operational.timeSlots", idx)
                      }
                    }}
                    intent="danger"
                    variant="minimal"
                  >
                    <Trash />
                  </Button>
                </div>
              </div>
            )
          })
        ) : (
          <p>No time slots available, please add a time slot</p>
        )}
      </SectionCard>
      <MandiFormSections loading={fetching} onSubmit={handleSubmit} />
    </SectionCard>
  )
}

export default Operational
