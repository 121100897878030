import { ITable } from "~/pages/pavti/lib/types"

export function Table<T>({ data, columns }: ITable<T>) {
  return (
    <table className="table-auto w-full">
      <thead>
        <tr className="border-b flex gap-2 px-2">
          {columns.map(({ Heading, flex = "1" }, i) => (
            <th
              key={i}
              className="text-sm text-left text-gray-500 font-400 py-1"
              style={{ flex }}
            >
              {Heading}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, i) => (
          <tr key={i} className="flex gap-2 px-2">
            {columns.map(({ accessor, Cell, flex = "1" }, i) => (
              <td key={i} className="align-top py-1" style={{ flex: flex }}>
                {Cell ? Cell(row) : row[accessor]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}
