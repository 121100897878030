import { useForm } from "@mantine/form"
import { CheckCircle } from "@phosphor-icons/react"
import { Breadcrumbs } from "@vesatogo/grass-core"
import classNames from "classnames"
import { isArray, pick } from "lodash-es"
import { useEffect, useMemo, useState } from "react"
import { Link, useParams } from "react-router-dom"
import VerifyAsset from "~/components/VerifyAsset"
import Codenames from "~/constants/Codenames"
import { MandiRegistrationFormSections } from "~/constants/registration/MandiRegistrationFormSections"
import { AppRoutes } from "~/constants/routes"
import { COLOURS, TIME_SLOTS } from "~/constants/static.items"
import {
  useMarketDetailQuery,
  useUpdateMarketMutation,
} from "~/generated/graphql"

export const MANDI_INITIAL_STATE = {
  id: null as any,
  is_verified: null as any,
  basic: {
    id: undefined,
    name: "",
    kind: {} as any,
    address: {} as any,
    codename: "",
  },
  poc: {
    id: null as any,
    first_name: "",
    last_name: "",
    username: null as any,
    number: "",
    email: "",
    address: {} as any,
    image: {},
    aadhar: {
      attachments: [],
    } as any,
    pan: {
      attachments: [],
    } as any,
  },
  operational: {
    mon: true,
    tue: true,
    wed: true,
    thu: true,
    fri: true,
    sat: true,
    sun: true,
    timeSlots: [] as any[],
  },
  area: {
    color: null as any,
    area_catered: {
      type: "MultiPolygon",
      coordinates: [[]],
    } as any,
  },
  license: {
    attachments: [],
  } as any,
}
export const MandiDetail = () => {
  const { id } = useParams()
  const idNum = Number(id) ? Number(id) : null
  const [activeSection, setActiveSection] = useState<any>("basic")
  const [{ data, fetching }, refetch] = useMarketDetailQuery({
    pause: !idNum,
    variables: {
      id: idNum,
    },
  })
  const form = useForm<Partial<typeof MANDI_INITIAL_STATE>>({
    initialValues: MANDI_INITIAL_STATE,
    validateInputOnChange: false,
    validateInputOnBlur: true,
    clearInputErrorOnChange: false,
  })

  useEffect(() => {
    if (data?.market_by_pk?.id) {
      const mandi = data.market_by_pk
      const pocAadhar = mandi.poc?.documents?.find(
        doc => doc?.kind?.codename === Codenames.AADHAR
      )
      const pocPan = mandi.poc?.documents?.find(
        doc => doc?.kind?.codename === Codenames.PAN_CARD
      )
      const licence = mandi?.documents?.[0]
      form.setValues({
        id: mandi.id,
        is_verified: mandi.is_verified,
        basic: {
          id: mandi?.id,
          name: mandi.name,
          kind: mandi.market_kind,
          address: mandi.address,
          codename: mandi.codename,
        },
        poc: {
          id: mandi.poc?.id,
          username: mandi?.poc?.username,
          first_name: mandi.poc?.first_name || "",
          last_name: mandi.poc?.last_name || "",
          number: mandi.poc?.number || "",
          email: mandi.poc?.email || "",
          address: mandi.poc?.address || {},
          image: mandi.poc?.image ? [mandi.poc?.image] : [],
          aadhar:
            {
              ...pocAadhar,
              m2m_id: pocAadhar?.id,
              attachments: pocAadhar?.attachments?.length
                ? pocAadhar?.attachments
                : [],
            } || {},
          pan:
            {
              ...pocPan,
              m2m_id: pocPan?.id,
              attachments: pocPan?.attachments?.length
                ? pocPan?.attachments
                : [],
            } || {},
        },
        operational: {
          ...pick(mandi, ["mon", "tue", "wed", "thu", "fri", "sat", "sun"]),
          timeSlots: mandi.time_slots
            ? mandi?.time_slots?.map(ts => ({
                ...ts,
                from_time: TIME_SLOTS.find(time => ts.from_time === time.id),
                to_time: TIME_SLOTS.find(time => ts.to_time === time.id),
              }))
            : [],
        },
        license: {
          ...licence,
          attachments: licence?.attachments?.length ? licence.attachments : [],
          m2m_id: licence?.id,
        },
        area: {
          area_catered: mandi.area_catered?.coordinates
            ? mandi.area_catered
            : {
                type: "MultiPolygon",
                coordinates: [[]],
              },
          color: COLOURS.find(c => c.id === mandi.area_color),
        },
      })
    }
  }, [data])

  const sectionsFilledMap = useMemo(() => {
    return MandiRegistrationFormSections.reduce((prev, section) => {
      return {
        ...prev,
        [section.id as any]:
          section.id === "documents"
            ? form.values?.[section?.id as any]?.gstin?.id &&
              form.values?.[section?.id as any]?.license?.id &&
              form.values?.[section?.id as any]?.pan?.id
              ? true
              : false
            : section.id === "operational"
            ? form.values?.[section?.id as any]?.timeSlots?.length > 0
              ? true
              : false
            : section.id === "area"
            ? form.values?.[section?.id as any]?.color?.id
              ? true
              : false
            : isArray(form.values?.[section?.id as any])
            ? form.values?.[section?.id as any]?.[0]?.id
            : form.values?.[section?.id as any]?.id || false,
      }
    }, {})
  }, [fetching, form.values])
  const allSectionsFilled = Object.values(sectionsFilledMap)?.every(e => e)
  return (
    <>
      <header className="bg-gray-100 shadow-sm p-3 border-b-gray-300 border-b flex justify-between">
        <Breadcrumbs
          items={[
            {
              text: "Mandis",
              link: AppRoutes.mandiMaster,
              isActive: false,
            },
            {
              text: form?.values?.basic?.name || id?.toString() || "New",
              link: `${AppRoutes.mandiMaster}/${id}`,
              isActive: true,
            },
          ]}
          linkRenderer={(link, item) => {
            return <Link to={link as any}>{item}</Link>
          }}
        />
      </header>
      <div className="p-4 ">
        <div className="max-w-6xl mx-auto p-4 flex gap-4 w-full">
          <div className="w-[20%] flex flex-col justify-between rounded-xl bg-white py-4 h-[80vh] overflow-auto">
            <div className="">
              {MandiRegistrationFormSections?.map(section => {
                const isSectionFilled = sectionsFilledMap[section.id]
                return (
                  <div
                    key={section?.id}
                    className={classNames(
                      "p-2 flex gag-2 items-center justify-between",
                      activeSection === section.id && "text-green bg-gray-200",
                      !form.values.id && section.id !== "basic"
                        ? "pointer-events-none text-gray-300"
                        : "cursor-pointer"
                    )}
                    onClick={() => {
                      setActiveSection(section.id)
                    }}
                  >
                    {section.title}{" "}
                    {isSectionFilled && (
                      <CheckCircle
                        className="text-green"
                        weight="fill"
                        size={20}
                      />
                    )}
                  </div>
                )
              })}
            </div>
          </div>
          <div className="w-[80%]">
            {MandiRegistrationFormSections?.map(section => {
              const Component = section.component
              if (section.id === activeSection)
                return (
                  <Component
                    disabled={section.id !== "basic" && !form.values.id}
                    form={form}
                    key={section?.id}
                    refetch={refetch}
                  />
                )
            })}
          </div>
        </div>
      </div>
      {allSectionsFilled && (
        <div className="bg-white p-3">
          <VerifyAsset
            id={form?.values?.id}
            is_verified={form?.values?.is_verified}
            useUpdateHook={useUpdateMarketMutation}
          />
        </div>
      )}
    </>
  )
}

export default MandiDetail
