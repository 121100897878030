import { useHotkeys } from "@mantine/hooks"
import {
  ArrowClockwise,
  ArrowCounterClockwise,
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  ArrowsClockwise,
  Download,
  Eye,
  FileDoc,
  FileImage,
  FilePdf,
  FileVideo,
  Lock,
  Minus,
  Plus,
  TrashSimple,
} from "@phosphor-icons/react"
import { Button, Modal, Progress, Tooltip } from "@vesatogo/grass-core"
import { getExtension } from "@vesatogo/utils"
import classNames from "classnames"
import { saveAs } from "file-saver"
import React, { useEffect, useState } from "react"
import { useDropzone } from "react-dropzone"
import { PanViewer } from "react-image-pan-zoom-rotate"
import { useClient } from "urql"
import { usePanImageHook } from "~/components/dropzone/usePanImageHook"
import { IMAGE_DETAIL } from "~/graphql/image.gql"
import styles from "./FileInput.module.css"
export type FileInputProps = FilePreviewProps & {
  className?: string
  media?: any[]
  maxFiles?: number
  multiple?: boolean
  onChange?: (acceptedFiles: File[]) => void
  onDelete?: (file: any) => void
  uploadCardClassName?: string
  uploadPlaceHolder?: string
  uploadImage?: React.ReactNode
}

type FilePreviewProps = {
  file?: any
  onDelete?: (file: any) => void
  resolveSrc?: (file: any) => string
  showPreviewForDocument?: boolean
  summary?: React.ReactNode
  decryptMediaMutation?: any
  cdnBaseUrl?: any
}
const FilePreview = ({
  file,
  onDelete,
  summary,
  decryptMediaMutation,
  cdnBaseUrl = typeof process !== "undefined"
    ? process.env["NX_CDN_BASE_URL"]
    : null,
}: FilePreviewProps) => {
  const [isOpen, setOpen] = useState(false)
  const [url, setUrl] = useState(file?.url)
  const client = useClient()
  const type = file?.kind?.includes("image")
    ? "image"
    : file?.kind?.includes("pdf")
    ? "pdf"
    : file?.kind?.includes("video")
    ? "video"
    : "file"

  const [previewError, setPreviewError] = useState(false)
  const {
    panState,
    setPanState,
    zoomIn,
    zoomOut,
    rotateLeft,
    rotateRight,
    pan,
    resetAll,
  } = usePanImageHook()

  useHotkeys([
    ["=", zoomIn],
    ["-", zoomOut],
    ["ArrowLeft", rotateLeft],
    ["ArrowRight", rotateRight],
    ["ArrowUp", zoomIn],
    ["ArrowDown", zoomOut],
    ["r", resetAll],
    ["R", resetAll],
  ])

  return (
    <>
      <div className={classNames(styles["preview-card"], "group relative")}>
        {file?.progress && file?.progress > 0 && file?.progress < 100 ? (
          <div className="flex flex-col items-center p-2 space-y-2">
            <span className="text-sm text-gray-600">Uploading...</span>
            <Progress value={file?.progress} size={"xs"} />
          </div>
        ) : (
          <>
            <div className="flex flex-col items-center p-2 group-hover:brightness-50">
              {previewError || file?.is_encrypted ? (
                <div className="bg-gray-300">
                  {file?.is_encrypted ? (
                    <Lock className="h-28 w-28 object-cover text-red-500" />
                  ) : type === "image" ? (
                    <FileImage className="h-28 w-28 object-cover text-red-500" />
                  ) : type === "video" ? (
                    <FileVideo className="h-28 w-28 object-cover text-red-500" />
                  ) : type === "pdf" ? (
                    <FilePdf className="h-28 w-28 object-cover text-red-500" />
                  ) : (
                    <FileDoc className="h-28 w-28 object-cover text-red-500" />
                  )}
                </div>
              ) : (
                <img
                  className="h-28 w-28 object-cover"
                  src={url}
                  onError={() => setPreviewError(true)}
                />
              )}
            </div>

            <div className="absolute m-auto inset-auto flex items-center text-white z-20 invisible group-hover:visible peer">
              {url || file?.is_encrypted ? (
                <Eye
                  size={20}
                  className="mr-1 text-white cursor-pointer"
                  onClick={async () => {
                    try {
                      if (file?.is_encrypted) {
                        const { data } = await client
                          .query(IMAGE_DETAIL, {
                            id: file?.id,
                          })
                          .toPromise()

                        setUrl(data?.media_by_pk?.private_url)
                        setOpen(true)
                      } else {
                        if (type !== "image") {
                          window.open(url, "_blank", "noopener,noreferrer")
                          return
                        }
                        setOpen(true)
                      }
                    } catch (e) {
                      console.error("Error" + e)
                    }
                  }}
                />
              ) : (
                ""
              )}
              {onDelete && (
                <TrashSimple
                  size={20}
                  className="ml-1 text-white cursor-pointer"
                  onClick={() => onDelete(file)}
                />
              )}
            </div>
          </>
        )}
      </div>

      <Modal
        contentClassName="flex justify-center h-[80vh] overflow-hidden relative"
        footerClassName="flex gap-3"
        bodyClassName="overflow-auto max-w-3xl"
        footer={
          <>
            <Tooltip
              content={
                <div className="text-center">
                  <p className="font-500">
                    Zoom out (
                    <span>
                      {" "}
                      - or <ArrowDown weight="bold" className="inline" />{" "}
                    </span>
                    )
                  </p>
                </div>
              }
            >
              <Button
                variant="outline"
                size="sm"
                onClick={zoomOut}
                leftIcon={<Minus />}
              />
            </Tooltip>
            <Tooltip
              content={
                <div className="text-center">
                  <p className="font-500">
                    Zoom in (
                    <span>
                      {" "}
                      + or <ArrowUp weight="bold" className="inline" />{" "}
                    </span>
                    )
                  </p>
                </div>
              }
            >
              <Button
                variant="outline"
                size="sm"
                onClick={zoomIn}
                leftIcon={<Plus />}
              />
            </Tooltip>
            <Tooltip
              content={
                <div className="text-center">
                  <p className="font-500">
                    Rotate Right (
                    <span>
                      {" "}
                      <ArrowRight weight="bold" className="inline" />{" "}
                    </span>
                    )
                  </p>
                </div>
              }
            >
              <Button
                variant="outline"
                size="sm"
                onClick={rotateRight}
                leftIcon={<ArrowClockwise />}
              />
            </Tooltip>
            <Tooltip
              content={
                <div className="text-center">
                  <p className="font-500">
                    Rotate left (
                    <span>
                      {" "}
                      <ArrowLeft weight="bold" className="inline" />{" "}
                    </span>
                    )
                  </p>
                </div>
              }
            >
              <Button
                size="sm"
                variant="outline"
                onClick={rotateLeft}
                leftIcon={<ArrowCounterClockwise />}
              />
            </Tooltip>
            <Tooltip
              content={
                <div className="text-center">
                  <p className="font-500">Reset all ( R )</p>
                </div>
              }
            >
              <Button
                variant="outline"
                size="sm"
                onClick={resetAll}
                leftIcon={<ArrowsClockwise />}
              />
            </Tooltip>
          </>
        }
        primaryActionButtonProps={{
          text: "Download",
          leftIcon: <Download />,
          size: "sm",
          onClick() {
            saveAs(url, `${Date.now()}.${getExtension(url)}`)
          },
        }}
        title={file?.name || "Preview File"}
        isOpen={isOpen}
        onClose={() => setOpen(false)}
      >
        <div className="m-auto">
          {summary}

          <PanViewer
            className="w-full h-full justify-center items-center "
            zoom={panState.zoom}
            setZoom={val => {
              setPanState(prev => ({ ...prev, zoom: val }))
            }}
            pandx={panState.dx}
            pandy={panState.dy}
            onPan={pan}
            rotation={panState.rotation}
            key={panState.dx}
          >
            <img
              style={{
                rotate: `${panState.rotation * 90}deg`,
              }}
              className="h-full w-full overflow-hidden m-auto"
              src={url}
              alt={file?.alt || ""}
            />
          </PanViewer>
        </div>
      </Modal>
    </>
  )
}

export const FileInput = ({
  className,
  media,
  maxFiles = 8,
  onChange,
  multiple = true,
  onDelete,
  resolveSrc,
  showPreviewForDocument = false,
  uploadCardClassName = "h-32 w-32",
  uploadPlaceHolder = "Upload",
  uploadImage = <Plus />,
  summary,
  decryptMediaMutation,
  cdnBaseUrl,
}: FileInputProps) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    multiple,
    maxFiles,
  })

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      onChange && onChange(acceptedFiles)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles])

  return (
    <div
      data-testid="upload"
      className={classNames(
        styles["base"],
        maxFiles > 1 && styles["multiple"],
        className
      )}
    >
      {media?.map?.(file => (
        <FilePreview
          key={file?.url}
          file={file}
          onDelete={onDelete}
          resolveSrc={resolveSrc}
          showPreviewForDocument={showPreviewForDocument}
          summary={summary}
          cdnBaseUrl={cdnBaseUrl}
          decryptMediaMutation={decryptMediaMutation}
        />
      ))}
      {(media as any)?.length < maxFiles && (
        <div
          {...getRootProps({
            className: classNames(
              styles["upload-card"],
              "dropzone",
              uploadCardClassName
            ),
          })}
        >
          <input data-testid="uploadFile" {...getInputProps()} />
          <div className="flex flex-col items-center">
            {uploadImage}
            <span className="text-sm mt-2.5">{uploadPlaceHolder}</span>
          </div>
        </div>
      )}
    </div>
  )
}
