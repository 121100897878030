import { Icon, IconBase, IconWeight } from "@phosphor-icons/react"
import React, { forwardRef } from "react"

// Define your custom weights and SVG contents
const customWeights: Map<IconWeight, React.ReactElement> = new Map([
  ["fill", <></>],
  // Replace with your custom thin icon content
  [
    "regular",
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M171.368 180.672C183.228 180.672 192.842 171.107 192.842 159.308C192.842 147.508 183.228 137.943 171.368 137.943C159.509 137.943 149.895 147.508 149.895 159.308C149.895 171.107 159.509 180.672 171.368 180.672ZM171.368 187.793C187.181 187.793 200 175.04 200 159.308C200 143.575 187.181 130.822 171.368 130.822C155.556 130.822 142.737 143.575 142.737 159.308C142.737 175.04 155.556 187.793 171.368 187.793Z"
      />
      <path d="M171.368 145.065L175.669 154.063L185.684 155.945L177.819 163.061L180.216 173.55L171.368 167.56L162.521 173.55L164.917 163.061L157.053 155.945L167.067 154.063L171.368 145.065Z" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M107.119 32.2785C111.315 28.7997 117.685 31.7682 117.685 37.2022V63.8791C117.685 67.4188 114.801 70.2884 111.243 70.2884H74.9427C68.9174 70.2884 70.3139 62.793 74.9427 58.9553L107.119 32.2785ZM110.526 34.6822L81.0438 63.1669H110.527L110.526 34.6822Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M164.211 113.018H96.2105V105.897H164.211V113.018Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M164.211 91.6538H96.2105V84.5323H164.211V91.6538Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M171.368 63.168L128.421 63.168V56.0465L171.368 56.0465V63.168Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M153.474 189.156L158.653 191.733C168.743 196.752 179.901 193.814 184.084 191.733L189.263 189.156V232L171.368 214.196L153.474 232V189.156ZM160.632 200.15V214.807L171.368 204.125L182.105 214.807V200.053C176.588 201.631 168.685 202.571 160.632 200.15Z"
      />
      <path d="M113.491 24C109.694 24 106.053 25.5006 103.368 28.1716L68.193 63.168C65.5083 65.839 64 69.4618 64 73.2392V173.55C64 181.417 70.4094 187.793 78.3158 187.793H149.891C144.979 184.121 141.648 182.782 139.158 177.111H74.9427V71.8977L113.491 34.6822H185.684V123.7C192.842 123.7 192.842 127.261 196.421 133.879V38.2429C196.421 30.3768 190.012 24 182.105 24H113.491Z" />
      <path d="M155.749 127.261H96.2105V134.382H145.81C148.672 131.477 152.031 129.058 155.749 127.261Z" />
      <path d="M136.295 152.186H96.2105V159.308H135.579C135.579 156.869 135.825 154.487 136.295 152.186Z" />
    </>,
  ], // Replace with your custom regular icon content
])

// Define your custom icon component
const GradeIcon: Icon = forwardRef((props, ref) => (
  <IconBase ref={ref} {...props} weights={customWeights} />
))

export default GradeIcon
