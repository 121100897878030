import { Card } from "@vesatogo/grass-core"
import classNames from "classnames"

export type SectionCardType = {
  title: string
  subtitle?: string
  icon?: any
  children?: any
  header?: any
  headerClassName?: string
  cardClassName?: string
  className?: string
}

export const SectionCard = ({
  title,
  subtitle,
  icon: Icon,
  children,
  header = undefined,
  headerClassName = "",
  cardClassName = "",
  className = "",
}: SectionCardType) => {
  return (
    <Card className={classNames("w-full", cardClassName)}>
      {(title || header || Icon) && (
        <>
          <h6
            className={classNames(
              children && "border-b-1 border-gray-300",
              "",
              headerClassName
            )}
          >
            <div className="text-green flex items-center justify-between font-500 text-base p-2 pb-0">
              <span className="flex items-center">
                {Icon && <Icon weight="fill" className="mr-1" />} {title}
              </span>
              {header}
            </div>
            <div
              className={classNames(
                `px-2 pb-2 text-sm text-gray-500`,
                Icon && "ml-5"
              )}
            >
              {subtitle}
            </div>
          </h6>
        </>
      )}
      {children && (
        <div className={classNames("p-4", className)}>{children}</div>
      )}
    </Card>
  )
}
