import { Disclosure } from "@headlessui/react"
import { CaretUp } from "@phosphor-icons/react"
import { Card, MenuDivider } from "@vesatogo/grass-core"
import { useState } from "react"

export type CollapsibleCardType = {
  children?: any
  title: string
  contentClassName?: string
  icon?: any
  header?: any
}

export const CollapsibleCard = ({
  children,
  title,
  contentClassName,
  icon: Icon,
  header,
}: CollapsibleCardType) => {
  const [caret, setCaret] = useState(true)
  return (
    <Card className="p-4">
      <Disclosure defaultOpen>
        <Disclosure.Button className="flex items-center justify-between w-full">
          <div className="flex items-center gap-1 w-full">
            {Icon && (
              <Icon size={20} className="text-green-500" weight="bold" />
            )}
            <div className="flex justify-between w-full">
              <div className="text-green-600 font-500 text-lg">{title}</div>
              <div className="px-4">{header}</div>
            </div>
          </div>
          <CaretUp
            size={18}
            className={`${
              caret && "rotate-180 selection:transform"
            }:rotate-180 selection:transform `}
            onClick={() => {
              setCaret(!caret)
            }}
          />
        </Disclosure.Button>

        <Disclosure.Panel>
          <MenuDivider></MenuDivider>
          <div className={contentClassName}>{children}</div>
        </Disclosure.Panel>
      </Disclosure>
    </Card>
  )
}
