import * as Yup from "yup"

export const vehicleModel = Yup.object().shape({
  registration_number: Yup.string().required(
    "Please enter Registration Number"
  ),
  vehicle_type: Yup.object().required("Please select Vehicle Type"),
  estimated_average: Yup.string().required("Please enter Estimated Average"),
  purchase_date: Yup.date().required("Please enter Purchase Date"),
})
