import { TradeTradeKindChoices } from "~/generated/graphql"

export const TradeKinds = {
  SaudaPavti: TradeTradeKindChoices.ProForma.toLowerCase(),
  Booking: TradeTradeKindChoices.Booking.toLowerCase(),
  HishobPatti: TradeTradeKindChoices.Final.toLowerCase(),
} as const

export const TradeKindsReadable = {
  [TradeKinds.SaudaPavti]: "Sauda Pavti",
  [TradeKinds.Booking]: "Booking Patti",
  [TradeKinds.HishobPatti]: "Hishob Patti",
}
