import React from 'react';
import { Marker, MarkerProps } from '@react-google-maps/api';

export type PinMarkerProps = MarkerProps & {
  position: google.maps.LatLng | google.maps.LatLngLiteral;
  title?: string;
  onClick?: () => void;
};

const PinMarker = ({
  position,
  title,
  onClick,
  ...otherProps
}: PinMarkerProps) => {
  return (
    <Marker
      onClick={onClick}
      animation={window?.google.maps.Animation.DROP}
      title={title}
      position={position}
      icon="https://cdn.vesatogo.com/images/pin-marker.png"
      {...otherProps}
    />
  );
};

PinMarker.defaultProps = {};

export default PinMarker;
