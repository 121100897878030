import { useState } from "react"

export const usePanImageHook = () => {
  const [panState, setPanState] = useState({
    dx: 0,
    dy: 0,
    zoom: 1,
    rotation: 0,
  })

  const resetAll = () => {
    setPanState(val => ({ ...val, dx: 0, dy: 0, zoom: 1, rotation: 0 }))
  }

  const zoomIn = () => {
    setPanState(val => ({ ...val, zoom: panState.zoom + 1 }))
  }

  const zoomOut = () => {
    if (panState.zoom - 1 < 0) return
    setPanState(prev => ({ ...prev, zoom: panState.zoom - 1 }))
  }

  const rotateLeft = () => {
    if (panState.rotation === -3) {
      setPanState(prev => ({ ...prev, rotation: 0 }))
    } else {
      setPanState(prev => ({ ...prev, rotation: panState.rotation - 1 }))
    }
  }

  const rotateRight = () => {
    if (panState.rotation === 3) {
      setPanState(prev => ({ ...prev, rotation: 0 }))
    } else {
      setPanState(prev => ({ ...prev, rotation: panState.rotation + 1 }))
    }
  }

  const pan = (dx: number, dy: number) => {
    setPanState(prev => ({ ...prev, dx: dx, dy: dy }))
  }

  return {
    panState,
    setPanState,
    zoomIn,
    zoomOut,
    rotateLeft,
    rotateRight,
    pan,
    resetAll,
  }
}
