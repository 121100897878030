import { Marker, useJsApiLoader } from "@react-google-maps/api"
import classNames from "classnames"
import React, { useEffect, useRef, useState } from "react"
import CustomDrawingManager from "~/components/Map/DrawingManager"
import Map from "~/components/Map/Map"
import PinMarker from "~/components/Map/PinMarker"
import { GeocodedAddressType, geoCoder } from "~/utils/map-helpers"

export type MapStyleConfig = {
  height: string
  width: string
}

type LocationMapProps = {
  center: google.maps.LatLng | google.maps.LatLngLiteral
  customMarker?: boolean
  onChange: (address: GeocodedAddressType) => void
  disabled: boolean
  mapStyle: MapStyleConfig
  children?: React.ReactNode
  className?: string
  drawingMode?: any[]
  onPolyComplete?: any
  zoom?: number
}

const libraries: any = ["drawing", "places", "visualization"]

const LocationMap = ({
  center,
  onChange,
  disabled,
  children,
  mapStyle,
  customMarker,
  className,
  onPolyComplete,
  drawingMode = ["polygon"],
  zoom = 7,
}: LocationMapProps) => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: import.meta.env.VITE_MAP_API || "",
    libraries,
  })
  const mapRef = useRef<any>(null)
  const [position, setPosition] = useState(center)

  useEffect(() => {
    setPosition(center)
  }, [center])

  function handleLoad(map) {
    mapRef.current = map
  }

  function handleZoomChanged() {
    if (!mapRef.current) return
    mapRef.current.panTo(position)
  }

  function handleDrag() {
    if (disabled) return
    if (!mapRef.current) return
    const newPos = mapRef.current.getCenter().toJSON()
    setPosition(newPos)
  }

  async function handleDragEnd() {
    if (disabled) return
    const newPos = mapRef.current.getCenter().toJSON()
    onChange?.((await geoCoder(newPos.lat, newPos.lng)) as any)
  }

  const renderMap = () => {
    return (
      <Map
        onLoad={handleLoad}
        onDrag={handleDrag}
        onDragEnd={handleDragEnd}
        zoom={zoom}
        id="map"
        center={center}
        disabled={disabled}
        className={classNames("mr-5 rounded shadow outline-none", className)}
        mapContainerStyle={mapStyle}
        onZoomChanged={handleZoomChanged}
      >
        {position?.lat &&
          position?.lng &&
          (customMarker && window?.google?.maps ? (
            <Marker
              position={position}
              icon={{
                url: "https://cdn.vesatogo.com/images/farm_selected1.png",
                // size: new window.google.maps.Size(45, 45),
                // scaledSize: new window.google.maps.Size(45, 45),
              }}
            />
          ) : (
            <PinMarker position={position} />
          ))}
        {children}
        {onPolyComplete && (
          <CustomDrawingManager
            onPolyComplete={onPolyComplete}
            drawingMode={drawingMode}
          />
        )}
      </Map>
    )
  }

  if (loadError) return <span>{loadError.message}</span>

  return isLoaded ? renderMap() : null
}

LocationMap.defaultProps = {}

export default LocationMap
