import { useForm, yupResolver } from "@mantine/form"
import {
  Button,
  Card,
  FormInput,
  FormQuerySelector,
  Modal,
  SpinnerOverlay,
  Toast,
} from "@vesatogo/grass-core"
import { pick } from "lodash-es"
import { useEffect } from "react"
import toast from "react-hot-toast"
import { useSearchParams } from "react-router-dom"
import { AddCompleteType } from "~/components/Admin/AddCompleteType"
import { FileUpload } from "~/components/dropzone/FileUploader/FileUploader"
import {
  Commodity_Constraint,
  Commodity_Update_Column,
  Crop_Constraint,
  Crop_Update_Column,
  Media_Constraint,
  Media_Update_Column,
  useAllCropsSelectorQuery,
  useCommodityDetailQuery,
  useInsertCommodityMutation,
} from "~/generated/graphql"
import { commodityModel } from "~/models/commodity"
import { getFileUploadProps, getInputProps } from "~/utils/form-helpers"

const INITIAL_STATE = {
  photo: [],
}

export const AddCommodityModal = ({ onAddComplete }: AddCompleteType) => {
  const [params, setParams] = useSearchParams()
  const commodityId = params?.get("id") || ""

  const form = useForm<any>({
    initialValues: INITIAL_STATE,
    validate: yupResolver(commodityModel),
    validateInputOnChange: true,
    validateInputOnBlur: true,
    clearInputErrorOnChange: false,
  })

  const state = form.values
  const setState = form.setValues

  const [{ data, fetching }] = useCommodityDetailQuery({
    variables: {
      id: commodityId,
    },
    pause: commodityId === "new" || !Boolean(commodityId),
  })

  const [{ fetching: insertFetching }, insertCommodity] =
    useInsertCommodityMutation()

  const onClose = () => {
    setParams({})
    form?.reset
  }

  useEffect(() => {
    setState(val => ({
      ...val,
      name: data?.commodity_by_pk?.name,
      codename: data?.commodity_by_pk?.codename,
      photo: data?.commodity_by_pk?.image ? [data?.commodity_by_pk?.image] : [],
      crop: data?.commodity_by_pk?.crop,
    }))
  }, [data])

  const onSubmit = async () => {
    const { errors, hasErrors } = form?.validate()
    if (hasErrors) {
      return toast.custom(
        <Toast
          intent="danger"
          title={"Please resolve all errors before proceeding!"}
        />
      )
    }
    const { data: insertData, error } = await insertCommodity({
      object: {
        //Todo: Please remove
        name: state?.name,
        codename: state?.codename,
        crop: {
          data: {
            codename: state?.crop?.codename,
            id: state?.crop?.id,
            name: state?.crop?.name,
          },
          on_conflict: {
            constraint: Crop_Constraint.CropPkey,
            update_columns: [
              Crop_Update_Column.Id,
              Crop_Update_Column.Name,
              Crop_Update_Column.Codename,
            ],
          },
        },
        image: state?.photo[0]
          ? {
              data: {
                ...pick(
                  state?.photo[0],
                  "kind",
                  "url",
                  "key",
                  "is_encrypted",
                  "id"
                ),
              },
              on_conflict: {
                constraint: Media_Constraint.MediaPkey,
                update_columns: [Media_Update_Column.Url],
              },
            }
          : undefined,
        id: commodityId !== "new" ? commodityId : undefined,
      },
      on_conflict: {
        constraint: Commodity_Constraint.CommodityPkey,
        update_columns: [
          Commodity_Update_Column.Codename,
          Commodity_Update_Column.Name,
          Commodity_Update_Column.ImageId,
          Commodity_Update_Column.CropId,
        ],
      },
    })
    if (error) {
      return toast.custom(
        <Toast
          title={"Something went wrong! " + error?.message}
          intent="danger"
        />
      )
    }
    if (insertData) {
      onClose()
      toast.custom(
        <Toast
          title={
            commodityId === "new"
              ? "Commodity added successfully!"
              : "Commodity updated successfully!"
          }
        />
      )

      onAddComplete({ requestPolicy: "network-only" })
    }
  }

  return (
    <Modal
      bodyClassName="overflow-visible w-[60%]"
      title="Commodity"
      contentClassName="bg-gray-100"
      footer={
        <div className="flex justify-end gap-3">
          <Button size="sm" onClick={onClose} variant="outline">
            Cancel
          </Button>
          <Button loading={insertFetching} size="sm" onClick={onSubmit}>
            Save
          </Button>
        </div>
      }
      isOpen={Boolean(params.get("id"))}
      onClose={onClose}
    >
      <SpinnerOverlay show={fetching} />
      <Card className="p-4 flex gap-20">
        <div>
          <FileUpload {...getFileUploadProps(form, "photo", 1)} />
        </div>
        <div className="grid grid-cols-2 gap-4 w-full">
          <FormInput
            label="Commodity Name"
            required
            placeholder="Enter Name"
            {...getInputProps(form, "name")}
          />
          <FormInput
            label="Code Name"
            placeholder="Code Name"
            {...getInputProps(form, "codename")}
          />
          <FormQuerySelector
            label="Crop Name"
            dataHook={useAllCropsSelectorQuery}
            required
            serverSideQuery
            {...getInputProps(form, "crop")}
          />
        </div>
      </Card>
    </Modal>
  )
}
