import { IdentificationCard, Stamp, Trash } from "@phosphor-icons/react"
import { openConfirmDialog } from "@vesatogo/grass-confirm-modal"
import {
  Button,
  FormInput,
  FormQuerySelector,
  Toast,
} from "@vesatogo/grass-core"
import { FormDateInput } from "@vesatogo/grass-dates"
import { set } from "lodash-es"
import toast from "react-hot-toast"
import { OperationContext } from "urql"
import { FileUpload } from "~/components/dropzone/FileUploader/FileUploader"
import {
  useDeleteAttachmentMutation,
  useDeleteDocumentMutation,
  useGetDocumentKindsQuery,
} from "~/generated/graphql"
import { getFileUploadProps, getInputProps } from "~/utils/form-helpers"

type CertificatesListProps = {
  certificates: any[]
  onChange?: (certificates: any[]) => void
  disabled?: any
  documentKindAccessor?: string
  refetch?: (opts?: Partial<OperationContext>) => void
  form?: any
  plotIdx
}
const CertificatesList = ({
  certificates,
  disabled,
  onChange,
  form,
  documentKindAccessor = "kind",
  plotIdx,
  refetch,
}: CertificatesListProps) => {
  const [, deleteDocument] = useDeleteDocumentMutation()
  const [, deleteAttachment] = useDeleteAttachmentMutation()

  const { values: state, setValues: setState } = form
  return (
    <>
      {certificates?.map((certificate, idx) => {
        return (
          <div>
            <div className="flex justify-between gap-4 mb-4 items-center">
              <div />
              <Button
                onClick={() => {
                  const onDelete = async () => {
                    const { error } = await deleteDocument({
                      id: certificate?.id,
                    })
                    if (error) {
                      return toast.custom(
                        <Toast
                          intent="danger"
                          title={"Something went wrong! " + error.message}
                        />
                      )
                    } else {
                      refetch?.({
                        requestPolicy: "network-only",
                      })
                      toast.custom(<Toast title={"Certificate deleted!"} />)
                    }
                  }
                  if (certificate?.id) {
                    openConfirmDialog({
                      isOpen: true,
                      onSubmit: onDelete,
                      confirmationButtonText: "Delete",
                      message:
                        "Are you sure you want to delete this certificate?",
                    })
                  } else {
                    const stateCopy = structuredClone(state)
                    stateCopy.plots?.[plotIdx].documents.splice(idx, 1)
                    setState(stateCopy)
                  }
                }}
                variant="outline"
                size="sm"
                intent="danger"
                leftIcon={<Trash className="!mr-0" />}
              />
            </div>
            <div
              key={certificate.id || `plots.${plotIdx}.documents.${idx}`}
              className="flex gap-4"
            >
              <div className="h-max">
                <FileUpload
                  className="uploadBankDoc"
                  {...getFileUploadProps(
                    form,
                    `plots.${plotIdx}.documents.${idx}.attachments`,
                    1,
                     true
                  )}
                  onDelete={e => {
                    const onDelete = async () => {
                      const { error } = await deleteAttachment({
                        id: e.id,
                      })
                      if (error) {
                        return toast.custom(
                          <Toast
                            intent="danger"
                            title={"Something went wrong! " + error.message}
                          />
                        )
                      } else {
                        refetch?.({
                          requestPolicy: "network-only",
                        })
                        toast.custom(<Toast title={"Certificate deleted!"} />)
                      }
                    }
                    if (e.id) {
                      openConfirmDialog({
                        isOpen: true,
                        onSubmit: () => onDelete(),
                        confirmationButtonText: "Delete",
                        message:
                          "Are you sure you want to delete this attachment?",
                      })
                    }
                    refetch?.({
                      requestPolicy: "network-only",
                    })
                  }}
                  appName="mandix"
                />
              </div>
              <div className="grid grid-cols-3 gap-4 h-max">
                <FormQuerySelector
                  required
                  label="Certificate Type"
                  {...getInputProps(
                    form,
                    `plots.${plotIdx}.documents.${idx}.kind`
                  )}
                  dataHook={useGetDocumentKindsQuery}
                  isOptionDisabled={(option: any) => {
                    return certificates?.filter(item => {
                      if (
                        parseInt(item?.[documentKindAccessor]?.id) ===
                        option?.id
                      ) {
                        return true
                      }
                    })[0]
                  }}
                />
                <FormInput
                  required
                  data-testid="inputCertificateNumber"
                  label="Certificate Number"
                  leftElement={<IdentificationCard />}
                  inputProps={{
                    value: certificate?.id_number ? certificate?.id_number : "",
                    disabled,
                    onChange: e => {
                      const certificatesCopy = structuredClone(certificates)
                      set(
                        certificatesCopy,
                        [idx, "id_number"],
                        e?.target ? e.target.value : e
                      )
                      onChange?.(certificatesCopy)
                    },
                  }}
                />
                <FormInput
                  className={"hidden"}
                  label="Issuing Authority"
                  leftElement={<Stamp />}
                  {...getInputProps(
                    form,
                    `plots.${plotIdx}.documents.${idx}.created_by.full_name`
                  )}
                />
                <FormDateInput
                  label="Issued On"
                  {...getInputProps(
                    form,
                    `plots.${plotIdx}.documents.${idx}.issued_on`
                  )}
                />
                <FormDateInput
                  label="Valid Till"
                  {...getInputProps(
                    form,
                    `plots.${plotIdx}.documents.${idx}.valid_till`
                  )}
                />
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}

export default CertificatesList
